/* eslint-disable complexity */
/* eslint-disable max-lines */
import { BackHeader, Button, FloatingInput, KeyboardAvoidView, Loading, UnderlineText } from '@components';
import { authVar, i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CreateUserScreenNavigationProps } from '@routes';
import { StatusBar } from 'expo-status-bar';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { SafeAreaView, View } from 'react-native';
import { Text } from 'react-native-paper';
import Dialogue from 'src/components/Dialogue/Dialogue';
import { tw } from 'src/core/tw';
import { useCreateUserHook } from './hooks';

const CreateUserScreen = () => {
  const navigation = useNavigation<CreateUserScreenNavigationProps>();
  const [showDialogue, setShowDialogue] = useState(false);

  const { colors } = useBanditTheme();

  const { onSubmitProfile, isEditMode, loading, control, isValid, errors, handleSubmit } = useCreateUserHook();

  const deleteAccount = () => {
    setShowDialogue(true);
    return;
  };

  const goBack = () => {
    if (!isEditMode) {
      authVar({
        token: null,
        loggedIn: false,
        idpToken: null,
        isShowTutorial: false,
        loginType: null,
        refreshToken: null,
      });
    }
    navigation.goBack();
  };

  return (
    <KeyboardAvoidView>
      {showDialogue && (
        <Dialogue
          title={i18n.t('deleteAccount_title')}
          closeText="OK"
          message={i18n.t('deleteAccount_description')}
          handleClose={() => setShowDialogue(false)}
        />
      )}
      <SafeAreaView style={tw`bg-white`} />
      <>
        <StatusBar style="light" translucent />
        <BackHeader title={isEditMode ? i18n.t('editUser_title') : i18n.t('createUser_title')} goBack={goBack} />
        {loading ? (
          <View
            style={[
              tw`flex-1`,
              {
                backgroundColor: colors.secondary_cosmos_tint,
              },
            ]}
          >
            <Loading />
          </View>
        ) : (
          <View
            style={[
              tw`flex-1`,
              {
                backgroundColor: colors.secondary_cosmos_tint,
              },
            ]}
          >
            <View style={[tw`flex mx-4 mt-4 border-[1.5px] rounded-xl overflow-hidden bg-transparent`]}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <View>
                      <FloatingInput
                        label={i18n.t('createUser_form_firstName')}
                        placeholder="First Name"
                        autoComplete="name-given"
                        textContentType="givenName"
                        testID="CREATE.USER.FIRSTNAME"
                        error={errors.firstName}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value || ''}
                      />
                    </View>
                  );
                }}
                name="firstName"
              />
              <View style={tw`border-b-[1.5px] border-black`} />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <FloatingInput
                      testID="CREATE.USER.LASTNAME"
                      autoComplete="name-family"
                      textContentType="familyName"
                      placeholder="Last Name"
                      keyboardType="default"
                      label={i18n.t('createUser_form_lastName')}
                      error={errors.lastName}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value || ''}
                    />
                  </View>
                )}
                name="lastName"
              />
              <View style={tw`border-b-[1.5px] border-black`} />
              <View style={tw`flex flex-row`}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`w-1/2`}>
                      <FloatingInput
                        label={i18n.t('createUser_form_mobile')}
                        autoComplete="tel"
                        textContentType="telephoneNumber"
                        testID="CREATE.USER.MOBILE"
                        containerStyles={tw`w-1/2 border-r-[1.5px] border-black`}
                        placeholder="MOBILE"
                        keyboardType="phone-pad"
                        onBlur={onBlur}
                        onChangeText={(e) => {
                          if (e.length <= 12) onChange(e);
                        }}
                        value={value || ''}
                        error={errors.mobile}
                      />
                    </View>
                  )}
                  name="mobile"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`w-1/2`}>
                      <FloatingInput
                        label={i18n.t('createUser_form_age')}
                        testID="CREATE.USER.AGE"
                        style={tw`w-1/2`}
                        placeholder="Age"
                        keyboardType="number-pad"
                        error={errors.age}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value || ''}
                      />
                    </View>
                  )}
                  name="age"
                />
              </View>
              <View style={tw`border-b-[1.5px] border-black`} />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <FloatingInput
                      label={i18n.t('createUser_form_email')}
                      autoComplete="email"
                      textContentType="emailAddress"
                      testID="CREATE.USER.EMAIL"
                      placeholder="Email"
                      keyboardType="email-address"
                      error={errors.email}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value || ''}
                    />
                  </View>
                )}
                name="email"
              />
              <View style={tw`border-b-[1.5px] border-black`} />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <FloatingInput
                      label={i18n.t('createUser_form_address')}
                      autoComplete="street-address"
                      testID="CREATE.USER.ADDRESS"
                      textContentType="streetAddressLine1"
                      placeholder="Street Address"
                      keyboardType="default"
                      error={errors.address}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value || ''}
                    />
                  </View>
                )}
                name="address"
              />
              <View style={tw`border-b-[1.5px] border-black`} />
              <View style={tw`flex flex-row`}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`w-1/2`}>
                      <FloatingInput
                        testID="CREATE.USER.ZIPCODE"
                        label={i18n.t('createUser_form_zipCode')}
                        autoComplete="postal-code"
                        textContentType="postalCode"
                        containerStyles={tw`w-1/2 border-r-[1.5px] border-black`}
                        placeholder="Zip Code"
                        maxLength={6}
                        keyboardType="number-pad"
                        onBlur={onBlur}
                        error={errors.zipCode}
                        onChangeText={(e) => {
                          if (e.length <= 4) onChange(e);
                        }}
                        value={value || ''}
                      />
                    </View>
                  )}
                  name="zipCode"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`w-1/2`}>
                      <FloatingInput
                        label={i18n.t('createUser_form_city')}
                        testID="CREATE.USER.CITY"
                        textContentType="addressCity"
                        style={tw`w-1/2`}
                        placeholder="City"
                        keyboardType="default"
                        error={errors.city}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value || ''}
                      />
                    </View>
                  )}
                  name="city"
                />
              </View>
            </View>
            <View style={tw`w-full p-4`}>
              <Button
                textVariant="labelLarge"
                customTextStyle={tw`text-white m-1`}
                title={isEditMode ? i18n.t('common_update') : i18n.t('common_next')}
                customStyle={tw`border border-black`}
                onPress={handleSubmit(onSubmitProfile)}
                testID="CREATE.SUBMIT"
                disabled={!isValid}
              />
            </View>

            {isEditMode && (
              <View style={tw`pt-20`}>
                <UnderlineText style={tw`self-center`} onPress={deleteAccount}>
                  <Text style={tw`text-center text-black`}>Delete Account</Text>
                </UnderlineText>
              </View>
            )}
          </View>
        )}
      </>
    </KeyboardAvoidView>
  );
};

export default CreateUserScreen;
