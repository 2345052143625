import { useBanditTheme } from '@core';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IPurchangeButtonProps {
  actionText: string;
  oldPrice?: number;
  price: number;
  currency: string;
  variant?: 'black' | 'outlined';
  disabled?: boolean;
  onPress?: () => void;
}

const PurchaseButton = ({
  actionText,
  oldPrice,
  price,
  currency,
  variant = 'black',
  disabled = false,
  onPress,
}: IPurchangeButtonProps) => {
  const theme = useBanditTheme();

  const blackDisabled = disabled && variant === 'black';

  const outlinedDisabled = disabled && variant === 'outlined';
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={tw.style('btn px-0', {
        'bg-black': variant === 'black',
        'bg-[#C2C2C2] border-black/0': blackDisabled,
        'border-[#C2C2C2]': outlinedDisabled,
      })}
      disabled={disabled}
      onPress={onPress}
    >
      <Text
        style={[
          tw.style('text-sm', { 'text-white': variant === 'black', 'opacity-20': outlinedDisabled }),
          { fontFamily: 'TheFuture-Medium' },
        ]}
      >
        {actionText}
      </Text>
      <View style={tw`flex flex-row mt-0.5 gap-x-2`}>
        <Text
          style={[
            tw.style('text-xl font-medium leading-6', {
              'text-white': variant === 'black',
              'opacity-20': outlinedDisabled,
            }),
            { fontFamily: 'TheFuture-Medium' },
          ]}
        >
          {`${price}${currency}`}
        </Text>
        {oldPrice && (
          <Text
            style={[
              tw.style('text-xl leading-6 line-through', {
                'text-white': variant === 'black',
                'opacity-20': outlinedDisabled,
              }),
              { fontFamily: 'TheFuture-Regular', textDecorationColor: theme.colors.secondary_ember },
            ]}
          >
            {`${oldPrice}${currency}`}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default PurchaseButton;
