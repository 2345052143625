import { useBanditTheme } from '@core';
import { useEffect, useState } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { tw } from 'src/core/tw';
import useCreateBrandVotes from '../../hooks/useCreateBrandVotes/useCreateBrandVotes';
import { EndOfFeedBrandItemProps } from '../../model';
import { useEndOfFeedScreenContext } from '../../Providers/EndOfFeedScreenProvider/EndOfFeedScreenProvider';

const EndOfFeedBrandItem = ({ brand }: EndOfFeedBrandItemProps) => {
  const { handleCreateBrandVotes } = useCreateBrandVotes();
  const { colors } = useBanditTheme();
  const [imageSize, setImageSize] = useState({ width: 1, height: 1 });
  const { selectedBrands, setSelectedBrands } = useEndOfFeedScreenContext();
  const isSelected = !!selectedBrands.find((item) => item.logoUrl === brand.logoUrl);

  useEffect(() => {
    Image.getSize(brand.logoUrl, (width, height) => {
      setImageSize({ width, height });
    });
  }, [brand.logoUrl]);

  const clickHandler = () => {
    const tempBrands = [...selectedBrands];
    tempBrands.push(brand);
    setSelectedBrands(tempBrands);

    if (brand?.name) {
      handleCreateBrandVotes({ brandNames: [brand.name] });
    }
  };

  return (
    <TouchableOpacity
      key={brand.logoUrl}
      style={[
        tw`bg-white border-black p-7.5 py-0 mr-2.5 mb-3.5 border-[1.5px] rounded-full overflow-hidden h-18 justify-center`,
        isSelected && { backgroundColor: colors.secondary_fuschia_tint },
      ]}
      onPress={clickHandler}
      disabled={isSelected}
    >
      <Image
        accessibilityRole="image"
        source={{ uri: brand.logoUrl }}
        style={[tw`h-[${imageSize.height}px] max-w-48 max-h-18`, { aspectRatio: imageSize.width / imageSize.height }]}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
};

export default EndOfFeedBrandItem;
