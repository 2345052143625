import { gql } from '@apollo/client';

export const GET_SEARCH_DEFAULT_DATA = gql`
  query getSearchDefaultScreen {
    categories(limit: 10) {
      id
      name
    }
    producers(limit: 5) {
      logoUrl
      organizationName
    }
    tags(limit: 10) {
      name
    }
  }
`;

export const GET_SEARCH_RESULT_DATA = gql`
  query getSearchData($searchKeyWord: String!) {
    tags(limit: 10, where: { name: { _ilike: $searchKeyWord } }) {
      name
    }
    categories(
      distinctOn: id
      limit: 10
      where: {
        _or: [
          { name: { _ilike: $searchKeyWord } }
          {
            productTypes: {
              disabled: { _eq: false }
              status: { _neq: "OutOfStock" }
              producer: { kycApproved: { _eq: true } }
              _or: [
                { name: { _ilike: $searchKeyWord } }
                { tags: { name: { _ilike: $searchKeyWord } } }
                { brandName: { _ilike: $searchKeyWord } }
                { producer: { organizationName: { _ilike: $searchKeyWord } } }
              ]
            }
          }
        ]
      }
    ) {
      id
      name
    }
    producers(limit: 10, where: { organizationName: { _ilike: $searchKeyWord } }) {
      organizationName
      logoUrl
    }
    productTypes(
      distinctOn: id
      limit: 10
      where: {
        disabled: { _eq: false }
        status: { _neq: "OutOfStock" }
        producer: { kycApproved: { _eq: true } }
        _or: [
          { name: { _ilike: $searchKeyWord } }
          { tags: { name: { _ilike: $searchKeyWord } } }
          { brandName: { _ilike: $searchKeyWord } }
          { producer: { organizationName: { _ilike: $searchKeyWord } } }
        ]
      }
    ) {
      soloPrice
      groupPrice
      name
      id
      imageUrls {
        name
        carouselUrl
        originalUrl
        splitUrl
        feedUrl
      }
      producer {
        organizationName
        logoUrl
        description
      }
      banditCashDiscount
      brandName
      currency
      activeGroupOrders {
        id
        numberOfMembers
        status
        createdAt
        expiredDate
      }
      groupSize
    }
  }
`;
