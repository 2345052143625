import { create } from 'zustand';

interface IUser {
  firstName: string | null;
  id: string | null;
  lastName: string | null;
  photoUrl: string | null;
  address: string | null;
  age: number | null;
  city: string | null;
  email: string | null;
  interests: string[] | null;
  phoneNumber: string | null;
  zipCode: string | null;
  coinAmount?: number;
}

export const initUserStoreStates: IUser = {
  firstName: null,
  id: null,
  lastName: null,
  photoUrl: null,
  address: null,
  age: 0,
  city: null,
  email: null,
  interests: null,
  phoneNumber: null,
  zipCode: null,
  coinAmount: 0,
};

export type UseUserStore = {
  user: IUser;
  resetAllState: () => void;
  setPhotoUrl: (url: string) => void;
  setUser: (user: IUser) => void;
  setUserInterest: (interest: string[]) => void;
  setCoinAmount: (amount: number) => void;
};

const useUserStore = create<UseUserStore>((set) => ({
  user: initUserStoreStates,
  resetAllState: () => set({ user: initUserStoreStates }),
  setPhotoUrl: (url: string) => set((state) => ({ user: { ...state.user, photoUrl: url } })),
  setUserInterest: (interest: string[]) => set((state) => ({ user: { ...state.user, interests: interest } })),
  setUser: (user: IUser) => set((state) => ({ user: { ...state.user, ...user } })),
  setCoinAmount: (amount: number) => set((state) => ({ user: { ...state.user, coinAmount: amount } })),
}));

export default useUserStore;
