import { i18n, useBanditTheme } from '@core';
import { Down, Up } from '@svgIcons';
import { useWindowDimensions, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Divider, List } from 'react-native-paper';
import { tw } from 'src/core/tw';
import useOrderList from '../../hooks/useOrderList/useOrderList';
import { OrderListProps } from '../../model';

const OrderList = ({ orders, handleOrderSelected }: OrderListProps) => {
  const { colors } = useBanditTheme();

  const { height } = useWindowDimensions();

  const { isExpanded, titleText, handleTitleClick, handleItemClick } = useOrderList({ handleOrderSelected });

  return (
    <View
      style={[
        tw`border-[1.5px] border-black rounded-lg w-full absolute z-10 overflow-hidden bg-white`,
        isExpanded && { maxHeight: height / 1.5 },
      ]}
    >
      <List.Accordion
        style={tw`pl-2.5 pr-1.5	py-1`}
        title={titleText}
        titleStyle={[tw`text-black text-sm p-0`, { fontFamily: 'TheFuture-Regular' }]}
        right={(props) => <View style={tw`w-4 h-4`}>{isExpanded ? <Up /> : <Down />}</View>}
        expanded={isExpanded}
        onPress={handleTitleClick}
      >
        <Divider
          style={tw`bg-[${colors.gray_300}]`}
          accessibilityLabelledBy={undefined}
          accessibilityLanguage={undefined}
        />
        <ScrollView style={{ maxHeight: height / 1.5 - 50 }}>
          {orders.map((item) => {
            const orderTitle = `${item?.productType?.name} (order ${item.orderRef})`;

            return (
              <List.Item
                key={item.orderId}
                title={orderTitle}
                accessibilityLabelledBy={orderTitle}
                accessibilityLanguage={i18n.locale}
                titleStyle={[
                  tw`text-sm leading-8`,
                  { fontFamily: 'TheFuture-Regular', color: colors.primary_black_300 },
                ]}
                style={tw`px-0.5 py-1.5 bg-[${titleText.includes(orderTitle) ? colors.primary : '#fff'}]`}
                onPress={() => handleItemClick(item)}
              />
            );
          })}
        </ScrollView>
      </List.Accordion>
    </View>
  );
};

export default OrderList;
