import { ProductCard } from '@components';
import { MasonryFlashList } from '@shopify/flash-list';
import { useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, SafeAreaView, ScrollView } from 'react-native';
import { tw } from 'src/core/tw';
import EndOfFeedScreen from '../../../EndOfFeedScreen/EndOfFeedScreen';
import { IDeals } from '../../model';

interface IGridScreenProps {
  products: IDeals[];
  onPullToRefresh: () => void;
}

const END_OF_SCREEN_OFFSET = 5;

const renderItem = (item: IDeals, index: number) => {
  const brand = item?.productType?.brandName || item.producer.organizationName;

  return <ProductCard product={item} key={index} brand={brand} />;
};

const GridScreen = ({ products, onPullToRefresh }: IGridScreenProps) => {
  const [isEndReached, setIsEndReached] = useState(false);
  const masonaryRef = useRef<ScrollView>();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <MasonryFlashList
        data={products}
        keyExtractor={(item: IDeals): string => item.id}
        numColumns={2}
        showsVerticalScrollIndicator={false}
        refreshing={false}
        contentContainerStyle={tw`p-1.5 bg-bandit_bg_primary`}
        estimatedItemSize={400}
        renderItem={({ item, index }) => renderItem(item, index)}
        ListFooterComponent={
          <EndOfFeedScreen
            scrollToTop={() => masonaryRef.current?.scrollTo({ x: 0, animated: true })}
            isEndReached={isEndReached}
          />
        }
        onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
          const isAtEnd =
            event.nativeEvent.contentSize.height -
              (event.nativeEvent.contentOffset.y + event.nativeEvent.layoutMeasurement.height) <=
            END_OF_SCREEN_OFFSET;

          if (isEndReached !== isAtEnd) {
            setIsEndReached(isAtEnd);
          }
        }}
        onRefresh={onPullToRefresh}
      />
    </SafeAreaView>
  );
};

export default GridScreen;
