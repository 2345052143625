/* eslint-disable complexity */
import { IImage, ProductDetails, ProductDetailsResponse } from '../types';

export const DESCRIPTION_LIMIT = 60;

export const PRODUCT_ID = '5c4f4d29-6e9d-5fe4-ba7c-ff22742c135b';

export const DEFAULT_CURRENCY = 'NOK';

export const START_GROUP_DISCOUNT = 20;

export const GROUP_POLL_INTERVAL = 10000;

export const NAME_LIMIT = 50;

export const NAME_ENDING = '...';

const mockImages = [
  'https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
  'https://images.unsplash.com/photo-1560159006-de4e5ffcfd47?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
  'https://images.unsplash.com/photo-1543508282-6319a3e2621f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80',
  'https://images.unsplash.com/photo-1615109255406-2a4354fc6b3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
  'https://images.unsplash.com/photo-1460353581641-37baddab0fa2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
];

const mockImagesArray: IImage[] = mockImages.map((image, index) => ({ name: `nike ${index + 1}`, url: image }));

const imageUrls = mockImages.map((image, index) => ({
  name: `nike ${index + 1}`,
  carouselUrl: image,
  originalUrl: image,
  splitUrl: image,
}));

const producerImageUrls = mockImages.map((image, index) => ({
  name: `nike ${index + 1}`,
  carouselUrl: image,
  originalUrl: image,
}));

const generateGroup = (numberOfGroups: number, hasUserId = false) => {
  const joinAt = new Date().toISOString();

  return [...Array(numberOfGroups).keys()].map((i) => ({
    id: `${i}`,
    creator: {
      ...(hasUserId ? { userId: `${i + 1}` } : {}),
      photoUrl:
        'https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      name: `Michael B${i}`,
      joinAt,
      city: 'Oslo',
    },
    numberOfMembers: 5,
    members: [
      {
        ...(hasUserId ? { userId: `${i + 2}` } : {}),
        name: `Michael B${i}`,
        photoUrl:
          'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        joinAt,
        city: 'Oslo',
      },
      {
        ...(hasUserId ? { userId: `${i + 3}` } : {}),
        name: 'Espen K',
        photoUrl:
          'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        joinAt,
        city: 'Oslo',
      },
      {
        ...(hasUserId ? { userId: `${i + 4}` } : {}),
        name: 'Lisa B',
        photoUrl:
          'https://images.unsplash.com/photo-1521119989659-a83eee488004?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=723&q=80',
        joinAt,
        city: 'Oslo',
      },
      {
        ...(hasUserId ? { userId: `${i + 5}` } : {}),
        name: 'Mary J',
        photoUrl:
          'https://images.unsplash.com/photo-1521119989659-a83eee488004?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=723&q=80',
        joinAt,
        city: 'Oslo',
      },
      {
        ...(hasUserId ? { userId: `${i + 6}` } : {}),
        name: 'Kelly C',
        photoUrl:
          'https://images.unsplash.com/photo-1521119989659-a83eee488004?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=723&q=80',
        joinAt,
        city: 'Oslo',
      },
    ],
    expiredDate: new Date(2023, 0, 10).toISOString().replace('Z', ''),
  }));
};

export const MOCK_PRODUCT_RESPONSE: ProductDetailsResponse = {
  id: PRODUCT_ID,
  videoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  brandName: 'Nike',
  name: 'Air Max One Plus',
  producer: {
    email: '',
    organizationName: 'Nike',
    description:
      'NIKE, Inc. is a team comprised of the Nike, Jordan and Converse brands driven by a shared purpose to leave an enduring impact.',
    imageUrls: producerImageUrls,
  },

  imageUrls: [
    {
      carouselUrl: '',
      originalUrl: '',
      splitUrl: '',
      feedUrl: '',
      name: '',
    },
  ],
  groupPrice: 999,
  soloPrice: 1299,
  currency: 'USD',
  description:
    'Enter the Nike Air Max INTRLK. Stacked Air cushioning turns the page in comfort with increased responsiveness and the perfect amount of bounce. Lightweight, easy-to-style materials stand up to wear and tear. Finishing it off, the Waffle-inspired outsole adds true Nike zest.',
  tags: [{ name: 'Fashion' }, { name: 'Footwear' }, { name: "Women's shoes" }],
  products: [
    {
      id: '1',
      inventoryStock: 0,
      options: [
        { optionName: 'Size', optionValue: '36' },
        { optionName: 'Color', optionValue: '#000000' },
      ],
    },
    {
      id: '2',
      inventoryStock: 1,
      options: [
        { optionName: 'Size', optionValue: '36' },
        { optionName: 'Color', optionValue: '#F5F4F4' },
      ],
    },
    {
      id: '3',
      inventoryStock: 1,
      options: [
        { optionName: 'Size', optionValue: '37' },
        { optionName: 'Color', optionValue: '#000000' },
      ],
    },
    {
      id: '4',
      inventoryStock: 0,
      options: [
        { optionName: 'Size', optionValue: '37' },
        { optionName: 'Color', optionValue: '#F5F4F4' },
      ],
    },
  ],
  faqs: [
    {
      question: 'How do i find the right size and fit?',
      answer:
        "When it comes to performance, fit matters. We'll help you find the right shoes and gear in the right size so you can perform your best.",
    },
    {
      question: "What are Nike's shipping options?",
      answer:
        "Nike Members get free standard shipping (and discounted expedited shipping) on every order-just sign in during checkout and you'll automatically get free shipping.",
    },
    {
      question: "What is Nike's return policy?",
      answer:
        'All purchases made on Nike.com, in any of the Nike apps, or at a Nike store have a 60-day return period. Go ahead and test out your Nike or Converse purchase to make sure it works for you.',
    },
    {
      question: 'Where is my Nike order?',
      answer:
        "After your order ships, you can track your shipment(s) and see which carrier is delivering your package(s) anytime at nike.com/orders. And please note, if you order multiple items, you may get multiple deliveries. For each shipment, we'll send you a shipping confirmation email with the carrier tracking number and a link to track the shipment.",
    },
  ],
  groupSize: 10,
  banditCashDiscount: false,
  hasOption: true,
};

export const MOCK_PRODUCT: ProductDetails = {
  groupShareText: 'Join my group to get a discount',
  ...MOCK_PRODUCT_RESPONSE,
  variants: [
    {
      name: 'Size',
      options: [
        { value: '36', parentName: 'Size' },
        { value: '37', parentName: 'Size' },
      ],
    },
    {
      name: 'Color',
      options: [
        { value: '#000000', parentName: 'Color' },
        { value: '#F5F4F4', parentName: 'Color' },
      ],
    },
  ],
  productImages: { carouselImages: mockImagesArray, originalImages: mockImagesArray, splitUrlImages: mockImagesArray },
  producerImages: { carouselImages: mockImagesArray, originalImages: mockImagesArray, splitUrlImages: mockImagesArray },
};

export const MOCK_ACTIVE_GROUP_ORDERS = generateGroup(3);

export const MOCK_MY_ACTIVE_GROUP_ORDERS = generateGroup(1, true);
