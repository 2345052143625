import { useBanditTheme } from '@core';
import { DealsLogo, FlameLogo, PlayLogo, Profile, SearchIcon } from '@svgIcons';
import { View } from 'react-native';
import { tw } from 'src/core/tw';

interface TabIconProps {
  focused: boolean;
  name: 'video' | 'deal' | 'search' | 'profile' | 'group';
}

const icon = {
  video: (focused: boolean) => <PlayLogo color={focused ? 'black' : 'gray'} width={24} height={24} />,
  deal: (focused: boolean) => <DealsLogo color={focused ? 'black' : 'gray'} width={24} height={24} />,
  search: (focused: boolean) => <SearchIcon color={focused ? 'black' : 'gray'} width={24} height={24} />,
  group: (focused: boolean) => <FlameLogo color={focused ? 'black' : 'gray'} width={24} height={24} />,
  profile: (focused: boolean) => <Profile color={focused ? 'black' : 'gray'} width={24} height={20} />,
};

const TabIcon = ({ focused, name }: TabIconProps) => {
  const { colors } = useBanditTheme();

  return (
    <View
      style={
        focused
          ? tw.style('rounded-full w-6 h-6 justify-center items-center', {
              backgroundColor: colors.primary,
            })
          : {}
      }
    >
      {icon[name](focused)}
    </View>
  );
};

export default TabIcon;
