import AppLogo from '@assets/icon.png';
import { Button } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CreateUserScreenNavigationProps } from '@routes';
import { CloseIcon } from '@svgIcons';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { useAppStoreUrl } from 'src/hooks/useAppStoreUrl';
import LoginOptions from './components/LoginOptions';

WebBrowser.maybeCompleteAuthSession();

const AppView = () => (
  <>
    <Text variant="displayLarge" style={tw`text-center text-3xl py-4`}>
      {i18n.t('login_title')}
    </Text>
    <Text style={tw`text-center`} variant="labelSmall">
      {i18n.t('login_description')}
    </Text>
    <LoginOptions />
  </>
);

const WebView = () => {
  const url = useAppStoreUrl();

  return (
    <View style={tw`flex-1 items-center mt-10 px-4 pt-10`}>
      <Image source={AppLogo} style={tw`w-20 h-20`} resizeMode="contain" />
      <Text variant="displayLarge" style={tw`text-center text-3xl py-4`}>
        {i18n.t('get_the_bandit_app_title')}
      </Text>
      <Text style={tw`text-center`} variant="labelSmall">
        {i18n.t('get_the_bandit_app_description')}
      </Text>
      <Button
        onPress={() => window.open(url, '_blank')}
        title={i18n.t('download_app')}
        customStyle={tw`mt-4 rounded-full px-6 py-3 bg-black border-[1.5px] border-black `}
        customTextStyle={[tw`text-white	font-normal text-sm`, { fontFamily: 'TheFuture-Regular' }]}
      />
    </View>
  );
};

const LoginModalScreen = () => {
  const navigation = useNavigation<CreateUserScreenNavigationProps>();
  const shouldAllowLogin = Platform.OS !== 'web' || Constants.expoConfig?.extra?.APP_ENV === 'development';
  return (
    <View style={tw`flex-1 justify-end`}>
      <View style={[tw`w-full relative bg-white rounded-tl-2xl rounded-tr-2xl`, { height: 450 }]}>
        {!shouldAllowLogin ? (
          <View style={tw`p-4 items-end`}>
            <TouchableOpacity onPress={navigation.goBack} testID="BUTTON.BACK">
              <CloseIcon style={tw`ml-4`} />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={tw`w-12 h-1 bg-black mx-auto mt-4`} />
        )}
        <View style={tw`flex-1 justify-center items-center mt-2 px-4`}>
          {!shouldAllowLogin ? <WebView /> : <AppView />}
        </View>
      </View>
    </View>
  );
};

export default LoginModalScreen;
