import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const BackBtn = (props: SvgProps) => (
  <Svg width={36} height={36} fill="none" {...props}>
    <Circle cx={18} cy={18} r={16.75} fill="#fff" stroke="#000" strokeWidth={1.5} />
    <Path
      d="m18 25-7-7 7-7M12.75 18.25h11.5"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default BackBtn;
