import { Assets } from 'src/generated/graphql';
import { NAME_ENDING, NAME_LIMIT, START_GROUP_DISCOUNT } from '../constant';
import {
  CustomProducts,
  IImage,
  IImages,
  IOption,
  IProductVariant,
  IProductVariantOption,
  ISelectedVariant,
  ProductDetails,
  ProductDetailsResponse,
} from '../types';

const initialImagesArray: IImages = { carouselImages: [], originalImages: [], splitUrlImages: [] };

export const getImagesArray = (assets: Assets[], key: keyof Assets): IImage[] => {
  return assets.reduce((imageArr: IImage[], curr: Assets) => {
    if (!!curr[key]) {
      imageArr.push({ url: curr[key], name: curr?.name || curr[key] });
    }
    return imageArr;
  }, []);
};

export const categorizeImagesUrls = (imageUrls: Assets[] = []): IImages => {
  if (!imageUrls.length) {
    return initialImagesArray;
  }

  return {
    carouselImages: getImagesArray(imageUrls, 'carouselUrl'),
    originalImages: getImagesArray(imageUrls, 'originalUrl'),
    splitUrlImages: getImagesArray(imageUrls, 'splitUrl'),
  };
};

export const convertOptionsToVariants = (products: CustomProducts[]): IProductVariant[] => {
  const optionsArr = products.map((product) => product.options);

  const options = optionsArr.flat().reduce((result: { [key: string]: IProductVariantOption[] }, option: IOption) => {
    const isExist = !!result?.[option.optionName]?.find(
      (item: IProductVariantOption) => item.value === option.optionValue,
    );

    if (!isExist) {
      return {
        ...result,
        [option.optionName]: [
          ...(result?.[option.optionName] || []),
          { value: option.optionValue, parentName: option.optionName },
        ],
      };
    }

    return result;
  }, {});

  return Object.entries(options).map(([key, value]) => ({ name: key, options: value }));
};

export const convertProductDetailResponse = (data: ProductDetailsResponse): ProductDetails => {
  return {
    groupShareText: '',
    ...data,
    productImages: categorizeImagesUrls(data.imageUrls),
    producerImages: categorizeImagesUrls(data.producer?.imageUrls),
    variants: convertOptionsToVariants(data.products),
  };
};

export const getInitialSelectedVariants = (variants?: IProductVariant[]): ISelectedVariant => {
  if (!variants?.length) {
    return {};
  }

  return variants.reduce(
    (prev: { [key: string]: string | null }, curr: IProductVariant) => ({ ...prev, [curr.name]: null }),
    {},
  );
};

// eslint-disable-next-line max-params
export const getDiscount = (soloPrice: number, groupPrice: number, isMemberPrice = true): number => {
  if (isMemberPrice) {
    return Math.round(((soloPrice - groupPrice) / soloPrice) * 100);
  }
  return Math.round(((soloPrice - groupPrice * 0.8) / soloPrice) * 100);
};

export const countOverAllStock = (products: CustomProducts[]) => {
  return products.reduce((result: { [key: string]: number }, product: CustomProducts) => {
    const currentOptionsStock = product.options.reduce(
      (stock: { [key: string]: number }, option: IOption) => ({
        ...stock,
        [`${option.optionValue}`]: (result?.[`${option.optionValue}`] || 0) + (product?.inventoryStock || 0),
      }),
      {},
    );
    return { ...result, ...currentOptionsStock };
  }, {});
};

export const getInitialOutOfStockOptions = (products: CustomProducts[]) => {
  const overAllStock = countOverAllStock(products);

  return Object.keys(overAllStock).filter((key) => overAllStock[key] <= 0);
};

export const getOutOfStockArray = (products: CustomProducts[], selectedVariants: { [key: string]: string | null }) => {
  const selectedValues = Object.values(selectedVariants).filter((value: string | null) => !!value) as string[];

  if (!selectedValues.length) {
    return getInitialOutOfStockOptions(products);
  }

  const filteredProducts = products.filter((product) => {
    const options = product.options.map((option) => option.optionValue);
    return selectedValues.some((value) => options.includes(value));
  });

  const currentStockCount = countOverAllStock(filteredProducts);

  const outOfStock = Object.keys(currentStockCount).filter((key) => currentStockCount[key] === 0);

  return [...getInitialOutOfStockOptions(products), ...outOfStock];
};

export const findProductId = (products: CustomProducts[], selectedVariant: { [key: string]: string | null }) => {
  const selectedValues = Object.values(selectedVariant);

  const filteredSelectedValues = selectedValues.filter((value) => value !== null) as string[];

  const isSelectedAllOptions = selectedValues.length === filteredSelectedValues.length;

  if (isSelectedAllOptions) {
    const selectedProduct = products.find((product) => {
      const options = product.options.map((option) => option.optionValue);
      return filteredSelectedValues.every((value) => options.includes(value));
    });

    return selectedProduct?.id;
  }
};

export const getDisplayCurrency = (currency: string) => {
  return ' kr';
};

export const getPrice = (price: number, currency: string) => {
  return `${price.toFixed(0) || 0}${getDisplayCurrency(currency)}`;
};

export const getStartGroupPrice = (groupPrice: number) => {
  return Math.round(groupPrice * (1 - START_GROUP_DISCOUNT / 100));
};

interface IGetDiscountForProductProps {
  soloPrice: number;
  groupPrice: number;
  currency: string;
}

export const getDiscountForProduct = ({ soloPrice, groupPrice, currency }: IGetDiscountForProductProps) => {
  const cashDiscount = Math.round(soloPrice - groupPrice);

  const discount = getDiscount(soloPrice, groupPrice);

  return {
    discountText: `${discount}%`,
    cashDiscount,
  };
};

export const getShortProductName = ({
  name,
  limit = NAME_LIMIT,
  ending = NAME_ENDING,
}: {
  name: string;
  limit?: number;
  ending?: string;
}) => {
  const nameExceedsLimit = name.length < limit;

  if (nameExceedsLimit) {
    return name;
  }

  const endIndex = limit - ending.length;

  return `${name.substring(0, endIndex)}${ending}`;
};
