import { gql } from '@apollo/client';

export const GET_BC_HISTORY = gql`
  query getBCHistory($date: timestamp!) {
    banditCash(where: { expiringAt: { _gte: $date } }) {
      id
      credit
      expiringAt
      createdAt
    }
  }
`;
