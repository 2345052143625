import { useRef, useState } from 'react';
import { Dimensions, StyleProp, View, ViewStyle } from 'react-native';
import { tw } from 'src/core/tw';

interface CustomShimmerProps {
  height: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const SCREEN_WIDTH = Dimensions.get('screen').width;

const CustomShimmer = ({ width, height, style }: CustomShimmerProps) => {
  const viewRef = useRef<View>(null);

  const [positionX, setPositionX] = useState<number | null>(null);

  return (
    <View
      style={[tw`overflow-hidden bg-[#eee]`, { width, height }, style]}
      ref={viewRef}
      onLayout={() => {
        // eslint-disable-next-line max-params
        viewRef.current?.measure((_x, _y, _width, _height, pageX, _pageY) => {
          if (isNaN(pageX)) {
            return;
          }
          setPositionX(Math.round(pageX));
        });
      }}
    >
      {positionX !== null && (
        <View
          style={{
            flex: 1,
            left: -positionX,
            width: SCREEN_WIDTH,
          }}
        />
      )}
    </View>
  );
};

export default CustomShimmer;
