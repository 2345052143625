import { GestureResponderEvent, TouchableOpacity, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

type TextButtonProps = {
  onPress: (event: GestureResponderEvent) => void;
  title: string;
  customStyle?: ViewStyle;
  disabled?: boolean;
  testID?: string;
  accessibilityLabel?: string;
  customTextStyle?: ViewStyle;
};

const TextButton = ({
  onPress,
  accessibilityLabel,
  title,
  customStyle,
  testID,
  disabled,
  customTextStyle,
}: TextButtonProps) => {
  return (
    <TouchableOpacity
      accessibilityRole="button"
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
      style={[tw`border-b py-2 w-auto mx-auto`, customStyle]}
    >
      <Text
        variant="labelSmall"
        style={[
          tw`border-black text-sm`,
          customTextStyle,
          {
            fontFamily: 'TheFuture-Regular',
          },
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default TextButton;
