/* eslint-disable complexity */
import { i18n, storeSetUserIdSignal, useUserStore } from '@core';
import Constants from 'expo-constants';
import { useEffect, useState } from 'react';
import { NativeModules, Platform } from 'react-native';
import WebOneSignal from 'react-onesignal';
import { ERROR_MESSAGES } from 'src/core/messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';

declare type LogLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6;
const LOCAL_HOST = 'localhost';
const appId = Constants?.expoConfig?.extra?.ONE_SIGNAL_APP_ID;
const isExpoGo = Constants?.executionEnvironment === 'storeClient';
const isOneSignalAvailable = !!NativeModules?.OneSignal;

let OneSignal: {
  promptForPushNotificationsWithUserResponse(
    fallbackToSettingsOrHandler?: boolean | ((response: boolean) => void),
    handler?: (response: boolean) => void,
  ): unknown;
  setLogLevel: (nsLogLevel: LogLevel, visualLogLevel: LogLevel) => void;
  setAppId: (appId: string) => void;
  getDeviceState: () => any;
  addTrigger: (key: string, value: string | number | boolean) => void;
  removeExternalUserId: (handler?: (results: object) => void) => void;
  setExternalUserId(
    externalId: string,
    handlerOrAuth?: ((results: object) => void) | string,
    handler?: (results: object) => void,
  ): void;
};

try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  OneSignal = require('react-native-onesignal')?.default;
} catch (e) {
  sendErrorToSentry(e, {
    tags: {
      error_type: 'OneSignal',
    },
  });
  console.log('Cannot find module react-native-onesignal. Make sure the application is not running in Expo go.');
}

const webSetUp = async () => {
  if (location.hostname === LOCAL_HOST) {
    return;
  }

  await WebOneSignal.init({ appId });
  WebOneSignal.showSlidedownPrompt();
};

const mobileSetUp = async () => {
  if (isExpoGo || !isOneSignalAvailable || !OneSignal) {
    return;
  }

  OneSignal.setLogLevel(6, 0);
  OneSignal.setAppId(appId);

  const device = await OneSignal.getDeviceState();

  const isNotificationEnabled = device?.hasNotificationPermission;

  if (!isNotificationEnabled) {
    OneSignal.promptForPushNotificationsWithUserResponse();
  }
};

const addExternalId = async (userId: string) => {
  if (isExpoGo || !isOneSignalAvailable || !OneSignal) {
    return;
  }

  try {
    if (userId) {
      OneSignal.setExternalUserId(userId, async (results: { push?: { success: boolean } }) => {
        if (!results.push) return;
        if (results.push.success) {
          await storeSetUserIdSignal(true);
        } else {
          sendErrorToSentry(i18n.t(ERROR_MESSAGES.failed_external_userID), {
            tags: {
              Screen: 'useLoginHook',
            },
          });
        }
      });
    }
  } catch (e) {
    sendErrorToSentry(e, {
      tags: {
        Screen: 'useOneSignal',
      },
    });
  }
};

const removeExternalId = () => {
  if (isExpoGo || !isOneSignalAvailable || !OneSignal) {
    return;
  }

  try {
    OneSignal.removeExternalUserId(async (results: { push?: { success: boolean } }) => {
      if (!results.push) return;
      if (results.push.success) {
        await storeSetUserIdSignal(false);
        return;
      }
      sendErrorToSentry('Failed to remove external id'),
        {
          tags: {
            Screen: 'useOneSignal',
          },
        };
    });
  } catch (e) {
    sendErrorToSentry(e, {
      tags: {
        Screen: 'useOneSignal',
      },
    });
  }
};

const setUp = () => {
  Platform.OS === 'web' ? webSetUp() : mobileSetUp();
};

const useOneSignal = () => {
  const { user } = useUserStore();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) return;

    if (user?.id) {
      addExternalId(user.id);
    } else {
      removeExternalId();
    }
  }, [initialized, user]);

  useEffect(() => {
    if (!initialized) {
      setUp();
      setInitialized(true);
    }
  }, [initialized]);
};

export default useOneSignal;
