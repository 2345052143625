import { View } from 'react-native';
import { Badge } from 'react-native-paper';
import { tw } from 'src/core/tw';

const BADGE_SIZE = 40;

const LINE_HEIGHT = 40;

const VARIANT_BORDER = '#C2C2C2';

interface IVarinatItemProps {
  bgColor?: string;
  content?: string;
  isFirstItem?: boolean;
  isSelect?: boolean;
  isAvailable?: boolean;
  onSelect?: () => void;
}

const VariantItem = ({
  bgColor = '#fff',
  content = '',
  isFirstItem = false,
  isSelect = false,
  isAvailable = true,
  onSelect,
}: IVarinatItemProps) => {
  const borderColor = isSelect ? 'black' : 'transparent';

  return (
    <View testID="variant-item" style={tw.style('relative z-1', { 'ml-2': isFirstItem })}>
      <View testID="selected-border" style={tw`border-2 border-${borderColor} rounded-full mr-1`}>
        <View style={tw`border-2 rounded-full overflow-hidden m-1 border-[${VARIANT_BORDER}]`}>
          <Badge
            accessibilityRole="button"
            testID="variant-button"
            size={BADGE_SIZE}
            style={tw.style('text-black text-sm font-medium px-2', {
              backgroundColor: bgColor,
              lineHeight: LINE_HEIGHT,
              fontFamily: 'TheFuture-Regular',
            })}
            disabled={!isAvailable}
            onPress={onSelect}
            suppressHighlighting
          >
            {content}
          </Badge>

          {!isAvailable && (
            <View
              testID="not-available-wrapper"
              style={tw.style('absolute w-full h-full items-center justify-center', {
                transform: [{ rotate: '-45deg' }],
              })}
            >
              <View style={tw`absolute z-2 bg-black w-full h-0.5`} />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default VariantItem;
