/* eslint-disable complexity */
import CashPointImage from '@assets/images/cashpoint.png';
import BanditFigure from '@assets/images/figure.png';
import GroupImage from '@assets/images/group1.png';
import { i18n, storeIsShowTutorial } from '@core';
import { AppLogo } from '@svgIcons';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { Button } from 'react-native-paper';
import { IStep } from 'rn-tourguide';
import { coachMarkDescriptions, coachMarksTitles } from 'src/core/constant/coachmark';
import { videoColors } from 'src/core/theme';
import { tw } from 'src/core/tw';
import styles from './styles';

export interface TooltipProps {
  isFirstStep?: boolean;
  isLastStep?: boolean;
  currentStep?: IStep;
  handleNext?: () => void;
  handlePrev?: () => void;
  handleStop?: () => void;
}

export const CustomTooltip = ({ isFirstStep, isLastStep, handleNext, handleStop, currentStep }: TooltipProps) => {
  const handleComplete = async () => {
    await storeIsShowTutorial(false);

    if (handleStop) {
      handleStop();
    }
  };

  return (
    <View
      style={
        currentStep?.order === 9
          ? [tw`w-full  m-3 rounded-2xl`]
          : [tw`w-full border-b-2 border-l-2 border-r-2 border-t-2 m-3 rounded-2xl`]
      }
    >
      <View>
        {currentStep?.order == 9 ? (
          <View style={[]}>
            <Image
              style={[
                {
                  // position: 'absolute',
                  left: 20,
                  bottom: 0,
                  height: 180,
                  width: 200,
                },
              ]}
              source={CashPointImage}
            />
          </View>
        ) : null}
      </View>
      <View style={[tw`rounded-2xl justify-center items-center w-full bg-white`, {}]}>
        {isFirstStep ? (
          <View
            style={[
              tw`w-full bg-[${videoColors.primaryColor}] border-b-2 h-40 rounded-tl-2xl rounded-tr-2xl justify-center items-center`,
            ]}
          >
            <AppLogo style={tw`my-2`} />
            <Image
              style={[
                {
                  position: 'absolute',
                  left: 10,
                  bottom: -30,
                  height: 125,
                  width: 100,
                },
              ]}
              source={BanditFigure}
            />
          </View>
        ) : null}

        {isLastStep ? (
          <View
            style={[
              tw`w-full bg-[${videoColors.primaryColor}] border-b-2 h-40 rounded-tl-2xl rounded-tr-2xl justify-center items-center`,
            ]}
          >
            <Image
              style={[
                {
                  alignContent: 'flex-end',
                  alignItems: 'flex-end',
                  position: 'absolute',

                  right: 30,
                  bottom: -30,
                  height: 160,
                  width: 220,
                },
              ]}
              source={GroupImage}
            />
          </View>
        ) : null}

        <View style={[tw`p-3.5 w-full`]}>
          <Text
            testID="stepTitle"
            style={[
              styles.tooltipText,
              { alignSelf: 'flex-start', alignItems: 'flex-start', paddingBottom: 10, fontFamily: 'TheFuture-Medium' },
            ]}
          >
            {currentStep && coachMarksTitles[currentStep.order - 1]}
          </Text>
          <Text testID="stepDescription" style={styles.tooltipText}>
            {currentStep && coachMarkDescriptions[currentStep.order - 1]}
          </Text>
        </View>
        <View style={[tw` rounded-bl-2xl rounded-br-2xl`, styles.bottomBar]}>
          {!isLastStep && (
            <TouchableOpacity onPress={handleStop}>
              <Button testID="skip" onPress={handleStop} labelStyle={styles.buttonText}>
                {i18n.t('coachmark_show_later')}
              </Button>
            </TouchableOpacity>
          )}
          {isFirstStep && (
            <TouchableOpacity onPress={handleNext}>
              <Button testID="next" onPress={handleNext} labelStyle={styles.buttonText}>
                {i18n.t('coachmark_button_show')}
              </Button>
            </TouchableOpacity>
          )}
          {!isFirstStep && !isLastStep ? (
            <TouchableOpacity onPress={handleNext}>
              <Button onPress={handleNext} labelStyle={styles.buttonText}>
                {i18n.t('coachmark_button_next')}
              </Button>
            </TouchableOpacity>
          ) : null}
          {isLastStep && (
            <TouchableOpacity onPress={handleComplete}>
              <Button testID="finish" onPress={handleComplete} labelStyle={styles.buttonText}>
                {i18n.t('coachmark_button_finish')}
              </Button>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default CustomTooltip;
