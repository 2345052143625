/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const GET_PRODUCT_IN_INVITE = gql`
  query GetProductInInvite($productId: String!) {
    productTypes(where: { id: { _eq: $productId } }) {
      id
      name
      groupPrice
      soloPrice
      currency
    }
  }
`;

export const GET_INVITE_DETAILS = gql`
  query GetInviteDetails($id: String!) {
    activeGroupOrders(where: { id: { _eq: $id } }) {
      id
      creator {
        userId
        name
        photoUrl
        joinAt
      }
      numberOfMembers
      expiredDate
      status
      members(
        orderBy: { joinAt: desc }
        where: { status: { _in: ["PENDING", "OPEN"] }, paymentStatus: { _in: ["PENDING", "AUTHORIZED"] } }
      ) {
        userId
        name
        photoUrl
        joinAt
      }
    }
  }
`;
