import { View } from 'react-native';
import { tw } from 'src/core/tw';
import CategoryItem from './CategoryItem/CategoryItem';

interface CategoryProps {
  list: {
    name: string;
    isSelected: boolean;
  }[];
  onSelectItem: (name: string) => void;
}

export const Category = ({ list, onSelectItem }: CategoryProps) => {
  return (
    <View testID="LIST.CATEGORY" style={tw`flex-row flex-wrap justify-center items-center`}>
      {list.map((item) => (
        <CategoryItem name={item.name} isSelected={item.isSelected} setSelectItem={onSelectItem} key={item.name} />
      ))}
    </View>
  );
};

export default Category;
