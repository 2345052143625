/* eslint-disable complexity */
import { getBcAds, storeBcAds, useLogout } from '@core';
import { useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

const useProfileHook = () => {
  const [adsBox, setAdsBox] = useState<boolean>(false);

  const scrollViewRef = useRef<ScrollView>(null);

  const { logout, loading } = useLogout();

  const checkAdsBox = async () => {
    const show = await getBcAds();
    setAdsBox(show);
  };

  useEffect(() => {
    checkAdsBox();
  }, []);

  const hideAdsBox = async (justTouch: boolean) => {
    if (scrollViewRef) {
      scrollViewRef.current?.scrollToEnd();
      if (!justTouch) {
        setAdsBox(false);
        await storeBcAds('true');
      }
    }
  };

  return { handleLogout: logout, adsBox, hideAdsBox, loading, scrollViewRef };
};

export default useProfileHook;
