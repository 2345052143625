import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
const Right = (props: SvgProps) => (
  <Svg fill="none" {...props}>
    <Path
      fill="#1C1B1F"
      d="M6.95 6c0 .133-.021.258-.063.375a.872.872 0 0 1-.212.325L2.05 11.325a.891.891 0 0 1-.688.262.975.975 0 0 1-.687-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l3.9-3.9L.65 2.075a.894.894 0 0 1-.263-.688A.978.978 0 0 1 .675.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l4.6 4.6c.1.1.17.208.212.325.042.117.063.242.063.375Z"
    />
  </Svg>
);
export default Right;
