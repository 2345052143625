import { ProductTags as IProductTags } from '@core';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IProductTagsProps {
  tags: IProductTags[];
}

const ProductTags = ({ tags }: IProductTagsProps) => {
  return (
    <View style={tw`flex-row flex-wrap`}>
      {tags.map(({ name }) => (
        <View key={name} style={tw`bg-white border-2 border-black rounded-md px-4 py-2.5 mr-4 mb-2`}>
          <Text testID="tag-name" style={tw`text-sm font-medium leading-4`}>
            {name}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default ProductTags;
