import { CustomShimmer } from '@components';
import { ScrollView, View } from 'react-native';
import { tw } from 'src/core/tw';

const ProductDetailsSkeleton = () => {
  return (
    <View testID="product-details-skeleton" style={tw`flex-1 bg-white`}>
      <ScrollView contentContainerStyle={tw`pb-47`} showsVerticalScrollIndicator={false}>
        <CustomShimmer height={428} />
        <View style={tw`border-t-2`}>
          <View style={tw`mx-4`}>
            <CustomShimmer height={40} style={tw`mt-8 w-5/6`} />
            <CustomShimmer height={20} style={tw`mt-2 w-1/5`} />
            <CustomShimmer height={40} style={tw`mt-4 w-1/2`} />
            <CustomShimmer height={24} style={tw`mt-1 w-1/3`} />
            <CustomShimmer height={50} style={tw`mt-4`} />
            <CustomShimmer height={24} style={tw`mt-6 w-1/2`} />
            <View style={tw`flex-row mt-6`}>
              {[...Array(3).keys()].map((_, index) => (
                <CustomShimmer key={index} height={38} style={tw`mr-4 w-1/5`} />
              ))}
            </View>
            <CustomShimmer height={32} style={tw`mt-10 w-1/5`} />
            <View style={tw`flex-row mt-4`}>
              {[...Array(3).keys()].map((_, index) => (
                <CustomShimmer key={index} height={40} style={tw`mt-4 rounded-full mr-4 w-10`} />
              ))}
            </View>
          </View>
        </View>
        <CustomShimmer height={720} style={tw`mt-11`} />
        <View style={tw`mx-4 mt-10`}>
          <CustomShimmer height={14} style={tw`w-1/3`} />
          <View style={tw`flex-row`}>
            {[...Array(2).keys()].map((_, index) => (
              <CustomShimmer key={index} height={120} style={tw`mt-4 rounded-xl mr-5 w-1/2`} />
            ))}
          </View>
          <CustomShimmer height={42} style={tw`mt-4`} />
          <CustomShimmer height={14} style={tw`mt-20 w-1/2`} />
          {[...Array(3).keys()].map((_, index) => (
            <View key={index} style={tw`flex-row items-center justify-between mt-8`}>
              <CustomShimmer height={14} style={tw`w-3/4`} />
              <CustomShimmer height={10} style={tw`w-1/15`} />
            </View>
          ))}
        </View>
      </ScrollView>
      <View style={tw`absolute bottom-0 w-full pb-7 bg-white border-t-2`}>
        <CustomShimmer height={32} />
        <View style={tw`border-t-2`}>
          <View style={tw`flex-row items-center mt-4 mx-4`}>
            <CustomShimmer height={72} style={tw`rounded-full mr-4 w-2/5`} />
            <CustomShimmer height={72} style={tw`rounded-full flex-1`} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ProductDetailsSkeleton;
