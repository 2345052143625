import { PaginationItem } from '@components';
import { IImage, IImages } from '@core';
import { useState } from 'react';
import { Dimensions, Image, Pressable, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { useSharedValue } from 'react-native-reanimated';
import Carousel from 'react-native-reanimated-carousel';
import { tw } from 'src/core/tw';

interface IProductCarouselProps {
  images: IImages;
  onPressImage: (images: IImage[], index: number) => void;
}

const ProductCarousel = ({ images, onPressImage }: IProductCarouselProps) => {
  const progressValue = useSharedValue<number>(0);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const handleProgressChange = (_: number, absoluteProgress: number) => {
    progressValue.value = absoluteProgress;
  };

  const renderItem = ({ item: { name, url }, index }: { item: IImage; index: number }) => (
    <Pressable key={name} style={tw`flex-1 justify-center`} onPress={() => onPressImage(images.originalImages, index)}>
      <Image
        testID="carousel-image"
        accessibilityRole="image"
        style={tw`w-auto h-full`}
        source={{ uri: url }}
        resizeMode="contain"
      />
    </Pressable>
  );

  return (
    <View
      style={tw`relative`}
      onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width);
      }}
    >
      {!!width && (
        <Carousel
          testID="carousel"
          scrollAnimationDuration={300}
          width={width}
          height={width}
          data={images.carouselImages}
          renderItem={renderItem}
          onProgressChange={handleProgressChange}
          loop={images.carouselImages.length > 1}
          pagingEnabled
        />
      )}
      <View style={tw`absolute bottom-3 flex-row self-center`}>
        {images.carouselImages.map(({ name }, index) => (
          <PaginationItem key={name} animValue={progressValue} index={index} length={images.carouselImages.length} />
        ))}
      </View>
      <Divider
        style={tw`border-b-2 border-black`}
        accessibilityLabelledBy={undefined}
        accessibilityLanguage={undefined}
      />
    </View>
  );
};

export default ProductCarousel;
