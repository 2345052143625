import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { HomeScreenNavigationProp } from '@routes';
import { Platform, SafeAreaView, StatusBar, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import OrderIsssuesThanksIcons from 'src/svgIcons/OrderIssuesThanksIcon';

const OrderIssuesThanksScreen = () => {
  const navigation = useNavigation<HomeScreenNavigationProp>();

  const { colors } = useBanditTheme();

  const handleGoBackHome = () => {
    navigation.navigate('ProfileScreen');
  };

  return (
    <View style={[tw`bg-white flex-1`, Platform.OS === 'android' && { paddingTop: StatusBar.currentHeight }]}>
      <SafeAreaView />
      <Text
        style={[
          tw`py-5 text-lg font-normal text-center text-black tracking-[0.15px]`,
          { fontFamily: 'TheFuture-Regular' },
        ]}
      >
        {i18n.t('profile_helpAndFeedback_orderIssues_screenTitle')}
      </Text>
      <View
        style={[
          tw`p-3 h-full border-t border-black justify-center flex-1 px-6`,
          { backgroundColor: colors.secondary_fuschia },
        ]}
      >
        <Text
          style={[tw`text-[40px] text-center mb-6 text-black leading-[51px]`, { fontFamily: 'FeatureDeck-Regular' }]}
        >
          {i18n.t('profile_helpAndFeedback_orderIssues_thanksMessage')}
        </Text>
        <OrderIsssuesThanksIcons style={tw`mx-auto`} />
        <Button
          customStyle={tw`mt-16 mx-auto py-3.5 px-6`}
          customTextStyle={[tw`text-sm font-normal`, { fontFamily: 'TheFuture-Regular' }]}
          title={i18n.t('profile_helpAndFeedback_orderIssues_buttonText_goBackHome')}
          onPress={handleGoBackHome}
        />
      </View>
    </View>
  );
};

export default OrderIssuesThanksScreen;
