import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import { IconButton } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IModalCloseButtonProps {
  customMobileStyle?: StyleProp<ViewStyle>;
  onClose: () => void;
}

const ModalCloseButton = ({ customMobileStyle, onClose }: IModalCloseButtonProps) => {
  return Platform.OS === 'web' ? (
    <IconButton
      testID="close-button"
      accessibilityRole="button"
      icon="close"
      style={tw`bg-white border-2 border-black m-0 absolute top-4 right-4 z-10`}
      onPress={onClose}
      size={18}
      accessibilityLabelledBy={undefined}
      accessibilityLanguage={undefined}
    />
  ) : (
    <View style={[tw`border-b-2 w-12 my-4`, customMobileStyle]} />
  );
};

export default ModalCloseButton;
