import { GestureResponderEvent, StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

type ButtonProps = {
  onPress: (event: GestureResponderEvent) => void;
  title: string;
  customStyle?: ViewStyle | ViewStyle[];
  disabled?: boolean;
  testID?: string;
  accessibilityLabel?: string;
  customTextStyle?: StyleProp<TextStyle>;
  loading?: boolean;
  textVariant?: 'labelLarge' | 'headlineSmall';
};

const Button = ({
  onPress,
  accessibilityLabel,
  title,
  customStyle,
  testID,
  disabled,
  customTextStyle,
  loading = false,
  textVariant = 'headlineSmall',
}: ButtonProps) => {
  return (
    <TouchableOpacity
      accessibilityRole="button"
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
      style={[
        tw`rounded-full py-2 flex justify-center items-center`,
        tw.style(disabled ? 'bg-gray-300' : 'bg-black'),
        customStyle,
      ]}
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <Text variant={textVariant} style={[tw`text-white`, customTextStyle]}>
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;
