import { Pressable, ViewStyle } from 'react-native';
import { tw } from 'src/core/tw';

interface IUnderlineTextProps {
  children: React.ReactNode;
  style?: ViewStyle;
  onPress?: () => void;
}

const UnderlineText = ({ children, style, onPress }: IUnderlineTextProps) => {
  return (
    <Pressable
      accessibilityRole="button"
      style={tw.style('border-black border-b-[1.5px] self-start', style)}
      onPress={onPress}
    >
      {children}
    </Pressable>
  );
};

export default UnderlineText;
