import * as React from 'react';
import Svg, { Circle, Line, SvgProps } from 'react-native-svg';

interface ClockIconProps extends SvgProps {
  size?: number;
  color?: string;
}

const ClockIcon = (props: ClockIconProps) => (
  <Svg
    {...props}
    width={props.size || 26}
    height={props.size || 26}
    fill="none"
    viewBox="0 0 26 25"
    stroke={props.color || 'currentColor'}
  >
    <Circle cx="13" cy="12.5" r="11.75" stroke="black" strokeWidth="1.5" />
    <Line x1="12.5" y1="12.9393" x2="18.4393" y2="7" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
);
export default ClockIcon;
