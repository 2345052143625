import { useUserStore } from '@core';
import { UseUserStore } from 'src/core/store/user/useUserStore';
import { shallow } from 'zustand/shallow';

const userStoreSelector = ({ user }: UseUserStore) => ({ user });

export function useGetUser() {
  const { user } = useUserStore(userStoreSelector, shallow);
  return user;
}
