import * as Sentry from 'sentry-expo';
import { isWeb } from '../helpers/platformHelper';

type UpdateUserTelemetryInfoProps = {
  id: string;
  email: string;
};

const updateUserTelemetryInfo = (user?: UpdateUserTelemetryInfoProps | null) => {
  if (user) {
    if (isWeb) {
      Sentry.Browser.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          email: user.email,
        });
      });
    } else {
      Sentry.Native.setUser({
        id: user.id,
        email: user.email,
      });
    }
  } else {
    if (isWeb) {
      Sentry.Browser.configureScope((scope) => {
        scope.setUser(null);
      });
    } else {
      Sentry.Native.setUser(null);
    }
  }
};

export default updateUserTelemetryInfo;
