/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useReactiveVar } from '@apollo/client';
import { ExpandableDescription } from '@components';
import {
  authVar,
  CustomProducts,
  getDisplayCurrency,
  getStartGroupPrice,
  i18n,
  IProductVariant,
  OrderType,
} from '@core';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { useRoute } from '@react-navigation/native';
import { ProductDetailScreenRouteProps } from '@routes';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { useAnimatedStyle, useSharedValue, withRepeat, withSequence, withTiming } from 'react-native-reanimated';
import { CustomBottomSheetModal } from 'src/components/CustomBottomSheetModal/CustomBottomSheetModal';
import { tw } from 'src/core/tw';
import { useCheckInventory, useCreateOrder, useJoinGroupOrder, useVariants } from '../../hooks';
import { usePurchaseActions, usePurchaseState } from '../../hooks/usePurchaseState/usePurchaseState';
import BanditCashCard from '../BanditCashCard/BanditCashCard';
import ProductVariants from '../ProductVariants/ProductVariants';
import PurchaseButton from '../PurchaseButton/PurchaseButton';
import StartGroupMessage from './components/StartGroupMessage';

const ACTION_BUTTONS = {
  soloBuy: 'purchase_modal_solo_buy_for',
  groupBuy: 'purchase_modal_group_buy_for',
  joinGroup: 'purchase_modal_join_group_buy',
};

const ANIMATION_ANGLE = 2;

interface IPurchasePanelProps {
  isVisible: boolean;
  name: string;
  description: string;
  products: CustomProducts[];
  variants: IProductVariant[];
  soloPrice: number;
  groupPrice: number;
  currency: string;
  hasOption: boolean;
  onClose: () => void;
}

// eslint-disable-next-line complexity
const PurchasePanel = ({
  isVisible,
  name,
  description,
  products,
  variants,
  soloPrice,
  groupPrice,
  currency,
  hasOption,
  onClose,
}: IPurchasePanelProps) => {
  const [useBanditCash, setUseBanditCash] = useState(false);
  const [pressedAtLeastOnce, setPressedAtleastOnce] = useState(false);
  const [coinAmount, setCoinAmount] = useState(0);

  const { params } = useRoute<ProductDetailScreenRouteProps>();

  const rotation = useSharedValue(0);

  const auth = useReactiveVar(authVar);

  const { selectedProductId, handleSelectVariant, handleSelectProductId } = useVariants();

  const { orderId, purchaseState } = usePurchaseState();
  const { handleChangeToGroupBuy } = usePurchaseActions();
  const isSoloBuy = purchaseState === 'soloBuy';

  const isJoinGroup = purchaseState === 'joinGroup';

  const displayCurrency = getDisplayCurrency(currency);

  const startGroupPrice = isJoinGroup ? groupPrice : getStartGroupPrice(groupPrice);

  const startGroupPriceWithDiscount =
    startGroupPrice - (useBanditCash ? Math.min(coinAmount, Number(Math.floor(startGroupPrice / 2))) : 0);

  const priceCoinAmount = isSoloBuy ? soloPrice : startGroupPrice;

  const { canPurchase, checkInventory } = useCheckInventory({ productId: params.id });

  useEffect(() => {
    if (isVisible) {
      checkInventory();
    }
  }, [isVisible, purchaseState]);

  useEffect(() => {
    if (!hasOption && !selectedProductId && products?.[0]?.inventoryStock > 0 && !!products?.[0]?.id) {
      if (!!variants?.[0]?.options?.[0]) {
        handleSelectVariant(variants[0].options[0]);
        return;
      }
      handleSelectProductId(products[0].id);
    }
  }, [hasOption, variants, products, selectedProductId, isVisible]);

  const handleToggleUseBanditCash = () => {
    setUseBanditCash((prev) => !prev);
  };

  const handleUpdateBanditCash = (coin: number) => {
    setCoinAmount(coin);
  };

  const { handleCreateOrder, isLoading } = useCreateOrder({ currentProductId: params?.id });

  const handlePurchaseProduct = (orderType: OrderType) => {
    if (!!selectedProductId) {
      handleCreateOrder({
        productId: selectedProductId,
        orderType: orderType,
        banditCash: useBanditCash ? coinAmount : 0,
      });
      onClose();
    }
  };

  const { handleJoinOrder, isJoining } = useJoinGroupOrder();

  const handleJoinGroupOrder = () => {
    if (!!selectedProductId && !!orderId) {
      handleJoinOrder({
        productId: selectedProductId,
        orderId,
        banditCash: useBanditCash ? coinAmount : 0,
      });
      onClose();
    }
  };

  const animateProductVariants = () => {
    rotation.value = withSequence(
      withTiming(-ANIMATION_ANGLE, { duration: 50 }),
      withRepeat(withTiming(ANIMATION_ANGLE, { duration: 100 }), 6, true),
      withTiming(0, { duration: 50 }),
    );
  };

  const clickPurchaseGroup = () => {
    if (!selectedProductId) {
      setPressedAtleastOnce(true);
      animateProductVariants();
      return;
    }

    handlePurchaseProduct(OrderType.Group);
  };

  const productVariantsStyle = useAnimatedStyle(() => ({
    transform: [{ rotateZ: `${rotation.value}deg` }],
  }));

  return (
    <CustomBottomSheetModal closeModal={onClose} isVisible={isVisible} title={name} snapPoints={['80%']}>
      <>
        <BottomSheetScrollView contentContainerStyle={tw`pb-4`} showsVerticalScrollIndicator={false}>
          <View style={tw`mx-4`}>
            <ExpandableDescription description={description} style={tw`mb-6`} charactersLimit={200} />
          </View>

          {hasOption && !!variants.length && (
            <ProductVariants
              animatedStyle={productVariantsStyle}
              products={products}
              variants={variants}
              pressedAtLeastOnce={pressedAtLeastOnce}
            />
          )}

          {!isJoinGroup && (
            <StartGroupMessage
              soloPrice={soloPrice}
              startGroupPrice={startGroupPrice}
              groupPrice={groupPrice}
              displayCurrency={displayCurrency}
            />
          )}

          {auth?.loggedIn && !isSoloBuy && (
            <View style={tw`mt-6 mx-4`}>
              <BanditCashCard
                priceCoinAmount={priceCoinAmount}
                isCheck={useBanditCash}
                onToggleCheck={handleToggleUseBanditCash}
                onUpdateBanditCash={handleUpdateBanditCash}
              />
            </View>
          )}
        </BottomSheetScrollView>
        <View style={tw`border-t-2  items-center justify-between p-4 w-full`}>
          {isSoloBuy ? (
            <View style={tw`w-full flex-row`}>
              <View style={tw`w-3/5 mr-4`}>
                <PurchaseButton
                  actionText={i18n.t('purchase_modal_group_buy_instead')}
                  price={startGroupPriceWithDiscount}
                  currency={displayCurrency}
                  disabled={!selectedProductId || isLoading || isJoining || !canPurchase.group}
                  onPress={handleChangeToGroupBuy}
                />
              </View>
              <View style={tw`flex-1`}>
                <PurchaseButton
                  actionText={i18n.t(ACTION_BUTTONS[purchaseState])}
                  price={soloPrice}
                  currency={displayCurrency}
                  variant="outlined"
                  disabled={!selectedProductId || isLoading || isJoining || !canPurchase.solo}
                  onPress={() => handlePurchaseProduct(OrderType.Solo)}
                />
              </View>
            </View>
          ) : (
            <View style={tw`w-full`}>
              <PurchaseButton
                actionText={i18n.t(ACTION_BUTTONS[purchaseState])}
                price={startGroupPriceWithDiscount}
                currency={displayCurrency}
                disabled={isLoading || isJoining || (purchaseState === 'groupBuy' && !canPurchase.group)}
                onPress={purchaseState === 'groupBuy' ? clickPurchaseGroup : handleJoinGroupOrder}
              />
            </View>
          )}
          <Text style={tw`mt-2 mx-auto text-xs`}>+ 29kr {i18n.t('common:shipping_fee').toLocaleLowerCase()}</Text>
        </View>
      </>
    </CustomBottomSheetModal>
  );
};

export default PurchasePanel;
