import FlameLogo from '@assets/images/flame.png';
import { useEffect, useState } from 'react';
import { Image, Pressable, TextProps } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { tw } from 'src/core/tw';

interface AnimatedButtonProps {
  groupText: string;
  isOpen: boolean;
  style?: any;
  onPress?: () => void;
}

const config = {
  duration: 500,
  easing: Easing.elastic(1),
};

interface FadeInTextProps extends TextProps {
  children: React.ReactNode;
}
const FadeInText: React.FC<FadeInTextProps> = (props) => {
  const opacity = useSharedValue(0);

  useEffect(() => {
    opacity.value = withTiming(1, {
      duration: 200,
      easing: Easing.ease,
    });
  }, []);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  return (
    <Animated.Text
      style={[
        {
          textAlign: 'center',
        },
        animatedStyle,
      ]}
    >
      {props.children}
    </Animated.Text>
  );
};

const AnimatedFab = ({ groupText, style, onPress, isOpen }: AnimatedButtonProps) => {
  const [showText, setShowText] = useState(true);
  const randomWidth = useSharedValue(34);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        setShowText(true);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
    setShowText(false);
  }, [isOpen]);

  useEffect(() => {
    randomWidth.value = isOpen ? Math.sqrt(groupText.length) * 42 : 34;
  }, [isOpen, groupText, randomWidth]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: withTiming(randomWidth.value, config),
      height: withTiming(34, config),
      borderRadius: withTiming(17, config),
    };
  });

  return (
    <Animated.View
      testID={'animatedView'}
      style={[tw`h-8 w-8 rounded-full border-2 justify-center items-center content-center`, style, animatedStyle]}
    >
      {isOpen ? (
        <Pressable
          onPress={onPress}
          testID="buttonText"
          style={[
            tw`absolute h-full w-full flex-row rounded-full justify-between items-center content-center px-4 overflow-hidden`,
          ]}
        >
          <Image style={[tw`h-5 w-5`, { resizeMode: 'contain' }]} source={FlameLogo} />

          {showText && <FadeInText>{groupText}</FadeInText>}
        </Pressable>
      ) : (
        <Pressable accessibilityRole="button" testID="animated-fab" onPress={onPress}>
          <Image style={[tw`h-5 w-5`, { resizeMode: 'contain' }]} source={FlameLogo} />
        </Pressable>
      )}
    </Animated.View>
  );
};

export default AnimatedFab;
