import { i18n } from '@core';
import { useState } from 'react';
import { OrderItem, UseOrderListProps } from '../../model';

const useOrderList = ({ handleOrderSelected }: UseOrderListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [titleText, setTitleText] = useState(i18n.t('profile_helpAndFeedback_orderIssues_defaultDropdown'));

  const handleTitleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleItemClick = (orderItem: OrderItem) => {
    setTitleText(`${orderItem?.productType?.name} (order ${orderItem.orderRef})`);
    handleOrderSelected(orderItem);
    setIsExpanded(false);
  };

  return { isExpanded, titleText, handleTitleClick, handleItemClick };
};

export default useOrderList;
