import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

type Os = 'Android' | 'iOS' | 'Desktop' | 'unknown';

// eslint-disable-next-line complexity
function detectOs(): Os {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Android';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  if (/Windows|Mac OS X|Linux/i.test(userAgent)) return 'Desktop';

  return 'unknown';
}

export function useDetectOs() {
  const [os, setOs] = useState<Os>('unknown');
  useEffect(() => {
    if (Platform.OS === 'web') {
      setOs(detectOs());
    }
  }, []);

  return os;
}
