import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { PaymentScreen } from '@screens';
import PaymentFailureScreen from 'src/domain/payment/screens/PaymentFailureScreen';
import PaymentSuccessScreen from 'src/domain/payment/screens/PaymentSuccessScreen';
import IScreen from '../../routes/IScreen';

type PaymentParamList = {
  PaymentScreen: {
    orderId: string;
    cartId: string;
    session?: {
      id: string;
      sessionData: string;
      timeout: string;
    };
    success?: boolean;
    redirectResult?: string;
  };
  PaymentSuccessScreen: {
    orderId: string;
  };
  PaymentFailureScreen: {
    orderId: string;
  };
};

export type PaymentScreenRouteProps = RouteProp<PaymentParamList, 'PaymentScreen'>;
export type PaymentScreenNavigationProps = StackNavigationProp<PaymentParamList, 'PaymentScreen'>;

export type PaymentSuccessScreenRouteProps = RouteProp<PaymentParamList, 'PaymentSuccessScreen'>;
export type PaymentSuccessScreenNavigationProps = StackNavigationProp<PaymentParamList, 'PaymentSuccessScreen'>;

export type PaymentFailureScreenRouteProps = RouteProp<PaymentParamList, 'PaymentFailureScreen'>;
export type PaymentFailureScreenNavigationProps = StackNavigationProp<PaymentParamList, 'PaymentFailureScreen'>;

export const PaymentRoutes: IScreen<PaymentParamList>[] = [
  {
    name: 'PaymentScreen',
    component: PaymentScreen,
    options: {
      gestureEnabled: false,
      headerShown: false,
    },
  },
  {
    name: 'PaymentSuccessScreen',
    component: PaymentSuccessScreen,
    options: {
      gestureEnabled: false,
      headerShown: false,
    },
  },
  {
    name: 'PaymentFailureScreen',
    component: PaymentFailureScreen,
    options: {
      gestureEnabled: false,
      headerShown: false,
    },
  },
];
