import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { InviteGroupIcon } from '@svgIcons';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

type GroupDefaultScreenProps = {
  goToExplore: () => void;
};

const GroupDefaultScreen = ({ goToExplore }: GroupDefaultScreenProps) => {
  const { colors } = useBanditTheme();

  return (
    <ScrollView style={tw`flex-1 bg-white`} contentContainerStyle={tw`pb-20`} showsVerticalScrollIndicator={false}>
      <View style={tw`bg-[${colors.primary}] border-b-[1.5px]`}>
        <Text variant="displayLarge" style={[tw`py-7 px-6 mb-25`, { fontFamily: 'FeatureDeck-Regular' }]}>
          {i18n.t('group_default_title')}
        </Text>
        <View style={tw`absolute -bottom-20 right-5`}>
          <InviteGroupIcon width={194} height={184} viewBox="0 0 288 272" style={{ transform: [{ scaleX: -1 }] }} />
        </View>
      </View>
      <View style={tw`items-center px-17 pt-26`}>
        <Text style={[tw`mb-7 text-center`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('group_default_first_description')}
        </Text>
        <Text style={[tw`text-center`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('group_default_second_description')}
        </Text>
        <Button
          title={i18n.t('group_default_button_explore')}
          customTextStyle={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}
          customStyle={tw`px-6 py-4 mt-8`}
          textVariant="labelLarge"
          onPress={goToExplore}
        />
      </View>
    </ScrollView>
  );
};

export default GroupDefaultScreen;
