import { Chip } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface CategoryItemProps {
  name: string;
  clickItem: (name: string) => void;
}

const CategoryItem = ({ name, clickItem }: CategoryItemProps) => {
  return (
    <Chip
      mode="outlined"
      style={tw`rounded-full mr-2.5 mt-2.5 bg-white border-black border-[1.5px]`}
      textStyle={[tw`text-black font-normal mx-5.5 my-3 capitalize`, { fontFamily: 'TheFuture-Regular' }]}
      onPress={() => clickItem(name)}
    >
      {name}
    </Chip>
  );
};

export default CategoryItem;
