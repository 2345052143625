import { useBanditTheme } from '@core';
import { Down, Up } from '@svgIcons';
import { useState } from 'react';
import { View } from 'react-native';
import { List, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { FaqCardProps } from '../../model';

const FaqCard = ({ faq }: FaqCardProps) => {
  const [expanded, setExpanded] = useState(false);

  const { colors } = useBanditTheme();

  const handlePress = () => setExpanded(!expanded);

  return (
    <View style={tw`mb-3 p-1 p-0 border-[1.5px] border-black rounded-lg bg-white overflow-hidden`}>
      <List.Accordion
        style={tw`bg-white rounded-lg font-bold p-3 py-[5] m-0`}
        title={faq.question}
        titleStyle={[tw`text-sm leading-6`, { fontFamily: 'TheFuture-Regular', color: colors.primary_black }]}
        right={(props) => <View style={tw`w-4 h-4`}>{expanded ? <Up /> : <Down />}</View>}
        titleNumberOfLines={2}
        expanded={expanded}
        onPress={handlePress}
      >
        <Text
          variant="bodyMedium"
          style={[
            tw`p-5 pt-0 text-sm font-normal`,
            { color: colors.primary_black_300, fontFamily: 'TheFuture-Regular' },
          ]}
        >
          {faq.answer}
        </Text>
      </List.Accordion>
    </View>
  );
};
export default FaqCard;
