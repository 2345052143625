import { Loading, UnderlineText } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import GroupDefaultScreen from './GroupDefaultScreen/GroupDefaultScreen';
import GroupResultScreen from './GroupResultScreen/GroupResultScreen';
import { useGroupHook } from './hook';

const GroupsScreen = () => {
  const navigation = useNavigation();

  const { data, loading } = useGroupHook();

  const goToExplore = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('DealScreen');
  };

  const goToOrderHistory = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('OrderHistoryScreen');
  };

  return (
    <View style={tw`flex-1 bg-white`}>
      {loading ? (
        <Loading />
      ) : !data?.length ? (
        <GroupDefaultScreen goToExplore={goToExplore} />
      ) : (
        <ScrollView style={tw`h-full`} contentContainerStyle={tw`h-auto pb-4`} showsVerticalScrollIndicator={false}>
          <GroupResultScreen orderList={data} />

          <View style={tw`mt-16 items-center`}>
            <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>
              {i18n.t('group_order_history_title')}
            </Text>
            <UnderlineText style={tw`self-center`} onPress={goToOrderHistory}>
              <Text testID="BUTTON.ORDER" style={[tw`text-sm mt-2`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('group_order_history_button')}
              </Text>
            </UnderlineText>
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default GroupsScreen;
