import CoinIcon from '@assets/icons/coin.png';
import { i18n, videoColors } from '@core';
import { Image, Text, View } from 'react-native';
import { tw } from 'src/core/tw';

interface Props {
  soloPrice: number;
  groupPrice: number;
}

const BanditCashView = ({ soloPrice, groupPrice }: Props) => {
  const differenceInCoin = Math.round(soloPrice - groupPrice);
  return (
    <View
      style={[
        tw`h-9 ml-2 mt-1 border-2 rounded-md`,
        {
          backgroundColor: videoColors.backgroundColor,
          justifyContent: 'center',
          fontFamily: 'TheFuture-Regular',
          fontSize: 28,
        },
      ]}
    >
      <View
        style={[
          tw`flex flex-row `,
          {
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            fontFamily: 'TheFuture-Regular',
            fontSize: 24,
          },
        ]}
      >
        <Text style={[tw`font-normal text-xl px-2 `, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('videoFeed_banditCash_get_text')} {differenceInCoin}
        </Text>
        <Image style={tw`h-5 w-5 mr-2`} source={CoinIcon} alt="Coin" />
      </View>
    </View>
  );
};

export default BanditCashView;
