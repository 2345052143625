import { Dimensions, ScrollView } from 'react-native';
import { Chip } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { ITag } from 'src/screens/SearchScreen/model';

interface TagsProps {
  clickHandler: (name: any) => void;
  tags: ITag[];
}

const { width } = Dimensions.get('screen');

const Tags = ({ tags, clickHandler }: TagsProps) => {
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={tw`mt-2`}
      testID="SCROLL.TAGS"
      contentContainerStyle={[
        tw`flex-wrap grow	`,
        {
          width: width + width / 3,
        },
      ]}
    >
      {tags.map((tag) => (
        <Chip
          testID={`TAG.${tag.name}`}
          key={tag.name}
          onPress={() => clickHandler(tag)}
          mode="outlined"
          style={tw`rounded mr-2 mt-2 bg-white border-black`}
          textStyle={tw`text-xs font-medium`}
        >
          {tag.name}
        </Chip>
      ))}
    </ScrollView>
  );
};

export default Tags;
