import Svg, { Path, SvgProps } from 'react-native-svg';

type PlayLogoProps = {
  color: 'gray' | 'black';
} & SvgProps;

const PlayLogo = (props: PlayLogoProps) => (
  <Svg width="24" height="24" viewBox="-4 -2 24 24" fill="none" {...props}>
    <Path
      d="M4.05883 18.7209L15.9804 11.7803C17.3399 10.9889 17.3399 9.01018 15.9804 8.2187L4.05883 1.27807C2.69935 0.486596 1 1.47594 1 3.05889V16.9402C1 18.5231 2.69935 19.5124 4.05883 18.7209Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PlayLogo;
