/* eslint-disable complexity */
import { useNavigation, useRoute } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps, ProductDetailScreenRouteProps } from '@routes';
import { useEffect } from 'react';
import { View } from 'react-native';
import { tw } from 'src/core/tw';
import { ProductDetailModals, ProductDetailsSkeleton, ProductDetailView } from './components';
import {
  useActiveGroupOrders,
  useActiveGroupState,
  useGetMyActiveGroup,
  useGroupDetails,
  useProductDetails,
  useProductDetailsState,
  useVariants,
} from './hooks';
import { usePurchaseActions } from './hooks/usePurchaseState/usePurchaseState';

const ProductDetailScreen = () => {
  const { params } = useRoute<ProductDetailScreenRouteProps>();
  const navigation = useNavigation<ProductDetailScreenNavigationProps>();
  const { product } = useProductDetails({ productId: params?.id });
  const { resetSelectedVariants } = useVariants(product?.products, product?.variants);
  const { resetAllState } = useProductDetailsState();
  const { groupDetails, loading: loadingInvite } = useGroupDetails({ id: params.inviteId });

  const { myActiveGroups, loading: loadingMyActiveGroups } = useGetMyActiveGroup({
    productId: params.id,
    groupSize: product?.groupSize,
    groupShareText: product?.groupShareText,
  });

  const { activeGroupOrders, loading: loadingActiveGroups } = useActiveGroupOrders({
    productId: params.id,
    groupSize: product?.groupSize,
  });

  const { handleViewActiveGroupInfo } = useActiveGroupState();
  const { handlePressGroupBuy } = usePurchaseActions();

  useEffect(() => {
    if (!!product?.variants) {
      resetSelectedVariants();
    }
  }, [product?.variants]);

  useEffect(() => {
    resetAllState();
  }, []);

  useEffect(() => {
    if (params?.inviteId && activeGroupOrders.find((group) => group.id === params.inviteId)) {
      handleViewActiveGroupInfo(params.inviteId);
      navigation.setParams({ ...params, inviteId: undefined });
    }

    if (params.startGroup) {
      handlePressGroupBuy();
      navigation.setParams({ ...params, startGroup: undefined });
    }
  }, [params, activeGroupOrders]);

  // TODO: combine queries...
  if (loadingMyActiveGroups || loadingActiveGroups || loadingInvite || !product) {
    return <ProductDetailsSkeleton />;
  }

  return (
    <>
      <View testID="product-detail-screen" style={tw`flex-1 bg-white`}>
        <ProductDetailView
          product={product}
          activeGroupOrders={activeGroupOrders}
          myActiveGroups={!!params?.inviteId && !myActiveGroups ? groupDetails : myActiveGroups}
          isInvited={!!params?.inviteId && !myActiveGroups}
        />
      </View>
      <ProductDetailModals
        myActiveGroups={!!params?.inviteId && !myActiveGroups ? groupDetails : myActiveGroups}
        product={product}
      />
    </>
  );
};

export default ProductDetailScreen;
