import { ApolloError, useQuery } from '@apollo/client';
import { IGetProductActiveGroupsPayload, IGetProductActiveGroupsResponse } from '@core';
import { useMemo } from 'react';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_PRODUCT_ACTIVE_GROUPS } from '../../query';

interface IUseActiveGroupOrdersProps {
  productId: string;
  groupSize?: number;
}

const useActiveGroupOrders = ({ productId, groupSize }: IUseActiveGroupOrdersProps) => {
  const toast = useShowToast();
  const currentTimestamp = useMemo(() => new Date().toISOString().replace('Z', ''), []);

  const { data, loading, refetch } = useQuery<IGetProductActiveGroupsResponse, IGetProductActiveGroupsPayload>(
    GET_PRODUCT_ACTIVE_GROUPS,
    {
      variables: { productId, groupSize: groupSize!, currentTimestamp },
      skip: !productId || !groupSize,
      pollInterval: 10000,
      onError: (error: ApolloError) => toast.danger(error.message),
    },
  );

  return {
    activeGroupOrders: !!groupSize
      ? data?.productTypes?.[0]?.activeGroupOrders
          ?.map((order) => ({
            ...order,
            numberOfMembers: order.members?.length,
          }))
          .filter((group) => group.numberOfMembers < groupSize) || []
      : [],
    loading,
    refetch,
  };
};

export default useActiveGroupOrders;
