import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { View } from 'react-native';
import { Badge } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { useEndOfFeedScreenContext } from '../../Providers/EndOfFeedScreenProvider/EndOfFeedScreenProvider';

const EndOfFeedActionsBtn = () => {
  const { colors } = useBanditTheme();
  const { scrollToTop, isEndReached, selectedBrands, openModal } = useEndOfFeedScreenContext();

  return (
    <View style={tw`mt-6 flex-row items-center justify-center`}>
      <View>
        <View
          style={[
            tw`h-7 w-7 bg-[${colors.primary_yellow}] border-[1.5px] rounded-full flex justify-center items-center absolute z-10 -top-2 -right-2`,
            !selectedBrands?.length && tw`hidden`,
          ]}
        >
          <Badge
            style={[
              tw`bg-transparent text-black border-0 text-sm font-normal self-center`,
              { fontFamily: 'TheFuture-Regular' },
            ]}
          >
            {selectedBrands?.length}
          </Badge>
        </View>
        <Button
          title={i18n.t('end_of_feed_add_brands')}
          customStyle={tw`rounded-full px-6 py-3 bg-transparent border-[1.5px] border-black`}
          customTextStyle={[tw`text-black font-normal text-sm`, { fontFamily: 'TheFuture-Regular' }]}
          onPress={openModal}
        />
      </View>

      <Button
        title={i18n.t('end_of_feed_back_to_top')}
        customStyle={tw`rounded-full px-6 py-3 bg-black border-[1.5px] border-black ml-2.5`}
        customTextStyle={[tw`text-white	font-normal text-sm`, { fontFamily: 'TheFuture-Regular' }]}
        onPress={() => {
          scrollToTop();
        }}
      />
    </View>
  );
};

export default EndOfFeedActionsBtn;
