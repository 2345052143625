/* eslint-disable complexity */
import { BackHeader } from '@components';
import { i18n } from '@core';
import { useCountdown } from '@hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ExclamationCircle } from '@svgIcons';
import { useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Dialogue, { AlertProps } from 'src/components/Dialogue/Dialogue';
import { tw } from 'src/core/tw';
import { PaymentScreenNavigationProps, PaymentScreenRouteProps } from 'src/domain/payment/PaymentRoutes';
import useCancelOrder from '../../order/hooks/useCancelOrder';
import PaymentWebView from '../components/PaymentWebView';
import { useHandlePaymentCallback } from '../hooks/useHandlePaymentCallback';

const PaymentScreen = () => {
  const navigation = useNavigation<PaymentScreenNavigationProps>();
  const { params } = useRoute<PaymentScreenRouteProps>();
  const [dialogueContent, setDialogueContent] = useState<AlertProps | null>(null);

  const currentTime = useMemo(() => new Date().toString(), []);
  const { timeLeft } = useCountdown({
    timeout: params.session?.timeout ?? currentTime,
    autoFormat: true,
    onTimeout: () => {
      if (!checkingPayment) {
        setDialogueContent({
          closeText: 'OK',
          title: i18n.t('session_timeout'),
          message: '',
          handleClose: () => handleGoback(),
        });
      }
    },
  });

  const { cancelOrder, loading } = useCancelOrder({
    orderId: params.orderId,
    cartId: params.cartId,
  });

  const { checkingPayment } = useHandlePaymentCallback();

  function handleGoback() {
    if (!checkingPayment) {
      cancelOrder();
    }
    navigation.goBack();
  }

  if (checkingPayment) {
    return (
      <>
        <BackHeader goBack={handleGoback} title={''} />
        <View style={tw`flex-1 justify-center items-center`}>
          <ActivityIndicator size={50} />
          <Text>{i18n.t('checking_order_status')}</Text>
        </View>
      </>
    );
  }

  if (!params.session?.id || !params.session?.sessionData) {
    return (
      <>
        <BackHeader goBack={handleGoback} title={''} />
        <View style={tw`flex-1 justify-center items-center bg-white`}>
          <ExclamationCircle style={tw`text-red-500 w-12 h-12`} />
        </View>
      </>
    );
  }

  return (
    <>
      <BackHeader goBack={handleGoback} title={timeLeft} />
      {dialogueContent && <Dialogue {...dialogueContent} />}
      {loading ? (
        <View style={tw`flex-1 justify-center items-center bg-white`}>
          <ActivityIndicator size={50} />
        </View>
      ) : (
        <PaymentWebView sessionId={params.session.id} sessionData={params.session.sessionData} />
      )}
    </>
  );
};
export default PaymentScreen;
