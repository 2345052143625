import { Video } from 'expo-av';
import { useRef as defaultUseRef, useEffect, useState } from 'react';
import { LayoutAnimation, Platform, Pressable, UIManager } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS } from 'react-native-reanimated';
import { tw } from 'src/core/tw';
import FloatingVideo from '../FloatingVideo/FloatingVideo';

type VideoSize = 'large' | 'mini';

const ANIMATION_DURATON = 500;

const PAN_OFFSET = -50;

const VIDEO_SIZE = {
  large: { width: 116, height: 204 },
  mini: { width: 52, height: 88 },
};

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface IFloatingVideoWrapperProps {
  videoUrl: string;
  useRef?: <Video>(initialValue: Video | null) => React.RefObject<Video>;
}

const FloatingVideoWrapper = ({ videoUrl, useRef = defaultUseRef }: IFloatingVideoWrapperProps) => {
  const videoRef = useRef<Video>(null);

  const [videoSize, setVideoSize] = useState<VideoSize>('large');

  const [shouldToggle, setShouldToggle] = useState(false);

  const toggleVideo = (size: VideoSize) => {
    LayoutAnimation.configureNext({
      duration: ANIMATION_DURATON,
      update: { type: 'spring', springDamping: 0.7 },
    });
    setVideoSize(size);
  };

  const panGesture = Gesture.Pan()
    .withTestId('pan')
    .onUpdate((event) => {
      if (event.translationY < PAN_OFFSET) {
        runOnJS(setShouldToggle)(true);
      }
    });

  const handleShowLargeVideo = () => {
    if (videoSize === 'mini') {
      setShouldToggle(false);
      toggleVideo('large');
    }
  };

  const handleShowMiniVideo = () => {
    toggleVideo('mini');
  };

  useEffect(() => {
    if (shouldToggle) {
      handleShowMiniVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldToggle]);

  if (Platform.OS === 'web') {
    return (
      <Animated.View style={[tw`z-10 right-4 items-end mt-20`, { position: 'fixed' }]}>
        <Pressable testID="video-wrapper" style={VIDEO_SIZE[videoSize]} onPress={handleShowLargeVideo}>
          <FloatingVideo videoUrl={videoUrl} videoRef={videoRef} videoSize={videoSize} />
        </Pressable>
      </Animated.View>
    );
  }

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View style={[tw`z-10 right-4 items-end mt-20 absolute`]}>
        <Pressable testID="video-wrapper" style={VIDEO_SIZE[videoSize]} onPress={handleShowLargeVideo}>
          <FloatingVideo videoUrl={videoUrl} videoRef={videoRef} videoSize={videoSize} />
        </Pressable>
      </Animated.View>
    </GestureDetector>
  );
};

export default FloatingVideoWrapper;
