import { Brands, Categories, Tags } from '@components';
import { i18n } from '@core';
import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { ICategory, IProducer, ITag } from '../../model';

interface SearchDefaultContainerProps {
  clickItem: (type: string, name: string) => void;
  categories: ICategory[];
  tags: ITag[];
  producers: IProducer[];
}

const SearchDefaultContainer = ({ categories, tags, producers, clickItem }: SearchDefaultContainerProps) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false} alwaysBounceVertical={false}>
      <View style={tw`mt-7.5`}>
        <Text
          variant="headlineSmall"
          style={[tw`mb-2.5 leading-5 text-black font-normal text-4.5`, { fontFamily: 'TheFuture-Regular' }]}
        >
          {i18n.t('search_default_categories')}
        </Text>
        <Categories list={categories} clickHandler={(name) => clickItem('categories', name)} />
      </View>

      {producers.length > 0 && (
        <View style={tw`mt-10`}>
          <Text variant="headlineSmall" style={tw`mb-5`}>
            {i18n.t('search_default_brands')}
          </Text>
          <Brands brands={producers} clickHandler={(name) => clickItem('producers', name)} />
        </View>
      )}
      {tags.length > 0 && (
        <View style={tw`mt-10`}>
          <Text variant="headlineSmall" style={tw`mb-5`}>
            {i18n.t('search_default_tags')}
          </Text>
          <Tags tags={tags} clickHandler={(name) => clickItem('tags', name)} />
        </View>
      )}
    </ScrollView>
  );
};

export default SearchDefaultContainer;
