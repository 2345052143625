import ExpoStatusBar from 'expo-status-bar/build/ExpoStatusBar';
import { useEffect } from 'react';
import { BackHandler, SafeAreaView, StatusBar, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import BackButton from './BackButton/BackButton';

interface BackHeaderProps {
  title?: string;
  goBack: () => void;
  titleStyle?: ViewStyle | ViewStyle[];
  containerStyle?: ViewStyle;
}

export const BackHeader = ({ title, goBack, containerStyle, titleStyle = {} }: BackHeaderProps) => {
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      goBack();
      return true;
    });

    return () => backHandler.remove();
  }, []);

  return (
    <SafeAreaView style={[tw`w-full border-b-2 bg-white`, { paddingTop: StatusBar.currentHeight }, containerStyle]}>
      <ExpoStatusBar style="dark" backgroundColor="white" />
      <View style={tw`w-full flex flex-row p-4 items-center bg-white`}>
        <View style={tw`flex-1`}>
          <BackButton goBack={goBack} />
        </View>
        {!!title && (
          <Text variant="bodyLarge" style={[tw`text-lg`, titleStyle]}>
            {title}
          </Text>
        )}
        {/* Dummy field for centering title text */}
        <Text style={tw`flex-1`}>{''}</Text>
      </View>
    </SafeAreaView>
  );
};

export default BackHeader;
