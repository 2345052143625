import { IImage, ProductActiveGroupOrders, ProductDetails } from '@core';
import { ScrollView, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import { useFullScreenImages } from '../../hooks';
import AboutSeller from '../AboutSeller/AboutSeller';
import BottomPanel from '../BottomPanel/BottomPanel';
import FaqSection from '../FaqSection/FaqSection';
import FloatingVideoWrapper from '../FloatingVideoWrapper/FloatingVideoWrapper';
import GroupOrders from '../GroupOrders/GroupOrders';
import ProductBasicDetail from '../ProductBasicDetail/ProductBasicDetail';
import ProductCarousel from '../ProductCarousel/ProductCarousel';
import ProductDetailTopAction from '../ProductDetailTopAction/ProductDetailTopAction';
import ProductImageGallery from '../ProductImageGallery/ProductImageGallery';

interface IProductDetailViewProps {
  product: ProductDetails;
  activeGroupOrders: ProductActiveGroupOrders[];
  myActiveGroups?: ProductActiveGroupOrders;
  isInvited?: boolean;
}

// eslint-disable-next-line complexity
const ProductDetailView = ({ product, myActiveGroups, activeGroupOrders, isInvited }: IProductDetailViewProps) => {
  const { handleFullScreenImage } = useFullScreenImages();

  const onPressImage = (images: IImage[] = [], index: number) => {
    handleFullScreenImage(images, index);
  };

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <View style={tw`flex-1`}>
        <ScrollView contentContainerStyle={tw`relative pb-40`} showsVerticalScrollIndicator={false}>
          <ProductDetailTopAction product={product} />

          {!!product.productImages.carouselImages.length && (
            <View>
              <ProductCarousel images={product.productImages} onPressImage={onPressImage} />
              {!!product.videoUrl && <FloatingVideoWrapper videoUrl={product.videoUrl} />}
            </View>
          )}

          <GroupOrders
            product={product}
            activeGroupOrders={activeGroupOrders}
            myActiveGroup={myActiveGroups}
            isInvited={isInvited}
          />

          <ProductBasicDetail product={product} activeGroupOrders={activeGroupOrders} />

          <View style={tw`mt-14`}>
            <Divider
              style={tw`border-black border-b-2`}
              accessibilityLabelledBy={undefined}
              accessibilityLanguage={undefined}
            />
            <ProductImageGallery images={product.productImages} onPressImage={onPressImage} />
          </View>

          {!!product.producer.description && (
            <View style={tw`mt-10 pb-${!product.faqs.length ? 10 : 0}`}>
              <AboutSeller
                images={product.producerImages.carouselImages}
                description={product.producer.description}
                onShowFullScreenImage={onPressImage}
              />
            </View>
          )}

          {!!product.producer.description && !!product.faqs.length && (
            <Divider
              style={tw`border-black border-b-2 mt-12`}
              accessibilityLabelledBy={undefined}
              accessibilityLanguage={undefined}
            />
          )}

          {!!product.faqs.length && (
            <View style={tw`mt-10 pb-7`}>
              <FaqSection faqList={product.faqs} />
            </View>
          )}
        </ScrollView>

        <BottomPanel
          id={product.id}
          groupSize={product.groupSize}
          soloPrice={product.soloPrice}
          groupPrice={product.groupPrice}
          currency={product.currency}
          myActiveGroups={myActiveGroups}
          isInvited={isInvited}
        />
      </View>
    </SafeAreaView>
  );
};

export default ProductDetailView;
