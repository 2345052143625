import { useBanditTheme } from '@core';
import { useCountdown } from '@hooks';
import { ClockIcon } from '@svgIcons';
import { Text, View } from 'react-native';
import { tw } from 'src/core/tw';

interface TimerCardProps {
  expiredDate: string;
}

const TimerCard = ({ expiredDate }: TimerCardProps) => {
  const { timeLeft } = useCountdown({ timeout: expiredDate, autoFormat: true });

  const { colors } = useBanditTheme();

  return (
    <View
      testID="timer-card"
      style={[
        tw`flex-row justify-center items-center py-2 mx-4 border rounded-xl mt-6`,
        {
          backgroundColor: colors.secondary_fuschia,
        },
      ]}
    >
      <Text style={[tw`mr-2`, { fontFamily: 'TheFuture-Regular', fontSize: 40 }]}>{timeLeft}</Text>
      <ClockIcon fontSize={18} accessibilityRole="image" style={tw`mt-1`} />
    </View>
  );
};

export default TimerCard;
