import { OrderStatus, useBanditTheme } from '@core';

export function useStatusColor(status: OrderStatus) {
  const { colors } = useBanditTheme();

  if (status === OrderStatus.Canceled) {
    return colors?.secondary_ember;
  }

  if (status === OrderStatus.Completed) {
    return colors?.primary_yellow;
  }

  return colors?.secondary_fuschia_tint;
}
