import Svg, { Path, SvgProps } from 'react-native-svg';

type DealsLogoProps = {
  color: string;
} & SvgProps;

const DealsLogo = (props: DealsLogoProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M6.45339 13.0546C6.34448 12.7603 6.06383 12.5649 5.75 12.5649C5.43617 12.5649 5.15552 12.7603 5.04661 13.0546L4.08593 15.6508L1.48972 16.6115C1.1954 16.7204 1 17.0011 1 17.3149C1 17.6287 1.1954 17.9094 1.48972 18.0183L4.08593 18.979L5.04661 21.5752C5.15552 21.8695 5.43617 22.0649 5.75 22.0649C6.06383 22.0649 6.34448 21.8695 6.45339 21.5752L7.41407 18.979L10.0103 18.0183C10.3046 17.9094 10.5 17.6287 10.5 17.3149C10.5 17.0011 10.3046 16.7204 10.0103 16.6115L7.41407 15.6508L6.45339 13.0546Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <Path
      d="M15.888 1.48923C15.779 1.19491 15.4984 0.999512 15.1846 0.999512C14.8707 0.999512 14.5901 1.19491 14.4812 1.48923L12.7102 6.27516L7.92429 8.04612C7.62997 8.15503 7.43457 8.43568 7.43457 8.74951C7.43457 9.06334 7.62997 9.34399 7.92429 9.4529L12.7102 11.2238L14.4812 16.0098C14.5901 16.3041 14.8707 16.4995 15.1846 16.4995C15.4984 16.4995 15.779 16.3041 15.888 16.0098L17.6589 11.2238L22.4448 9.4529C22.7392 9.34399 22.9346 9.06334 22.9346 8.74951C22.9346 8.43568 22.7392 8.15503 22.4448 8.04612L17.6589 6.27516L15.888 1.48923Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </Svg>
);

export default DealsLogo;
