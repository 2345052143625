import { i18n, useBanditTheme } from '@core';
import { CoinLogo } from '@svgIcons';
import { useEffect } from 'react';
import { Pressable, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import useUserStore, { UseUserStore } from 'src/core/store/user/useUserStore';
import { tw } from 'src/core/tw';
import { shallow } from 'zustand/shallow';

const selector = ({ user }: UseUserStore) => ({ user });

interface IBanditCashCardProps {
  priceCoinAmount: number;
  isCheck: boolean;
  onToggleCheck: () => void;
  onUpdateBanditCash: (coin: number) => void;
}

const BanditCashCard = ({ priceCoinAmount, isCheck, onToggleCheck, onUpdateBanditCash }: IBanditCashCardProps) => {
  const { colors } = useBanditTheme();

  const { user } = useUserStore(selector, shallow);

  const banditCashAmount = Math.min(user?.coinAmount || 0, Number(Math.floor(priceCoinAmount / 2)));

  useEffect(() => {
    onUpdateBanditCash(banditCashAmount);
  }, [banditCashAmount]);

  if (!user?.coinAmount || user?.coinAmount <= 0) {
    return <View />;
  }

  return (
    <Pressable
      onPress={onToggleCheck}
      style={tw`p-2 pr-5 bg-[${colors.secondary_cosmos_tint}] border-2 rounded-xl flex-row items-center justify-between`}
    >
      <View style={tw`flex-row items-center mr-3`}>
        <IconButton
          accessibilityRole="button"
          icon="check"
          style={tw`border-2 border-black bg-${isCheck ? 'black' : 'white'}`}
          iconColor="white"
          size={18}
          accessibilityLabelledBy={undefined}
          accessibilityLanguage={undefined}
        />
        <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('purchase_modal_use_bandit_cash')}
        </Text>
      </View>

      <View style={tw`flex-row items-center`}>
        <Text style={[tw`text-sm mr-1`, { fontFamily: 'TheFuture-Regular' }]}>{banditCashAmount}</Text>
        <CoinLogo />
      </View>
    </Pressable>
  );
};

export default BanditCashCard;
