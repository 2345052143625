/* eslint-disable complexity */
import { ApolloProvider } from '@apollo/client';
import { AnimatedAppLoader, AppBox } from '@components';
import { client, config, i18n, injectWebCss, storeInvitedBy, theme } from '@core';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { NavigationContainer } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as Updates from 'expo-updates';
import { useEffect, useMemo } from 'react';
import { Platform, Text } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider as PaperProvider } from 'react-native-paper';
import { ToastProvider } from 'react-native-toast-notifications';
import { TourGuideProvider } from 'rn-tourguide';
import * as Sentry from 'sentry-expo';
import { CustomTooltip } from 'src/components/CoachMark/CustomToolTip';
import { Messages } from 'src/components/Messages/Messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useDetectOs } from 'src/hooks/useDetectOs';
import useOneSignal from 'src/hooks/useOneSignal/useOneSignal';
import SplashLogo from './assets/splash.png';
import { Routes } from './src/routes/index';

injectWebCss();

const checkForOTAUpdates = async () => {
  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
    }
  } catch (e) {
    sendErrorToSentry(e, {
      tags: {
        feature: 'ota-update',
      },
    });
  }
};

// TODO need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (typeof window !== 'undefined') {
  // @ts-expect-error Property _frameTimestamp does not exist on type 'Window & typeof globalThis'.
  window._frameTimestamp = null;
}

function App() {
  const prefix = Linking.createURL('/');
  const BASE_URL = Constants?.expoConfig?.extra?.WEBSITE_URL;
  const APP_NAME = Constants?.expoConfig?.extra?.APP_NAME;

  const API_ENDPOINT = Constants?.expoConfig?.extra?.apiUrl;

  const scheme = Constants?.expoConfig?.scheme;

  const url = Linking.useURL();

  const os = useDetectOs();

  useEffect(() => {
    if (url) {
      handleInviteUrl(url);
    }
  }, [url]);

  useEffect(() => {
    const isDev = Constants?.expoConfig?.extra?.APP_ENV === 'development';
    if (!isDev) {
      Sentry.init({
        dsn: Constants?.expoConfig?.extra?.SENTRY_DSN,
        enableInExpoDevelopment: false,
        debug: false,
      });
      checkForOTAUpdates();
    }
  }, []);

  const checkOpenInApp = async (webUrl: string) => {
    const origin = window.location.origin;

    const appURL = webUrl.replace(new RegExp(origin, 'i'), `${scheme}:/`);

    await Linking.openURL(appURL);
  };

  const handleInviteUrl = async (inviteUrl: string) => {
    const { queryParams } = Linking.parse(inviteUrl);

    if (queryParams?.ref) {
      await storeInvitedBy(queryParams?.ref as string);
    }

    if (Platform.OS === 'web') {
      await checkOpenInApp(inviteUrl);
    }
  };

  const apolloClient = useMemo(() => client(i18n.t('general_error_message'), API_ENDPOINT), [API_ENDPOINT]);
  useOneSignal();

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ApolloProvider client={apolloClient}>
        <TourGuideProvider preventOutsideInteraction tooltipComponent={CustomTooltip}>
          <PaperProvider theme={theme}>
            <Messages />
            <AnimatedAppLoader image={SplashLogo}>
              {Platform.OS === 'web' && os !== 'unknown' && <AppBox osType={os} />}
              <BottomSheetModalProvider>
                <NavigationContainer
                  documentTitle={{
                    formatter: () => APP_NAME,
                  }}
                  linking={{
                    prefixes: [prefix, BASE_URL, `${scheme}://`],
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    config: config,
                  }}
                  fallback={<Text>Loading...</Text>}
                >
                  <ToastProvider offsetBottom={Platform.OS === 'android' ? 80 : 95}>
                    <Routes />
                  </ToastProvider>
                </NavigationContainer>
              </BottomSheetModalProvider>
            </AnimatedAppLoader>
          </PaperProvider>
        </TourGuideProvider>
      </ApolloProvider>
    </GestureHandlerRootView>
  );
}

export default App;
