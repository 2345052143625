import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query GetBrands {
    brands(where: { logoUrl: { _neq: "" } }) {
      name
      logoUrl
    }
  }
`;

export const CREATE_BRAND_VOTES = gql`
  mutation CreateBrandVotes($brandNames: [String!]) {
    brandVotesCreate(request: { brandNames: $brandNames }) {
      id
    }
  }
`;
