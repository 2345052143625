import { i18n } from '@core';
import { FC } from 'react';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

export const SearchResultHeader: FC = () => {
  return (
    <Text
      variant="headlineSmall"
      style={[tw`mb-3 text-black font-normal text-4.5`, { fontFamily: 'TheFuture-Regular' }]}
    >
      {i18n.t('search_result_products')}
    </Text>
  );
};
