import { PaginationItem } from '@components';
import { i18n } from '@core';
import { setStatusBarHidden } from 'expo-status-bar';
import { useEffect } from 'react';
import { Dimensions, Image, Platform, ScrollView, View } from 'react-native';
import Modal from 'react-native-modal';
import { IconButton } from 'react-native-paper';
import { useSharedValue } from 'react-native-reanimated';
import Carousel from 'react-native-reanimated-carousel';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import { useFullScreenImages } from '../../hooks';

const SCREEN_DIMENSIONS = Dimensions.get('screen');

const MAXIMUM_ZOOM_SCALE = 5;

interface IFullScreenCarouselProps {
  isVisible: boolean;
  onClose: () => void;
}

const FullScreenCarousel = ({ isVisible, onClose }: IFullScreenCarouselProps) => {
  const { top } = useSafeAreaInsets();

  const {
    fullScreenImageState: { images, activeIndex },
  } = useFullScreenImages();

  const progressValue = useSharedValue<number>(activeIndex || 0);

  const handleProgressChange = (_: number, absoluteProgress: number) => {
    progressValue.value = absoluteProgress;
  };

  useEffect(() => {
    if (isVisible) {
      setStatusBarHidden(true, 'fade');
    }

    return () => setStatusBarHidden(false, 'fade');
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      testID="full-screen-image-carousel"
      style={tw`flex-1 m-0`}
      backdropOpacity={1}
      animationIn="fadeIn"
      animationInTiming={200}
      animationOut="fadeOut"
      animationOutTiming={200}
      useNativeDriver
      coverScreen={Platform.OS !== 'android'}
      onBackButtonPress={onClose}
    >
      <SafeAreaProvider style={tw`flex-1 justify-end`}>
        <View style={tw`absolute top-[${top + 16}px] right-4 z-5`}>
          <IconButton
            accessibilityLabelledBy={'full-screen-image-carousel-close-button'}
            accessibilityLanguage={i18n.locale}
            testID="full-screen-image-carousel-close-button"
            accessibilityRole="button"
            icon="close"
            style={tw`bg-white border-2 border-black m-0`}
            onPress={onClose}
            size={18}
          />
        </View>
        <ScrollView
          contentContainerStyle={tw`flex-1 items-center justify-center`}
          maximumZoomScale={MAXIMUM_ZOOM_SCALE}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEnabled
          horizontal
        >
          <Carousel
            testID="carousel"
            data={images}
            width={SCREEN_DIMENSIONS.width}
            height={SCREEN_DIMENSIONS.height}
            defaultIndex={activeIndex}
            onProgressChange={handleProgressChange}
            renderItem={({ item: { name, url } }) => (
              <Image
                key={name}
                accessibilityRole="image"
                style={{ width: SCREEN_DIMENSIONS.width, height: '100%' }}
                source={{ uri: url }}
                resizeMode="contain"
              />
            )}
          />
        </ScrollView>

        <View style={tw`absolute bottom-10 flex-row self-center`}>
          {images.map(({ name }, index) => (
            <PaginationItem key={name} animValue={progressValue} index={index} length={images.length} />
          ))}
        </View>
      </SafeAreaProvider>
    </Modal>
  );
};

export default FullScreenCarousel;
