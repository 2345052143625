import { ApolloError, useLazyQuery } from '@apollo/client';
import { generatePaymentScreenLink, IGetPaymentSessionRequest, IGetPaymentSessionResponse, PAYMENT_URL } from '@core';
import { useNavigation } from '@react-navigation/native';
import { Alert, Platform } from 'react-native';
import useModalState from '../../../../screens/ProductDetailScreen/hooks/useModalState/useModalState';
import { GET_PAYMENT_SESSION } from '../../../../screens/ProductDetailScreen/query';
import { PaymentScreenNavigationProps } from '../../PaymentRoutes';

const usePaymentSession = () => {
  const navigation = useNavigation<PaymentScreenNavigationProps>();

  const { handleCloseLoading } = useModalState();

  const [getPaymentSession, { loading }] = useLazyQuery<IGetPaymentSessionResponse, IGetPaymentSessionRequest>(
    GET_PAYMENT_SESSION,
  );

  const handleCreatePayment = async ({ cartId, orderId }: { cartId: string; orderId: string }) => {
    const returnUrl = generatePaymentScreenLink({ cartId, orderId });

    getPaymentSession({
      variables: { cartId, returnUrl },
      onCompleted: async ({ paymentSession: { session } }: IGetPaymentSessionResponse) => {
        handleCloseLoading();
        if (Platform.OS === 'web') {
          const url = new URL(PAYMENT_URL);
          url.searchParams.append('sessionId', session.id);
          url.searchParams.append('sessionData', session.sessionData);
          url.searchParams.append('returnUrl', returnUrl);
          window.location.href = url.toString();
          return;
        }

        navigation.navigate('PaymentScreen', {
          orderId,
          cartId,
          session: {
            ...session,
            timeout: session.expiresAt.replace('Z', ''),
          },
        });
      },
      onError: (error: ApolloError) => {
        handleCloseLoading();
        Alert.alert('Error', error.message);
      },
    });
  };

  return { handleCreatePayment, loading };
};

export default usePaymentSession;
