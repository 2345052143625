/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/display-name */
import { useReactiveVar } from '@apollo/client';
import { TabHeader } from '@components';
import { authVar, getIsShowTutorial, i18n, storeIsShowTutorial, useBanditTheme } from '@core';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DealScreen, GroupsScreen, ProfileScreen, SearchScreen, VideoScreen } from '@screens';
import { useEffect, useState } from 'react';
import { Dimensions, Keyboard, Platform, SafeAreaView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { TourGuideZone, useTourGuideController } from 'rn-tourguide';
import { tw } from 'src/core/tw';
import { MainTabParamList } from './mainRoutes.model';
import TabIcon from './TabIcon';
const Tab = createBottomTabNavigator<MainTabParamList>();

export const MainRoutes = () => {
  const [isKeyboardActive, setIsKeyboardActive] = useState<boolean>(false);
  const auth = useReactiveVar(authVar);

  const windowHeight = Dimensions.get('window').height;

  const { colors } = useBanditTheme();

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardActive(true);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardActive(false);
    });

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const ios = Platform.OS === 'ios';

  const maskOffset = ios ? windowHeight / 4 : 120;

  const maskBottomOffset = ios ? windowHeight / 1.8 : windowHeight / 2.2;

  const { canStart, start, tourKey } = useTourGuideController();

  const [isShowTutorial, setShowTutorial] = useState<string | null>();

  const checkIsShowTutorial = async () => {
    const intialValue = await getIsShowTutorial();
    setShowTutorial(intialValue);
  };

  const storeShowValue = async () => {
    await storeIsShowTutorial(false);
  };

  useEffect(() => {
    checkIsShowTutorial();
    if (isShowTutorial === null) {
      start();
      storeShowValue();
    }
  }, [canStart, isShowTutorial]);

  const insets = useSafeAreaInsets();
  return (
    <>
      <Tab.Navigator
        screenOptions={() => ({
          tabBarActiveTintColor: colors.black,
          tabBarInactiveTintColor: colors.gray,
          tabBarStyle: {
            height: Math.min(insets.bottom, 25) + 50,
            borderTopWidth: 2,
            paddingTop: insets.bottom > 0 ? 5 : 0,
            borderTopColor: colors.black,
          },
          tabBarItemStyle: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
          tabBarLabelStyle: {
            fontSize: 9,
          },
          tabBarIconStyle: {
            height: 25,
            width: 25,
          },
          header: () => <TabHeader showBorder />,
        })}
      >
        <Tab.Screen
          name="VideoScreen"
          component={VideoScreen}
          options={{
            tabBarLabel: `${i18n.t('tab_video_label')}`,
            // headerShown: false,
            tabBarIcon: ({ focused }) => {
              return (
                <TourGuideZone tourKey={tourKey} zone={2} maskOffset={50} tooltipBottomOffset={80} shape={'circle'}>
                  <TabIcon focused={focused} name="video" />
                </TourGuideZone>
              );
            },
          }}
        />
        <Tab.Screen
          name="DealScreen"
          component={DealScreen}
          options={{
            tabBarLabel: `${i18n.t('tab_deals_label')}`,
            lazy: true,
            headerShown: false,
            tabBarIcon: ({ focused }) => {
              return (
                <TourGuideZone tourKey={tourKey} zone={5} maskOffset={50} tooltipBottomOffset={80} shape={'circle'}>
                  <TabIcon focused={focused} name="deal" />
                </TourGuideZone>
              );
            },
          }}
        />
        <Tab.Screen
          name="SearchScreen"
          component={SearchScreen}
          options={{
            tabBarLabel: `${i18n.t('tab_search_label')}`,
            header: () => <TabHeader showBorder={false} backgroundColor={colors.bg_primary} />,
            tabBarIcon: ({ focused }) => {
              return (
                <TourGuideZone tourKey={tourKey} zone={6} maskOffset={50} tooltipBottomOffset={80} shape={'circle'}>
                  <TabIcon focused={focused} name="search" />
                </TourGuideZone>
              );
            },
          }}
        />
        <Tab.Screen
          name="GroupsScreen"
          component={GroupsScreen}
          options={{
            tabBarLabel: `${i18n.t('tab_groups_label')}`,
            header: () => <TabHeader showBorder={true} />,
            tabBarIcon: ({ focused }) => {
              return (
                <TourGuideZone tourKey={tourKey} zone={7} maskOffset={50} tooltipBottomOffset={80} shape={'circle'}>
                  <TabIcon focused={focused} name="group" />
                </TourGuideZone>
              );
            },
          }}
        />
        <Tab.Screen
          name="ProfileScreen"
          component={ProfileScreen}
          options={{
            headerShown: false,
            tabBarLabel: `${i18n.t('tab_profile_label')}`,
            tabBarIcon: ({ focused }) => {
              return (
                <TourGuideZone tourKey={tourKey} zone={8} maskOffset={50} tooltipBottomOffset={80} shape={'circle'}>
                  <TabIcon focused={focused} name="profile" />
                </TourGuideZone>
              );
            },
          }}
        />
      </Tab.Navigator>

      {ios && <SafeAreaView style={tw`bg-white max-h-3.5`} />}

      <TourGuideZone
        tourKey={tourKey}
        maskOffset={maskOffset}
        zone={1}
        tooltipBottomOffset={maskBottomOffset}
        shape={'rectangle'}
      >
        <View />
      </TourGuideZone>

      <TourGuideZone
        isTourGuide
        tourKey={tourKey}
        zone={9}
        maskOffset={maskOffset}
        tooltipBottomOffset={maskBottomOffset}
        shape={'rectangle'}
      >
        <View />
      </TourGuideZone>

      <TourGuideZone
        isTourGuide
        tourKey={tourKey}
        zone={10}
        maskOffset={maskOffset}
        tooltipBottomOffset={maskBottomOffset}
        shape={'rectangle'}
      >
        <View />
      </TourGuideZone>
    </>
  );
};

export default MainRoutes;
