import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { TextInput } from 'react-native-paper';
import { tw } from 'src/core/tw';
import useOrderInqueryFields from '../../hooks/useOrderInquiryFields/useOrderInquiryFields';
import { OrderInquiryFieldsProps } from '../../model';

const OrderInquiryFields = ({ orderId }: OrderInquiryFieldsProps) => {
  const { colors } = useBanditTheme();

  const { handleInputChange, loadingCreateOrder, handleSubmitIssue, isDisabledButton } = useOrderInqueryFields({
    orderId,
  });

  return (
    <>
      <TextInput
        mode="outlined"
        multiline
        placeholder={i18n.t('profile_helpAndFeedback_orderIssues_input_placeholder')}
        onChangeText={handleInputChange}
        textColor="black"
        style={tw`h-70 mt-14 mb-4 text-sm font-normal`}
        accessibilityLabelledBy={undefined}
        accessibilityLanguage={undefined}
        outlineStyle={tw`border border-black rounded-lg`}
        placeholderTextColor={colors.primary_black_300}
        dense
        theme={{
          fonts: { bodyLarge: { fontFamily: 'TheFuture-Regular' } },
        }}
      />
      <Button
        loading={loadingCreateOrder}
        title={i18n.t('profile_helpAndFeedback_orderIssues_submitButtonText')}
        onPress={handleSubmitIssue}
        disabled={isDisabledButton}
        customStyle={[
          tw`border-[1.5px] border-black p-3 bg-[${isDisabledButton ? colors.bg_primary_300 : colors.black}]`,
        ]}
        customTextStyle={[tw`text-sm text-white`, { fontFamily: 'TheFuture-Regular' }]}
      />
    </>
  );
};

export default OrderInquiryFields;
