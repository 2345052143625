import { ProductActiveGroupOrders, ProductDetails } from '@core';
import { View } from 'react-native';
import { tw } from 'src/core/tw';
import ProductInfo from '../ProductInfo/ProductInfo';
import ProductTags from '../ProductTags/ProductTags';
import ProductVariants from '../ProductVariants/ProductVariants';

interface IProductBasicDetailProps {
  product: ProductDetails;
  activeGroupOrders: ProductActiveGroupOrders[];
}

const ProductBasicDetail = ({ product, activeGroupOrders }: IProductBasicDetailProps) => {
  return (
    <>
      <View style={tw`mt-8 mx-4`}>
        <ProductInfo {...product} activeGroupOrders={activeGroupOrders} />
      </View>

      {!!product.tags.length && (
        <View style={tw`mx-4 mt-6`}>
          <ProductTags tags={product.tags} />
        </View>
      )}

      {product.hasOption && !!product.variants.length && (
        <View style={tw`mt-10`}>
          <ProductVariants {...product} />
        </View>
      )}
    </>
  );
};

export default ProductBasicDetail;
