import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const CoinLogo = (props: SvgProps) => (
  <Svg width="24" height="24" fill="none" {...props}>
    <Circle cx={12} cy={12} r={11.25} fill="#FFE757" stroke="#FF7545" strokeWidth={1.5} />
    <Path
      d="M13.172 5.007c-.402-1.088-1.942-1.088-2.344 0L9.256 9.256l-4.249 1.572c-1.088.402-1.088 1.942 0 2.344l4.249 1.572 1.572 4.249c.402 1.088 1.942 1.088 2.344 0l1.572-4.249 4.249-1.572c1.088-.402 1.088-1.942 0-2.344l-4.249-1.572-1.572-4.249Z"
      fill="#FFE757"
      stroke="#FF7545"
      strokeWidth={1.5}
    />
  </Svg>
);

export default CoinLogo;
