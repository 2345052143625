import { Loading } from '@components';
import { ProductActiveGroupOrders, ProductDetails } from '@core';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { tw } from 'src/core/tw';
import useModalState from '../../hooks/useModalState/useModalState';
import ActiveGroupInfoModal from '../ActiveGroupInfoModal/ActiveGroupInfoModal';
import FullScreenCarousel from '../FullScreenCarousel/FullScreenCarousel';
import PurchasePanel from '../PurchasePanel/PurchasePanel';

interface IProductDetailModalsProps {
  product: ProductDetails;
  myActiveGroups?: ProductActiveGroupOrders;
}

const ProductDetailModals = ({ product, myActiveGroups }: IProductDetailModalsProps) => {
  const { modalState, showLoading, handleCloseModal } = useModalState();

  const isAlreadyInGroup = !!myActiveGroups;

  return (
    <>
      <FullScreenCarousel isVisible={modalState === 'fullScreenImage'} onClose={handleCloseModal} />

      <PurchasePanel
        {...product}
        isVisible={modalState === 'purchase' && !isAlreadyInGroup}
        onClose={handleCloseModal}
      />

      <ActiveGroupInfoModal
        isVisible={modalState === 'activeGroupInfo'}
        productId={product.id}
        groupSize={product.groupSize}
        onClose={handleCloseModal}
        groupPrice={product.groupPrice}
        soloPrice={product.soloPrice}
      />

      {showLoading && (
        <Animated.View style={tw`absolute w-full h-full bg-[#FFE757]/50 z-10`} entering={FadeIn} exiting={FadeOut}>
          <Loading style={tw`w-60 h-69`} />
        </Animated.View>
      )}
    </>
  );
};

export default ProductDetailModals;
