import { i18n } from '@core';

export const coachMarksTitles = [
  '',
  i18n.t('coachmark_title_video'),
  i18n.t('coachmark_title_tap_video'),
  i18n.t('coachmark_title_tap_deal'),
  i18n.t('coachmark_title_categories'),
  i18n.t('coachmark_title_search'),
  i18n.t('coachmark_title_group'),
  i18n.t('coachmark_title_profile'),
  i18n.t('coachmark_title_cashpoints'),
  i18n.t('coachmark_title_final'),
];

export const coachMarkDescriptions = [
  i18n.t('coachmark_description'),
  i18n.t('coachmark_description_video'),
  i18n.t('coachmark_description_tapping_video'),
  i18n.t('coachmark_description_video_button'),
  i18n.t('coachmark_description_deals'),
  i18n.t('coachmark_description_search'),
  i18n.t('coachmark_description_groups'),
  i18n.t('coachmark_description_profile'),
  i18n.t('coachmark_description_cashpoint'),
  i18n.t('coachmark_description_final'),
];
