import { View } from 'react-native';
import { tw } from 'src/core/tw';
import useGetBrandsHook from '../../hooks/useGetBrandsHook/useGetBrandsHook';
import EndOfFeedActionsBtn from '../EndOfFeedActionsBtn/EndOfFeedActionsBtn';
import EndOfFeedAddBrandsModal from '../EndOfFeedAddBrandsModal/EndOfFeedAddBrandsModal';
import EndOfFeedBrandItem from '../EndOfFeedBrandItem/EndOfFeedBrandItem';

const EndOfFeedBrands = () => {
  const { brandsData } = useGetBrandsHook();

  return (
    <View style={tw`items-center`}>
      <View style={tw`flex-row flex-wrap justify-center mt-9.5 -mr-2.5`}>
        {brandsData?.map((brand) => (
          <EndOfFeedBrandItem key={brand.logoUrl} brand={brand} />
        ))}
      </View>

      <EndOfFeedActionsBtn />

      <EndOfFeedAddBrandsModal />
    </View>
  );
};

export default EndOfFeedBrands;
