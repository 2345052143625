import { gql } from '@apollo/client';

export const GET_ACTIVE_GROUP_ORDERS = gql`
  query getActiveGroupOrders($visible: Boolean!, $isSingleOrder: Boolean!, $allowedStatuses: [String]!) {
    myOrders(
      where: {
        visible: { _eq: $visible }
        singleOrder: { _eq: $isSingleOrder }
        cart: { status: { _in: $allowedStatuses } }
      }
    ) {
      visible
      orderId
      orderDate
      status
      singleOrder
      members {
        userId
        photoUrl
      }
      productType {
        name
        currency
        id
        length
        soloPrice
        groupPrice
        groupSize
        imageUrls {
          originalUrl
        }
      }
      cart {
        id
      }
    }
  }
`;
