import { i18n, useBanditTheme } from '@core';
import { Platform, ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { CustomBottomSheetModal } from 'src/components/CustomBottomSheetModal/CustomBottomSheetModal';
import { tw } from 'src/core/tw';
import { useEndOfFeedScreenContext } from '../../Providers/EndOfFeedScreenProvider/EndOfFeedScreenProvider';
import EndOfFeedBrandInput from '../EndOfFeedAddBrandInput/EndOfFeedAddBrandInput';
import EndOfFeedModalActionsBtn from '../EndOfFeedModalActionsBtn/EndOfFeedModalActionsBtn';

const EndOfFeedAddBrandsModal = () => {
  const { colors } = useBanditTheme();
  const {
    isVisibleModal,
    closeModal,
    customBrands,
    intialCustomBrand,
    setCustomBrands,
    openFeedbackToast,
    scrollToTop,
    setSelectedBrands,
  } = useEndOfFeedScreenContext();

  return (
    <CustomBottomSheetModal
      backgroundColor={colors.secondary_cosmos_tint}
      closeModal={closeModal}
      isVisible={isVisibleModal}
      snapPoints={['20%', '75%']}
    >
      <SafeAreaProvider style={tw`flex-1 bg-red-200 justify-start`}>
        <View
          style={[
            tw`bg-[${colors.secondary_cosmos_tint}] px-3.5 flex-1`,
            Platform.OS === 'web' && tw`max-w-205 mx-auto w-full`,
          ]}
        >
          <View style={tw`flex-1 pt-${Platform.OS !== 'web' ? 0.5 : 18}`}>
            <View
              style={tw`bg-[${colors.secondary_fuschia_tint}] border-[1.5px] border-black border-black px-4.5 py-4 rounded-t-xl`}
            >
              <Text style={[tw`text-sm font-normal text-black`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('end_of_feed_title')}
              </Text>
            </View>

            <ScrollView
              contentContainerStyle={tw`overflow-hidden bg-white rounded-b-xl`}
              showsVerticalScrollIndicator={false}
            >
              <EndOfFeedBrandInput
                intialCustomBrand={intialCustomBrand}
                setCustomBrands={setCustomBrands}
                closeModal={closeModal}
                customBrands={customBrands}
                isVisibleModal={isVisibleModal}
              />
            </ScrollView>
          </View>

          <EndOfFeedModalActionsBtn
            setSelectedBrands={setSelectedBrands}
            openFeedbackToast={openFeedbackToast}
            scrollToTop={scrollToTop}
            closeModal={closeModal}
            customBrands={customBrands}
          />
        </View>
      </SafeAreaProvider>
    </CustomBottomSheetModal>
  );
};

export default EndOfFeedAddBrandsModal;
