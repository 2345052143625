import { i18n } from '@core';
import { LogoutLogo } from '@svgIcons';
import { TouchableOpacity } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface LogoutProps {
  handleLogout: () => void;
}

const Logout = ({ handleLogout }: LogoutProps) => {
  return (
    <TouchableOpacity
      style={tw`flex-row border-2 rounded-lg px-2 bg-white mt-4 py-4 items-center`}
      onPress={handleLogout}
      accessibilityRole="button"
      testID="BUTTON.LOGOUT"
    >
      <LogoutLogo accessibilityRole="image" />
      <Text variant="bodyMedium" style={tw`ml-4`}>
        {i18n.t('profile_logout')}
      </Text>
    </TouchableOpacity>
  );
};
export default Logout;
