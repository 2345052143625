import Svg, { Path, SvgProps } from 'react-native-svg';

const Pencil = (props: SvgProps) => (
  <Svg width={12} height={12} fill="none" {...props}>
    <Path
      d="m4.315 10.177-1.62.206a1 1 0 0 1-1.118-1.118l.206-1.62a1 1 0 0 1 .285-.582l5.356-5.356a1 1 0 0 1 1.415 0l1.414 1.414a1 1 0 0 1 0 1.414L4.896 9.892a1 1 0 0 1-.58.285Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Pencil;
