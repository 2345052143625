import {
  CustomProducts,
  findProductId,
  getInitialSelectedVariants,
  IProductVariant,
  IProductVariantOption,
  useProductDetailsStore,
  UseProductDetailsStore,
} from '@core';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

const selector = ({
  selectedVariants,
  setSelectedVariants,
  selectedProductId,
  setSelectedProductId,
}: UseProductDetailsStore) => ({
  selectedVariants,
  setSelectedVariants,
  selectedProductId,
  setSelectedProductId,
});

const useVariants = (products?: CustomProducts[], variants?: IProductVariant[]) => {
  const { selectedVariants, setSelectedVariants, selectedProductId, setSelectedProductId } = useProductDetailsStore(
    selector,
    shallow,
  );

  const handleSelectVariant = (
    { parentName, value }: IProductVariantOption,
    callBack?: (newState: { [key: string]: string | null }, parentName: string) => void,
  ) => {
    const newValue = { ...selectedVariants, [parentName]: selectedVariants?.[parentName] === value ? null : value };
    setSelectedVariants(newValue);
    callBack?.(newValue, parentName);
  };

  const resetSelectedVariants = () => {
    setSelectedVariants(getInitialSelectedVariants(variants));
  };

  const handleSelectProductId = (id: string | null) => {
    setSelectedProductId(id);
  };

  useEffect(() => {
    if (!!products && !!selectedVariants && !!Object.entries(selectedVariants).length) {
      handleSelectProductId(findProductId(products, selectedVariants) || null);
    }
  }, [products, selectedVariants]);

  return { selectedVariants, selectedProductId, handleSelectVariant, resetSelectedVariants, handleSelectProductId };
};

export default useVariants;
