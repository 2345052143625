/* eslint-disable complexity */
import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import { authVar } from 'src/core/constant/auth';
import { useShowToast } from 'src/hooks/useShowToast';
import { IDealsFeed, IDealsFeedUser } from '../../model';
import { GET_DEALS_BY_CATEGORY, GET_DEALS_BY_CATEGORY_USER } from '../../query';

interface IUseDealsProps {
  categoryName?: string;
  limit?: number;
  offset?: number;
}

const useDealsHooks = ({ categoryName, offset }: IUseDealsProps) => {
  const auth = useReactiveVar(authVar);
  const toast = useShowToast();
  const { loggedIn } = auth;

  const onError = (error: ApolloError) => {
    toast.danger(error.message);
  };

  const {
    data: authenticatedFeedData,
    loading: loadingAuthFeedData,
    fetchMore: fetchMoreAuth,
    refetch: pullToRefreshAuth,
  } = useQuery<IDealsFeedUser>(GET_DEALS_BY_CATEGORY_USER, {
    variables: categoryName ? { where: { categoryName: { _eq: categoryName } }, offset: offset } : {},
    skip: !loggedIn,
    onError: onError,
  });

  const {
    data: unauthenticatedFeedData,
    loading: loadingUnAuthFeedData,
    fetchMore: fetchMoreNoAuth,
    refetch: pullToRefreshNoAuth,
  } = useQuery<IDealsFeed>(GET_DEALS_BY_CATEGORY, {
    variables: categoryName ? { where: { categoryName: { _eq: categoryName } }, offset: offset } : {},
    skip: loggedIn,
    onError: onError,
  });

  const isLoading = loadingAuthFeedData || loadingUnAuthFeedData;

  const fetchMore = fetchMoreAuth || fetchMoreNoAuth;

  const pullToRefresh = () => {
    if (loggedIn) {
      pullToRefreshAuth();
    } else {
      pullToRefreshNoAuth();
    }
  };

  return {
    feedData: auth.loggedIn
      ? authenticatedFeedData?.productTypeUserFeed
      : unauthenticatedFeedData?.productTypeGeneralFeed,
    isLoading,
    fetchMore,
    pullToRefresh,
  };
};

export default useDealsHooks;
