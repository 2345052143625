import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg';

type ProfileProps = {
  color: 'black' | 'gray';
} & SvgProps;

export const Profile = (props: ProfileProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#clip0_5368_17306)">
      <Path
        d="M15.8689 9.32768C15.3847 11.5751 13.3108 13.3292 11.0177 13.3292C9.88486 13.3292 9.07176 12.909 8.58756 12.2786C8.10336 11.6482 7.89323 10.7255 8.13076 9.6109C8.61497 7.36347 10.6888 5.60938 12.9819 5.60938C14.1148 5.60938 14.9279 6.02963 15.4121 6.66C15.8963 7.29038 16.1064 8.21311 15.8689 9.32768V9.32768Z"
        stroke={props.color}
        strokeWidth="1.5"
      />
      <Path
        d="M12.0044 0.685181C5.75548 0.685181 0.685059 5.74647 0.685059 12.0046C0.685059 15.4214 2.21075 18.4819 4.60436 20.5558C6.31277 17.4861 9.58342 15.3483 13.1556 15.3483C15.4213 15.3483 17.1114 16.1979 18.1346 17.5226C18.7833 18.3631 19.1852 19.432 19.2675 20.6654C21.7433 18.5915 23.3147 15.4762 23.3147 11.9954C23.3147 5.74647 18.2534 0.685181 12.0044 0.685181Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.0044 23.3148C18.2534 23.3148 23.3238 18.2535 23.3238 11.9954C23.3238 5.73733 18.2534 0.685181 12.0044 0.685181C5.75548 0.685181 0.685059 5.74647 0.685059 12.0046C0.685059 18.2626 5.75548 23.3239 12.0044 23.3239V23.3148Z"
        stroke={props.color}
        strokeWidth="1.5"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5368_17306">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Profile;
