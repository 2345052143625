/* eslint-disable complexity */
import { ShareGroupWrapper } from '@components';
import { getDiscountForProduct, i18n, useBanditTheme } from '@core';
import { LimitTimeOfferIcon, StarSuccessIcon } from '@svgIcons';
import { useMemo, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { Order } from 'src/domain/order/types';
import { TimerCard } from 'src/screens/GroupProgressModalScreen/components';
import TimeToMakeGroupIcon from 'src/svgIcons/StarShineIcon/TimeToMakeGroupIcon';
import useGetGroupMembers from '../hooks/useGetGroupMembers';
import ReceiptModal from './ReceiptModal';

const ShowReceiptButton = ({ order }: { order: Order }) => {
  const [showReceiptModal, setShowReceiptModal] = useState(false);

  return (
    <>
      <TouchableOpacity style={tw`px-6 py-4`} onPress={() => setShowReceiptModal(true)}>
        <Text style={[tw`text-sm text-center mx-18 underline`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('group_success_modal_see_details')}
        </Text>
      </TouchableOpacity>
      <ReceiptModal order={order} isVisible={showReceiptModal} handleCloseModal={() => setShowReceiptModal(false)} />
    </>
  );
};

const useGroupStateInfo = ({
  groupSize,
  numberOfGroupMembers,
}: {
  groupSize: number;
  numberOfGroupMembers: number;
}) => {
  const { colors } = useBanditTheme();

  return useMemo(() => {
    const remainingMembers = groupSize - numberOfGroupMembers;
    if (remainingMembers === 0) {
      return {
        title: i18n.t('group_success_modal_congratulations'),
        description: i18n.t('group_success_modal_group_filled_in_time'),
        icon: <StarSuccessIcon style={tw`my-10 mx-auto`} />,
      };
    }
    if (remainingMembers === 1) {
      return {
        title: i18n.t('only_member_left_modal_one_left_to_go'),
        description: i18n.t('only_member_left_modal_group_needs_only_one_more_member'),
        icon: <LimitTimeOfferIcon style={tw`my-10 mx-auto`} fill={colors.primary} viewBox="0 0 265 318" />,
      };
    }
    if (numberOfGroupMembers === 1) {
      return {
        title: i18n.t('time_to_make_group_modal_title'),
        description: i18n.t('time_to_make_group_modal_invite_friends', {
          count: remainingMembers,
        }),
        icon: <TimeToMakeGroupIcon style={tw`my-10 mx-auto`} />,
      };
    }
    return {
      title: i18n.t('you_are_in_the_group_title'),
      description: i18n.t('you_are_in_the_group_description', {
        count: remainingMembers,
      }),
      icon: (
        <LimitTimeOfferIcon
          accessibilityRole="image"
          style={tw`my-10 mx-auto`}
          width={136}
          height={163}
          viewBox="0 0 265 318"
          fill={colors.primary}
        />
      ),
    };
  }, [colors.primary, groupSize, numberOfGroupMembers]);
};

const GroupConfirmation = ({ order }: { order: Order }) => {
  const { colors } = useBanditTheme();
  const { groupDetails, loading } = useGetGroupMembers({ id: order.orderId });
  const { discountText } = getDiscountForProduct({
    soloPrice: order.productType.soloPrice,
    groupPrice: order.productType.groupPrice,
    currency: order.productType.currency,
  });
  const { title, description, icon } = useGroupStateInfo({
    groupSize: order.productType.groupSize,
    numberOfGroupMembers: order.members.length,
  });

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={tw`bg-[${colors.primary}]`}>
      <View style={tw`flex items-center py-10`}>
        <View onStartShouldSetResponder={() => true}>
          {loading || !groupDetails ? (
            <ActivityIndicator testID="activity-indicator" />
          ) : (
            <>
              <Text style={[tw`text-center mx-7`, { fontFamily: 'FeatureDeck-Regular', fontSize: 36 }]}>{title}</Text>

              {icon}

              <Text style={[tw`text-sm text-center mx-18 `, { fontFamily: 'TheFuture-Regular' }]}>{description}</Text>

              {order.productType.groupSize !== order.members.length && (
                <>
                  <TimerCard expiredDate={groupDetails.expiredDate} />

                  <Text style={[tw`text-sm text-center mt-5`, { fontFamily: 'TheFuture-Regular' }]}>
                    {`${i18n.t('you_are_in_the_group_save_when_group_reaches_goal', {
                      discount: discountText.replace(/^\-/, ''),
                    })}`}
                  </Text>

                  <ShareGroupWrapper
                    style={tw`mt-8 mx-auto bg-black rounded-full px-6 py-4`}
                    productId={order.productType.id}
                    groupId={groupDetails.id}
                    disabled={!groupDetails.id}
                  >
                    <Text style={[tw`text-sm text-white`, { fontFamily: 'TheFuture-Regular' }]}>
                      {i18n.t('common_invite_others')}
                    </Text>
                  </ShareGroupWrapper>
                </>
              )}

              <ShowReceiptButton order={order} />
            </>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default GroupConfirmation;
