import { i18n } from '@core';
import { StarFailure } from '@svgIcons';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface NoResultContainerProps {
  query: string;
}

const NoResultContainer = ({ query }: NoResultContainerProps) => {
  return (
    <View style={tw`flex-1 items-center justify-center`}>
      <Text
        style={[tw`text-center text-sm leading-5 font-normal text-black mb-9`, { fontFamily: 'TheFuture-Regular' }]}
        variant="labelSmall"
      >
        {i18n.t('search_no_result_text')} {'\n'} “{query}”
      </Text>
      <View>
        <StarFailure fillColor="transparent" />
      </View>
    </View>
  );
};

export default NoResultContainer;
