export const textColors = {
  'text-basic-color': '#002440',
};

const commonColors = {
  'color-grey-background': '#F5F7F9',
  'color-border': '#CDD4D9',
  'color-unread': '#CBE0EF',
  'color-white': '#ffffff',
  'color-curapoint': '#D0BC00',
  'color-tutorial': '#EBF2F8',
  'color-affiliate': '#15395A',
  'color-green': '#2FA118',
  'color-grey-200': '#F2F4F6',
  'color-grey-400': '#9DACB9',
  'color-grey-500': '#657B8C',
  'color-grey-600': '#4B5563',
  'color-grey-700': '#374151',
  'color-success-100': '#F4FDCA',
  'color-success-200': '#E6FB96',
  'color-success-300': '#D0F361',
  'color-success-400': '#B8E739',
  'color-success-500': '#97D700',
  'color-success-600': '#7CB800',
  'color-success-700': '#639A00',
  'color-success-800': '#4C7C00',
  'color-success-900': '#3B6700',
  'color-success-transparent-100': 'rgba(151, 215, 0, 0.08)',
  'color-success-transparent-200': 'rgba(151, 215, 0, 0.16)',
  'color-success-transparent-300': 'rgba(151, 215, 0, 0.24)',
  'color-success-transparent-400': 'rgba(151, 215, 0, 0.32)',
  'color-success-transparent-500': 'rgba(151, 215, 0, 0.4)',
  'color-success-transparent-600': 'rgba(151, 215, 0, 0.48)',
  'color-info-100': '#C9F1FB',
  'color-info-200': '#94DEF7',
  'color-info-300': '#5DBDE7',
  'color-info-400': '#3497D0',
  'color-info-500': '#0167B1',
  'color-info-600': '#004F98',
  'color-info-700': '#003B7F',
  'color-info-800': '#002A66',
  'color-info-900': '#001D54',
  'color-info-transparent-100': 'rgba(1, 103, 177, 0.08)',
  'color-info-transparent-200': 'rgba(1, 103, 177, 0.16)',
  'color-info-transparent-300': 'rgba(1, 103, 177, 0.24)',
  'color-info-transparent-400': 'rgba(1, 103, 177, 0.32)',
  'color-info-transparent-500': 'rgba(1, 103, 177, 0.4)',
  'color-info-transparent-600': 'rgba(1, 103, 177, 0.48)',
  'color-warning-100': '#FFF0CC',
  'color-warning-200': '#FFDC99',
  'color-warning-300': '#FFC366',
  'color-warning-400': '#FFAA3F',
  'color-warning-500': '#FF8200',
  'color-warning-600': '#DB6500',
  'color-warning-700': '#B74B00',
  'color-warning-800': '#933500',
  'color-warning-900': '#7A2600',
  'color-warning-transparent-100': 'rgba(255, 130, 0, 0.08)',
  'color-warning-transparent-200': 'rgba(255, 130, 0, 0.16)',
  'color-warning-transparent-300': 'rgba(255, 130, 0, 0.24)',
  'color-warning-transparent-400': 'rgba(255, 130, 0, 0.32)',
  'color-warning-transparent-500': 'rgba(255, 130, 0, 0.4)',
  'color-warning-transparent-600': 'rgba(255, 130, 0, 0.48)',
  'color-danger-100': '#FDE2D1',
  'color-danger-200': '#FBBFA3',
  'color-danger-300': '#F39274',
  'color-danger-400': '#E86750',
  'color-danger-500': '#DA291C',
  'color-danger-600': '#BB1416',
  'color-danger-700': '#9C0E1B',
  'color-danger-800': '#7E081E',
  'color-danger-900': '#68051F',
  'color-danger-transparent-100': 'rgba(218, 41, 28, 0.08)',
  'color-danger-transparent-200': 'rgba(218, 41, 28, 0.16)',
  'color-danger-transparent-300': 'rgba(218, 41, 28, 0.24)',
  'color-danger-transparent-400': 'rgba(218, 41, 28, 0.32)',
  'color-danger-transparent-500': 'rgba(218, 41, 28, 0.4)',
  'color-danger-transparent-600': 'rgba(218, 41, 28, 0.48)',
};

export const appThemeColor = {
  'color-primary-100': '#D0ECF6',
  'color-primary-200': '#A5D6EE',
  'color-primary-300': '#6EAACD',
  'color-primary-400': '#42759C',
  'color-primary-500': '#15395A',
  'color-primary-600': '#0F2C4D',
  'color-primary-700': '#0A2140',
  'color-primary-800': '#061734',
  'color-primary-900': '#04102B',
  'color-primary-transparent-100': 'rgba(21, 57, 90, 0.08)',
  'color-primary-transparent-200': 'rgba(21, 57, 90, 0.16)',
  'color-primary-transparent-300': 'rgba(21, 57, 90, 0.24)',
  'color-primary-transparent-400': 'rgba(21, 57, 90, 0.32)',
  'color-primary-transparent-500': 'rgba(21, 57, 90, 0.4)',
  'color-primary-transparent-600': 'rgba(21, 57, 90, 0.48)',
  'color-patient-msg': '#D5DCDE',
  ...commonColors,
  ...textColors,
};

const socialColors = {
  facebook: '#4267B2',
  google: '#4885ED',
  apple: '#1C1C1E',
  email: '#0167B1',
};

const medalColors = {
  gold: '#d1bd00',
  platinum: '#4f514e',
  silver: '#e5e4e2',
};

export const colors = {
  social: socialColors,
  appThemeColor: appThemeColor,
  medal: medalColors,
};

export const videoColors = {
  backgroundColor: '#F9F3DB',
  vidoeBackgroundColor: 'rgba(1,1,1,0.9)',
  textColorGrey: '#6E6E6E',
  videoOrange: '#FF7545',
  primaryColor: '#FFE757',
};

export const RANDOM_COLORS_LIST = ['#FFE757', '#FF94FF', '#9182FF', '#FF7545'];
