/* eslint-disable complexity */
import { Loading, SearchInput } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { tw } from 'src/core/tw';
import { useDebounce } from 'use-debounce';
import EndOfFeedScreen from '../EndOfFeedScreen/EndOfFeedScreen';
import { NoResultContainer, SearchDefaultContainer, SearchResultContainer } from './components';
import { useSearchHook } from './hooks';

const SEARCH_TEXT_LENGTH = 2;

const END_OF_SCREEN_OFFSET = 5;

const SearchScreen = () => {
  const searchScrollRef = useRef<ScrollView>(null);
  const [isEndReached, setIsEndReached] = useState(false);

  const navigation = useNavigation();

  const { resetData, resultData, handleSearchResult, loading } = useSearchHook();

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 300);

  const { colors } = useBanditTheme();

  useEffect(() => {
    if (debouncedQuery.length >= SEARCH_TEXT_LENGTH) {
      handleSearchResult(debouncedQuery);
    }
  }, [debouncedQuery, handleSearchResult]);

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
    if (query.length < SEARCH_TEXT_LENGTH) {
      resetData();
    }
  };

  const handleClick = (type: string, obj: any) => {
    if (type === 'categories') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate('DealScreen', { categoryId: obj.id });
    }
    if (type === 'product')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate('ProductDetailScreen', {
        id: obj.id,
      });
  };

  return (
    <ScrollView
      scrollEventThrottle={16}
      contentContainerStyle={{ flexGrow: 1 }}
      style={[tw`flex-1`, { backgroundColor: colors.bg_primary }]}
      ref={searchScrollRef}
      onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const isAtEnd =
          event.nativeEvent.contentSize.height -
            (event.nativeEvent.contentOffset.y + event.nativeEvent.layoutMeasurement.height) <=
          END_OF_SCREEN_OFFSET;

        if (isEndReached !== isAtEnd) {
          setIsEndReached(isAtEnd);
        }
      }}
    >
      <View style={[tw`p-4 pb-0`, loading && tw`flex-1 flex-col`]}>
        <SearchInput
          placeholder={i18n.t('search_search_input_placeholder')}
          onChangeText={onChangeSearch}
          value={searchQuery}
        />

        <View style={tw.style((!resultData?.data || loading) && 'py-10', loading && 'flex-1')}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {resultData?.isDefault && resultData?.data && (
                <SearchDefaultContainer
                  tags={[]}
                  producers={[]}
                  categories={resultData?.data?.categories}
                  clickItem={handleClick}
                />
              )}
              {!resultData?.isDefault &&
                (resultData?.data ? (
                  <SearchResultContainer
                    clickItem={handleClick}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    products={resultData?.data.productTypes || []}
                    tags={[]}
                    categories={resultData?.data.categories || []}
                    brands={[]}
                    keyWord={searchQuery}
                  />
                ) : (
                  <NoResultContainer query={searchQuery} />
                ))}
            </>
          )}
        </View>
      </View>

      {!!searchQuery && (
        <View style={tw`flex-1 pt-6`}>
          <EndOfFeedScreen
            scrollToTop={() => searchScrollRef.current?.scrollTo({ x: 0, animated: true })}
            isEndReached={isEndReached}
          />
        </View>
      )}
    </ScrollView>
  );
};

export default SearchScreen;
