import ExportIcon from '@assets/icons/export.svg';
import { ShareButtonWrapper } from '@components';
import { getStartGroupPrice, i18n, ProductDetails } from '@core';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { IconButton } from 'react-native-paper';
import { tw } from 'src/core/tw';

const buttonStyle = tw`bg-white border-2 border-black m-0`;

interface IProductDetailTopActionProps {
  product: ProductDetails;
}

const ProductDetailTopAction = ({ product }: IProductDetailTopActionProps) => {
  const navigation = useNavigation();
  const routes = navigation.getState().routes;

  const handleGoBack = () => {
    const previousRoute = routes[routes.length - 2];
    if (previousRoute && ['VideoScreen', 'DealScreen'].includes(previousRoute.state?.routes[0].name ?? '')) {
      navigation.goBack();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate('Main', { screen: 'DealScreen' });
    }
  };

  return (
    <>
      <View style={tw`absolute left-0 pl-4 pt-4 z-1`}>
        <IconButton
          accessibilityLabelledBy={'Back'}
          accessibilityLanguage={'en'}
          testID="top-action-back-button"
          accessibilityRole="button"
          icon="arrow-left"
          style={buttonStyle}
          onPress={handleGoBack}
          size={18}
        />
      </View>

      <View style={tw`absolute right-0 pr-4 pt-4 z-1`}>
        <ShareButtonWrapper
          productId={product.id}
          productShareText={i18n.t('product_share_msg', {
            brand: product.brandName ?? product.producer.organizationName,
            productName: product.name,
            groupLeaderPrice: getStartGroupPrice(product.groupPrice),
            soloPrice: product.soloPrice.toFixed(2),
          })}
        >
          <IconButton
            accessibilityLabelledBy={'Share'}
            accessibilityLanguage={'en'}
            testID="top-action-share-button"
            accessibilityRole="button"
            icon={ExportIcon}
            style={buttonStyle}
            size={18}
          />
        </ShareButtonWrapper>
      </View>
    </>
  );
};

export default ProductDetailTopAction;
