import { gql, useQuery } from '@apollo/client';
import { MyOrdersResponse } from '../types';

export const GetMyOrders = gql`
  query GetMyOrders($where: MyOrdersBoolExp) {
    myOrders(where: $where) {
      orderId
      orderDate
      status
      singleOrder
      cartRef
      address
      zipCode
      city
      members {
        name
      }
      productType {
        id
        name
        brandName
        producer {
          organizationName
        }
        groupSize
        soloPrice
        groupPrice
        currency
        vat
        imageUrls {
          originalUrl
        }
      }
      members {
        name
      }
      cart {
        id
        status
        items {
          cost
          totalCost
          currency
        }
        shipment {
          delivery
          trackingNumber
        }
        payment {
          discount
          paymentTransactions {
            paymentMethod
            amount
            type
          }
          groupLeaderDiscount
        }
      }
    }
  }
`;

export default function useOrder({ orderId, pollInterval = 0 }: { orderId: string; pollInterval?: number }) {
  const { data, error, loading } = useQuery<MyOrdersResponse, { where: { orderId: { _eq: string } } }>(GetMyOrders, {
    variables: { where: { orderId: { _eq: orderId } } },
    pollInterval,
  });

  return { order: data?.myOrders[0], loading, error };
}
