import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($request: UserUpdateRequest!) {
    userUpdate(request: $request) {
      id
      interests
    }
  }
`;
