import AsyncStorage from '@react-native-async-storage/async-storage';
import { TokenResponse } from 'expo-auth-session';
import { OauthType } from './oauth';

export enum STORAGE_KEY {
  ACCESS_TOKEN = '@access_token',
  LOGIN_TYPE = '@login_type',
  TOKEN_TYPE = '@token_type',
  EXPIRE_IN = '@expire_in',
  ID_TOKEN = '@id_token',
  USER_ID = '@user_id',
  REFRESH_TOKEN = '@refresh_token',
  IS_SHOW_TUTORIAL = '@is_show_tutorial',
  IS_VIDEO_MUTED = '@is_video_muted',
  INVITED_BY = '@invited_by',
  BC_ADS = '@bc_ads',
  PURCHASE_DATA = '@purchase_data',
  SET_USER_SIGNAL = '@set_user_signal',
}

export const storeOauthInfo = async (response: TokenResponse, type?: OauthType) => {
  return Promise.all([
    AsyncStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, response.accessToken),
    AsyncStorage.setItem(STORAGE_KEY.LOGIN_TYPE, type || ''),
    AsyncStorage.setItem(STORAGE_KEY.TOKEN_TYPE, response.tokenType),
    AsyncStorage.setItem(STORAGE_KEY.EXPIRE_IN, response?.expiresIn?.toString() || ''),
    AsyncStorage.setItem(STORAGE_KEY.ID_TOKEN, response?.idToken || ''),
    AsyncStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, response?.refreshToken || ''),
  ]);
};

export const getUserInfo = async () => {
  const accessToken = await AsyncStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);

  const loginType = await AsyncStorage.getItem(STORAGE_KEY.LOGIN_TYPE);

  const tokenType = await AsyncStorage.getItem(STORAGE_KEY.TOKEN_TYPE);

  const expireIn = await AsyncStorage.getItem(STORAGE_KEY.EXPIRE_IN);

  const idToken = await AsyncStorage.getItem(STORAGE_KEY.ID_TOKEN);

  const userId = await AsyncStorage.getItem(STORAGE_KEY.USER_ID);

  const refreshToken = await AsyncStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);

  return {
    accessToken,
    loginType,
    tokenType,
    expireIn,
    idToken,
    userId,
    refreshToken,
  };
};

export const getToken = async () => {
  const accessToken = await AsyncStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  return accessToken;
};

export const getStorageLoginType = () => {
  return AsyncStorage.getItem(STORAGE_KEY.LOGIN_TYPE) as unknown as OauthType;
};

export const storeUserId = async (userId: string) => {
  await AsyncStorage.setItem(STORAGE_KEY.USER_ID, userId);
};

export const getUserId = async () => {
  const userId = await AsyncStorage.getItem(STORAGE_KEY.USER_ID);
  return userId;
};

export const deleteAllUserInfo = async () => {
  await AsyncStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  await AsyncStorage.removeItem(STORAGE_KEY.LOGIN_TYPE);
  await AsyncStorage.removeItem(STORAGE_KEY.TOKEN_TYPE);
  await AsyncStorage.removeItem(STORAGE_KEY.EXPIRE_IN);
  await AsyncStorage.removeItem(STORAGE_KEY.ID_TOKEN);
  await AsyncStorage.removeItem(STORAGE_KEY.USER_ID);
  await AsyncStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
  await AsyncStorage.removeItem(STORAGE_KEY.IS_SHOW_TUTORIAL);
};

export const storeIsShowTutorial = async (isShow: boolean) => {
  await AsyncStorage.setItem(STORAGE_KEY.IS_SHOW_TUTORIAL, isShow.toString());
};

export const getIsShowTutorial = async () => {
  const isShow = await AsyncStorage.getItem(STORAGE_KEY.IS_SHOW_TUTORIAL);
  return isShow;
};

export const isUserLogin = async () => {
  const userInfo = await getUserInfo();
  return userInfo.accessToken !== null;
};

export const storeInvitedBy = async (invitedBy: string) => {
  await AsyncStorage.setItem(STORAGE_KEY.INVITED_BY, invitedBy);
};

export const getInvitedBy = async () => {
  const invitedBy = await AsyncStorage.getItem(STORAGE_KEY.INVITED_BY);
  return invitedBy || null;
};

export const storeBcAds = async (bcAds: string) => {
  await AsyncStorage.setItem(STORAGE_KEY.BC_ADS, bcAds);
};

export const getBcAds = async () => {
  const bcAds = await AsyncStorage.getItem(STORAGE_KEY.BC_ADS);
  return !!!bcAds;
};

export const storeIsMuted = async (isMuted: boolean) => {
  await AsyncStorage.setItem(STORAGE_KEY.IS_VIDEO_MUTED, isMuted.toString());
};

export const getIsMuted = async () => {
  const isMuted = await AsyncStorage.getItem(STORAGE_KEY.IS_VIDEO_MUTED);

  return isMuted != null ? isMuted === 'true' : true;
};

export const signalIsSet = async () => {
  const result = await AsyncStorage.getItem(STORAGE_KEY.SET_USER_SIGNAL);
  return result != null ? result === 'true' : false;
};
export const storeSetUserIdSignal = async (result: boolean) => {
  await AsyncStorage.setItem(STORAGE_KEY.SET_USER_SIGNAL, result.toString());
};
