import { Button } from '@components';
import { getDateFormat, getDisplayCurrency, i18n, OrderStatus } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { GestureResponderEvent, Image, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { GroupProgressModalScreenNavigationProps } from 'src/routes/ModalRoutes';
import { useHideOrder } from '../../hook/useHideOrder/useHideOrder';
import { useStatusColor } from '../../hook/useStatusColor/useStatusColor';
import { useTimerStatus } from '../../hook/useTimerStatus/useTimerStatus';
import { IActiveGroupH } from '../../model';

interface OrderItemProps {
  order: IActiveGroupH;
}

const OrderItem = ({ order }: OrderItemProps) => {
  const { productType: product, status } = order;

  const navigation = useNavigation<GroupProgressModalScreenNavigationProps & ProductDetailScreenNavigationProps>();

  const isGroupFull = order?.members?.length === product.groupSize;

  const timerStatus = useTimerStatus(order.orderDate, status, isGroupFull);

  const statusColor = useStatusColor(status);

  const hideOrder = useHideOrder(order.orderId);

  const orderItemClick = () => {
    if (status !== OrderStatus.Open) {
      return;
    }
    navigation.navigate('GroupProgressModalScreen', {
      activeOrderId: order.orderId,
      isGroupScreen: true,
    });
  };

  const handleViewProduct = (event: GestureResponderEvent) => {
    navigation.navigate('ProductDetailScreen', { id: product.id });
  };

  return (
    <TouchableOpacity
      testID={`ORDER.ITEM-${product.name}`}
      onPress={orderItemClick}
      style={[
        tw`mb-4 flex border-[1.5px] rounded-xl h-36 overflow-hidden`,
        {
          backgroundColor: statusColor,
        },
      ]}
    >
      <View style={tw`flex-row w-full pr-1 h-24 bg-white rounded-t-lg border-b-[1.5px]`}>
        <View style={tw`overflow-hidden border-r-[1.5px]`}>
          <Image
            accessibilityRole="image"
            style={tw`w-24 h-24`}
            source={{
              uri: product?.imageUrls?.[0]?.originalUrl,
            }}
          />
        </View>
        <View style={tw`p-2 flex-auto`}>
          <Text style={[tw`text-sm text-black`, { fontFamily: 'TheFuture-Regular' }]} numberOfLines={1}>
            {product.name}
          </Text>
          <Text style={[tw`text-sm text-[#868686] mt-2`, { fontFamily: 'TheFuture-Regular' }]}>
            {getDateFormat(order.orderDate)}
          </Text>
          <View style={tw`flex flex-row items-center mt-2 gap-2`}>
            <Text style={[tw`text-sm font-thefuture_regular line-through`]}>
              {product.soloPrice}
              {getDisplayCurrency(product.currency)}
            </Text>
            <Text style={[tw`text-sm font-thefuture_regular`]}>
              {product.groupPrice}
              {getDisplayCurrency(product.currency)}
            </Text>
          </View>
        </View>
      </View>
      <View style={[tw`flex-1 flex-row px-2 justify-between items-center`]}>
        <View style={tw`flex-row items-center`}>
          {order.members.length > 5 && (
            <View style={[tw`w-8 h-8 -mr-4 rounded-full border bg-white justify-center items-center z-60`]}>
              <Text style={[tw`text-white text-black`, { fontFamily: 'TheFuture-Regular' }]}>
                +{order.members.length - 5}
              </Text>
            </View>
          )}
          {order.members.slice(0, 5).map((member, index) => {
            return (
              <Image
                key={`${order.orderId}-${order.cart.id}-${member.userId}-photo-${Math.random()}`}
                source={{
                  uri: member.photoUrl,
                }}
                style={[
                  tw`w-8 h-8 rounded-full border bg-red-400`,
                  {
                    marginLeft: index === 0 ? 0 : -12,
                    zIndex: 50 - index * 2,
                  },
                ]}
              />
            );
          })}
          <Text style={tw`ml-2 text-lg`}>{order.members.length}</Text>

          <Text style={tw`text-sm`}>
            {'/'}
            {product.groupSize}
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'TheFuture-Regular' }}>
            <Text style={[tw`text-sm text-black ml-3`, { fontFamily: 'TheFuture-Regular' }]}>{timerStatus}</Text>
          </Text>
        </View>
      </View>
      {status !== OrderStatus.Open && (
        <View style={tw`absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center`}>
          <View style={tw`w-full h-full opacity-50 bg-gray-900`} />
          {hideOrder.loading ? (
            <Text style={tw`absolute text-white`}>Loading...</Text>
          ) : (
            <View style={tw`absolute z-50 flex flex-row gap-x-3`}>
              <Button
                testID="BUTTON.REMOVE"
                title={i18n.t('common_remove')}
                customStyle={tw`bg-white px-6 py-4 border border-black`}
                customTextStyle={[tw`text-black`, { fontFamily: 'TheFuture-Regular' }]}
                onPress={hideOrder.hide}
                textVariant="labelLarge"
              />
              <Button
                testID="BUTTON.RETRY"
                title={i18n.t('common_try_again')}
                customStyle={tw`bg-black px-6 py-4`}
                customTextStyle={{ fontFamily: 'TheFuture-Regular' }}
                onPress={handleViewProduct}
                textVariant="labelLarge"
              />
            </View>
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};

export default OrderItem;
