import Svg, { Circle, Path } from 'react-native-svg';

export const CheckMark = () => (
  <Svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <Circle cx="18" cy="18" r="16.75" fill="black" stroke="black" stroke-width="1.5" />
    <Path
      d="M11 17.5L16.3103 23L25 14"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default CheckMark;
