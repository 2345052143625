/* eslint-disable complexity */
import { ModalCloseButton } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GroupMemberListScreenNavigationProps, GroupProgressModalScreenRouteProps } from '@routes';
import { ScrollView, View } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import ActiveGroupCard from '../ProductDetailScreen/components/ActiveGroupCard/ActiveGroupCard';
import GroupInviteAction from '../ProductDetailScreen/components/GroupInviteAction/GroupInviteAction';
import GroupMembersList from '../ProductDetailScreen/components/GroupMembersList/GroupMembersList';
import { useGroupProgressHook } from './hooks';

const GroupProgressModalScreen = () => {
  const { params } = useRoute<GroupProgressModalScreenRouteProps>();

  const navigation = useNavigation<GroupMemberListScreenNavigationProps>();

  const { data, loading } = useGroupProgressHook({ activeOrderId: params.activeOrderId });

  const groupDetails = data?.activeGroupOrders?.[0];

  const { colors } = useBanditTheme();

  const goToMembersListPage = (orderId: string) => {
    navigation.navigate('GroupMemberListScreen', { activeOrderId: orderId });
  };

  const handleCloseModal = () => {
    navigation.goBack();
  };

  const groupMembers = groupDetails?.members.filter((member) => member.userId !== groupDetails?.creator.userId);

  return (
    <View style={tw`flex-1 justify-end`}>
      <View style={tw`flex bg-[${colors.secondary_fuschia_tint}] rounded-t-3xl border-2 max-h-[90%] px-4`}>
        <ModalCloseButton onClose={handleCloseModal} customMobileStyle={tw`self-center`} />

        <ScrollView contentContainerStyle={tw`pb-18`} showsVerticalScrollIndicator={false} bounces={false}>
          <Text style={[tw`pt-13 pb-9 text-5xl text-center`, { fontFamily: 'FeatureDeck-Regular' }]}>
            {i18n.t('product_detail_group_card_modal_almost_there')}
          </Text>

          {loading || !groupDetails ? (
            <ActivityIndicator testID="activity-indicator" style={tw`pb-18`} color="black" />
          ) : (
            <View testID="group-card-modal-info">
              <View style={tw`border-[1.5px] rounded-xl bg-white mb-2 overflow-hidden`}>
                <ActiveGroupCard
                  creator={groupDetails.creator}
                  groupSize={data.groupSize}
                  soloPrice={data.soloPrice}
                  groupPrice={data.groupPrice}
                  numberOfMembers={groupDetails.numberOfMembers}
                  expiredDate={groupDetails.expiredDate}
                  hasBorder={false}
                  isJoining={false}
                  showName
                />

                <View style={tw`bg-[${colors.secondary_fuschia}] border-t-[1.5px]`}>
                  <Text style={[tw`text-xs self-center py-2`, { fontFamily: 'TheFuture-Regular' }]}>
                    {i18n.t(
                      params.isGroupScreen
                        ? 'product_detail_group_card_modal_reminding_members'
                        : 'product_detail_group_card_modal_only_member_missing',
                      {
                        count: data.groupSize - groupDetails.members.length,
                      },
                    )}
                  </Text>
                </View>

                {!!groupMembers?.length && (
                  <>
                    <View style={tw`bg-black h-[1.5px]`} />
                    <GroupMembersList
                      members={groupMembers.slice(0, 2)}
                      isMoreMembers={groupMembers.length > 2}
                      goToMemberListPage={() => goToMembersListPage(groupDetails.id)}
                    />
                  </>
                )}
              </View>

              <Text style={[tw`text-sm mt-4 mb-8 text-center`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('product_detail_group_card_modal_fill_this_group')}
              </Text>

              <View style={tw`mx-auto`}>
                <GroupInviteAction productId={data.id} groupId={groupDetails.id} />
              </View>
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
};
export default GroupProgressModalScreen;
