import { gql } from '@apollo/client';

export const GET_MEMBERS_BY_ACTIVE_ORDER_ID = gql`
  query getMembersListByActiveOrderID($activeOrderId: String!) {
    activeGroupOrders(where: { id: { _eq: $activeOrderId } }) {
      id
      numberOfMembers
      members(orderBy: { joinAt: desc }, where: { status: { _in: ["PENDING", "OPEN"] } }) {
        name
        photoUrl
        city
        status
        joinAt
      }
    }
  }
`;
