import { i18n, useBanditTheme } from '@core';
import { Text, View } from 'react-native';
import { tw } from 'src/core/tw';
import EndOfFeedBrands from './components/EndOfFeedBrands/EndOfFeedBrands';
import { EndOfFeedScreenProps } from './model';
import { EndOfFeedScreenProvider } from './Providers/EndOfFeedScreenProvider/EndOfFeedScreenProvider';

const EndOfFeedScreen = ({ isEndReached, scrollToTop }: EndOfFeedScreenProps) => {
  const { colors } = useBanditTheme();

  return (
    <EndOfFeedScreenProvider values={{ isEndReached, scrollToTop }}>
      <View style={[tw`py-8.5 px-4.5 pb-18.5`, { backgroundColor: colors.secondary_cosmos_tint }]}>
        <Text style={[tw`text-[40px] leading-10 text-black font-normal mb-4.5`, { fontFamily: 'FeatureDeck-Regular' }]}>
          {i18n.t('end_of_feed_title')}
        </Text>
        <Text style={[tw`text-sm text-black font-normal`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('end_of_feed_description')}
        </Text>

        <EndOfFeedBrands />
      </View>
    </EndOfFeedScreenProvider>
  );
};

export default EndOfFeedScreen;
