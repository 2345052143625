import { getPrice, getStartGroupPrice, useBanditTheme } from '@core';
import { Text, View } from 'react-native';
import { tw } from 'src/core/tw';
import BanditCashView from 'src/screens/VideoScreen/components/VideoWithDetails/components/BanditCashView/BanditCashView';

// eslint-disable-next-line complexity
export default function ProductDetailPrices({
  groupPrice,
  currency,
  soloPrice,
  hasActiveGroupOrders,
  banditCashDiscount,
  fontColor,
  fontSizes,
  shouldShowBanditCash,
}: {
  groupPrice: number;
  currency: string;
  soloPrice: number;
  hasActiveGroupOrders: boolean;
  banditCashDiscount: boolean;
  fontColor: string;
  fontSizes: { heading: string; subHeading: string };
  shouldShowBanditCash?: boolean;
}) {
  const theme = useBanditTheme();
  const initialGroupPrice = hasActiveGroupOrders
    ? getPrice(groupPrice, currency)
    : getPrice(getStartGroupPrice(groupPrice), currency);

  const percentage = Math.abs(((soloPrice - parseInt(initialGroupPrice)) / soloPrice) * 100);

  const showBanditCash = banditCashDiscount && shouldShowBanditCash;
  return (
    <View style={tw`z-20`}>
      <View style={tw`flex flex-row items-center`}>
        <Text
          style={[
            tw`font-normal ${fontSizes.subHeading} line-through font-thefuture_regular`,
            {
              color: fontColor,
              textDecorationColor: hasActiveGroupOrders ? theme.colors.secondary_fuschia : theme.colors.secondary_ember,
            },
          ]}
        >
          {getPrice(soloPrice, currency)}
        </Text>
        {showBanditCash && <BanditCashView soloPrice={soloPrice} groupPrice={parseInt(initialGroupPrice)} />}
        {!showBanditCash && percentage < 100 && (
          <View
            style={tw`ml-2 py-0.5 px-2 justify-center ${
              hasActiveGroupOrders ? 'bg-bandit_pink' : 'bg-bandit_orange'
            }  font-thefuture_regular`}
          >
            <Text style={tw`font-normal ${fontSizes.subHeading} font-thefuture_regular`}>
              -{percentage.toFixed(0)}%
            </Text>
          </View>
        )}
      </View>
      <Text style={[tw`font-normal mt-1 ${fontSizes.heading} font-featuredeck_regular`, { color: fontColor }]}>
        {initialGroupPrice}
      </Text>
    </View>
  );
}
