import { BANDITCASH_TYPE, getDateFormat, getDisplayCurrency, OrderStatus } from '@core';
// @ts-expect-error Could not find a declaration file for module 'expo-fast-image'
import FastImage from 'expo-fast-image';
import { useState } from 'react';
import { Pressable, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { Order } from 'src/domain/order/types';
import ReceiptModal from 'src/domain/payment/components/ReceiptModal';

interface Props {
  order: Order;
}

const OrderCard = ({ order }: Props) => {
  const [showModal, setShowModal] = useState(false);

  const totalPayment =
    order.cart.payment?.paymentTransactions?.filter((payment) => payment?.type !== BANDITCASH_TYPE)?.[0]?.amount ?? 0;

  return (
    <>
      <Pressable
        style={tw`flex flex-row mt-3 border-2 rounded-lg pr-2 h-24 bg-transparent overflow-hidden`}
        key={order.orderId}
        onPress={() => {
          if (order.status === OrderStatus.Completed || order.status === OrderStatus.Fulfilled || OrderStatus.Open) {
            setShowModal(true);
          }
        }}
      >
        <FastImage
          testID="order-card_image"
          source={{ uri: order.productType.imageUrls?.[0].originalUrl }}
          style={tw`h-full w-24`}
        />
        <View style={tw`m-2 flex-auto`}>
          <Text variant="bodyMedium" style={tw`text-black`} numberOfLines={1}>
            {order.productType.name}
          </Text>
          <Text variant="bodyMedium" style={tw`text-[#868686] mt-2`}>
            {getDateFormat(order.orderDate)}
          </Text>
          <View style={tw`mt-auto flex flex-row justify-between`}>
            <Text variant="bodyLarge" style={tw`font-medium`}>
              {totalPayment}
              {getDisplayCurrency('')}
            </Text>
            <Text variant="bodyMedium">{order.status}</Text>
          </View>
        </View>
      </Pressable>
      <ReceiptModal isVisible={showModal} handleCloseModal={() => setShowModal(false)} order={order} />
    </>
  );
};

export default OrderCard;
