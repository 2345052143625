import { UnderlineText } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { LoginModalScreenNavigationProps } from '@routes';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';

const InvitedHeader = () => {
  const { colors } = useBanditTheme();

  const { top } = useSafeAreaInsets();

  const site = Constants?.expoConfig?.extra?.WEBSITE_URL;

  const navigation = useNavigation<LoginModalScreenNavigationProps>();

  const handleLogin = () => {
    navigation.navigate('LoginModalScreen');
  };

  const handleViewSite = () => {
    Linking.openURL(site);
  };

  return (
    <View style={tw`flex flex-row justify-between items-center bg-white p-4 pt-[${top + 28}px]`}>
      <UnderlineText style={tw`border-[${colors.secondary_gray}]`} onPress={handleViewSite}>
        <Text style={[tw`text-sm text-[${colors.secondary_gray}]`, { fontFamily: 'TheFuture-Regular' }]}>
          banditapp.com
        </Text>
      </UnderlineText>
      <UnderlineText style={tw`border-[${colors.secondary_gray}]`} onPress={handleLogin}>
        <Text style={[tw`text-sm text-[${colors.secondary_gray}]`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('you_have_been_invited_modal_create_an_account')}
        </Text>
      </UnderlineText>
    </View>
  );
};

export default InvitedHeader;
