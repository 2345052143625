import { useBanditTheme } from '@core';
import { TouchableOpacity } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface CategoryItemProps {
  name: string;
  isSelected: boolean;
  setSelectItem: (name: string) => void;
}

export const CategoryItem = ({ name, isSelected, setSelectItem }: CategoryItemProps) => {
  const { colors } = useBanditTheme();

  const cardSelected = isSelected ? colors.primary : '#fff';

  return (
    <TouchableOpacity
      testID={`VIEW.CATEGORY_ITEM.${name}`}
      onPress={() => setSelectItem(name)}
      style={tw.style(['rounded-full border px-6 text-center flex justify-center mb-2 ml-1'], {
        backgroundColor: cardSelected,
        height: 40,
      })}
    >
      <Text style={tw`my-2 capitalize`} variant="bodyMedium">
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default CategoryItem;
