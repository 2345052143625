import { i18n, ProductActiveGroupOrdersMembers } from '@core';
import { MoreIcon } from '@svgIcons';
import { Fragment } from 'react';
import { TouchableOpacity } from 'react-native';
import { Divider, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import MemberCardItem from '../MemberCardItem/MemberCardItem';

interface IGroupMembersListProps {
  members: ProductActiveGroupOrdersMembers[];
  isMoreMembers?: boolean;
  goToMemberListPage?: () => void;
}

const GroupMembersList = ({ members, isMoreMembers = false, goToMemberListPage }: IGroupMembersListProps) => {
  const renderItem = (data: ProductActiveGroupOrdersMembers, index: number) => {
    const notFirstMember = index !== 0;

    return (
      <Fragment key={data.userId}>
        {notFirstMember && (
          <Divider style={tw`border-b-2`} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} />
        )}
        <MemberCardItem data={data} />
      </Fragment>
    );
  };

  return (
    <>
      {members.map(renderItem)}
      {isMoreMembers && (
        <TouchableOpacity
          accessibilityRole="button"
          style={tw`border-t-2 p-4 flex-row justify-between`}
          onPress={goToMemberListPage}
        >
          <Text variant="labelLarge">{i18n.t('groups:see_all_members')}</Text>
          <MoreIcon />
        </TouchableOpacity>
      )}
    </>
  );
};

export default GroupMembersList;
