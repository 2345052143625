import { useQuery, useReactiveVar } from '@apollo/client';
import { authVar, IGetActiveGroupsRequest, IGetActiveGroupsResponse, ProductActiveGroupOrders } from '@core';
import { useMemo } from 'react';
import useUserStore, { UseUserStore } from 'src/core/store/user/useUserStore';
import { shallow } from 'zustand/shallow';
import { GET_PRODUCT_MY_ACTIVE_GROUP } from '../../query';

const userStoreSelector = ({ user }: UseUserStore) => ({ user });

interface IUseGetMyActiveGroupProps {
  productId: string;
  groupSize?: number;
  groupShareText?: string;
}

const useGetMyActiveGroup = ({ productId, groupSize = 0 }: IUseGetMyActiveGroupProps) => {
  const { user } = useUserStore(userStoreSelector, shallow);

  const auth = useReactiveVar(authVar);

  const isLoggedIn = auth?.loggedIn;

  const currentTimestamp = useMemo(() => new Date().toISOString().replace('Z', ''), []);
  const { data, loading, refetch } = useQuery<IGetActiveGroupsResponse, IGetActiveGroupsRequest>(
    GET_PRODUCT_MY_ACTIVE_GROUP,
    {
      pollInterval: 10000,
      skip: !isLoggedIn || !user.id || !groupSize,
      variables: { productId, userId: user.id!, currentTimestamp },
    },
  );

  const filterResult = (group?: ProductActiveGroupOrders[]) => {
    const filteredGroupOrder = group?.filter((order) => order.members.length < groupSize)?.[0];

    if (!!filteredGroupOrder) {
      return {
        ...filteredGroupOrder,
        numberOfMembers: filteredGroupOrder.members.length,
      };
    }
    return undefined;
  };

  return {
    myActiveGroups: groupSize ? filterResult(data?.activeGroupOrders) : undefined,
    loading,
    refetch,
  };
};

export default useGetMyActiveGroup;
