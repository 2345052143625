import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const ExclamationCircle = (props: SvgProps) => (
  <Svg fill="none" stroke="red" strokeWidth={1.5} viewBox="0 0 24 24" {...props}>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-9 3.75h.008v.008H12v-.008z"
    />
  </Svg>
);
export default ExclamationCircle;
