import { i18n, ProductFaqs } from '@core';
import { Down, Up } from '@svgIcons';
import { Fragment, useState } from 'react';
import { TouchableHighlight, View } from 'react-native';
import { Divider, List, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IFaqSectionProps {
  faqList: ProductFaqs[];
}

const FaqSection = ({ faqList }: IFaqSectionProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number>();

  return (
    <>
      <Text style={[tw`text-sm font-medium mb-3 mx-4`, { fontFamily: 'TheFuture-Medium' }]}>
        {i18n.t('product_detail_faq_title')}
      </Text>
      <List.AccordionGroup>
        {faqList.map(({ question, answer }, index) => (
          <Fragment key={question}>
            {index !== 0 && (
              <Divider
                style={tw`border-b border-black opacity-10`}
                accessibilityLabelledBy={undefined}
                accessibilityLanguage={undefined}
              />
            )}
            <TouchableHighlight
              accessibilityRole="button"
              style={tw`p-4`}
              underlayColor="#F9F3DB"
              onPress={() => {
                setExpandedIndex(expandedIndex === index ? undefined : index);
              }}
            >
              <View style={tw`flex-row items-center justify-between`}>
                <Text style={[tw`text-sm flex-shrink leading-6`, { fontFamily: 'TheFuture-Regular' }]}>{question}</Text>

                <View style={tw`text-xl ml-8 pt-1 w-4 h-4`}>{expandedIndex === index ? <Up /> : <Down />}</View>
              </View>
            </TouchableHighlight>
            {expandedIndex === index && (
              <Text style={[tw`text-sm text-[#494949] pt-2 pb-6 px-4`, { fontFamily: 'TheFuture-Regular' }]}>
                {answer}
              </Text>
            )}
          </Fragment>
        ))}
      </List.AccordionGroup>
    </>
  );
};
export default FaqSection;
