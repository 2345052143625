import { ProductActiveGroupOrders, ProductDetails } from '@core';
import { useNavigation } from '@react-navigation/native';
import { GroupProgressModalScreenNavigationProps } from '@routes';
import { View } from 'react-native';
import { tw } from 'src/core/tw';
import ActiveGroupCarousel from '../ActiveGroupCarousel/ActiveGroupCarousel';
import GroupTimerCard from '../GroupTimerCard/GroupTimerCard';

interface IGroupOrdersProps {
  myActiveGroup?: ProductActiveGroupOrders;
  activeGroupOrders: ProductActiveGroupOrders[];
  isInvited?: boolean;
  product: ProductDetails;
}

const GroupOrders = ({ myActiveGroup, activeGroupOrders, product, isInvited = false }: IGroupOrdersProps) => {
  const hasActiveGroups = !!activeGroupOrders.length;

  const isAlreadyInGroup = !!myActiveGroup;

  const navigation = useNavigation<GroupProgressModalScreenNavigationProps>();

  const handleViewGroupInfo = () => {
    if (!!myActiveGroup) {
      navigation.navigate('GroupProgressModalScreen', { activeOrderId: myActiveGroup.id, isGroupScreen: false });
    }
  };

  return (
    <>
      {!isAlreadyInGroup && hasActiveGroups && (
        <View style={tw`mt-4 mx-4`}>
          <ActiveGroupCarousel product={product} activeGroupOrders={activeGroupOrders} />
        </View>
      )}

      {isAlreadyInGroup && (
        <View style={tw`mt-4 mx-4`}>
          <GroupTimerCard
            {...myActiveGroup}
            photoUrl={myActiveGroup.creator.photoUrl}
            groupSize={product.groupSize}
            isInvited={isInvited}
            onPress={handleViewGroupInfo}
          />
        </View>
      )}
    </>
  );
};

export default GroupOrders;
