import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import { i18n } from '@core';
import { authVar } from 'src/core/constant/auth';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { IVideoFeed, IVideoFeedUser } from '../model';
import { GET_USER_VIDEO_FEED, GET_VIDEO_FEED } from '../query';

const useVideoFeedHook = () => {
  const toast = useShowToast();
  const { loggedIn } = useReactiveVar(authVar);

  const onError = (error: ApolloError) => {
    toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
  };

  const {
    data: authenticatedFeedData,
    loading: loadingAuthFeedData,
    refetch: pullToRefreshAuth,
  } = useQuery<IVideoFeedUser>(GET_USER_VIDEO_FEED, {
    skip: !loggedIn,
    onError: onError,
  });

  const {
    data: unauthenticatedFeedData,
    loading: loadingUnAuthFeedData,
    refetch: pullToRefreshNoAuth,
  } = useQuery<IVideoFeed>(GET_VIDEO_FEED, {
    skip: loggedIn,
    onError: onError,
  });

  const feedData:
    | (IVideoFeedUser & { type: 'authenticated' })
    | (IVideoFeed & { type: 'unauthenticated' })
    | undefined =
    (!!authenticatedFeedData ? { ...authenticatedFeedData, type: 'authenticated' } : undefined) ??
    (!!unauthenticatedFeedData ? { ...unauthenticatedFeedData, type: 'unauthenticated' } : undefined);

  const isLoading = loadingAuthFeedData || loadingUnAuthFeedData;

  const pullToRefresh = () => {
    if (loggedIn) {
      pullToRefreshAuth();
    } else {
      pullToRefreshNoAuth();
    }
  };

  return {
    feedData,
    isLoading,
    pullToRefresh,
  };
};

export default useVideoFeedHook;
