import Svg, { Circle, Path } from 'react-native-svg';

export const AddIcon = () => (
  <Svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <Circle cx="18" cy="18" r="16.75" fill="white" stroke="black" stroke-width="1.5" />
    <Path d="M18 9V27" stroke="black" stroke-width="1.5" stroke-linecap="round" />
    <Path d="M27 18L9 18" stroke="black" stroke-width="1.5" stroke-linecap="round" />
  </Svg>
);

export default AddIcon;
