/* eslint-disable complexity */
import { useBanditTheme } from '@core';
import { CoinLogo } from '@svgIcons';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface BoxProps {
  title: string;
  titleSize?: 'big' | 'sm';
  amount?: string;
  titleBgColor?: string;
  showCoinLogo?: boolean;
  description?: string | null;
}

const Box = ({ title, amount, titleBgColor, titleSize = 'big', showCoinLogo = false, description }: BoxProps) => {
  const { colors } = useBanditTheme();
  return (
    <View style={tw`border-2 rounded-lg overflow-hidden mt-2`}>
      <View style={tw`rounded-t-lg`}>
        <Text
          style={[
            tw`p-4 text-black`,
            tw.style(titleSize === 'big' ? 'text-2xl' : 'text-sm'),
            { backgroundColor: titleBgColor ? titleBgColor : colors.secondary_cosmos_tint },
          ]}
          variant="displaySmall"
        >
          {title}
        </Text>
      </View>
      {(amount || description) && (
        <View style={tw`flex-row border-t p-4 items-center`}>
          <Text variant="bodySmall">{amount || description}</Text>
          {showCoinLogo && <CoinLogo style={tw`ml-2`} accessibilityRole="image" />}
        </View>
      )}
    </View>
  );
};

export default Box;
