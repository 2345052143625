import { Loading } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';
import BackHeader from 'src/components/BackHeader/BackHeader';
import { tw } from 'src/core/tw';
import FaqCard from './components/FaqCard/FaqCard';
import useFaqsHook from './hooks/useFaqsHook/useFaqsHook';

const FaqScreen = () => {
  const { colors } = useBanditTheme();

  const navigation = useNavigation();

  const { faqLoading, faqsData } = useFaqsHook();

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{
        backgroundColor: colors.bg_primary,
      }}
    >
      <BackHeader goBack={navigation.goBack} title={i18n.t('profile_faq_title')} titleStyle={tw`text-black`} />

      {faqLoading && <Loading />}

      {!!faqsData?.length && !faqLoading && (
        <View style={tw`p-4 py-5 pb-2`}>
          {faqsData.map((faq) => (
            <FaqCard key={faq.question} faq={faq} />
          ))}
        </View>
      )}
    </ScrollView>
  );
};
export default FaqScreen;
