import { View } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated';

const PAGINATION_WIDTH = 7;

interface IPaginationItemProps {
  index: number;
  length: number;
  animValue: Animated.SharedValue<number>;
}

const PaginationItem = ({ animValue, index, length }: IPaginationItemProps) => {
  const animStyle = useAnimatedStyle(() => {
    let inputRange = [index - 1, index, index + 1];

    const outputRange = [-PAGINATION_WIDTH, 0, PAGINATION_WIDTH];

    if (index === 0 && animValue?.value > length - 1) {
      inputRange = [length - 1, length, length + 1];
    }

    return {
      transform: [
        {
          translateX: interpolate(animValue.value, inputRange, outputRange, Extrapolate.CLAMP),
        },
      ],
    };
  }, [animValue, index, length]);

  return (
    <View
      testID="pagination-item"
      style={{
        backgroundColor: '#6E6E6E',
        width: PAGINATION_WIDTH,
        height: PAGINATION_WIDTH,
        borderRadius: 50,
        overflow: 'hidden',
        marginRight: index === length - 1 ? 0 : 8,
      }}
    >
      <Animated.View
        testID="active-pagination"
        style={[
          {
            borderRadius: 50,
            backgroundColor: 'white',
            flex: 1,
            opacity: 1,
          },
          animStyle,
        ]}
      />
    </View>
  );
};

export default PaginationItem;
