import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers {
    users {
      email
      id
      age
      firstName
      lastName
      interests
      photoUrl
      zipCode
      city
      address
      phoneNumber
    }
  }
`;

export const GET_BC_LIST = gql`
  query getBCHistory($date: timestamp!) {
    banditCash(where: { expiringAt: { _gte: $date } }) {
      credit
    }
  }
`;
