import { useReactiveVar } from '@apollo/client';
import { authVar, getDisplayCurrency, ProductActiveGroupOrders, ProductDetails, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { useRef } from 'react';
import { Dimensions, Platform, View } from 'react-native';
import { Divider, Text } from 'react-native-paper';
import Carousel from 'react-native-reanimated-carousel';
import { tw } from 'src/core/tw';
import useContainerDimensions from 'src/hooks/useContainerDimensions';
import { ProductDetailScreenRouteProps } from 'src/routes/ProductDetailRoutes/productDetailRoutes.model';
import { useActiveGroupState } from '../../hooks';
import { usePurchaseActions } from '../../hooks/usePurchaseState/usePurchaseState';
import ActiveGroupCard from '../ActiveGroupCard/ActiveGroupCard';

const INTERVAL = 2000;

const SCROLL_DURATION = 1500;

const CAROUSEL_HEIGHT = 139;

const MARGIN = 32.5;

const SCREEN_DIMENSIONS_WIDTH = Dimensions.get('screen').width;

interface IActiveGroupCarouselProps {
  product: ProductDetails;
  activeGroupOrders: ProductActiveGroupOrders[];
}

const ActiveGroupCarousel = ({ product, activeGroupOrders }: IActiveGroupCarouselProps) => {
  const theme = useBanditTheme();
  const carouselRef = useRef(null);

  const { width: carouselContainerWidth } = useContainerDimensions(carouselRef);

  const SCREEN_WIDTH = Platform.OS === 'web' ? carouselContainerWidth : SCREEN_DIMENSIONS_WIDTH - MARGIN;

  const navigation = useNavigation<ProductDetailScreenRouteProps>();

  const auth = useReactiveVar(authVar);

  const isLoggedIn = auth?.loggedIn;

  const { handleViewActiveGroupInfo } = useActiveGroupState();

  const displayCurrency = getDisplayCurrency(product.currency);

  const { handleJoinGroup } = usePurchaseActions();

  const handleLogin = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('LoginModalScreen');
  };

  const handlePressJoinGroup = (id: string) => {
    if (!isLoggedIn) {
      handleLogin();
      return;
    }

    handleJoinGroup(id);
  };

  const border =
    activeGroupOrders.length > 1
      ? `border-[1.5px] border-l-[${1.5 / 2}px] border-r-[${1.5 / 2}px] border-t-0`
      : 'border-0';

  const renderItem = ({ item }: { item: ProductActiveGroupOrders }) => (
    <View style={tw`${border} rounded-b-xl bg-transparent flex-1`}>
      <ActiveGroupCard
        {...item}
        groupSize={product.groupSize}
        soloPrice={product.soloPrice}
        groupPrice={product.groupPrice}
        onPressCard={() => handleViewActiveGroupInfo(item.id)}
        onPressJoinGroup={() => handlePressJoinGroup(item.id)}
        hasBorder={false}
        rounded="bottom"
      />
    </View>
  );

  return (
    <View style={tw`rounded-xl border-[1.5px]`} ref={carouselRef}>
      <View style={tw`flex flex-row justify-between px-4 py-3`}>
        <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>Active groups to join</Text>
      </View>
      <Divider style={tw`border-b-[1.5px]`} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} />
      <View style={tw`h-[${CAROUSEL_HEIGHT}px] bg-white rounded-b-xl overflow-hidden`}>
        <Carousel
          testID="active-group-carousel"
          style={tw`-ml-[1.5px] overflow-hidden rounded-b-xl`}
          data={activeGroupOrders}
          renderItem={renderItem}
          width={SCREEN_WIDTH || SCREEN_DIMENSIONS_WIDTH}
          height={CAROUSEL_HEIGHT}
          autoPlayInterval={INTERVAL}
          scrollAnimationDuration={SCROLL_DURATION}
          autoPlay={activeGroupOrders.length > 1}
          loop={activeGroupOrders.length > 1}
          enabled={activeGroupOrders.length > 1}
          snapEnabled
        />
      </View>
    </View>
  );
};

export default ActiveGroupCarousel;
