import Constants from 'expo-constants';
import { Order } from 'src/domain/order/types';

export enum OrderType {
  Group = 'GROUP',
  Solo = 'SOLO',
}

export enum OrderStatus {
  Abandoned = 'ABANDONED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Fulfilled = 'FULFILLED',
  Open = 'OPEN',
  Pending = 'PENDING',
}

export enum DeliveryMethod {
  Bandit3PL = 'Bandit3PL',
  OwnDelivery = 'OwnDelivery',
  Bandit2C = 'Bandit2C',
}

export const BANDITCASH_TYPE = 'BANDITCASH';

export const MOCK_ORDERS: Order[] = [
  {
    orderId: '5832020',
    address: 'Markveien 47',
    zipCode: '5000',
    city: 'Bergen, Norge',
    status: OrderStatus.Open,
    singleOrder: true,
    cartRef: 123,
    cart: {
      payment: {
        discount: 0,
        groupLeaderDiscount: 0,
        paymentTransactions: [
          {
            amount: 100,
            paymentMethod: 'Vipps',
            type: 'Vipps',
          },
        ],
      },
      shipment: {
        delivery: 'Bandit3PL',
        trackingNumber: '1234',
      },
      id: '1234',
      status: OrderStatus.Open,
      items: [
        {
          cost: 100,
          totalCost: 100,
          currency: 'USD',
        },
      ],
    },
    productType: {
      id: '123',
      name: 'test product',
      brandName: 'Test',
      producer: {
        organizationName: 'Test org',
      },
      groupSize: 5,
      groupPrice: 100,
      soloPrice: 150,
      currency: 'USD',
      vat: 10,
    },
    members: [{ name: 'Test' }],
    orderDate: 'todo',
  },
];

export const PAYMENT_URL = Constants.expoConfig?.extra?.PAYMENT_URL;
