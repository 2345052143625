export const SUCCESS_MESSAGES = {};

export const INFO_MESSAGES = {};

export const ERROR_MESSAGES = {
  default: 'general_error_message',
  authentication_error: 'authentication_error_message',
  authentication_cancelled: 'authentication_cancelled',
  authentication_locked: 'authentication_locked',
  error_create_profile: 'error_create_profile',
  token_error: 'token_error',
  session_timeout: 'session_timeout',
  failed_external_userID: 'failed_external_userID',
};
export const VALIDATION_MESSAGES = {};
