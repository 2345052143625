import Svg, { Path, SvgProps } from 'react-native-svg';

type FlameLogoProps = {
  color: string;
} & SvgProps;

const FlameLogo = (props: FlameLogoProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M17.5744 8.72276C17.4397 8.58683 17.251 8.52507 17.0663 8.55665C16.8812 8.58811 16.7214 8.7089 16.6356 8.88223C16.3403 9.47857 15.9653 10.0266 15.5241 10.511C15.568 10.1611 15.5902 9.80902 15.5902 9.45605C15.5902 8.77875 15.5029 8.08177 15.3306 7.38421C14.7641 5.09303 13.2788 3.1554 11.2555 2.0682C11.0794 1.97357 10.8695 1.9769 10.6962 2.07706C10.5229 2.17726 10.4094 2.36097 10.3938 2.56646C10.2361 4.64858 9.20452 6.54286 7.56204 7.7648C7.5403 7.78108 7.51872 7.79757 7.49714 7.81397C7.45244 7.84798 7.41031 7.88014 7.37093 7.90712C7.36478 7.91139 7.35866 7.91574 7.35267 7.92021C6.31967 8.68913 5.46732 9.71411 4.88766 10.8847C4.29865 12.0753 4 13.353 4 14.6821C4 15.3592 4.08731 16.0562 4.25951 16.7539C5.16828 20.4312 8.35138 22.9995 12.0002 22.9995C16.4114 22.9995 20 19.2683 20 14.6821C20 12.4205 19.1386 10.3041 17.5744 8.72276Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </Svg>
);

export default FlameLogo;
