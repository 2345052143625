import { UseProductDetailsStore, useProductDetailsStore } from '@core';
import { shallow } from 'zustand/shallow';

const selector = ({ orderId, setModalState, setOrderId }: UseProductDetailsStore) => ({
  orderId,
  setModalState,
  setOrderId,
});

const useActiveGroupState = () => {
  const { orderId, setModalState, setOrderId } = useProductDetailsStore(selector, shallow);

  const handleViewActiveGroupInfo = (id: string) => {
    setOrderId(id);
    setModalState('activeGroupInfo');
  };

  return {
    orderId,
    handleViewActiveGroupInfo,
  };
};

export default useActiveGroupState;
