import { gql, useLazyQuery } from '@apollo/client';

const query = gql`
  query PaymentValidateRedirectResult($redirectResult: String!) {
    paymentValidateRedirectResult(request: { redirectResult: $redirectResult }) {
      pspReference
      resultCode
    }
  }
`;

export type PaymentRedirectResult = {
  paymentValidateRedirectResult: {
    pspReference: string;
    resultCode: 'Authorised' | 'Error' | 'Pending' | 'PresentToShopper' | 'Refused' | 'Received' | 'Cancelled';
  };
};

const usePaymentRedirectResult = () => {
  const [checkPaymentRedirectResult] = useLazyQuery<PaymentRedirectResult>(query);

  return { checkPaymentRedirectResult };
};

export default usePaymentRedirectResult;
