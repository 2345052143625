import { useBanditTheme } from '@core';
import { CloseIcon, SearchIcon } from '@svgIcons';
import { useRef, useState } from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { tw } from 'src/core/tw';

interface SearchInputProps {
  placeholder: string;
  value: string;
  onChangeText: (value: string) => void;
  onFocused?: (isFocus: boolean) => void;
}

const SearchInput = ({ placeholder, value, onChangeText, onFocused }: SearchInputProps) => {
  const { colors } = useBanditTheme();

  const searchInputRef = useRef<TextInput | null>(null);

  const [isInputFocused, setInputFocused] = useState(false);

  const onFocusHandler = () => {
    if (onFocused) {
      onFocused(true);
    }
    setInputFocused(true);
  };

  const onBlurHandler = () => {
    if (onFocused) {
      onFocused(false);
    }
    setInputFocused(false);
  };

  return (
    <View
      style={tw`w-full flex-row justify-between items-center relative border-black rounded-[10px] border-[1.5px] bg-white`}
    >
      <View style={tw`mx-4`}>
        <SearchIcon color={isInputFocused ? 'black' : colors.gray_400} />
      </View>
      <TextInput
        testID="SEARCH.INPUT"
        style={[
          tw`flex-auto text-black w-auto text-base py-4 text-left items-start justify-start font-normal pr-12 w-full`,
          { fontFamily: 'TheFuture-Regular' },
        ]}
        onChangeText={onChangeText}
        value={value}
        numberOfLines={1}
        placeholder={placeholder}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        placeholderTextColor={colors.gray_400}
        ref={searchInputRef}
      />
      {value.length > 0 && (
        <TouchableOpacity
          testID="BUTTON.CLEAR"
          style={tw`flex-1 items-end justify-end mr-4`}
          onPress={() => {
            onChangeText('');
            onFocused && onFocused(false);
          }}
        >
          <CloseIcon />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default SearchInput;
