import { ShareGroupWrapper } from '@components';
import { getDisplayCurrency, getStartGroupPrice, i18n, ProductActiveGroupOrders } from '@core';
import { useRoute } from '@react-navigation/native';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import { ProductDetailScreenRouteProps } from 'src/routes/ProductDetailRoutes/productDetailRoutes.model';
import { useCheckInventory } from '../../hooks';
import { usePurchaseActions } from '../../hooks/usePurchaseState/usePurchaseState';
import PurchaseButton from '../PurchaseButton/PurchaseButton';

const PADDING_BOTTOM = 16;

interface IBottomPanelProps {
  id: string;
  groupSize: number;
  soloPrice: number;
  groupPrice: number;
  currency: string;
  myActiveGroups?: ProductActiveGroupOrders;
  isInvited?: boolean;
}

// eslint-disable-next-line complexity
const BottomPanel = (props: IBottomPanelProps) => {
  const { id, groupSize, soloPrice, groupPrice, currency, myActiveGroups, isInvited = false } = props;

  const { bottom } = useSafeAreaInsets();

  const paddingBottom = bottom > PADDING_BOTTOM ? 0 : PADDING_BOTTOM - bottom;

  const { params } = useRoute<ProductDetailScreenRouteProps>();

  const { handlePressSoloBuy, handlePressGroupBuy, handleJoinGroup } = usePurchaseActions();

  const { canPurchase } = useCheckInventory({ productId: id });

  const isAlreadyInGroup = !!myActiveGroups;

  const displayCurrency = getDisplayCurrency(currency);

  const startGroupPrice = getStartGroupPrice(groupPrice);

  return (
    <View style={[tw`bottom-0 w-full`, { position: 'absolute' }]}>
      <View style={tw`bg-[#FFE757] border-t-2 border-b-2`}>
        <Text style={[tw`text-xs font-medium self-center py-2`, { fontFamily: 'TheFuture-Medium' }]}>
          {groupSize} {i18n.t('product_detail_bottom_panel_members_needed_for_group')}
        </Text>
      </View>
      {isAlreadyInGroup ? (
        <View style={tw`bg-white pt-4 pb-[${paddingBottom}px] px-6`}>
          {isInvited && params.inviteId ? (
            <TouchableOpacity style={tw`bg-black py-4 rounded-full`} onPress={() => handleJoinGroup(params.inviteId!)}>
              <Text style={[tw`text-sm text-white text-center`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('product_detail_bottom_panel_join_this_group')}
              </Text>
            </TouchableOpacity>
          ) : (
            <ShareGroupWrapper style={tw`bg-black py-4 rounded-full`} productId={id} groupId={myActiveGroups.id}>
              <Text style={[tw`text-sm text-white text-center`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('product_detail_group_invite_others')}
              </Text>
            </ShareGroupWrapper>
          )}
        </View>
      ) : (
        <View style={tw`flex-row justify-between items-center bg-white pt-4 pb-[${paddingBottom}px] px-4`}>
          <View style={tw`w-2/5 pr-4`}>
            <PurchaseButton
              actionText={i18n.t('product_detail_bottom_panel_solo_buy_button')}
              price={soloPrice}
              currency={displayCurrency}
              variant="outlined"
              onPress={handlePressSoloBuy}
              disabled={!canPurchase.solo}
            />
          </View>
          <View style={tw`flex-1`}>
            <PurchaseButton
              actionText={i18n.t('product_detail_bottom_panel_start_group_button')}
              oldPrice={soloPrice}
              price={startGroupPrice}
              currency={displayCurrency}
              onPress={handlePressGroupBuy}
              disabled={!canPurchase.group}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default BottomPanel;
