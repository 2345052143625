import { i18n, ProductActiveGroupOrdersMembers, useBanditTheme } from '@core';
import { useCountdown, useGetUser } from '@hooks';
import { ClockIcon, FlameLogo, ProfileBig } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { Avatar, Text } from 'react-native-paper';
import { capitalizeFirstLetter } from 'src/core/helpers/capitalizeFirstLetter';
import { tw } from 'src/core/tw';

const IMAGE_SIZE = 62;

interface IActiveGroupCardProps {
  creator: ProductActiveGroupOrdersMembers;
  numberOfMembers: number;
  groupSize: number;
  soloPrice: number;
  groupPrice: number;
  expiredDate: string;
  hasBorder?: boolean;
  showName?: boolean;
  isJoining?: boolean;
  showGroupMembers?: boolean;
  rounded?: 'top' | 'bottom';
  onPressCard?: () => void;
  onPressJoinGroup?: () => void;
}

// eslint-disable-next-line complexity
const ActiveGroupCard = ({
  creator,
  numberOfMembers,
  groupSize,
  soloPrice,
  groupPrice,
  expiredDate,
  hasBorder = true,
  showName = false,
  isJoining = true,
  showGroupMembers = true,
  rounded = 'top',
  onPressCard,
  onPressJoinGroup,
}: IActiveGroupCardProps) => {
  const { timeLeft, lessThanTenMins } = useCountdown({ timeout: expiredDate, autoFormat: true });

  const { colors } = useBanditTheme();

  const { id } = useGetUser();

  const notTimedOut = timeLeft !== '00 : 00';

  const [firstname] = creator?.name?.split(' ');

  const creatorName = capitalizeFirstLetter(firstname || '') + ', ' + capitalizeFirstLetter(creator?.city || '');

  return (
    <TouchableOpacity
      testID="active-group-card"
      style={[
        tw`flex-row items-center justify-between px-4 py-4 rounded-${rounded === 'top' ? 't' : 'b'}-xl border-[${
          hasBorder ? 1.5 : 0
        }px]`,
        { backgroundColor: lessThanTenMins && notTimedOut ? colors.secondary_ember_tint : 'transparent' },
      ]}
      activeOpacity={!onPressCard ? 1 : 0.2}
      onPress={onPressCard}
    >
      <View style={tw`flex-row items-center flex-shrink`}>
        <View style={tw`border-[1.5px] rounded-full self-start mr-3`}>
          {!!creator.photoUrl ? (
            <Avatar.Image accessibilityRole="image" source={{ uri: creator.photoUrl }} size={IMAGE_SIZE} />
          ) : (
            <ProfileBig
              testID="default-profile-image"
              accessibilityRole="image"
              width={IMAGE_SIZE + 2}
              height={IMAGE_SIZE + 2}
              viewBox="0 0 82 82"
            />
          )}
        </View>

        <View style={[tw`flex flex-shrink pr-4 `, { height: 'auto' }]}>
          {showName && (
            <Text style={[tw`text-xl overflow-hidden`, { fontFamily: 'TheFuture-Regular' }]}>
              {!isJoining && id === creator.userId ? i18n.t('general:you') : creatorName}
            </Text>
          )}
          {showGroupMembers && (
            <Text>
              <Text style={tw`text-3xl font-thefuture_regular`}>{numberOfMembers}</Text>
              <Text style={tw`text-xl font-thefuture_medium`}>/{groupSize}</Text>
            </Text>
          )}
        </View>
      </View>

      <View style={tw`flex items-end`}>
        <View style={tw`flex-row items-center`}>
          <Text style={tw`mr-2 font-thefuture_regular text-lg`}>{timeLeft}</Text>
          <ClockIcon size={20} />
        </View>

        {isJoining && (
          <View style={tw`self-end mt-4`}>
            <TouchableOpacity
              accessibilityRole="button"
              style={tw`btn-sm flex-col bg-bandit_pink`}
              onPress={onPressJoinGroup}
            >
              <View style={tw`flex-row items-center justify-center`}>
                <FlameLogo color={colors.primary_black} />
                <View style={tw`items-start ml-2 font-thefuture_regular`}>
                  <Text style={[tw`text-sm font-thefuture_regular`]}>
                    {i18n.t('product_detail_active_group_card_join_group')}
                  </Text>
                  <View style={tw`flex-row items-center gap-2`}>
                    <Text style={tw`font-thefuture_medium`}>{groupPrice} kr</Text>
                    <Text style={tw`line-through font-thefuture_regular`}>{soloPrice} kr</Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ActiveGroupCard;
