import { ResizeMode, Video } from 'expo-av';
import { forwardRef } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { TourGuideZone, useTourGuideController } from 'rn-tourguide';
import { tw } from 'src/core/tw';
import { IVideo, MediaRef } from '../../model';
import VideoActions from './components/VideoActions/VideoActions';
import VideoProductDetail from './components/VideoProductDetail';
import useVideoWithDetails from './hooks/useVideoWithDetails/useVideoWithDetails';

interface VideoPlayerProps {
  data: IVideo;
  isMuted: boolean;
  index: number;
  handleMuteChange: () => void;
}

const VideoWithDetails = forwardRef<MediaRef, VideoPlayerProps>(function ForwardedVideo(props, parentRef) {
  const { data, index, isMuted, handleMuteChange } = props;

  const { ref, handleViewProduct, isPlaying } = useVideoWithDetails({ parentRef, isMuted });

  const { tourKey } = useTourGuideController();

  return (
    <View style={[tw`flex-1`]}>
      <TouchableWithoutFeedback
        onPress={() => {
          handleViewProduct(data.id);
        }}
      >
        <Video
          testID="video-player"
          ref={ref}
          resizeMode={ResizeMode.COVER}
          style={{
            flex: 1,
          }}
          source={{ uri: data.videoUrl }}
          useNativeControls={false}
          isMuted={isMuted}
          isLooping
          volume={1}
          videoStyle={{
            width: '100%',
            height: '100%',
          }}
        />
      </TouchableWithoutFeedback>
      <TourGuideZone
        isTourGuide={index === 0}
        tourKey={tourKey}
        zone={3}
        maskOffset={40}
        tooltipBottomOffset={index === 0 ? 250 : 0}
        shape={'circle'}
      >
        <VideoProductDetail data={data} />
      </TourGuideZone>
      <VideoActions
        data={data}
        index={index}
        isMuted={isMuted}
        handleMuteChange={handleMuteChange}
        isPlaying={isPlaying}
      />
    </View>
  );
});

export default VideoWithDetails;
