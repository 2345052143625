import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { Right } from '@svgIcons';
import { Linking, View } from 'react-native';
import { List } from 'react-native-paper';
import { useTourGuideController } from 'rn-tourguide';
import { tw } from 'src/core/tw';
import { SettingNaviation } from 'src/routes/settingRoutes';

interface QuickLinksProps {
  isLogin: boolean;
  onShare?: () => void;
}

const QuickLinks = ({ isLogin, onShare }: QuickLinksProps) => {
  const navigation = useNavigation<SettingNaviation>();
  const { start } = useTourGuideController();

  const menu = [
    {
      title: i18n.t('profile_settings_menu_order_history'),
      isLogin,
      onPress: () => navigation.navigate('OrderHistoryScreen'),
    },
    {
      title: i18n.t('profile_settings_menu_FAQ'),
      isLogin: true,
      onPress: () => Linking.openURL('https://www.joinbandit.com/#b-faq'),
    },
    {
      title: i18n.t('profile_settings_menu_help_and_feedback'),
      isLogin,
      onPress: () => navigation.navigate('HelpAndFeedbackScreen'),
    },
    {
      title: i18n.t('profile_settings_menu_favorite_category'),
      isLogin,
      onPress: () => navigation.navigate('FavoriteCategoryScreen', { isProfile: true }),
    },
    {
      title: i18n.t('profile_settings_menu_terms_and_conditions'),
      isLogin: true,
      onPress: () => navigation.navigate('TermsAndConditionsScreen'),
    },
    {
      title: i18n.t('profile_settings_menu_onboarding'),
      isLogin: true,
      onPress: () => start(),
    },
    {
      title: i18n.t('profile_settings_menu_share'),
      isLogin,
      onPress: onShare,
    },
  ];

  const renderItem = (item: { title: string; isLogin: boolean; onPress?: () => void }) => (
    <List.Item
      accessibilityLabelledBy={item.title}
      accessibilityLanguage={i18n.locale}
      key={item.title}
      style={tw`border-b border-gray-300 py-3`}
      title={item.title}
      titleStyle={{ fontFamily: 'TheFuture-Regular', fontSize: 14, color: !item.isLogin ? 'grey' : 'black' }}
      right={(props) => <Right style={tw`w-4 h-4 mt-1`} />}
      onPress={item.onPress}
      disabled={!item.isLogin}
    />
  );

  return <View style={tw`border-2 rounded-lg bg-white`}>{menu.map(renderItem)}</View>;
};

export default QuickLinks;
