import { useLazyQuery, useQuery } from '@apollo/client';
import { i18n } from '@core';
import { useCallback, useEffect, useState } from 'react';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { ISearchDataScreen, ISearchDefaultScreen, ISearchShowData } from '../../model';
import { GET_SEARCH_DEFAULT_DATA, GET_SEARCH_RESULT_DATA } from '../../query';

const useSearchHook = () => {
  const toast = useShowToast();
  const [defaultData, setDefaultData] = useState<ISearchDefaultScreen | undefined>();
  const [resultData, setResultData] = useState<ISearchShowData>();
  const { data, loading: defaultLoading } = useQuery<ISearchDefaultScreen>(GET_SEARCH_DEFAULT_DATA);
  const [getSearchData, { loading: searchLoading }] = useLazyQuery<ISearchDataScreen>(GET_SEARCH_RESULT_DATA);

  useEffect(() => {
    if (data) {
      setDefaultData(data);
      setResultData({
        isDefault: true,
        data,
      });
    }
  }, [data]);

  const resetData = () => {
    setResultData({
      isDefault: true,
      data: defaultData,
    });
  };

  const checkResult = (result: ISearchDataScreen) => {
    const keys = Object.keys(result);

    const results = keys.map((key: string) => result[key].length > 0);
    return results.some((res) => res);
  };

  const handleSearchResult = useCallback(
    (keyWord: string) => {
      getSearchData({
        variables: {
          searchKeyWord: `%${keyWord}%`,
        },
        onCompleted: (response) => {
          setResultData({
            isDefault: false,
            data: checkResult(response) ? response : undefined,
          });
        },
        onError: (error) => {
          toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
        },
      });
    },
    [getSearchData],
  );

  return {
    resultData,
    loading: defaultLoading || searchLoading,
    resetData,
    handleSearchResult,
  };
};

export default useSearchHook;
