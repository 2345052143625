import { initializeUserLogin } from '@core';
import { Asset } from 'expo-asset';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useCallback, useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import AnimatedSplashScreen from '../SplashScreen/SplashScreen';
SplashScreen.preventAutoHideAsync();

interface AnimatedAppLoaderProps {
  children: React.ReactNode;
  image: {
    uri: string | undefined;
  };
}

function cacheImages(images: any) {
  return images.map((image: any) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

export function AnimatedAppLoader({ children, image }: AnimatedAppLoaderProps) {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [iconFontLoaded] = useFonts({
    'TheFuture-Regular': require('../../../assets/fonts/TheFuture-Regular.ttf'),
    'TheFuture-Medium': require('../../../assets/fonts/TheFuture-Medium.ttf'),
    'TheFuture-Black': require('../../../assets/fonts/TheFuture-Black.ttf'),
    'FeatureDeck-Regular': require('../../../assets/fonts/FeatureDeck-Regular-App.ttf'),
  });
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await Promise.all([initializeUserLogin(), cacheImages([require('../../../assets/splash.png')])]);
      } catch (e) {
        sendErrorToSentry(e, {
          tags: {
            screen: 'AnimatedSplashScreen',
            function: 'onImageLoaded',
          },
        });
      } finally {
        setResourcesLoaded(true);
      }
    }

    loadResourcesAndDataAsync();
  }, [image]);

  const onLayoutRootView = useCallback(async () => {
    if (resourcesLoaded && iconFontLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [resourcesLoaded, iconFontLoaded]);

  if (!resourcesLoaded || !iconFontLoaded) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <AnimatedSplashScreen image={image}>
        <View style={{ flex: 1 }}>{children}</View>
      </AnimatedSplashScreen>
    </View>
  );
}
export default AnimatedAppLoader;
