import { useQuery } from '@apollo/client';
import { Faqs } from '../../model';
import { GET_FAQS } from '../../query';

const useFaqsHook = () => {
  const { data, loading } = useQuery<Faqs>(GET_FAQS, { fetchPolicy: 'cache-and-network' });

  return {
    faqsData: data?.faqs,
    faqLoading: loading,
  };
};

export default useFaqsHook;
