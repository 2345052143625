import { gql } from '@apollo/client';

export const GET_FAQS = gql`
  query GetFaqs {
    faqs(where: { productTypeId: { _isNull: true } }, distinctOn: question) {
      question
      answer
    }
  }
`;
