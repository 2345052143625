/* eslint-disable complexity */
import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import {
  ageCalculator,
  authVar,
  fetchVippsToken,
  getInvitedBy,
  i18n,
  jwtDecode,
  storeUserId,
  userSchema,
  useUserStore,
} from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation, useRoute } from '@react-navigation/native';
import { CreateUserScreenNavigationProps, CreateUserScreenRouteProps } from '@routes';
import Constants from 'expo-constants';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ERROR_MESSAGES } from 'src/core/messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useShowToast } from 'src/hooks/useShowToast';
import { IUserCreatePayload, IUserCreateResponse, IUserUpdateResponse, UserFormData } from '../../model';
import { CREATE_USER, UPDATE_USER } from '../../query';

const useCreateUserHook = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const navigation = useNavigation<CreateUserScreenNavigationProps>();
  const route = useRoute<CreateUserScreenRouteProps>();
  const toast = useShowToast();
  const auth = useReactiveVar(authVar);
  const [userCreate] = useMutation<IUserCreateResponse, IUserCreatePayload>(CREATE_USER);
  const [userUpdate] = useMutation<IUserUpdateResponse, IUserCreatePayload>(UPDATE_USER);
  const userStore = useUserStore();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<UserFormData>({
    resolver: yupResolver(userSchema),
    mode: 'onChange',
  });

  const getVippsUserInfo = async (token: string) => {
    const vippsData = await fetchVippsToken(token);
    if (vippsData) {
      const age = ageCalculator(vippsData?.birthdate);
      reset({
        firstName: vippsData?.given_name,
        lastName: vippsData?.family_name,
        email: vippsData?.email,
        mobile: vippsData?.phone_number,
        age: age.toString(),
        address: vippsData?.address?.street_address,
        city: vippsData?.address?.region,
        zipCode: vippsData?.address?.postal_code,
      });
    }
  };

  const constants = Constants.expoConfig?.extra;
  const vippsAuthIdentifier = constants?.VIPPS_AUTH_PARAMETER?.toLowerCase();

  useEffect(() => {
    if (route.params) {
      const { idpToken, isEdit } = route.params;
      if (idpToken) {
        const data = jwtDecode(idpToken);
        const tfp = data.tfp.toLowerCase();
        if (tfp.includes(vippsAuthIdentifier)) {
          getVippsUserInfo(data.idp_access_token);
        } else {
          setValue('firstName', data?.given_name);
          setValue('lastName', data?.family_name);
          setValue('email', data?.emails?.[0] ?? '');
        }
      }

      if (isEdit) {
        setIsEditMode(true);

        const user = userStore.user;
        setValue('firstName', user?.firstName);
        setValue('lastName', user?.lastName);
        setValue('email', user?.email);
        setValue('mobile', user?.phoneNumber);
        setValue('age', user.age?.toString() || '');
        setValue('address', user?.address);
        setValue('city', user?.city);
        setValue('zipCode', user?.zipCode);
      }
    }
  }, [route]);

  const handleError = (error: ApolloError) => {
    if (error.graphQLErrors.some((e) => e.extensions && e.extensions.code === 409)) {
      toast.info(i18n.t('user_phone_already_exists'));
    } else {
      toast.danger(i18n.t(ERROR_MESSAGES.default));
    }
    sendErrorToSentry(error.message, {
      tags: {
        Screen: 'useCreateUserHook',
      },
    });
  };

  const onSubmitProfile = async (userData: UserFormData) => {
    const { token } = auth;
    setLoading(true);

    if (isEditMode) {
      userUpdate({
        variables: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          request: {
            ...userData,
            age: userData.age ? parseInt(userData.age) : 0,
          },
        },

        onCompleted: async (data: IUserUpdateResponse) => {
          setLoading(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          userStore.setUser({ ...data.userUpdate, phoneNumber: data.userUpdate.mobile });
          authVar({ ...authVar(), loggedIn: true });
          navigation.goBack();
        },
        onError: (error) => {
          setLoading(false);
          handleError(error);
        },
      });
    } else {
      const invitedBy = await getInvitedBy();
      if (token) {
        userCreate({
          variables: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            request: {
              ...userData,
              age: userData.age ? parseInt(userData.age) : 0,
              invitedBy: invitedBy,
            },
          },
          onCompleted: async (data: IUserCreateResponse) => {
            setLoading(false);
            storeUserId(data.userCreate.id);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userStore.setUser({ ...data.userCreate });
            authVar({ ...authVar(), loggedIn: true });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigation.navigate('CreateProfileScreen');
          },
          onError: (error) => {
            setLoading(false);
            handleError(error);
          },
        });
      }
    }
  };

  return {
    onSubmitProfile,
    control,
    handleSubmit,
    errors,
    loading,
    isEditMode,
    isValid,
  };
};

export default useCreateUserHook;
