/* eslint-disable complexity */
import { ApolloError, useApolloClient, useLazyQuery, useReactiveVar } from '@apollo/client';
import { authVar, logout, useUserStore } from '@core';
import { useEffect } from 'react';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import updateUserTelemetryInfo from 'src/core/telemetry/updateUserContext';
import { IUsers } from '../model';
import { GET_BC_LIST, GET_USERS } from '../query';

const useInitialize = () => {
  const auth = useReactiveVar(authVar);

  const { loggedIn, token } = auth;

  const store = useUserStore();

  const client = useApolloClient();

  const onCompleted = async (data: IUsers) => {
    const user = data.users[0];
    if (user) {
      // TODO: fix typing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.setUser(user);
      updateUserTelemetryInfo({
        id: user.id,
        email: user.email,
      });
    } else {
      handleLogout();
    }
  };

  const handleLogout = async () => {
    logout();
    store.resetAllState();
    await client.clearStore();
    updateUserTelemetryInfo(null);
  };

  const onError = (error: ApolloError) => {
    sendErrorToSentry(error, {
      tags: {
        Screen: 'SplashScreen',
      },
    });
  };

  const [getUsers] = useLazyQuery<IUsers>(GET_USERS, { onCompleted, onError });

  const [getBCList] = useLazyQuery(GET_BC_LIST);

  useEffect(() => {
    if (loggedIn && token) {
      if (!store.user.id) {
        getUsers({
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        });
      }
    }
  }, [loggedIn, store, token]);

  return {};
};

export default useInitialize;
