import { AppLogo } from '@svgIcons';
import { StatusBar as ExpoStatusBar } from 'expo-status-bar';
import { SafeAreaView, StatusBar, View } from 'react-native';
import { tw } from 'src/core/tw';

interface HeaderProps {
  showBorder?: boolean;
  backgroundColor?: string;
}

export const TabHeader = ({ showBorder, backgroundColor = 'white' }: HeaderProps) => {
  return (
    <SafeAreaView
      accessibilityRole="header"
      style={[
        tw`w-full`,
        tw.style(showBorder ? 'border-b-2' : null),
        {
          paddingTop: StatusBar.currentHeight,
          backgroundColor,
        },
      ]}
    >
      <ExpoStatusBar style="dark" backgroundColor={backgroundColor} />
      <View style={[tw`w-full flex justify-center items-center py-1`, { backgroundColor }]}>
        <AppLogo style={tw`my-2`} />
      </View>
    </SafeAreaView>
  );
};

export default TabHeader;
