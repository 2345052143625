import { FC } from 'react';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface PriceDifferenceProp {
  oldPrice: number;
  newPrice: number;
  currency: string;
  color?: string;
}

export const PriceDifference: FC<PriceDifferenceProp> = ({ oldPrice, newPrice, currency, color = 'black' }) => {
  return (
    <>
      <Text style={[tw`line-through text-sm font-bold`, { color }]}>{`${oldPrice}${currency}`}</Text>
      <Text style={[tw`text-sm font-bold`, { color }]}>{` ${newPrice}${currency}`}</Text>
    </>
  );
};
