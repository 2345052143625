/* eslint-disable complexity */
import dayjs from 'dayjs';
import { getTimeDiff } from './time';

export const getTranslationValuesForRemainingTime = (date: string) => {
  const difference = getTimeDiff(date);
  const durations = dayjs.duration(difference);
  const minutes = durations.minutes();
  const hours = durations.hours();
  const values = { hours, minutes };

  if (hours >= 6) {
    return { key: 'remaining_group_hours', values };
  }

  if (hours >= 2) {
    return { key: 'remaining_group_hours_and_minutes', values };
  }

  if (hours >= 1) {
    return { key: 'remaining_group_hour', values };
  }

  if (hours === 0 && minutes >= 2) {
    return { key: 'remaining_group_minutes', values };
  }

  if (minutes < 2 && minutes > 0) {
    return { key: 'remaining_group_minute', values };
  }

  return '';
};
