import { i18n, useBanditTheme } from '@core';
import { CloseIcon } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface BCAdsProps {
  onHideBanditAds: (justTouch: boolean) => void;
}

const BCAds = ({ onHideBanditAds }: BCAdsProps) => {
  const { colors } = useBanditTheme();

  return (
    <TouchableOpacity
      onPress={() => onHideBanditAds(true)}
      style={tw`bg-[${colors.secondary_fuschia}] border-[1.5px] rounded-lg p-4 flex-row flex-shrink mt-2 mb-4`}
    >
      <View style={tw`flex flex-shrink`}>
        <Text style={tw`text-sm font-bold mb-3`}>{i18n.t('profile_share_title')}</Text>
        <Text style={tw`text-sm`}>{i18n.t('profile_share_description')}</Text>
      </View>
      <TouchableOpacity onPress={() => onHideBanditAds(false)} style={tw`-mt-1 ml-1`} accessibilityRole="button">
        <CloseIcon fillColor="#FF94FF" />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

export default BCAds;
