import { IImage, IImages } from '@core';
import { Image, Pressable, View } from 'react-native';
import { tw } from 'src/core/tw';

interface IProductImageProps {
  images: IImages;
  onPressImage: (images: IImage[], index: number) => void;
}

const ProductImageGallery = ({ images, onPressImage }: IProductImageProps) => {
  const totalLength = images.originalImages.length;

  return (
    <View testID="product-image-gallery" style={tw`flex-row flex-wrap`}>
      {images.originalImages.map(({ name, url }, index) => {
        const remainder = index % 3;

        if ((remainder === 0 && index !== totalLength - 1) || remainder === 1) {
          return (
            <Pressable
              key={name}
              testID="image-wrapper"
              style={tw.style('border-b-2 w-1/2 h-86', { 'border-r-2': remainder === 0 })}
              onPress={() => onPressImage(images.originalImages, index)}
            >
              <Image
                accessibilityRole="image"
                source={{ uri: images.splitUrlImages[index].url }}
                resizeMode="contain"
                style={tw`w-full h-full`}
              />
            </Pressable>
          );
        }

        return (
          <Pressable
            key={name}
            testID="image-wrapper"
            style={tw.style('border-b-2 w-full h-180')}
            onPress={() => onPressImage(images.originalImages, index)}
          >
            <Image accessibilityRole="image" source={{ uri: url }} style={tw`w-full h-full`} resizeMode="contain" />
          </Pressable>
        );
      })}
    </View>
  );
};

export default ProductImageGallery;
