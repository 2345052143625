const { plugin } = require('twrnc');

module.exports = {
  content: [],
  theme: {
    fontFamily: {
      thefuture_regular: ['TheFuture-Regular', 'sans-serif'],
      thefuture_medium: ['TheFuture-Medium', 'sans-serif'],
      thefuture_black: ['TheFuture-Black', 'sans-serif'],
      featuredeck_regular: ['FeatureDeck-Regular', 'serif'],
    },
    extend: {
      colors: {
        bandit_yellow: '#FFE757',
        bandit_purple: '#8D7DFF',
        bandit_pink: '#FF94FF',
        bandit_orange: '#FF7545',
        bandit_bg_primary: '#F9F3DB',
      },
    },
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        btn: 'flex items-center rounded-full py-3 px-6 border-2',
        'btn-sm': 'btn py-2 px-4 flex-row',
      });
    }),
  ],
};
