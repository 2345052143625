/* eslint-disable complexity */
import { Loading } from '@components';
import { generateRefLink, i18n, useUserStore } from '@core';
import { useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import { Platform, Share } from 'react-native';
import { ERROR_MESSAGES } from 'src/core/messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useShowToast } from 'src/hooks/useShowToast';
import { LoginModalScreenNavigationProps } from 'src/routes/ModalRoutes';
import useBCHook from '../BCModalScreen/hooks/useBCHook';
import ProfileView from './components/ProfileView/ProfileView';
import useProfileHook from './hooks/useProfileHook/useProfileHook';

const ProfileScreen = () => {
  const toast = useShowToast();
  const { adsBox, handleLogout, hideAdsBox, loading, scrollViewRef } = useProfileHook();

  const navigation = useNavigation<LoginModalScreenNavigationProps>();

  const userStore = useUserStore();

  const bc = useBCHook();

  const handleLogin = () => {
    navigation.navigate('LoginModalScreen');
  };

  const goToBCModal = () => {
    navigation.navigate('BCModalScreen');
  };

  const shareReferralLink = async () => {
    if (!userStore.user.id) return;

    const refUrl = await generateRefLink(userStore.user?.id);

    if (Platform.OS === 'web') {
      await Clipboard.setStringAsync(refUrl);
      alert(`The following referral link was copied to your clipboard.\n\n${refUrl}`);
    } else {
      try {
        await Share.share({
          message: `${i18n.t('profile_share_to_your_friends_description')} ${refUrl}`,
          url: refUrl,
          title: i18n.t('profile_share_to_your_friends_title'),
        });
      } catch (error: any) {
        sendErrorToSentry(error, {
          tags: {
            screen: 'ProfileScreen',
            function: 'shareReferralLink',
          },
        });
        toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
      }
    }
  };

  const clickNotification = () => {
    return;
  };

  const editProfile = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('CreateProfileScreen', {
      isEdit: true,
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ProfileView
          editProfileImage={editProfile}
          city={userStore.user.city ?? ''}
          coinAmount={bc.coinAmount?.toString()}
          email={userStore.user.email ?? ''}
          firstName={userStore.user.firstName ?? ''}
          lastName={userStore.user.lastName ?? ''}
          photoUrl={userStore.user.photoUrl ?? ''}
          loginUser={handleLogin}
          handleLogout={handleLogout}
          goToBCModal={goToBCModal}
          onShare={shareReferralLink}
          isAdsShow={adsBox}
          onClickNotification={clickNotification}
          onHideBanditAds={hideAdsBox}
          setScrollRef={scrollViewRef}
        />
      )}
    </>
  );
};

export default ProfileScreen;
