/* eslint-disable complexity */
import { BackHeader, KeyboardAvoidView, Loading } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { tw } from 'src/core/tw';
import { SettingNaviation } from 'src/routes/settingRoutes';
import OrderInquiryFields from './components/OrderInquiryFields/OrderInquiryFields';
import OrderList from './components/OrderList/OrderList';
import OrdersNotFoundMessage from './components/OrdersNotFoundMessage/OrdersNotFoundMessage';
import useOrderIssuesScreen from './hooks/useOrderIssuesScreen/useOrderIssuesScreen';

const OrderIsssuesScreen = () => {
  const { colors } = useBanditTheme();

  const navigation = useNavigation<SettingNaviation>();

  const { fetchingOrders, orders, handleOrderSelected, selectedOrderId } = useOrderIssuesScreen();

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, flex: 1 }} style={tw`bg-white`}>
      <KeyboardAvoidView>
        <SafeAreaView />
        <BackHeader
          goBack={navigation.goBack}
          title={i18n.t('profile_helpAndFeedback_orderIssues_screenTitle')}
          titleStyle={[tw`text-black`, { fontFamily: 'TheFuture-Regular' }]}
        />
        <View style={[tw`p-4 flex-1`, { backgroundColor: colors.bg_primary }]}>
          <View style={tw`flex-1`}>
            {fetchingOrders && <Loading />}

            {!orders?.length && !fetchingOrders && <OrdersNotFoundMessage />}

            {!!orders?.length && !fetchingOrders && (
              <>
                <OrderList orders={orders} handleOrderSelected={handleOrderSelected} />
                {!!selectedOrderId && <OrderInquiryFields orderId={selectedOrderId} />}
              </>
            )}
          </View>
        </View>
      </KeyboardAvoidView>
    </ScrollView>
  );
};

export default OrderIsssuesScreen;
