import { UseProductDetailsStore, useProductDetailsStore } from '@core';
import { shallow } from 'zustand/shallow';

const selector = ({ resetAllState, resetAfterPayment }: UseProductDetailsStore) => ({
  resetAllState,
  resetAfterPayment,
});

const useProductDetailsState = () => {
  const { resetAllState, resetAfterPayment } = useProductDetailsStore(selector, shallow);

  return { resetAllState, resetAfterPayment };
};

export default useProductDetailsState;
