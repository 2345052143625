import { Text, View } from 'react-native';
import { tw } from 'src/core/tw';

const DividerText = ({ children }: { children: string }) => (
  <View style={tw`flex-row justify-center items-center my-4`}>
    <View style={tw`border-b w-full`} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} />
    <Text style={tw`text-sm mx-2`}>{children}</Text>
    <View style={tw`border-b w-full`} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} />
  </View>
);
export default DividerText;
