/* eslint-disable complexity */
import CoinIcon from '@assets/icons/coin.png';
import { getPrice, getShortProductName, getStartGroupPrice, i18n, videoColors } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { Image, StyleProp, View, ViewStyle } from 'react-native';
// @ts-expect-error Could not find a declaration file for module 'react-native-expo-cached-image'
import CachedImage from 'react-native-expo-cached-image';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { Text } from 'react-native-paper';
import GradientPlaceHolderImage from 'src/components/ImagePlaceHolder/GradientImagePlaceholder';
import { isWeb } from 'src/core/helpers/platformHelper';
import { tw } from 'src/core/tw';
import ProductDetailPrices from 'src/domain/prices/components/ProductDetailPrices';
import { IProduct } from './model';
import ShowMember from './ShowMember/ShowMember';

interface IProductCardProps {
  product: IProduct;
  brand: string;
  style?: StyleProp<ViewStyle>;
  withExitingAnimation?: boolean;
}

const ProductCard = ({ product, style, brand, withExitingAnimation = true }: IProductCardProps) => {
  const navigation = useNavigation<ProductDetailScreenNavigationProps>();

  const handleViewProduct = (id: string) => navigation.navigate('ProductDetailScreen', { id: id });

  const initialGroupPrice =
    !!product && product.activeGroupOrders != null && product.activeGroupOrders.length > 0
      ? getPrice(product.groupPrice, product.currency)
      : getPrice(getStartGroupPrice(product.groupPrice), product.currency);

  const priceDifference = Math.abs(product.soloPrice - parseInt(initialGroupPrice));

  const mainProductImageProps = {
    key: product.id,
    testID: 'product-image',
    style: [
      tw`w-auto h-auto rounded-tr rounded-tl  `,
      {
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
      },
    ],
    source: {
      uri: product.imageUrls[0].feedUrl ?? product.producer.logoUrl,
    },
  };

  const producerLogoProps = {
    testID: 'producer-image',
    style: tw`h-8 w-8 bg-black rounded-full`,
    source: {
      uri: product.producer.logoUrl,
    },
  };

  const name = getShortProductName({ name: product.name });

  return (
    <TouchableWithoutFeedback
      testID={`view-product-${product.id}`}
      key={product.id}
      onPress={() => handleViewProduct(product.id)}
    >
      <View style={[tw`h-auto border-2 bg-white rounded-xl overflow-hidden m-1.5`, style]} key={product.name}>
        <View style={tw`border-b-2`}>
          {isWeb ? (
            <GradientPlaceHolderImage {...mainProductImageProps} resizeMode="contain" />
          ) : (
            <CachedImage {...mainProductImageProps} />
          )}
        </View>
        {product.activeGroupOrders.length > 0 ? (
          <ShowMember product={product} withExitingAnimation={withExitingAnimation} />
        ) : (
          <View />
        )}
        {product.banditCashDiscount && priceDifference !== 0 ? (
          <View
            testID="bandit-cash"
            accessibilityRole="header"
            style={[
              tw` p-2 h-auto w-auto border-b-2 items-start justify-start`,
              { backgroundColor: videoColors.backgroundColor },
            ]}
          >
            <View style={tw`flex-row`}>
              <Text style={[tw`text-black text-xs`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('deals_coin_title')} {priceDifference.toFixed(0)}
              </Text>
              <Image style={tw`h-4 w-4 mx-1 mt-0`} source={CoinIcon} />
              <Text style={[tw`text-black text-xs`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('deals_coin_title1')}
              </Text>
            </View>
            <Text style={[tw`text-black text-xs`, { fontFamily: 'TheFuture-Regular' }]}>
              {i18n.t('deals_coin_title2')}
            </Text>
          </View>
        ) : (
          <View />
        )}

        <View style={tw`p-2 px-2`}>
          <Text style={[tw`text-sm font-bold underline`, { fontFamily: 'TheFuture-Medium' }]}>{brand}</Text>

          <Text
            numberOfLines={3}
            style={[tw`font-normal text-black text-sm my-1`, { fontFamily: 'TheFuture-Regular' }]}
          >
            {name}
          </Text>

          <ProductDetailPrices
            groupPrice={product.groupPrice}
            currency={product.currency}
            soloPrice={product.soloPrice}
            hasActiveGroupOrders={product.activeGroupOrders.length > 0}
            banditCashDiscount={product.banditCashDiscount}
            fontSizes={{ heading: 'text-2xl', subHeading: 'text-sm' }}
            fontColor="black"
          />
        </View>

        {product.producer.logoUrl != null && (
          <View style={tw`absolute top-0 left-0 right-0 mx-2 my-2`}>
            <View style={tw`bg-black justify-center items-center h-9 w-9 items-center rounded-full`}>
              {isWeb ? (
                <Image {...producerLogoProps} accessibilityRole="image" />
              ) : (
                <CachedImage {...producerLogoProps} />
              )}
            </View>
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
export default ProductCard;
