/* eslint-disable complexity */
import { useReactiveVar } from '@apollo/client';
import { authVar, i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { GroupMemberListScreenNavigationProps } from '@routes';
import { useEffect, useMemo } from 'react';
import { InteractionManager, ScrollView, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { CustomBottomSheetModal } from 'src/components/CustomBottomSheetModal/CustomBottomSheetModal';
import DividerText from 'src/components/DividerText';
import { tw } from 'src/core/tw';
import { LoginModalScreenNavigationProps } from 'src/routes/ModalRoutes';
import { useActiveGroupState, useCheckInventory, useGroupDetails } from '../../hooks';
import { usePurchaseActions } from '../../hooks/usePurchaseState/usePurchaseState';
import ActiveGroupCard from '../ActiveGroupCard/ActiveGroupCard';
import GroupCTACard from '../GroupCTACard/GroupCTACard';
import GroupMembersList from '../GroupMembersList/GroupMembersList';

interface IActiveGroupInfoModalProps {
  isVisible: boolean;
  productId: string;
  groupSize: number;
  soloPrice: number;
  groupPrice: number;
  onClose: () => void;
}

// eslint-disable-next-line complexity
const ActiveGroupInfoModal = ({
  isVisible,
  productId,
  groupSize,
  onClose,
  soloPrice,
  groupPrice,
}: IActiveGroupInfoModalProps) => {
  const { orderId } = useActiveGroupState();

  const { groupDetails, loading, refetch } = useGroupDetails({ id: orderId || undefined });

  const navigation = useNavigation<LoginModalScreenNavigationProps>();

  const groupNavigation = useNavigation<GroupMemberListScreenNavigationProps>();

  const auth = useReactiveVar(authVar);

  const isLoggedIn = auth?.loggedIn;

  const { handleJoinGroup, handlePressGroupBuy } = usePurchaseActions();

  const { canPurchase, checkInventory } = useCheckInventory({ productId });

  const { titleKey, options: titleOptions } = useMemo(() => {
    const [creator] = (groupDetails?.creator?.name || '').split(' ');
    const city = groupDetails?.creator?.city || '';
    const options = { city, creator };

    if (creator && city) {
      return { titleKey: 'product_detail_active_group_info_modal_group_to_success_full', options };
    }

    if (creator) {
      return { titleKey: 'product_detail_active_group_info_modal_group_to_success_creator', options };
    }

    if (city) {
      return { titleKey: 'product_detail_active_group_info_modal_group_to_success_location', options };
    }

    return { titleKey: 'product_detail_active_group_info_modal_group_to_success', options };
  }, [groupDetails?.creator]);

  useEffect(() => {
    if (isVisible && !!orderId) {
      checkInventory();
      refetch({ id: orderId });
    }
  }, [isVisible, orderId]);

  const handleLogin = () => {
    onClose();
    navigation.navigate('LoginModalScreen');
  };

  const handlePressJoinGroup = () => {
    if (!isLoggedIn) {
      handleLogin();
      return;
    }

    if (!!groupDetails?.id) {
      onClose();
      InteractionManager.runAfterInteractions(() => handleJoinGroup(groupDetails.id));
    }
  };

  const handleCreateGroup = () => {
    if (!isLoggedIn) {
      handleLogin();
      return;
    }

    onClose();
    InteractionManager.runAfterInteractions(handlePressGroupBuy);
  };

  const handleViewMemberList = () => {
    if (!!orderId) {
      onClose();
      groupNavigation.navigate('GroupMemberListScreen', { activeOrderId: orderId });
    }
  };

  const groupMembers = groupDetails?.members.filter((member) => member.userId !== groupDetails?.creator.userId);

  return (
    <CustomBottomSheetModal closeModal={onClose} isVisible={isVisible} title={i18n.t(titleKey, titleOptions)}>
      <View style={tw`flex bg-white px-4 pb-4`}>
        {!loading && !!groupDetails && (
          <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
            <View style={tw`flex-1`} onStartShouldSetResponder={() => true}>
              <View style={tw`w-full rounded-xl border-2 overflow-hidden`}>
                <ActiveGroupCard
                  creator={groupDetails.creator}
                  groupSize={groupSize}
                  soloPrice={soloPrice}
                  groupPrice={groupPrice}
                  numberOfMembers={groupDetails.numberOfMembers}
                  expiredDate={groupDetails.expiredDate}
                  onPressJoinGroup={handlePressJoinGroup}
                  hasBorder={false}
                />

                {!!groupMembers?.length && (
                  <>
                    <Divider
                      style={tw`border-b-2`}
                      accessibilityLabelledBy={undefined}
                      accessibilityLanguage={undefined}
                    />
                    <GroupMembersList
                      members={groupMembers.slice(0, 3)}
                      goToMemberListPage={handleViewMemberList}
                      isMoreMembers={groupMembers.length > 3}
                    />
                  </>
                )}
              </View>
              {canPurchase.group && (
                <>
                  <DividerText>{i18n.t('common_or')}</DividerText>
                  <GroupCTACard onPressStartGroup={handleCreateGroup} groupPrice={groupPrice} soloPrice={soloPrice} />
                </>
              )}
            </View>
          </ScrollView>
        )}
      </View>
    </CustomBottomSheetModal>
  );
};

export default ActiveGroupInfoModal;
