import { i18n, useBanditTheme, videoColors } from '@core';
import { AddIcon, CheckMark } from '@svgIcons';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Text, TextInput, View } from 'react-native';
import { tw } from 'src/core/tw';
import { EndOfFeedAddBrandsListState } from '../../model';

const EndOfFeedBrandInput = ({
  isVisibleModal,
  closeModal,
  customBrands,
  setCustomBrands,
  intialCustomBrand,
}: {
  isVisibleModal: boolean;
  closeModal: () => void;
  customBrands: EndOfFeedAddBrandsListState[];
  setCustomBrands: Dispatch<SetStateAction<EndOfFeedAddBrandsListState[]>>;
  intialCustomBrand: EndOfFeedAddBrandsListState;
}) => {
  const { colors } = useBanditTheme();

  const handleChange = (text: string, brandId: number) => {
    const brandCharacterLimit = 50;
    const tempBrands = [...customBrands];
    const brandIndex = tempBrands.findIndex((tBrand) => tBrand.id === brandId);
    tempBrands[brandIndex].value = text;
    tempBrands[brandIndex].hasError = text.length > brandCharacterLimit;
    setCustomBrands(tempBrands);
  };

  useEffect(() => {
    const lastBrandIndex = customBrands?.length - 1;
    const isLastBrandEmpty = customBrands[lastBrandIndex].value === '';
    if (!isLastBrandEmpty) {
      setCustomBrands([
        ...customBrands,
        {
          ...intialCustomBrand,
          id: customBrands?.length + 1,
        },
      ]);
    }
  }, [customBrands]);

  return (
    <View style={tw`border-[1.5px] border-black border-black rounded-xl rounded-t-none overflow-hidden border-t-0`}>
      {customBrands?.map(({ id, value, hasError }) => (
        <View
          style={tw`w-full flex-row justify-between items-center relative border-black bg-white relative py-2.5 pr-4 rounded-xl`}
          key={id}
        >
          {hasError && (
            <Text
              style={[
                tw`text-[${videoColors.videoOrange}] text-xs font-normal absolute top-2.5 right-4`,
                { fontFamily: 'TheFuture-Regular' },
              ]}
            >
              {i18n.t('end_of_feed_character_limit_text')}
            </Text>
          )}

          <View style={tw`mx-4`}>{value != '' ? <CheckMark /> : <AddIcon />}</View>
          <TextInput
            style={[
              tw`flex-auto text-black w-auto text-sm py-4 text-left items-start justify-start font-normal w-full`,
              { fontFamily: 'TheFuture-Regular' },
            ]}
            numberOfLines={1}
            value={value}
            placeholder={i18n.t('end_of_feed_add_brand_placeholder')}
            placeholderTextColor={colors.black}
            onChangeText={(text) => handleChange(text, id)}
          />
        </View>
      ))}
    </View>
  );
};

export default EndOfFeedBrandInput;
