import { getRelativeTimeText, ProductActiveGroupOrdersMembers } from '@core';
import { ProfileBig } from '@svgIcons';
import { FC } from 'react';
import { View } from 'react-native';
import { Avatar, Text } from 'react-native-paper';
import { capitalizeFirstLetter } from 'src/core/helpers/capitalizeFirstLetter';
import { tw } from 'src/core/tw';

const IMAGE_SIZE = 30;

interface IMemberCardItemProps {
  data: ProductActiveGroupOrdersMembers;
}

const getFormattedName = (name = '', city?: string) => {
  const [firstname] = name.split(' ');

  if (firstname && city) {
    return capitalizeFirstLetter(firstname) + ', ' + capitalizeFirstLetter(city);
  }

  return capitalizeFirstLetter(firstname || city || '');
};

const MemberCardItem: FC<IMemberCardItemProps> = ({ data }) => {
  const formName = getFormattedName(data.name, data.city);

  return (
    <View testID="member-card-item" style={tw`flex-row items-center justify-between py-2 px-5`}>
      <View style={tw`flex-row items-center`}>
        <View style={tw`border${data.photoUrl ? '-2' : ''} rounded-full self-start mr-3`}>
          {data.photoUrl ? (
            <Avatar.Image accessibilityRole="image" source={{ uri: data.photoUrl }} size={IMAGE_SIZE} />
          ) : (
            <ProfileBig
              testID="default-profile-image"
              accessibilityRole="image"
              width={IMAGE_SIZE + 2}
              height={IMAGE_SIZE + 2}
              viewBox="0 0 82 82"
            />
          )}
        </View>

        <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]} testID="mci:creator_name">
          {formName}
        </Text>
      </View>
      {!!data.joinAt && (
        <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>{getRelativeTimeText(data.joinAt)}</Text>
      )}
    </View>
  );
};

export default MemberCardItem;
