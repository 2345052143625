import { HelpAndFeedbackScreen, UserInterestedScreen } from '@screens';
import FaqScreen from 'src/screens/FaqScreen/FaqScreen';
import OrderHistoryScreen from 'src/screens/OrderHistoryScreen/OrderHistoryScreen';
import OrderIsssuesScreen from 'src/screens/OrderIssuesScreen/OrderIssuesScreen';
import OrderIssuesThanksScreen from 'src/screens/OrderIssuesThanksScreen/OrderIssuesThanksScreen';
import PersonalInfoScreen from 'src/screens/PersonalInfoScreen/PersonalInfoScreen';
import TermsAndConditionScreen from 'src/screens/TermsAndConditionsScreen/TermsAndConditionsScreen';
import IScreen from '../IScreen';
import { SettingStackParamList } from './settingRoutes.model';

export const SettingRoutes: IScreen<SettingStackParamList>[] = [
  {
    name: 'PersonalInfoScreen',
    component: PersonalInfoScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'FaqScreen',
    component: FaqScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'OrderHistoryScreen',
    component: OrderHistoryScreen,
    options: {
      headerShown: false,
    },
  },

  {
    name: 'FavoriteCategoryScreen',
    component: UserInterestedScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'TermsAndConditionsScreen',
    component: TermsAndConditionScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'HelpAndFeedbackScreen',
    component: HelpAndFeedbackScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'OrderIssuesScreen',
    component: OrderIsssuesScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'OrderIssuesThanksScreen',
    component: OrderIssuesThanksScreen,
    options: {
      headerShown: false,
    },
  },
];

export default SettingRoutes;
