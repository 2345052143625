import { BackHeader, Button } from '@components';
import { i18n } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { View } from 'react-native-animatable';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import useOrder from 'src/domain/order/hooks/useOrder';
import GroupConfirmation from '../components/GroupConfirmation';
import Receipt from '../components/Receipt/Receipt';
import { PaymentScreenNavigationProps, PaymentSuccessScreenRouteProps } from '../PaymentRoutes';

const BackToHomeButton = ({ goBack }: { goBack: () => void }) => {
  const { bottom } = useSafeAreaInsets();

  return (
    <View style={[tw`bottom-0 w-full border-t-2`, { position: 'absolute' }]}>
      <View style={[tw`bg-white pt-4 px-6`, { paddingBottom: bottom || 16 }]}>
        <Button
          onPress={goBack}
          title={i18n.t('back_to_home')}
          customStyle={tw`rounded-full px-6 py-3 bg-black border-[1.5px] border-black `}
          customTextStyle={[tw`text-white	font-normal text-sm`, { fontFamily: 'TheFuture-Regular' }]}
        />
      </View>
    </View>
  );
};

export default function PaymentSuccessScreen() {
  const navigation = useNavigation<PaymentScreenNavigationProps>();
  const { params } = useRoute<PaymentSuccessScreenRouteProps>();

  const { order, loading } = useOrder({ orderId: params.orderId });

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      // Prevent navigating with deep link away from this screen, as payment is already successful
      if ((e.data.action.payload as { name: string } | undefined)?.name === 'PaymentScreen') {
        e.preventDefault();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  const goBack = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('Main', { screen: 'VideoScreen' });
  }, [navigation]);

  const renderContent = useCallback(() => {
    if (loading || !order) {
      return <ActivityIndicator />;
    }

    if (order.singleOrder) {
      return (
        <>
          <Receipt order={order} />
          <BackToHomeButton goBack={goBack} />
        </>
      );
    }

    return <GroupConfirmation order={order} />;
  }, [loading, order, goBack]);

  return (
    <>
      <BackHeader goBack={goBack} title={i18n.t('payment_successful_title')} />
      {renderContent()}
    </>
  );
}
