import { ApolloError, MutationHookOptions, useMutation } from '@apollo/client';
import {
  i18n,
  ICreateOrderRequest,
  ICreateOrderResponse,
  OrderType,
  useProductDetailsStore,
  UseProductDetailsStore,
} from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { shallow } from 'zustand/shallow';
import usePaymentSession from '../../../../domain/payment/hooks/usePaymentSession/usePaymentSession';
import { CREATE_ORDER } from '../../query';
import useModalState from '../useModalState/useModalState';

const PRODUCT_QUANTITY = 1;

interface ICreateOrderParams {
  productId: string;
  orderType: OrderType;
  banditCash: number;
  options?: MutationHookOptions;
}

const selector = ({ setCartId, setOrderPlacedId }: UseProductDetailsStore) => ({ setCartId, setOrderPlacedId });

interface IUseCreateOrderProps {
  currentProductId: string;
}

const useCreateOrder = ({ currentProductId }: IUseCreateOrderProps) => {
  const toast = useShowToast();
  const { setCartId, setOrderPlacedId } = useProductDetailsStore(selector, shallow);

  const { handleShowLoading, handleCloseLoading, showLoading } = useModalState();

  const { handleCreatePayment } = usePaymentSession();

  const [createOrder] = useMutation<ICreateOrderResponse, ICreateOrderRequest>(CREATE_ORDER);

  const showError = (error?: ApolloError) => {
    handleCloseLoading();
    toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
  };

  const handleCreateOrder = ({ productId, orderType, banditCash, options }: ICreateOrderParams) => {
    handleShowLoading();
    createOrder({
      ...options,
      variables: { productId, orderType, quantity: PRODUCT_QUANTITY, banditCash },
      onCompleted: ({ orderCreate }) => {
        if (!orderCreate?.cartId || !orderCreate?.id) {
          showError();
          return;
        }
        handleCreatePayment({ cartId: orderCreate.cartId, orderId: orderCreate.id });
        setCartId(orderCreate.cartId);
        setOrderPlacedId(orderCreate.id);
      },
      onError: (error: ApolloError) => showError(error),
    });
  };

  return { handleCreateOrder, isLoading: showLoading };
};

export default useCreateOrder;
