import { BackHeader } from '@components';
import { i18n } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { tw } from 'src/core/tw';
import useOrder from 'src/domain/order/hooks/useOrder';
import { PaymentFailureScreenRouteProps } from '../PaymentRoutes';

export default function PaymentFailureScreen() {
  const navigation = useNavigation<ProductDetailScreenNavigationProps>();
  const openContactLink = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('Main', { screen: 'ProfileScreen' }); // TODO: figure out best way to handle support
  };

  const { params } = useRoute<PaymentFailureScreenRouteProps>();
  const { order, loading } = useOrder({ orderId: params.orderId });
  const title = i18n.t('payment_not_successful_title');
  const description = i18n.t('payment_not_successful_description');

  return (
    <>
      <BackHeader
        title={title}
        goBack={() => {
          if (order?.productType?.id) {
            navigation.navigate('ProductDetailScreen', {
              id: order?.productType.id,
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigation.navigate('Main', { screen: 'VideoScreen' });
          }
        }}
      />

      {loading ? (
        <ActivityIndicator testID="activity-indicator" />
      ) : (
        <View style={tw`flex-1 justify-center items-center bg-white`}>
          <Text style={[tw`text-center mx-7`, { fontFamily: 'FeatureDeck-Regular', fontSize: 36 }]}>{title}</Text>
          <Text style={[tw`text-sm text-center mx-6`, { fontFamily: 'TheFuture-Regular' }]}>{description}</Text>

          <TouchableOpacity onPress={openContactLink} style={tw`mt-8 mx-auto bg-black rounded-full px-6 py-4`}>
            <Text style={[tw`text-sm text-white`, { fontFamily: 'TheFuture-Regular' }]}>
              {i18n.t('common_contact_support')}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}
