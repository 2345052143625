import { formatTimeLeft, getTimeDiff } from '@core';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const INTERVAL = 1000;

interface IUseCountdownProps {
  timeout: string;
  autoFormat?: boolean;
  formatSpacing?: 'wide' | 'normal';
  onTimeout?: () => void;
}

const useCountdown = ({ timeout, autoFormat = false, formatSpacing = 'wide', onTimeout }: IUseCountdownProps) => {
  const timerRef = useRef<NodeJS.Timeout>();

  const remainingTime = getTimeDiff(timeout);

  const [timeLeft, setTimeLeft] = useState(remainingTime);

  const calculateTimeLeft = () => {
    const currentTimeLeft = getTimeDiff(timeout);

    setTimeLeft(currentTimeLeft);

    if (currentTimeLeft === 0) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    if (remainingTime > 0) {
      timerRef.current = setInterval(calculateTimeLeft, INTERVAL);
    }

    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeout?.();
    }
  }, [onTimeout, timeLeft]);

  return {
    timeLeft: formatTimeLeft({ timeLeft, autoFormat, formatSpacing }),
    lessThanTenMins: dayjs.duration(timeLeft).asMinutes() < 10,
  };
};

export default useCountdown;
