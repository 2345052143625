import * as yup from 'yup';

const userSchema = yup.object({
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
  mobile: yup
    .string()
    .min(8, 'min 8 characters.')
    .max(12, 'max 12 characters.')
    .required('required')
    .matches(/^[0-9]+$/, 'Must be only digits'),
  age: yup.string().test('is-digits', 'must be digits', (value) => {
    if (!value) return true; // if value is empty, skip the test (optional
    return /^[0-9]+$/.test(value);
  }),
  email: yup.string().email('must be a valid email').required('required'),
  address: yup.string().required('required'),
  city: yup.string().required('required'),
  zipCode: yup
    .string()
    .test('is-4-digits', 'must be 4 digits', (value) => {
      if (!value) return true; // if value is empty, skip the test (optional
      return /^\d{4}$/.test(value);
    })
    .required('required'),
});

export default userSchema;
