import { ShareGroupWrapper } from '@components';
import { i18n } from '@core';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IGroupInviteActionProps {
  productId: string;
  groupId: string;
}

const GroupInviteAction = ({ productId, groupId }: IGroupInviteActionProps) => {
  return (
    <View style={tw`flex-row`}>
      <ShareGroupWrapper style={tw`border-2 bg-black rounded-full px-7 py-3`} productId={productId} groupId={groupId}>
        <Text style={[tw`text-sm text-white`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('product_detail_group_invite_others')}
        </Text>
      </ShareGroupWrapper>
    </View>
  );
};

export default GroupInviteAction;
