import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($request: UserCreateRequest!) {
    userCreate(request: $request) {
      lastName
      address
      age
      city
      email
      firstName
      mobile
      zipCode
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($request: UserUpdateRequest!) {
    userUpdate(request: $request) {
      lastName
      address
      age
      city
      email
      firstName
      mobile
      zipCode
      id
    }
  }
`;
