import { MutableRefObject, useEffect, useState } from 'react';
import { Platform } from 'react-native';

const useContainerDimensions = <T extends HTMLElement>(ref: MutableRefObject<T | null>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: ref?.current?.offsetWidth || 0,
      height: ref?.current?.offsetWidth || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    handleResize();

    if (Platform.OS === 'web') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ref]);

  return dimensions;
};

export default useContainerDimensions;
