import { i18n, useBanditTheme } from '@core';
import { CoinLogo } from '@svgIcons';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { IBCItem } from '../../model';
import Item from './Item/Item';

interface HistoryListProps {
  amount?: string;
  list?: IBCItem[];
}

const HistoryList = ({ amount, list }: HistoryListProps) => {
  const { colors } = useBanditTheme();
  return (
    <View style={tw`border-2 rounded-lg overflow-hidden mt-2`}>
      <View style={tw`rounded-t-lg`}>
        <Text
          style={[tw`p-4 text-black text-sm`, { backgroundColor: colors.secondary_cosmos_tint }]}
          variant="bodySmall"
        >
          {i18n.t('banditCash_your_bandit_balance')}
        </Text>
      </View>
      <View style={tw`flex-row border-t p-4 items-center`}>
        <Text variant="bodySmall">{amount}</Text>
        <CoinLogo style={tw`ml-2`} accessibilityRole="image" />
      </View>
      {list && list.length > 0 && (
        <View style={tw`border-t`}>
          <Text
            style={[tw`p-4 text-black text-xs`, { backgroundColor: colors.secondary_cosmos_tint }]}
            variant="bodySmall"
          >
            {i18n.t('banditCash_your_bandit_balance_history')}
          </Text>
        </View>
      )}
      {list &&
        list?.length > 0 &&
        list.map((item) => <Item date={item.expiringAt} credit={item.credit.toString()} key={item.id} />)}
    </View>
  );
};

export default HistoryList;
