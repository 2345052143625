import { getLocales } from 'expo-localization';
import { I18n } from 'i18n-js';
import { en } from './en';
import { no } from './no';

const translations = {
  en,
  no,
  nb: no,
  nn: no,
};

const i18n = new I18n(translations);

i18n.locale = getLocales()[0].languageCode;

i18n.enableFallback = true;

export default i18n;
