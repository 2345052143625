import { CustomAlert } from '@components';
import { useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CloseIcon } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { tw } from 'src/core/tw';

const ModalScreen = () => {
  const { colors } = useBanditTheme();

  const navigation = useNavigation();

  const msgObj = {
    title: 'Thanks for signing up!',
    subTitle: null,
    description:
      'The best way to buy new products is by group-buying with your friends to get the BEST deals on the market. ',
    image: '',
    btnType: 'btn' as 'btn' | 'logo',
    layoutColor: colors.secondary_fuschia,
    btnSuccessLabel: 'Go and explore',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    btnSuccessAction: () => navigation.navigate('Main', { screen: 'VideoScreen' }),
    btnFailLabel: '',
    btnFailAction: () => navigation.goBack(),
  };

  const onPressBack = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate('Main', { screen: 'VideoScreen' });
  };

  return (
    <View style={tw`flex-1 justify-end`}>
      <View style={tw`h-[10%] w-full bg-transparent justify-center `}>
        <TouchableOpacity onPress={onPressBack} testID="BUTTON.BACK">
          <CloseIcon style={tw`ml-4`} />
        </TouchableOpacity>
      </View>

      <CustomAlert {...msgObj} />
    </View>
  );
};

export default ModalScreen;
