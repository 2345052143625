import { TabHeader } from '@components';
import { View } from 'react-native';
import { tw } from 'src/core/tw';
import TabBarComponent from './components/DealsTabBar/TabBarComponent';

const DealScreen = () => {
  return (
    <View testID="deal-screen" style={tw`h-full w-full`}>
      <TabHeader showBorder={false} />
      <TabBarComponent />
    </View>
  );
};

export default DealScreen;
