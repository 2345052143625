import BanditTime from '@assets/images/time.png';
import { i18n, useBanditTheme } from '@core';
import { Image, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { usePurchaseState } from 'src/screens/ProductDetailScreen/hooks/usePurchaseState/usePurchaseState';
import { GroupInfoMessage } from './GroupInfoMessage';

interface IStartGroupMessageProps {
  soloPrice: number;
  startGroupPrice: number;
  groupPrice: number;
  displayCurrency: string;
}

const StartGroupMessage = ({ soloPrice, startGroupPrice, groupPrice, displayCurrency }: IStartGroupMessageProps) => {
  const { colors } = useBanditTheme();
  const { purchaseState } = usePurchaseState();

  const isSoloBuy = purchaseState === 'soloBuy';

  return (
    <View
      style={tw`mt-10 mx-4 p-2 pr-5 bg-[${colors.secondary_ember_tint}] border-2 rounded-xl flex-row items-center justify-between`}
    >
      <View style={tw`flex-row items-center flex-wrap flex-1`}>
        <Image style={tw`m-1 w-20 h-20`} resizeMode="contain" source={BanditTime} />
        {isSoloBuy ? (
          <View style={tw`w-full flex flex-1`}>
            <Text>{i18n.t('purchase_modal_solo_buy_missing_group_buy_discount')}</Text>
          </View>
        ) : (
          <GroupInfoMessage
            soloPrice={soloPrice}
            startGroupPrice={startGroupPrice}
            groupPrice={groupPrice}
            displayCurrency={displayCurrency}
          />
        )}
      </View>
    </View>
  );
};

export default StartGroupMessage;
