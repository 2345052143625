import { useQuery } from '@apollo/client';
import { Brands } from '../../model';
import { GET_BRANDS } from '../../query';

const useGetBrandsHook = () => {
  const { data, loading } = useQuery<Brands>(GET_BRANDS);

  return {
    brandsData: data?.brands,
    brandsLoading: loading,
  };
};

export default useGetBrandsHook;
