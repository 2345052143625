import { i18n } from '@core';
import { BottomSheetBackdrop, BottomSheetModal, useBottomSheetDynamicSnapPoints } from '@gorhom/bottom-sheet';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Platform, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { IconButton } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';

interface CustomBottomSheetModalProps {
  isVisible: boolean;
  closeModal: () => void;
  children: JSX.Element;
  snapPoints?: (string | number)[];
  backgroundColor?: string;
  title?: string;
}

// eslint-disable-next-line complexity
export const CustomBottomSheetModal: React.FC<CustomBottomSheetModalProps> = ({
  isVisible,
  closeModal,
  children,
  snapPoints,
  backgroundColor,
  title = '',
}) => {
  const isWeb = Platform.OS === 'web';

  if (isWeb) {
    return (
      <Modal
        isVisible={isVisible}
        style={tw`flex-1 flex-center justify-end m-0 bg-bandit_yellow`}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={200}
        useNativeDriver
        coverScreen={Platform.OS !== 'android'}
        onBackButtonPress={closeModal}
      >
        <View
          style={[
            tw`flex w-full items-center bg-white pt-4 border-2 rounded-t-3xl `,
            backgroundColor ? { backgroundColor: backgroundColor } : { backgroundColor: 'white' },
          ]}
        >
          {children}
        </View>
      </Modal>
    );
  }
  return (
    <BottomSheet
      closeModal={closeModal}
      backgroundColor={backgroundColor}
      title={title}
      snapPoints={snapPoints}
      isVisible={isVisible}
    >
      {children}
    </BottomSheet>
  );
};

const BottomSheet = ({
  children,
  closeModal,
  backgroundColor,
  title,
  snapPoints,
  isVisible,
}: {
  children: JSX.Element;
  closeModal: () => void;
  backgroundColor?: string;
  title: string;
  snapPoints?: (string | number)[];
  isVisible: boolean;
}) => {
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);
  const initialSnapPoints = useMemo(() => {
    return ['CONTENT_HEIGHT'];
  }, []);

  useEffect(() => {
    if (isVisible) {
      bottomSheetModalRef.current?.present();
    } else {
      bottomSheetModalRef.current?.dismiss();
      bottomSheetModalRef.current?.forceClose();
    }
  }, [isVisible]);

  const insets = useSafeAreaInsets();
  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints);

  const renderBackdrop = useCallback(
    (props: Parameters<typeof BottomSheetBackdrop>[0]) => (
      <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} opacity={0.7} />
    ),
    [],
  );
  const bgColor = backgroundColor ?? 'white';

  return (
    <BottomSheetModal
      backdropComponent={renderBackdrop}
      ref={bottomSheetModalRef}
      onDismiss={closeModal}
      snapPoints={snapPoints ?? animatedSnapPoints}
      bottomInset={insets.bottom}
      enableContentPanningGesture
      handleStyle={tw`hidden`}
      {...(snapPoints
        ? {}
        : {
            handleHeight: animatedHandleHeight,
            contentHeight: animatedContentHeight,
          })}
      backgroundStyle={{ backgroundColor: bgColor }}
    >
      <View
        onLayout={({ nativeEvent }) => {
          if (nativeEvent.layout.height > 120) {
            handleContentLayout({ nativeEvent });
          }
        }}
        style={tw`flex-1 h-full relative rounded-t-2xl pt-4 overflow-hidden`}
      >
        <View style={[tw`flex-row w-full px-4 pb-4 items-center justify-between`, { backgroundColor: bgColor }]}>
          <Text style={tw`font-thefuture_medium text-base w-6/7`}>{title}</Text>
          <View style={tw`w-1/7 items-end`}>
            <IconButton
              accessibilityLabelledBy={'close-button'}
              accessibilityLanguage={i18n.locale}
              testID="close-button"
              accessibilityRole="button"
              icon="close"
              style={tw`border-2 border-black m-0`}
              onPress={closeModal}
              size={18}
            />
          </View>
        </View>
        {children}
      </View>
    </BottomSheetModal>
  );
};
