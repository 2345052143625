/* eslint-disable complexity */
import { useMutation } from '@apollo/client';
import { authVar, useUserStore } from '@core';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { MessageModalScreenNavigationProps } from 'src/routes/ModalRoutes';
import useCategoriesHook from 'src/screens/DealScreen/hooks/useCategoriesHook/useCategoriesHook';
import { UPDATE_USER } from '../../model';
import { IUserUpdatePayload, IUserUpdateResponse } from '../../query';

interface CategoryObject {
  id: string;
  name: string;
  isSelected: boolean;
}

const useUserInterestedHook = () => {
  const navigation = useNavigation<MessageModalScreenNavigationProps>();

  const [categories, setCategories] = useState<CategoryObject[]>([]);

  const [userUpdate] = useMutation<IUserUpdateResponse, IUserUpdatePayload>(UPDATE_USER, { fetchPolicy: 'no-cache' });

  const { categoriesData, categoryLoading } = useCategoriesHook();

  const [loading, setLoading] = useState(false);

  const auth = authVar();

  const store = useUserStore();

  const { token } = auth;

  useEffect(() => {
    getCategoryList();
  }, [categoriesData]);

  const getCategoryList = () => {
    const interests = store.user.interests ?? [];

    if (categoriesData && categoriesData?.length > 0) {
      const cateData =
        categoriesData?.map((e) => {
          return {
            id: e.id,
            name: e.name,
            isSelected: interests.includes(e.name) ?? false,
          };
        }) ?? [];

      setCategories(cateData);
    }
  };

  const onSelectItem = (name: string) => {
    const newCategories = categories.map((item) => {
      if (item?.name === name) {
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }
      return item;
    });

    setCategories(newCategories);
  };

  const handleInterested = (isSkip: boolean, isProfile: boolean) => {
    setLoading(true);

    if (isSkip) {
      navigation.navigate('MessageModalScreen', { type: 'success' });
    } else if (isProfile) {
      userUpdate({
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        variables: {
          request: {
            interests: categories.filter((item) => item.isSelected).map((item) => item.name),
          },
        },
        onCompleted: (data: IUserUpdateResponse) => {
          if (data.userUpdate) {
            store.setUserInterest(data.userUpdate.interests ?? []);

            navigation.goBack();
          }
        },
        onError: () => {
          setLoading(false);
          return;
        },
      });
    } else {
      userUpdate({
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        variables: {
          request: {
            interests: categories.filter((item) => item.isSelected).map((item) => item.name),
          },
        },
        onCompleted: (data: IUserUpdateResponse) => {
          if (data.userUpdate) {
            setLoading(false);

            navigation.navigate('MessageModalScreen', { type: 'success' });
          }
        },
        onError: () => {
          setLoading(false);
          return;
        },
      });
    }
  };
  return {
    categories,
    categoryLoading,
    onSelectItem,
    handleInterested,
    loading,
  };
};

export default useUserInterestedHook;
