import AppleLogo from '@assets/icons/apple_logo.png';
import {
  appleAuthConfig,
  AppleDiscoveryDocument,
  googleAuthConfig,
  GoogleDiscoveryDocument,
  i18n,
  OauthType,
  vippsAuthConfig,
  VippsDiscoveryDocument,
} from '@core';
import { useOauth } from '@hooks';
import { useNavigation } from '@react-navigation/native';
import { CreateUserScreenNavigationProps } from '@routes';
import { BtnVipps, GoogleLogo } from '@svgIcons';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { tw } from 'src/core/tw';

export default function LoginOptions() {
  const navigation = useNavigation<CreateUserScreenNavigationProps>();

  const { login: loginGoogle } = useOauth({
    authConfig: googleAuthConfig,
    discoveryDocument: GoogleDiscoveryDocument,
    oauthType: OauthType.GOOGLE,
  });

  const { login: loginVipps } = useOauth({
    authConfig: vippsAuthConfig,
    discoveryDocument: VippsDiscoveryDocument,
    oauthType: OauthType.VIPPS,
  });

  const { login: loginApple } = useOauth({
    authConfig: appleAuthConfig,
    discoveryDocument: AppleDiscoveryDocument,
    oauthType: OauthType.APPLE,
  });

  const goToTermCondition = () => {
    navigation?.navigate('TermsScreen');
  };

  return (
    <View style={tw`flex-1 w-full p-2`}>
      <View style={tw`flex-row justify-center`}>
        <TouchableOpacity style={tw`items-center bg-orange-500 p-4 rounded-3xl mt-6 w-68`} onPress={loginVipps}>
          <BtnVipps />
        </TouchableOpacity>
      </View>
      <View style={tw`w-full flex-row mt-8 items-center mx-auto`}>
        <View style={tw`h-0.5 flex-auto bg-black`}></View>
        <Text style={tw`flex-1 flex-auto text-center`}>{i18n.t('login_line_continue')}</Text>
        <View style={tw`h-0.5 flex-auto bg-black`}></View>
      </View>
      <View style={tw`flex-row mx-auto justify-evenly mt-8 w-full`}>
        <TouchableOpacity
          style={tw`border border-gray-300 w-18 h-18 p-0 rounded-full justify-center items-center`}
          onPress={loginApple}
        >
          <Image source={AppleLogo} style={tw`w-10 h-10`} resizeMode="contain" />
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`border border-gray-300 rounded-full w-18 h-18 justify-center items-center`}
          onPress={loginGoogle}
        >
          <GoogleLogo />
        </TouchableOpacity>
      </View>

      <View style={tw`w-full mt-8 mx-auto items-center`}>
        <TouchableOpacity style={tw`pb-1 border-b border-gray-900`} onPress={goToTermCondition}>
          <Text>{i18n.t('login_btn_term')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
