import { i18n } from '@core';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { useAppStoreUrl } from 'src/hooks/useAppStoreUrl';
import Button from '../Button/Button';

type AppBoxProps = {
  osType: string;
};

const AppBox = ({ osType }: AppBoxProps) => {
  const url = useAppStoreUrl();
  const goToApp = async () => {
    await Linking.openURL(url);
  };

  return (
    <View style={tw`px-4 bg-red-500 w-full flex-row justify-between items-center py-4`}>
      <Text variant="bodyLarge" style={tw`text-center`}>
        {osType === 'Android' ? i18n.t('ads_box_title_android') : i18n.t('ads_box_title_ios')}
      </Text>

      <Button
        title={i18n.t('ads_box_btn_text')}
        customStyle={tw`rounded px-4`}
        onPress={goToApp}
        textVariant="labelLarge"
      />
    </View>
  );
};

export default AppBox;
