import { useLazyQuery } from '@apollo/client';
import { GROUP_POLL_INTERVAL } from '@core';
import { useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import { useShowToast } from 'src/hooks/useShowToast';
import { GroupProgressModalScreenRouteProps } from 'src/routes/ModalRoutes';
import { IActiveOrders } from '../../model';
import { GET_MEMBERS_BY_ACTIVE_ORDER_ID } from '../../query';

const useGroupMemberListHook = () => {
  const toast = useShowToast();
  const routes = useRoute<GroupProgressModalScreenRouteProps>();

  const [getMemberListByActiveOrderId, { data, loading }] = useLazyQuery<IActiveOrders>(
    GET_MEMBERS_BY_ACTIVE_ORDER_ID,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    if (routes) {
      const activeOrderId = routes.params?.activeOrderId;
      getMemberListByActiveOrderId({
        variables: { activeOrderId: activeOrderId },
        pollInterval: GROUP_POLL_INTERVAL,
        onError: (error) => {
          toast.danger(error.message);
        },
      });
    }
  }, [routes]);

  return { data: data?.activeGroupOrders[0], loading };
};

export default useGroupMemberListHook;
