import Svg, { Path, SvgProps } from 'react-native-svg';

export const GoogleLogo = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 15.341c0-1.063-.095-2.086-.273-3.068H15v5.802h8.073c-.348 1.875-1.405 3.464-2.994 4.527v3.764h4.848c2.837-2.611 4.473-6.457 4.473-11.025Z"
      fill="#4285F4"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 30c4.05 0 7.445-1.343 9.927-3.634l-4.848-3.764c-1.343.9-3.06 1.432-5.079 1.432-3.907 0-7.214-2.639-8.393-6.184H1.595v3.886C4.064 26.64 9.136 30 15 30Z"
      fill="#34A853"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.607 17.85c-.3-.9-.47-1.861-.47-2.85 0-.989.17-1.95.47-2.85V8.264H1.595A14.994 14.994 0 0 0 0 15c0 2.42.58 4.711 1.595 6.736l5.012-3.886Z"
      fill="#FBBC05"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5.966c2.202 0 4.18.757 5.734 2.243l4.302-4.302C22.44 1.487 19.043 0 15 0 9.136 0 4.064 3.361 1.595 8.264l5.012 3.886C7.787 8.605 11.093 5.966 15 5.966Z"
      fill="#EA4335"
    />
  </Svg>
);

export default GoogleLogo;
