import { i18n, ProductActiveGroupOrdersMembers } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { Right } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { tw } from 'src/core/tw';
import GroupMembersList from '../GroupMembersList/GroupMembersList';

interface GroupMembersCardProps {
  orderId?: string;
  members: ProductActiveGroupOrdersMembers[];
  numberOfMembers: number;
  groupSize: number;
  canViewGroupInfo?: boolean;
  onCloseGroupCardModal?: () => void;
  hasBorder?: boolean;
}

export const getRemindingMembersText = (membersNeeded: number) => {
  const tr = i18n.t('product_detail_group_card_modal_reminding_members', { count: membersNeeded });
  try {
    const parsedTr = JSON.parse(tr);
    if (membersNeeded === 1) {
      return parsedTr.one;
    }
    return parsedTr.other;
  } catch (error) {
    sendErrorToSentry(error, {
      tags: {
        screen: 'GroupMembersCard',
        function: 'getRemindingMembersText',
      },
    });
    if (error instanceof SyntaxError) {
      return tr;
    }
    return '';
  }
};

const GroupMembersCard = ({
  orderId,
  members,
  numberOfMembers,
  groupSize,
  canViewGroupInfo = true,
  onCloseGroupCardModal,
  hasBorder = true,
}: GroupMembersCardProps) => {
  const membersNeeded = groupSize - numberOfMembers;

  const navigation = useNavigation<ProductDetailScreenNavigationProps>();

  const handleViewGroupInfo = () => {
    onCloseGroupCardModal?.();
    navigation.navigate('GroupMemberListScreen', { activeOrderId: orderId || '' });
  };

  const reminderText = getRemindingMembersText(membersNeeded);

  return (
    <View testID="group-members-card" style={tw`w-full rounded-xl bg-white border-${hasBorder ? 2 : 0}`}>
      {canViewGroupInfo && (
        <>
          <TouchableOpacity
            style={tw`flex-row items-center justify-between py-2 px-5 border-b-2`}
            onPress={handleViewGroupInfo}
          >
            <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Medium' }]}>
              {i18n.t('product_detail_group_card_modal_your_group_members')}
            </Text>
            <Right style={tw`text-xl pt-1 w-4 h-4`} />
          </TouchableOpacity>
          {membersNeeded > 0 && (
            <View style={tw`bg-[#FF94FF] border-b-2 py-2 px-5`}>
              <Text style={[tw`text-xs text-center`, { fontFamily: 'TheFuture-Regular' }]}>{reminderText}</Text>
            </View>
          )}
        </>
      )}

      <GroupMembersList members={members} />
    </View>
  );
};

export default GroupMembersCard;
