import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';

const BASE_URL = Constants?.expoConfig?.extra?.WEBSITE_URL;

const APP_ENV = Constants?.expoConfig?.extra?.APP_ENV;

const SHORTEN_KEY = Constants?.expoConfig?.extra?.SHORTEN_KEY;

const isDev = APP_ENV === 'development';

export const config = {
  screens: {
    Main: {
      screens: {
        VideoScreen: 'home',
        GroupsScreen: 'groups',
        SearchScreen: 'search',
        DealScreen: 'deals',
        ProfileScreen: 'profile',
      },
    },
    CreateUserScreen: {
      path: 'newuser',
    },
    CreateProfileScreen: {
      path: 'newprofile',
    },
    UserInterestedScreen: {
      path: 'interested',
    },
    ProductDetailScreen: {
      path: 'product/:id',
    },
    InvitedModalScreen: {
      path: 'invite/:inviteId',
    },
    PaymentScreen: {
      path: 'payment',
    },
    PaymentSuccessScreen: {
      path: 'payment/success',
    },
    PaymentFailureScreen: {
      path: 'payment/failure',
    },
  },
};
export const generatePaymentScreenLink = ({ orderId, cartId }: { orderId: string; cartId: string }) => {
  return Linking.createURL('payment', {
    queryParams: {
      orderId,
      cartId,
    },
  });
};

export const generateRefLink = async (userId: string) => {
  return isDev
    ? Linking.createURL('', {
        queryParams: {
          ref: userId,
        },
      })
    : await generateShortenLink(`${BASE_URL}/?ref=${userId}`, 'recruit');
};

export const generateProductLink = async (productId: string) => {
  const userId = await AsyncStorage.getItem('userId');

  if (!userId) {
    return isDev
      ? Linking.createURL('product/' + productId)
      : await generateShortenLink(`${BASE_URL}/product/${productId}`, 'product');
  }
  return isDev
    ? Linking.createURL('product/' + productId, {
        queryParams: {
          ref: userId as string,
        },
      })
    : await generateShortenLink(`${BASE_URL}/${productId}?ref=${userId}`, 'product');
};

interface IGenerateInviteGroupLinkParams {
  productId: string;
  groupId: string;
  inviteUserId: string;
}

export const generateInviteGroupLink = async ({ productId, groupId, inviteUserId }: IGenerateInviteGroupLinkParams) => {
  return isDev
    ? Linking.createURL('invite/' + groupId, { queryParams: { productId, ref: inviteUserId } })
    : await generateShortenLink(`${BASE_URL}/invite/${groupId}?productId=${productId}&ref=${inviteUserId}`, 'group');
};

const generateShortenLink = async (url: string, folderName: string) => {
  const data = {
    domain: 'invite.thebandit.app',
    originalURL: url,
    tags: [folderName],
  };

  try {
    const response = await fetch('https://api.short.io/links/public', {
      method: 'post',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: SHORTEN_KEY,
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result.shortURL;
  } catch (error) {
    return null;
  }
};

export default config;
