export const fontConfig = {
  bodyLarge: {
    fontFamily: 'TheFuture-Medium',
  },
  headlineLarge: {
    fontFamily: 'FeatureDeck-Regular',
  },
  fontFamily: 'TheFuture-Regular',
};

export default fontConfig;
