import MuteIcon from '@assets/images/mute.png';
import UnmuteIcon from '@assets/images/unmute.png';
import { i18n } from '@core';
import { useCountdown } from '@hooks';
import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { useEffect, useMemo, useState } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { TourGuideZone, useTourGuideController } from 'rn-tourguide';
import { tw } from 'src/core/tw';
import { IVideo } from 'src/screens/VideoScreen/model';
import AnimatedFab from '../AnimatedFab/AnimatedFab';

interface Props {
  data: IVideo;
  isMuted: boolean;
  index: number;
  handleMuteChange: () => void;
  isPlaying: boolean;
}

const VideoActions = ({ data, isMuted, index, handleMuteChange, isPlaying }: Props) => {
  const activeOrders = data.activeGroupOrders && data.activeGroupOrders.length > 0;

  const navigation = useNavigation<ProductDetailScreenNavigationProps>();

  const buttonClick = () => {
    if (data.activeGroupOrders[0]?.id) {
      navigation.navigate('ProductDetailScreen', { id: data.id, inviteId: data.activeGroupOrders[0]?.id });
    } else {
      navigation.navigate('ProductDetailScreen', { id: data.id, startGroup: true });
    }
  };

  const { timeLeft } = useCountdown({
    timeout: data.activeGroupOrders[0]?.expiredDate ?? '',
    autoFormat: true,
    formatSpacing: 'normal',
  });

  const { tourKey } = useTourGuideController();
  const { style, groupText } = useMemo(() => {
    if (activeOrders) {
      return {
        style: tw`bg-bandit_pink`,
        // join group
        groupText: timeLeft,
      };
    }

    return {
      style: tw`bg-white`,
      // start group
      groupText: i18n.t('videoFeed_animated_button_title') as string,
    };
  }, [activeOrders, timeLeft]);

  const [isOpen, setIsOpen] = useState(activeOrders);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    if (!isPlaying) {
      setIsOpen(false);
      return;
    }

    const sequence = async () => {
      if (!isFirstTime) return;
      setIsFirstTime(false);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsOpen(true);
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setIsOpen(false);
    };
    sequence();
  }, [isPlaying, isFirstTime, setIsOpen]);

  return (
    <View style={tw`absolute bottom-3 right-3 flex-col items-end z-20`}>
      <TourGuideZone
        isTourGuide={index === 0}
        tourKey={tourKey}
        zone={4}
        maskOffset={40}
        tooltipBottomOffset={40}
        shape={'circle'}
      >
        <AnimatedFab onPress={buttonClick} isOpen={isOpen} style={style} groupText={groupText} />
      </TourGuideZone>
      {data.producer.logoUrl && (
        <View style={tw`mt-6 bg-white justify-center h-8 w-8 items-center rounded-full`}>
          <Image
            testID="producer-image"
            style={tw`h-7 w-7 bg-white rounded-full`}
            source={{
              uri: data.producer.logoUrl,
            }}
          />
        </View>
      )}
      <TouchableOpacity
        testID="mute-button"
        style={[tw`mt-6 justify-center h-8 w-8 items-center rounded-full bg-neutral-500`]}
        onPress={handleMuteChange}
      >
        <Image testID="mute-unmute-image" style={tw`h-8 w-8 rounded-full`} source={isMuted ? UnmuteIcon : MuteIcon} />
      </TouchableOpacity>
    </View>
  );
};

export default VideoActions;
