import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
const Up = (props: SvgProps) => (
  <Svg fill="none" {...props}>
    <Path
      fill="#1C1B1F"
      d="M6 .05c-.133 0-.258.021-.375.063A.872.872 0 0 0 5.3.325L.675 4.95a.891.891 0 0 0-.262.688.975.975 0 0 0 .287.687.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l3.9-3.9L9.925 6.35a.894.894 0 0 0 .688.263.978.978 0 0 0 .687-.288.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L6.7.325a.872.872 0 0 0-.325-.212A1.099 1.099 0 0 0 6 .05Z"
    />
  </Svg>
);
export default Up;
