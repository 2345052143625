import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { ProfileBig } from '@svgIcons';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface NotLoggedInProfileProps {
  loginUser: () => void;
}

const NotLoggedInProfile = ({ loginUser }: NotLoggedInProfileProps) => {
  const { colors } = useBanditTheme();
  return (
    <View style={tw`border-2 rounded-lg bg-white`}>
      <View style={tw`py-2 flex flex-row items-center px-2`}>
        <View style={tw`mx-1 rounded-full`}>
          <ProfileBig accessibilityRole="image" />
        </View>
        <View style={tw`mx-3`}>
          <Text style={[tw`text-lg`, { fontFamily: 'TheFuture-Medium' }]}>
            {i18n.t('profile_setting_not_loggedin_title')}
          </Text>
        </View>
      </View>

      <View
        style={[
          tw`py-3 px-4 border-t-2 mt-2 flex-row justify-between items-center rounded-bl-lg rounded-br-lg`,
          { backgroundColor: colors.secondary_fuschia_tint },
        ]}
      >
        <Text>{i18n.t('profile_setting_not_loggedin_description')}</Text>
        <Button
          title={i18n.t('profile_btn_login_text')}
          textVariant="labelLarge"
          customStyle={tw`px-3`}
          testID="BUTTON.LOGIN"
          onPress={loginUser}
        />
      </View>
    </View>
  );
};

export default NotLoggedInProfile;
