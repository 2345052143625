import { useLazyQuery } from '@apollo/client';
import { useUserStore } from '@core';
import { UseUserStore } from 'src/core/store/user/useUserStore';
import { shallow } from 'zustand/shallow';
import { GET_USER_BANDIT_CASH } from '../../query';

interface IGetUserBanditCashResponse {
  banditCash: {
    credit: number;
  }[];
}

interface IGetUserBanditCashParams {
  currentTime: string;
}

const userSelector = ({ setCoinAmount }: UseUserStore) => setCoinAmount;

const useUpdateBanditCash = () => {
  const [getUserBanditCash] = useLazyQuery<IGetUserBanditCashResponse, IGetUserBanditCashParams>(GET_USER_BANDIT_CASH, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ banditCash }) => {
      const bcAmount = banditCash.reduce((acc: number, curr: { credit: number }) => acc + +curr.credit, 0);
      setCoinAmount(bcAmount);
    },
  });

  const setCoinAmount = useUserStore(userSelector, shallow);

  const updateBanditCash = () => {
    getUserBanditCash({ variables: { currentTime: new Date().toISOString().replace('Z', '') } });
  };

  return { updateBanditCash };
};

export default useUpdateBanditCash;
