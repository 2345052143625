import { ApolloError, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { i18n, IGetActiveGroupsResponse } from '@core';
import { useEffect } from 'react';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_INVITE_DETAILS } from '../../query';

interface IUseGetInviteDetailsProps {
  id: string | null;
  options?: LazyQueryHookOptions<IGetActiveGroupsResponse, { id: string }>;
}

const useGetInviteDetails = ({ id, options }: IUseGetInviteDetailsProps) => {
  const toast = useShowToast();
  const [getGroupDetails, { data, loading, refetch }] = useLazyQuery<IGetActiveGroupsResponse, { id: string }>(
    GET_INVITE_DETAILS,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    if (!!id) {
      getGroupDetails({
        variables: { id },
        onError: (error: ApolloError) => toast.danger(error.message || i18n.t(ERROR_MESSAGES.default)),
        ...options,
      });
    }
  }, [id]);

  return {
    groupDetails: data?.activeGroupOrders?.[0],
    loading,
    refetch,
  };
};

export default useGetInviteDetails;
