import { getDateFormatSlash } from '@core';
import { CoinLogo } from '@svgIcons';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface ItemProps {
  credit: string;
  date: string;
}

const Item = ({ credit, date }: ItemProps) => {
  return (
    <View style={tw`border-t flex-row p-4 items-center justify-between`}>
      <View style={tw`items-center flex-row`}>
        <Text variant="bodySmall">{credit}</Text>
        <CoinLogo style={tw`ml-2`} accessibilityRole="image" />
      </View>
      <View>
        <Text variant="bodySmall">Expiring on {getDateFormatSlash(date)}</Text>
      </View>
    </View>
  );
};

export default Item;
