import {
  CustomProducts,
  getInitialOutOfStockOptions,
  getOutOfStockArray,
  IProductVariant,
  IProductVariantOption,
} from '@core';
import { useEffect, useState } from 'react';
import { FlatList, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { tw } from 'src/core/tw';
import { useVariants } from '../../hooks';
import VariantItem from '../VariantItem/VariantItem';

interface IProductVariantsProps {
  products: CustomProducts[];
  variants: IProductVariant[];
  animatedStyle?: ViewStyle;
  pressedAtLeastOnce?: boolean;
}

const ProductVariants = ({
  products,
  variants,
  animatedStyle = {},
  pressedAtLeastOnce = false,
}: IProductVariantsProps) => {
  const { selectedProductId, selectedVariants, handleSelectVariant } = useVariants(products, variants);

  const [outOfStockList, setOutOfStockList] = useState(getInitialOutOfStockOptions(products));

  const calculateOutOfStock = (newState: { [key: string]: string | null }) => {
    const outOfStockArray = getOutOfStockArray(products, newState);
    setOutOfStockList(outOfStockArray);
  };

  useEffect(() => {
    if (!!selectedVariants && !!Object.entries(selectedVariants).length) {
      calculateOutOfStock(selectedVariants);
    }
  }, [selectedVariants]);

  const renderItem = ({ item: { value, parentName }, index }: { item: IProductVariantOption; index: number }) => {
    const isColorCode = value.match(/#[a-fA-F0-9]{6}/g);
    const isSelected = selectedVariants?.[parentName] === value;
    const containerStyle = !isSelected ? animatedStyle : {};

    const handleSelect = () => {
      handleSelectVariant({ parentName, value }, calculateOutOfStock);
    };

    return (
      <Animated.View key={value} style={containerStyle}>
        <VariantItem
          bgColor={isColorCode ? value : '#fff'}
          content={isColorCode ? '' : value}
          isFirstItem={index === 0}
          isSelect={isSelected}
          onSelect={handleSelect}
          isAvailable={!outOfStockList.includes(value)}
        />
      </Animated.View>
    );
  };

  const renderVariantItem = ({ name, options }: IProductVariant, index: number) => {
    const variantSelected = selectedVariants?.[name];
    const displayExclamationMark = pressedAtLeastOnce && !variantSelected && !selectedProductId;

    return (
      <View key={name} style={tw.style({ 'mt-4': index !== 0 })}>
        <View style={tw`gap-x-1 flex flex-row items-center ml-4 mb-3`}>
          <Text style={[tw`text-sm font-medium`, { fontFamily: 'TheFuture-Medium' }]}>{name}</Text>
          {displayExclamationMark && (
            <Animated.Text
              entering={FadeIn}
              exiting={FadeOut}
              style={[tw`text-sm font-medium text-bandit_orange`, { fontFamily: 'TheFuture-Medium' }]}
            >
              !
            </Animated.Text>
          )}
        </View>
        <FlatList
          data={options}
          renderItem={renderItem}
          showsHorizontalScrollIndicator={false}
          alwaysBounceHorizontal={false}
          horizontal
        />
      </View>
    );
  };

  return <>{variants.map(renderVariantItem)}</>;
};

export default ProductVariants;
