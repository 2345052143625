import { PaymentRoutes } from '../domain/payment/PaymentRoutes';
import { AuthRoutes } from './AuthRoutes';
import IScreen from './IScreen';
import { MainRoutes } from './MainRoutes';
import { ProductDetailRoutes } from './ProductDetailRoutes';
import { SettingRoutes } from './settingRoutes';

export const mainScreens: IScreen[] = [
  {
    name: 'Main',
    component: MainRoutes,
    options: {
      headerShown: false,
    },
  },
  ...AuthRoutes,
  ...SettingRoutes,
  ...ProductDetailRoutes,
  ...PaymentRoutes,
];
