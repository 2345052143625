import { i18n, useBanditTheme } from '@core';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { InBetweenIcon } from '@svgIcons';
import { Text, View } from 'react-native';
import { tw } from 'src/core/tw';
import { Order } from 'src/domain/order/types';
import OrderInfo from './components/OrderInfo';
import OrderSubtotal from './components/OrderSubtotal';

export default function Receipt({ order }: { order: Order }) {
  const { colors } = useBanditTheme();

  return (
    <BottomSheetScrollView
      contentContainerStyle={tw`bg-white pb-16 overflow-hidden`}
      showsVerticalScrollIndicator={false}
    >
      <View onStartShouldSetResponder={() => true}>
        <View style={tw`bg-[${colors.primary}] px-4 py-6 flex-row border-b-2`}>
          <Text style={[tw`w-3/4`, { fontFamily: 'FeatureDeck-Regular', fontSize: 40 }]}>
            {i18n.t('order_placed_title')}
          </Text>
          <InBetweenIcon style={tw`absolute z-2 top-6 right-5`} width={96} height={120} viewBox="0 0 188 235" />
        </View>

        <View style={tw`p-4`}>
          <Text style={[tw`text-sm bg-white `, { fontFamily: 'TheFuture-Regular' }]}>
            {i18n.t('order_placed_description')}
          </Text>

          <View style={[tw`bg-white`]}>
            <OrderInfo order={order} />
            <OrderSubtotal order={order} />
          </View>
        </View>
      </View>
    </BottomSheetScrollView>
  );
}
