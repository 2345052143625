import { useLazyQuery } from '@apollo/client';
import { useUserStore } from '@core';
import { useEffect, useState } from 'react';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { IBCHistory } from '../model';
import { GET_BC_HISTORY } from '../query';

const useBCHook = () => {
  const [getBCHistory, { loading }] = useLazyQuery<IBCHistory>(GET_BC_HISTORY);
  const [BCList, setBCList] = useState<IBCHistory['banditCash']>([]);
  const [coinAmount, setCoinAmount] = useState<number>(0);
  const userStore = useUserStore();

  useEffect(() => {
    if (!userStore.user?.id) {
      setCoinAmount(0);
      return;
    }

    getBCHistory({
      variables: {
        date: new Date().toISOString(),
      },
      onCompleted: (data) => {
        setBCList(data.banditCash);

        const coins = data?.banditCash.length
          ? data?.banditCash.reduce((acc, item) => {
              return acc + item.credit;
            }, 0)
          : 0;
        setCoinAmount(coins);
      },
      onError: (error) => {
        sendErrorToSentry(error.message, {
          tags: {
            Screen: 'Banditcash',
          },
        });
      },
    });
  }, [userStore.user]);

  return { data: BCList, loading, coinAmount };
};

export default useBCHook;
