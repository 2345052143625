/* eslint-disable complexity */
import { i18n, randomizeColor, useBanditTheme } from '@core';
import { useCountdown } from '@hooks';
import { ClockIcon, UserIcon } from '@svgIcons';
import { useMemo } from 'react';
import { Text } from 'react-native';
import Animated, { FadeInRight, FadeOutLeft } from 'react-native-reanimated';
import { tw } from 'src/core/tw';
import { IProduct } from '../model';

interface ShowMemberProps {
  product: IProduct;
  withExitingAnimation: boolean;
}

const ShowMember = ({ product, withExitingAnimation }: ShowMemberProps) => {
  const randomColor = useMemo(() => {
    return randomizeColor();
  }, []);

  const activeGroup = product.activeGroupOrders.length > 0 ? product.activeGroupOrders[0] : null;

  const numberOfMembers = !!activeGroup ? activeGroup.numberOfMembers : 0;

  const activeGroupLength = product.activeGroupOrders.length;

  const groupSize = product.groupSize;

  const successOrder = product.successOrders;

  const expireDate = !!activeGroup ? activeGroup.expiredDate : '';

  const { timeLeft } = useCountdown({ timeout: expireDate, autoFormat: true });

  const time = timeLeft == '00 : 00' ? '' : timeLeft;

  const { colors } = useBanditTheme();

  const exitingAnimation = withExitingAnimation ? FadeOutLeft : undefined;

  if (activeGroupLength < 0 && successOrder) {
    return (
      <Animated.View
        entering={FadeInRight}
        exiting={exitingAnimation}
        testID="more-member"
        style={[tw`p-2 h-8  border-b-2 items-start justify-center`, { backgroundColor: randomColor }]}
      >
        <Text style={[tw`text-black text-xs	`, { fontFamily: 'TheFuture-Regular' }]}>
          {successOrder} {i18n.t('group_have_title')}
        </Text>
        <ClockIcon color={colors.backdrop} accessibilityRole="image" size={14} />
      </Animated.View>
    );
  } else if (activeGroupLength > 0 && time) {
    return (
      <Animated.View
        entering={FadeInRight}
        exiting={exitingAnimation}
        testID="more-member2"
        style={[tw`flex-row px-2 h-8 border-b-2 justify-start items-center bg-bandit_pink`]}
      >
        <UserIcon size={14} style={tw`mr-1`} color={colors.backdrop} accessibilityRole="image" />
        <Text style={[tw`text-black pr-2 text-xs`, { fontFamily: 'TheFuture-Regular' }]}>
          {numberOfMembers}
          {'/'}
          {groupSize}
        </Text>
        <ClockIcon size={14} style={tw`mr-1`} color={colors.backdrop} accessibilityRole="image" />
        <Text style={[tw`text-black text-xs`, { fontFamily: 'TheFuture-Regular' }]}>{time}</Text>
      </Animated.View>
    );
  } else {
    return (
      <Animated.View
        entering={FadeInRight}
        exiting={exitingAnimation}
        testID="more-member3"
        style={[tw`p-2 h-8  border-b-2 items-start justify-center`, { backgroundColor: randomColor }]}
      >
        <Text style={[tw`text-black text-xs	`, { fontFamily: 'TheFuture-Regular' }]}>
          {i18n.t('deals_member_new_title')}
        </Text>
      </Animated.View>
    );
  }
};

export default ShowMember;
