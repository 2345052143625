import { Button } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { HomeScreenNavigationProp } from '@routes';
import { tw } from 'src/core/tw';

const OrdersNotFoundMessage = () => {
  const navigation = useNavigation<HomeScreenNavigationProp>();

  const handleGoBackHome = () => {
    navigation.navigate('ProfileScreen');
  };

  return (
    <Button
      customStyle={tw`mx-auto py-3.5 px-6`}
      customTextStyle={[tw`text-sm font-normal`, { fontFamily: 'TheFuture-Regular' }]}
      title={i18n.t('profile_helpAndFeedback_orderIssues_buttonText_notFoundMessage')}
      onPress={handleGoBackHome}
    />
  );
};

export default OrdersNotFoundMessage;
