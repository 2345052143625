import { Platform } from 'react-native';

enum Device {
  iOS = 'ios',
  Android = 'android',
}

const majorVersionIOS = parseInt(String(Platform.Version), 10);

export const isAutoFillSupported = Platform.OS === 'ios' && majorVersionIOS >= 12;

export const behavior = Platform.OS === Device.iOS ? 'padding' : 'height';
