import { Loading } from '@components';
import { i18n, OrderStatus } from '@core';
import { useRoute } from '@react-navigation/native';
import { DealScreenRouteProp } from '@routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';
import { NavigationState, Route, SceneRendererProps, TabBar, TabView } from 'react-native-tab-view';
import { tw } from 'src/core/tw';
import useCategoriesHook from '../../hooks/useCategoriesHook/useCategoriesHook';
import useDealsHooks from '../../hooks/useDealsHook/useDealsHook';
import { IDeals } from '../../model';
import GridScreen from '../GridScreen/GridScreen';

type TabRoute = Route & { key: string; title: string };

type State = NavigationState<{
  key: string;
  title: string;
}>;

const Scene = (sceneProps: SceneRendererProps & { route: TabRoute }) => {
  const { feedData, isLoading, pullToRefresh } = useDealsHooks({});

  const dontMissProducts = useMemo(() => {
    const getOpenOrders = (orders: IDeals) => {
      return orders.activeGroupOrders.filter((order) => order?.status === OrderStatus.Open && order.id !== '');
    };

    return (
      feedData?.filter((e) => {
        return e.activeOrders && e.activeGroupOrders && getOpenOrders(e);
      }) ?? []
    );
  }, [feedData]);

  const products = useMemo(() => {
    if (sceneProps.route.title === i18n.t('dont_miss')) return dontMissProducts;

    return feedData?.filter(({ categoryName }) => categoryName == sceneProps.route.title) ?? [];
  }, [feedData, sceneProps.route.title, dontMissProducts]);

  if (isLoading) {
    return <Loading />;
  }
  return <GridScreen products={products} onPullToRefresh={pullToRefresh} />;
};

const renderTabBar = (props: SceneRendererProps & { navigationState: State }) => (
  <TabBar
    {...props}
    scrollEnabled
    indicatorStyle={tw`bg-black h-[1.5px]`}
    style={tw`bg-white border-b border-black m-0 p-0`}
    bounces
    tabStyle={tw`w-auto`}
    labelStyle={[tw`text-black w-full normal-case p-0 m-0`, { fontFamily: 'TheFuture-Regular', fontSize: 16 }]}
  />
);

const TabBarComponent = () => {
  const { categoriesData } = useCategoriesHook();
  const [index, setIndex] = useState(0);
  const categoryRoute = useRoute<DealScreenRouteProp>();
  const categoryId = categoryRoute.params?.categoryId;

  const allRoutes = useMemo(() => {
    return [
      { key: 'static-tab', title: i18n.t('dont_miss') },
      ...(categoriesData?.map((e) => ({
        key: e.id,
        title: e.name,
      })) ?? []),
    ];
  }, [categoriesData]);

  useEffect(() => {
    const getCatIndex = allRoutes?.findIndex((category) => category.key === categoryId);

    if (getCatIndex !== undefined && getCatIndex !== -1) {
      setIndex(getCatIndex);
    }
  }, [categoryId, categoriesData, categoryRoute.params, allRoutes]);

  const renderScene = useCallback((sceneProps: SceneRendererProps & { route: TabRoute }) => {
    return <Scene {...sceneProps} />;
  }, []);

  if (allRoutes.length === 0) return <Loading />;

  return (
    <TabView
      initialLayout={{
        height: 0,
        width: Dimensions.get('window').width,
      }}
      navigationState={{ index, routes: allRoutes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      lazy
      lazyPreloadDistance={2}
      swipeEnabled
      renderLazyPlaceholder={() => <Loading />}
      renderTabBar={renderTabBar}
    />
  );
};

export default TabBarComponent;
