import { useState } from 'react';
import { OrderInquiryFieldsProps } from '../../model';
import useCreateInquiry from '../useCreateInquiry/useCreateInquiry';

const useOrderInqueryFields = ({ orderId }: OrderInquiryFieldsProps) => {
  const [feedbackText, setFeedbackText] = useState('');

  const { handleCreateInquiry, loadingCreateOrder } = useCreateInquiry();

  const isDisabledButton = !feedbackText || !orderId || loadingCreateOrder;

  const handleInputChange = (issue: string) => {
    setFeedbackText(issue);
  };

  const handleSubmitIssue = () => {
    handleCreateInquiry({ feedback: feedbackText, orderId });
  };

  return { handleInputChange, loadingCreateOrder, handleSubmitIssue, isDisabledButton, feedbackText };
};

export default useOrderInqueryFields;
