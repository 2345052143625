import { BackHeader, KeyboardAvoidView } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { Right } from '@svgIcons';
import { Linking, SafeAreaView, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { List } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { SettingNaviation } from 'src/routes/settingRoutes';

const HelpAndFeedbackScreen = () => {
  const { colors } = useBanditTheme();

  const navigation = useNavigation<SettingNaviation>();

  const menu = [
    {
      title: i18n.t('profile_helpAndFeedback_menu_issueWithOrder'),
      onPress: () => navigation.navigate('OrderIssuesScreen'),
    },
    {
      title: i18n.t('profile_helpAndFeedback_menu_contactBandit'),
      onPress: () => Linking.openURL('https://forms.clickup.com/37463619/f/13q9j3-1741/VEU0EU9E2MK97RWW98'),
    },
  ];

  const renderItem = (item: { title: string; onPress?: () => void }) => (
    <List.Item
      accessibilityLabelledBy={item.title}
      accessibilityLanguage={i18n.locale}
      key={item.title}
      style={tw`border-b border-gray-300 py-3`}
      title={item.title}
      titleStyle={{ fontFamily: 'TheFuture-Regular', fontSize: 14, color: 'black' }}
      right={(props) => <Right style={tw`w-4 h-4 mt-1`} />}
      onPress={item.onPress}
    />
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, flex: 1 }} style={tw`bg-white`}>
      <KeyboardAvoidView>
        <SafeAreaView />
        <BackHeader
          goBack={navigation.goBack}
          title={i18n.t('profile_helpAndFeedback_screenTitle')}
          titleStyle={[tw`text-black`, { fontFamily: 'TheFuture-Regular' }]}
        />
        <View style={[tw`p-4 flex-1`, { backgroundColor: colors.bg_primary }]}>
          <View style={tw`border-2 rounded-lg bg-white`}>{menu.map(renderItem)}</View>
        </View>
      </KeyboardAvoidView>
    </ScrollView>
  );
};

export default HelpAndFeedbackScreen;
