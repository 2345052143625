import { BackHeader } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

const TermsAndConditionScreen = () => {
  const navigation = useNavigation();
  return (
    <View style={tw`bg-white flex-1`}>
      <BackHeader
        goBack={navigation.goBack}
        title={i18n.t('profile_terms_and_conditions_title')}
        titleStyle={[tw`text-black`, { fontFamily: 'TheFuture-Regular' }]}
        containerStyle={tw`border-b`}
      />
      <ScrollView style={tw`flex-1`}>
        <Text
          variant="bodyLarge"
          style={[tw`p-4 text-sm text-black font-normal tracking-normal`, { fontFamily: 'TheFuture-Regular' }]}
        >
          {i18n.t('profile_terms_and_conditions_text')}
        </Text>
      </ScrollView>
    </View>
  );
};
export default TermsAndConditionScreen;
