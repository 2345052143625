import { useLazyQuery } from '@apollo/client';
import {
  IGetProductDetailsPayload,
  IGetRemainingInventoryResponse,
  UseProductDetailsStore,
  useProductDetailsStore,
} from '@core';
import { shallow } from 'zustand/shallow';
import { GET_REMAINING_INVENTORY } from '../../query';

interface IUseCheckInventoryProps {
  productId: string;
}

const selector = ({ canPurchase, setCanPurchase }: UseProductDetailsStore) => ({
  canPurchase,
  setCanPurchase,
});

const useCheckInventory = ({ productId }: IUseCheckInventoryProps) => {
  const { canPurchase, setCanPurchase } = useProductDetailsStore(selector, shallow);

  const [checkInventory] = useLazyQuery<IGetRemainingInventoryResponse, IGetProductDetailsPayload>(
    GET_REMAINING_INVENTORY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { productId },
      onCompleted: ({ productTypes }) => {
        if (!!productTypes?.[0]) {
          const remaining = productTypes[0].remainingInventory ?? 0;
          const groupSize = productTypes[0].groupSize ?? 0;

          setCanPurchase({ solo: remaining > 0, group: remaining >= groupSize });
        }
      },
    },
  );

  return { canPurchase, checkInventory };
};

export default useCheckInventory;
