import { ApolloError, useMutation } from '@apollo/client';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { SettingNaviation } from 'src/routes/settingRoutes';
import { CreateInquiryProps } from '../../model';
import { CREATE_INQUIRY } from '../../query';

const useCreateInquiry = () => {
  const navigation = useNavigation<SettingNaviation>();
  const toast = useShowToast();

  const [createInquiry, { loading }] = useMutation(CREATE_INQUIRY);

  const showError = (error?: ApolloError) => {
    toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
  };

  const handleCreateInquiry = ({ feedback, orderId, options }: CreateInquiryProps) => {
    createInquiry({
      ...options,
      variables: { feedback, orderId },
      onCompleted: () => {
        navigation.navigate('OrderIssuesThanksScreen');
      },
      onError: (error: ApolloError) => showError(error),
    });
  };

  return { handleCreateInquiry, loadingCreateOrder: loading };
};

export default useCreateInquiry;
