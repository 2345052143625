import { PAYMENT_URL } from '@core';
import { useRef } from 'react';
import { ActivityIndicator, Linking, Platform } from 'react-native';
import WebView from 'react-native-webview';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { tw } from 'src/core/tw';

export default function PaymentWebView({ sessionId, sessionData }: { sessionId: string; sessionData: string }) {
  const ref = useRef<WebView>(null);

  return (
    <WebView
      ref={ref}
      testID="payment-web-view"
      style={tw`flex-1`}
      source={{ uri: `${PAYMENT_URL}/?sessionId=${sessionId}&sessionData=${sessionData}` }}
      renderLoading={() => <ActivityIndicator size={50} style={tw`absolute top-0 left-0 right-0 bottom-0`} />}
      setSupportMultipleWindows={false} // https://github.com/react-native-webview/react-native-webview/issues/1869#issuecomment-822368928
      onShouldStartLoadWithRequest={({ url }) => {
        // This is a universal link from vipps. It works with WebView on some devices, but others need explicit Linking openURL
        if (url.includes('vipps.no/dwo-api-application/v1/deeplink')) {
          try {
            const appUrl = url
              .replace('https://apitest.vipps.no', 'vippsMT://')
              .replace('https://api.vipps.no', 'vipps://');

            if (Platform.OS === 'ios') {
              Linking.openURL(url);
            } else {
              Linking.openURL(appUrl).catch(() => Linking.openURL(url));
            }
          } catch (e) {
            sendErrorToSentry(e, {
              tags: {
                screen: 'PaymentWebView',
                function: 'onShouldStartLoadWithRequest',
              },
            });
            return true;
          }
          return false;
        }
        return url.startsWith('http://') || url.startsWith('https://');
      }}
      onContentProcessDidTerminate={() => ref.current?.reload()}
    />
  );
}
