import { createContext, useContext, useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import EndOfFeedFeedbackToast from '../../components/EndOfFeedFeedbackToast/EndOfFeedFeedbackToast';
import {
  BrandItem,
  EndOfFeedAddBrandsListState,
  EndOfFeedScreenContextValues,
  EndOfFeedScreenProviderProps,
} from '../../model';

const EndOfFeedScreen = createContext<EndOfFeedScreenContextValues>(undefined!);

const intialCustomBrand = {
  id: 1,
  value: '',
  hasError: false,
};

export const EndOfFeedScreenProvider = ({ children, values }: EndOfFeedScreenProviderProps) => {
  const [selectedBrands, setSelectedBrands] = useState<BrandItem[] | []>([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [customBrands, setCustomBrands] = useState<EndOfFeedAddBrandsListState[]>(() => [{ ...intialCustomBrand }]);
  const toast = useToast();

  const openModal = () => {
    setIsVisibleModal(true);
    closeFeedbackToast();
  };

  const closeModal = () => {
    setIsVisibleModal(false);
    setCustomBrands([{ ...intialCustomBrand }]);
  };

  const openFeedbackToast = () => {
    toast.show(<EndOfFeedFeedbackToast />, {
      duration: 3000,
      style: {
        backgroundColor: 'transparent',
        maxWidth: '100%',
        width: '100%',
        padding: 0,
        margin: 0,
        overflow: 'hidden',
      },
    });
  };
  const closeFeedbackToast = () => {
    toast.hideAll();
  };

  return (
    <EndOfFeedScreen.Provider
      value={{
        ...values,
        selectedBrands,
        setSelectedBrands,
        openModal,
        closeModal,
        customBrands,
        setCustomBrands,
        intialCustomBrand,
        isVisibleModal,
        openFeedbackToast,
        closeFeedbackToast,
      }}
    >
      {children}
    </EndOfFeedScreen.Provider>
  );
};

export const useEndOfFeedScreenContext = () => useContext(EndOfFeedScreen);
