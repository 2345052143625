/* eslint-disable max-params */
/* eslint-disable complexity */
import { ProductCard } from '@components';
import { BrandObject } from '@core';
import { FC, useMemo } from 'react';
import { View } from 'react-native';
import { tw } from 'src/core/tw';
import { ICategory, IProducer, ITag } from '../../model';
import { SearchResultFooter } from '../SearchResultFooter/SearchResultFooter';
import { SearchResultHeader } from '../SearchResultHeader/SearchResultHeader';

type SearchResultContainerProps = {
  products: BrandObject[];
  categories: ICategory[];
  tags: ITag[];
  brands: IProducer[];
  keyWord: string;
  clickItem: (type: string, name: any) => void;
};

const SearchResultContainer: FC<SearchResultContainerProps> = ({
  products,
  brands,
  categories,
  tags,
  keyWord,
  clickItem,
}) => {
  const data = useMemo(() => {
    const existProducts = products.length > 0;
    const productsData = products.reduce<{ left: BrandObject[]; right: BrandObject[] }>(
      (acc, product, index) => {
        const productBelongsToLeftColumn = index % 2 === 0;

        if (productBelongsToLeftColumn) {
          acc.left = [...acc.left, product];

          return acc;
        }

        acc.right = [...acc.right, product];

        return acc;
      },
      { left: [], right: [] },
    );

    return { productsData, existProducts };
  }, [products]);

  const renderItem = (item: BrandObject) => {
    const brand = item.brandName;

    return <ProductCard product={item} key={item.id} brand={brand} withExitingAnimation={false} />;
  };

  return (
    <View style={tw`mt-7.5 mb-8 flex-1`}>
      {data.existProducts && (
        <>
          <SearchResultHeader />
          <View style={tw`flex flex-row`}>
            <View style={tw`w-6/12`}>{data.productsData.left.map(renderItem)}</View>
            <View style={tw`w-6/12`}>{data.productsData.right.map(renderItem)}</View>
          </View>
        </>
      )}
      <SearchResultFooter brands={brands} categories={categories} tags={tags} clickItem={clickItem} />
    </View>
  );
};

export default SearchResultContainer;
