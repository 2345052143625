import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { Audio, Video } from 'expo-av';
import { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { MediaRef } from 'src/screens/VideoScreen/model';

interface Props {
  parentRef: ForwardedRef<MediaRef>;
  isMuted: boolean;
}

const useVideoWithDetails = ({ isMuted, parentRef }: Props) => {
  const ref = useRef<Video>(null);
  const navigation = useNavigation<ProductDetailScreenNavigationProps>();
  const [isPlaying, setIsPlaying] = useState(false);
  useImperativeHandle(parentRef, () => ({
    play,
    unload,
    stop,
  }));

  useEffect(() => {
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
    if (ref.current !== null) {
      ref.current._onPlaybackStatusUpdate = (status) => {
        setIsPlaying(status.isLoaded && status.isPlaying);
      };
    }

    return () => {
      unload();
    };
  }, []);

  const play = async () => {
    if (ref.current == null) {
      return;
    }

    try {
      await ref.current.playAsync();
    } catch (e) {
      sendErrorToSentry(e, {
        tags: {
          error_type: 'Video playback error',
          action: 'play',
        },
      });
    }
  };

  const stop = async () => {
    if (ref.current == null) {
      return;
    }

    try {
      await ref.current.stopAsync();
    } catch (e) {
      sendErrorToSentry(e, {
        tags: {
          error_type: 'Video playback error',
          action: 'stop',
        },
      });
    }
  };

  const unload = async () => {
    if (ref.current == null) {
      return;
    }

    try {
      await ref.current.unloadAsync();
    } catch (e) {
      sendErrorToSentry(e, {
        tags: {
          error_type: 'Video playback error',
          action: 'unload',
        },
      });
    }
  };

  const handleViewProduct = (id: string) => navigation.navigate('ProductDetailScreen', { id });

  return { ref, handleViewProduct, isPlaying };
};

export default useVideoWithDetails;
