import * as Sentry from 'sentry-expo';
import { isWeb } from '../helpers/platformHelper';

export type CaptureContext = {
  user?: {
    id?: string;
    email?: string;
  };
  tags?: {
    [key: string]: string;
  };
};

export const sendErrorToSentry = (error: any, context?: CaptureContext) => {
  if (isWeb) {
    Sentry.Browser.captureException(new Error(error), context);
  } else {
    Sentry.Native.captureException(new Error(error), context);
  }
};
