import { ExpandableDescription, UnderlineText } from '@components';
import { DESCRIPTION_LIMIT, ProductActiveGroupOrders, ProductProducers } from '@core';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import ProductDetailPrices from 'src/domain/prices/components/ProductDetailPrices';

interface IProductInfoProps {
  name: string;
  brandName: string | null;
  producer: ProductProducers;
  soloPrice: number;
  groupPrice: number;
  currency: string;
  description: string;
  banditCashDiscount?: boolean;
  activeGroupOrders?: ProductActiveGroupOrders[];
}

const ProductInfo = ({
  name,
  brandName,
  producer,
  soloPrice,
  groupPrice,
  currency,
  description,
  banditCashDiscount = false,
  activeGroupOrders,
}: IProductInfoProps) => {
  return (
    <>
      <Text style={[{ fontFamily: 'FeatureDeck-Regular', fontSize: 40 }]}>{name}</Text>
      <UnderlineText style={tw`my-2`}>
        <Text style={[tw`text-sm font-medium`, { fontFamily: 'TheFuture-Medium' }]}>
          {brandName ?? producer.organizationName}
        </Text>
      </UnderlineText>
      <ProductDetailPrices
        groupPrice={groupPrice}
        currency={currency}
        soloPrice={soloPrice}
        hasActiveGroupOrders={(activeGroupOrders?.length ?? 0) > 0}
        banditCashDiscount={banditCashDiscount}
        fontSizes={{ heading: 'text-4xl', subHeading: 'text-lg' }}
        fontColor="black"
      />

      <ExpandableDescription description={description} charactersLimit={DESCRIPTION_LIMIT} style={tw`mt-4`} />
    </>
  );
};

export default ProductInfo;
