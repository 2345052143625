/* eslint-disable complexity */
import { BANDITCASH_TYPE, getDisplayCurrency, i18n } from '@core';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { Order } from 'src/domain/order/types';
import OrderInfoItem from './OrderInfoItem';

interface IOrderSubTotalProps {
  order: Order;
}

const FLAT_SHIPPING_FEE = 29;

const formatCurr = (num: number, currency: string) => {
  if (num > 0) {
    const displayCurrency = getDisplayCurrency(currency);
    return `${num.toFixed(2)}${displayCurrency}`;
  }
  return '-';
};

const OrderSubtotal = ({ order }: IOrderSubTotalProps) => {
  const price = order.cart.items[0].totalCost;

  const discount = order.cart.payment?.groupLeaderDiscount ?? order.cart.payment?.discount ?? 0;

  const vatAmount = price - price / (1 + order.productType.vat / 100);
  const currency = order.cart.items[0].currency;

  const banditCash =
    order.cart.payment?.paymentTransactions?.find((payment) => payment?.type === BANDITCASH_TYPE)?.amount ?? 0;

  const totalPayment =
    order.cart.payment?.paymentTransactions?.filter((payment) => payment?.type !== BANDITCASH_TYPE)?.[0]?.amount ?? 0;

  const paymentInfoItems = [
    { label: 'order_placed_subtotal', value: formatCurr(price, currency) },
    {
      label: 'order_placed_discount',
      value: formatCurr(discount, currency),
    },
    { label: 'common:shipping_fee', value: formatCurr(FLAT_SHIPPING_FEE, currency) },
    { label: 'order_placed_vat', value: formatCurr(vatAmount, currency) },
    { label: 'order_placed_bandit_cash', value: formatCurr(banditCash, currency) },
  ];

  const soloBuyInfoItems = paymentInfoItems.filter((item) => item.label !== 'order_placed_discount');

  return (
    <>
      <View style={tw`mt-6`}>
        <View>
          <Text style={[tw`text-sm text-[#6E6E6E]`, { fontFamily: 'TheFuture-Regular' }]}>
            {i18n.t('order_placed_order_info')}
          </Text>
          {(order.singleOrder ? soloBuyInfoItems : paymentInfoItems).map(({ label, value }) => {
            if (value !== '-') {
              return <OrderInfoItem key={label} label={i18n.t(label, { vat: order.productType.vat })} value={value} />;
            }
          })}
        </View>
      </View>
      <View style={tw`mt-7 border-t-2 border-b-2 py-3 mb-3`}>
        <OrderInfoItem label={i18n.t('order_placed_total')} value={formatCurr(totalPayment, currency)} />
      </View>
    </>
  );
};

export default OrderSubtotal;
