import { ApolloError, gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { i18n, ProductActiveGroupOrdersMembers } from '@core';
import { useEffect } from 'react';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';

const GET_GROUP_MEMBERS_BY_ID = gql`
  query GetGroupMembersById($id: String!) {
    activeGroupOrders(where: { id: { _eq: $id } }) {
      id
      expiredDate
      members(
        orderBy: { joinAt: desc }
        where: { status: { _in: ["PENDING", "OPEN"] }, paymentStatus: { _in: ["PENDING", "AUTHORIZED"] } }
      ) {
        userId
        name
        photoUrl
        joinAt
      }
    }
  }
`;

interface IUseGetGroupMembersResponse {
  activeGroupOrders: {
    id: string;
    expiredDate: string;
    members: ProductActiveGroupOrdersMembers[];
  }[];
}

interface IUseGetGroupMembersProps {
  id: string | null;
  options?: LazyQueryHookOptions<IUseGetGroupMembersResponse, { id: string }>;
}

const useGetGroupMembers = ({ id, options }: IUseGetGroupMembersProps) => {
  const toast = useShowToast();
  const [getGroupDetails, { data, loading, refetch }] = useLazyQuery<IUseGetGroupMembersResponse, { id: string }>(
    GET_GROUP_MEMBERS_BY_ID,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    if (!!id) {
      getGroupDetails({
        variables: { id },
        onError: (error: ApolloError) => toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default)),
        ...options,
      });
    }
  }, [id]);

  return {
    groupDetails: data?.activeGroupOrders?.[0],
    loading,
    refetch,
  };
};

export default useGetGroupMembers;
