/* eslint-disable complexity */
import { useReactiveVar } from '@apollo/client';
import { Button } from '@components';
import { authVar, getDisplayCurrency, getInvitedBy, i18n, useBanditTheme } from '@core';
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { HomeScreenNavigationProp, InvitedModalScreenRouteProps } from '@routes';
import { InviteGroupIcon } from '@svgIcons';
import ExpoStatusBar from 'expo-status-bar/build/ExpoStatusBar';
import { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { ActivityIndicator, IconButton, Text } from 'react-native-paper';
import Dialogue from 'src/components/Dialogue/Dialogue';
import { tw } from 'src/core/tw';
import InvitedHeader from './components/InvitedHeader/InvitedHeader';
import { useGetInviteDetails, useGetProductFromInvite } from './hooks';

const InvitedModalScreen = () => {
  const [showDialogue, setShowDialogue] = useState(false);
  const { colors } = useBanditTheme();

  const auth = useReactiveVar(authVar);

  const navigation = useNavigation<HomeScreenNavigationProp>();

  const { params } = useRoute<InvitedModalScreenRouteProps>();

  const [invitedBy, setInvitedBy] = useState<string | null>(null);

  const handleCloseInvite = () => {
    setShowDialogue(false);
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Main',
          },
        ],
      }),
    );
  };

  const { product } = useGetProductFromInvite({ productId: params.productId });

  const { groupDetails, loading: loadingInvite } = useGetInviteDetails({
    id: params?.inviteId || null,
    options: {
      onCompleted: ({ activeGroupOrders }) => {
        if (!activeGroupOrders.length) {
          setShowDialogue(true);
        }
      },
    },
  });

  const findInvitedBy = async () => {
    const ref = await getInvitedBy();

    const invitedMember = groupDetails?.members?.filter((member) => member.userId === ref)?.[0];

    if (!!invitedMember) {
      setInvitedBy(invitedMember.name);
    }
  };

  useEffect(() => {
    findInvitedBy();
  }, []);

  const handleViewInvite = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: 'Main', params: { screen: 'DealScreen' } },
          {
            name: 'ProductDetailScreen',
            params: { id: params.productId, inviteId: params.inviteId },
          },
        ],
      }),
    );
  };

  const invitedByText = `${i18n.t('you_have_been_invited_modal_invited_by')} ${
    invitedBy || groupDetails?.creator?.name
  } ${i18n.t('you_have_been_invited_modal_join_group_purchase')} `;

  const displayCurrency = getDisplayCurrency(product?.currency || '');

  return (
    <>
      <ExpoStatusBar style="dark" backgroundColor="white" />

      {showDialogue && (
        <Dialogue
          handleClose={handleCloseInvite}
          closeText="OK"
          title="Invalid invitation"
          message="This invitation is not exist or the group is already completed. Please try again."
        />
      )}

      {!auth?.loggedIn && <InvitedHeader />}

      <View style={tw`flex-1 justify-end`}>
        <View
          style={tw`flex-1 bg-[${colors.primary}] rounded-t-3xl border-[1.5px] max-h-[${!auth?.loggedIn ? 95 : 84}%]`}
        >
          {loadingInvite || !product || !groupDetails ? (
            <View style={tw`flex-1 justify-center items-center`}>
              <ActivityIndicator testID="loading" size={50} color="black" />
            </View>
          ) : (
            <>
              <View style={tw`absolute z-20 top-7 right-5 bg-[${colors.primary}]`}>
                <IconButton
                  testID="close-button"
                  accessibilityRole="button"
                  icon="close"
                  style={[tw`border-2 border-black m-0 self-end`]}
                  onPress={handleCloseInvite}
                  size={18}
                  accessibilityLabelledBy={undefined}
                  accessibilityLanguage={undefined}
                />
              </View>

              <View style={tw`rounded-t-3xl flex-1`}>
                <ScrollView
                  style={tw`rounded-t-3xl`}
                  contentContainerStyle={tw`py-28`}
                  showsVerticalScrollIndicator={false}
                >
                  <Text style={[tw`mx-10 text-center`, { fontFamily: 'FeatureDeck-Regular', fontSize: 40 }]}>
                    {i18n.t('you_have_been_invited_modal_title')}
                  </Text>
                  <InviteGroupIcon style={tw`mx-auto mt-8`} width={233} height={220} viewBox="0 0 288 272" />
                  <Text style={[tw`text-sm mx-20 text-center mt-8`, { fontFamily: 'TheFuture-Regular' }]}>
                    {invitedByText}
                    <Text style={{ fontFamily: 'TheFuture-Medium' }}>{product.name}</Text>{' '}
                    {i18n.t('you_have_been_invited_modal_for')}{' '}
                    <Text style={[tw`line-through`, { fontFamily: 'TheFuture-Medium' }]}>
                      {product.soloPrice} {displayCurrency}
                    </Text>{' '}
                    <Text style={{ fontFamily: 'TheFuture-Medium' }}>
                      {product.groupPrice} {displayCurrency}
                    </Text>
                  </Text>
                  <Button
                    title={i18n.t('you_have_been_invited_modal_check_it_out')}
                    customStyle={tw`self-start mt-8 mx-auto py-4 px-6`}
                    customTextStyle={{ fontFamily: 'TheFuture-Regular', fontSize: 14, lineHeight: 16 }}
                    onPress={handleViewInvite}
                  />
                </ScrollView>
              </View>
            </>
          )}
        </View>
      </View>
    </>
  );
};

export default InvitedModalScreen;
