/* eslint-disable max-params */
/* eslint-disable complexity */
import { i18n, OrderStatus } from '@core';
import { useCountdown } from '@hooks';
import dayjs from 'dayjs';

export function useTimerStatus(orderDate: string, orderStatus: OrderStatus, isGroupFull: boolean) {
  const expiryDate = dayjs(orderDate).add(1, 'day').toISOString();

  const { timeLeft } = useCountdown({ timeout: expiryDate, autoFormat: true });

  if (orderStatus == OrderStatus.Canceled) {
    return i18n.t('groups:deal_missed');
  }

  if (orderStatus == OrderStatus.Completed) {
    return i18n.t('groups:deal_success');
  }

  if (orderStatus == OrderStatus.Open && timeLeft === '00:00:00') {
    if (isGroupFull) {
      return i18n.t('groups:deal_success');
    }
    return i18n.t('groups:deal_missed');
  }

  return timeLeft;
}
