import { View } from 'react-native';
import { tw } from 'src/core/tw';
import { IActiveGroupH } from '../model/model';
import OrderItem from './OrderItem/OrderItem';

interface GroupResultScreenProps {
  orderList: IActiveGroupH[];
}

const GroupResultScreen = ({ orderList }: GroupResultScreenProps) => {
  return (
    <View style={tw`p-4`} testID="LIST.ORDER">
      {orderList.map((order: IActiveGroupH) => {
        return <OrderItem order={order} key={`${order.orderId}-${order.cart.id}`} />;
      })}
    </View>
  );
};

export default GroupResultScreen;
