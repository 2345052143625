import { useQuery, useReactiveVar } from '@apollo/client';
import { authVar, OrderStatus } from '@core';
import { IActiveGroupH, IActiveGroupOrders } from '../../model/model';
import { GET_ACTIVE_GROUP_ORDERS } from '../../query';

export const GROUP_SCREEN_QUERY_VARIABLES = {
  visible: true,
  isSingleOrder: false,
  allowedStatuses: [
    OrderStatus.Open,
    OrderStatus.Fulfilled,
    OrderStatus.Completed,
    OrderStatus.Canceled,
    OrderStatus.Abandoned,
  ],
};

const ORDER_STATUS = [
  OrderStatus.Open,
  OrderStatus.Fulfilled,
  OrderStatus.Completed,
  OrderStatus.Canceled,
  OrderStatus.Abandoned,
];

const sortByStatusAndDate = (a: IActiveGroupH, b: IActiveGroupH) => {
  const statusAIndex = ORDER_STATUS.indexOf(a.status);
  const statusBIndex = ORDER_STATUS.indexOf(b.status);

  if (statusAIndex < statusBIndex) return -1;
  if (statusAIndex > statusBIndex) return 1;

  if (a.orderDate < b.orderDate) return -1;
  if (a.orderDate > b.orderDate) return 1;

  return 0;
};

const useGroupHook = () => {
  const auth = useReactiveVar(authVar);
  const { data: { myOrders } = { myOrders: [] }, loading } = useQuery<IActiveGroupOrders>(GET_ACTIVE_GROUP_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: GROUP_SCREEN_QUERY_VARIABLES,
    skip: !auth.loggedIn,
    pollInterval: 10000,
  });

  const sortedActiveGroupOrders = [...myOrders].sort(sortByStatusAndDate);

  return { data: sortedActiveGroupOrders, loading };
};

export default useGroupHook;
