/* eslint-disable prettier/prettier */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

export const DATE_FORMAT = 'DD-MM-YY';

export const DATE_FORMAT_SLASH = 'MM/DD/YY';

export const RELATIVE_TIME_FORMAT = 'HH:mm D MMM';

export const TIME_FORMAT = {
  normal: 'HH:mm:ss',
  wide: 'HH : mm : ss',
};

export const SHORT_TIME_FORMAT = { normal: 'mm:ss', wide: 'mm : ss' };

export const getRelativeTimeText = (time: string) => {
  const currentTime = dayjs(new Date().toISOString().replace('Z', '')).set('millisecond', 0);

  const timeDiff = dayjs.duration(currentTime.diff(time));

  const timeDiffMin = timeDiff.asMinutes();

  if (timeDiffMin < 1) {
    return 'a few seconds ago';
  }
  if (timeDiffMin >= 60) {
    return dayjs(`${time}Z`).format(RELATIVE_TIME_FORMAT);
  }

  return `${timeDiff.format('m')} min${timeDiff.asMinutes() > 2 ? 's' : ''} ago`;
};

export const getTimeDiff = (endTime: string) => {
  const startTimeJs = dayjs(new Date().toISOString().replace('Z', '')).set('millisecond', 0);

  const endTimeJs = dayjs(endTime);

  const timeDiff = endTimeJs.diff(startTimeJs);

  return timeDiff > 0 ? timeDiff : 0;
};

interface IFormatTimeLeftProps {
  timeLeft: number;
  autoFormat: boolean;
  formatSpacing?: 'wide' | 'normal';
}

export const formatTimeLeft = ({ timeLeft, autoFormat, formatSpacing = 'wide' }: IFormatTimeLeftProps) => {
  const isLessThanTenMins = dayjs.duration(timeLeft).asMinutes() < 10;

  const timeFormat = isLessThanTenMins && autoFormat ? SHORT_TIME_FORMAT : TIME_FORMAT;

  return dayjs.duration(timeLeft).format(timeFormat[formatSpacing]);
};

export const getDateFormat = (date: string) => {
  return dayjs(new Date(date)).format(DATE_FORMAT);
};

export const getDateFormatSlash = (date: string) => {
  return dayjs(new Date(date)).format(DATE_FORMAT_SLASH);
};

export const getRelativeHour = (date: string): string => {
  const timeDiff = getTimeDiff(date);
  const hours = dayjs.duration(timeDiff).asHours();

  if (hours < 1) {
    return '1 hr';
  } else {
    return Math.floor(hours) + ' hrs';
  }
};
