import { gql } from '@apollo/client';

export const GET_GROUP_PROGRESS_BY_ID = gql`
  query GetGroupProgressById($activeOrderId: String!) {
    productTypes(where: { activeGroupOrders: { id: { _eq: $activeOrderId } } }) {
      id
      name
      brandName
      producer {
        organizationName
      }
      soloPrice
      groupPrice
      groupSize
      activeGroupOrders {
        id
        creator {
          userId
          city
          name
          photoUrl
          joinAt
        }
        numberOfMembers
        expiredDate
        orderDate
        purchaseDate
        status
        orderRef
        members {
          userId
          city
          name
          photoUrl
          joinAt
        }
      }
    }
  }
`;
