import { useQuery } from '@apollo/client';
import { ICategories } from '../../model';
import { GET_CATEGORIES } from '../../query';

const useCategoriesHook = () => {
  const { data, loading } = useQuery<ICategories>(GET_CATEGORIES);

  return {
    categoriesData: data?.categories,
    categoryLoading: loading,
  };
};

export default useCategoriesHook;
