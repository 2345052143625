import { BackHeader, KeyboardAvoidView } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { FloatingLabelInput } from 'react-native-floating-label-input';
import { tw } from 'src/core/tw';

export const PersonalInfoScreen = () => {
  const navigation = useNavigation();

  const formData = {
    firstName: 'Carl',
    lastName: 'Kahlo, III',
    mobile: '+47 411 12 345',
    age: 35,
    email: 'carlkahlo@gmail.com',
    address: 'Christies Gate 123',
    zipCode: '5011',
    city: 'Bergen',
  };

  return (
    <KeyboardAvoidView>
      <SafeAreaView style={tw`flex flex-1 bg-white`}>
        <BackHeader title={i18n.t('profile_personalInfo_title')} goBack={navigation.goBack} />
        <View style={tw`flex px-5 mt-5`}>
          <FloatingLabelInput
            containerStyles={{ ...styles.input, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            editable={false}
            label="First name"
            placeholder="First name"
            value={formData.firstName}
            testID="CREATE.USER.FIRSTNAME"
          />
          <FloatingLabelInput
            containerStyles={styles.input}
            editable={false}
            keyboardType="default"
            label="Last name"
            placeholder="Last name"
            testID="CREATE.USER.LASTNAME"
            value={formData.lastName}
          />
          <View style={tw`flex flex-row`}>
            <FloatingLabelInput
              containerStyles={styles.input}
              editable={false}
              keyboardType="phone-pad"
              label="Mobile"
              placeholder="MOBILE"
              testID="CREATE.USER.MOBILE"
              value={formData.mobile}
            />
            <FloatingLabelInput
              label="Age"
              testID="CREATE.USER.AGE"
              containerStyles={styles.input}
              value={formData.age.toString()}
              placeholder="Age"
              keyboardType="number-pad"
              editable={false}
            />
          </View>
          <FloatingLabelInput
            label="Email"
            testID="CREATE.USER.EMAIL"
            containerStyles={styles.input}
            value={formData.email}
            placeholder="Email"
            keyboardType="email-address"
            editable={false}
          />
          <FloatingLabelInput
            label="Address"
            testID="CREATE.USER.ADDRESS"
            containerStyles={styles.input}
            value={formData.address}
            placeholder="Street Address"
            keyboardType="default"
            editable={false}
          />
          <View style={tw`flex flex-row`}>
            <FloatingLabelInput
              testID="CREATE.USER.ZIPCODE"
              label="Zip code"
              containerStyles={{ ...styles.input, borderBottomWidth: 1, borderBottomLeftRadius: 8 }}
              value={formData.zipCode}
              placeholder="Zip Code"
              maxLength={6}
              keyboardType="number-pad"
              editable={false}
            />
            <FloatingLabelInput
              label="City"
              testID="CREATE.USER.CITY"
              containerStyles={{ ...styles.input, borderBottomWidth: 1, borderBottomRightRadius: 8 }}
              value={formData.city}
              placeholder="City"
              keyboardType="default"
              editable={false}
            />
          </View>
        </View>
      </SafeAreaView>
    </KeyboardAvoidView>
  );
};

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 0,
    borderColor: '#E1E1E1',
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 4,
    borderBottomWidth: 0,
    height: 60,
  },
});
export default PersonalInfoScreen;
