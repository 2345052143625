import { videoColors } from '@core';
import { FailureStateIcon } from '@svgIcons';
import React from 'react';
import { Dimensions, Modal, TouchableOpacity, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

export interface AlertProps {
  message: string;
  closeText: string;
  confirmText?: string;
  title?: string;
  handleClose: () => void;
  handleConfirm?: () => void;
}

const { height, width } = Dimensions.get('window');

const Dialogue: React.FC<AlertProps> = ({ message, closeText, confirmText, handleClose, handleConfirm, title }) => {
  return (
    <Modal transparent animationType="none">
      <Animatable.View animation="fadeIn" duration={500} useNativeDriver style={{ flex: 1 }}>
        <TouchableOpacity
          onPress={handleClose}
          activeOpacity={1}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.8)',
            position: 'absolute',
            zIndex: 900,
            height: height,
            width: width,
          }}
        >
          <View style={tw`w-[90%] bg-white p-4 border-2 rounded-md justify-center items-center`}>
            <View style={tw`items-center`}>
              <FailureStateIcon fill={videoColors.primaryColor} viewBox="0 0 190 200" />
            </View>
            {title && (
              <Text variant="headlineMedium" style={tw`mb-2`}>
                {title}
              </Text>
            )}
            <Text variant="labelMedium">{message}</Text>

            <View style={tw`flex flex-row mt-8 w-full justify-around`}>
              <TouchableOpacity
                onPress={handleClose}
                accessibilityRole="button"
                style={tw`rounded-full p-3 items-center bg-black flex flex-1 mx-2`}
              >
                <Text style={[tw`text-white`]}>{closeText}</Text>
              </TouchableOpacity>
              {confirmText && handleConfirm && (
                <TouchableOpacity
                  onPress={handleConfirm}
                  accessibilityRole="button"
                  style={tw`rounded-full p-3 items-center bg-white border-2 flex flex-1 mx-2`}
                >
                  <Text style={[tw`text-black`]}>{confirmText}</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </Animatable.View>
    </Modal>
  );
};

export default Dialogue;
