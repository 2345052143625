import { ActivityIndicator, Platform, StyleProp, Text, View, ViewStyle } from 'react-native';
import { tw } from 'src/core/tw';

interface ILoadingProps {
  style?: StyleProp<ViewStyle>;
}

const Loading = ({ style }: ILoadingProps) => {
  return (
    <View style={tw`flex-1 w-full h-full items-center justify-center`}>
      {Platform.OS === 'web' ? <Text>Loading...</Text> : <ActivityIndicator color="black" size="large" />}
    </View>
  );
};
export default Loading;
