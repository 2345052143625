import { Button, ModalCloseButton, UnderlineText } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { TimeoutModalScreenNavigationProps } from '@routes';
import { FailureStateIcon } from '@svgIcons';
import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { usePurchaseActions } from '../ProductDetailScreen/hooks/usePurchaseState/usePurchaseState';

const TimeoutModalScreen = () => {
  const { colors } = useBanditTheme();

  const { handlePressGroupBuy } = usePurchaseActions();

  const navigation = useNavigation<TimeoutModalScreenNavigationProps>();

  const handleTryAgain = () => {
    navigation.goBack();
    handlePressGroupBuy();
  };

  const handleCloseModal = () => {
    navigation.goBack();
  };

  return (
    <View style={tw`flex-1 justify-end`}>
      <View style={tw`flex items-center bg-[${colors.secondary_ember}] rounded-t-3xl border-2 max-h-[90%]`}>
        <ModalCloseButton onClose={handleCloseModal} />

        <ScrollView
          contentContainerStyle={tw`py-16 flex items-center`}
          showsVerticalScrollIndicator={false}
          bounces={false}
        >
          <Text style={[tw`text-center`, { fontFamily: 'FeatureDeck-Regular', fontSize: 48 }]}>
            {i18n.t('time_out_modal_time_is_out')}
          </Text>
          <Text style={[tw`text-sm text-center mx-20 mt-4`, { fontFamily: 'TheFuture-Regular' }]}>
            {i18n.t('time_out_modal_get_it_next_time')}
          </Text>
          <FailureStateIcon
            style={tw`mt-25`}
            fill={colors.secondary_ember}
            width={216}
            height={176}
            viewBox="0 0 186 152"
          />
          <Button
            title={i18n.t('common_try_again')}
            customStyle={tw`mt-22 px-6 py-4`}
            customTextStyle={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}
            onPress={handleTryAgain}
          />
          <UnderlineText style={tw`self-center mt-4`} onPress={handleCloseModal}>
            <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>
              {i18n.t('time_out_modal_miss_the_deal')}
            </Text>
          </UnderlineText>
        </ScrollView>
      </View>
    </View>
  );
};

export default TimeoutModalScreen;
