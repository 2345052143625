import { OrderStatus } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import useCart from 'src/domain/order/hooks/useCart';
import { PaymentScreenNavigationProps, PaymentScreenRouteProps } from 'src/domain/payment/PaymentRoutes';
import usePaymentRedirectResult from './usePaymentRedirectResult';

type WatchProps = {
  onSuccess: () => void;
  onFailure: () => void;
};

function useCartStatusWatch({
  cartId,
  onSuccess,
  onFailure,
}: {
  cartId: string;
  onSuccess: () => void;
  onFailure: () => void;
}) {
  const { cart } = useCart({ cartId, pollInterval: 3000 });
  useEffect(() => {
    if (!cart) return;
    const successStatuses = [OrderStatus.Completed, OrderStatus.Fulfilled, OrderStatus.Open];
    const failureStatuses = [OrderStatus.Abandoned, OrderStatus.Canceled];
    if (failureStatuses.includes(cart.status)) {
      onFailure();
      return;
    }
    if (successStatuses.includes(cart.status)) {
      onSuccess();
      return;
    }
  }, [onFailure, onSuccess, cart]);
}

function useSuccessWatch({ onSuccess, onFailure }: WatchProps) {
  const { params } = useRoute<PaymentScreenRouteProps>();

  useEffect(() => {
    const success = typeof params.success === 'string' ? params.success === 'true' : params.success;

    if (success !== undefined) {
      if (success) {
        onSuccess();
      } else {
        onFailure();
      }
    }
  }, [onFailure, onSuccess, params.success]);
}

function useRedirectResultWatch({ onSuccess, onFailure }: WatchProps) {
  const { params } = useRoute<PaymentScreenRouteProps>();

  const { checkPaymentRedirectResult } = usePaymentRedirectResult();
  const handleRedirectResult = useCallback(
    async (redirectResult: string) => {
      await checkPaymentRedirectResult({
        variables: { redirectResult },
        onCompleted: ({ paymentValidateRedirectResult: { resultCode } }) => {
          if (resultCode === 'Authorised') {
            onSuccess();
          }
          if (resultCode === 'Cancelled' || resultCode === 'Error' || resultCode === 'Refused') {
            onFailure();
          }
        },
        onError: (e) =>
          sendErrorToSentry(e, {
            tags: {
              feature: 'payment',
            },
          }),
      });
    },
    [checkPaymentRedirectResult, onFailure, onSuccess],
  );

  useEffect(() => {
    const redirectResult = params.redirectResult;
    if (redirectResult) {
      handleRedirectResult(redirectResult);
    }
  }, [handleRedirectResult, params.redirectResult]);
}

export function useHandlePaymentCallback() {
  const { params } = useRoute<PaymentScreenRouteProps>();
  const navigation = useNavigation<PaymentScreenNavigationProps>();
  const [hasNavigated, setHasNavigated] = useState(false);

  const onSuccess = useCallback(() => {
    if (hasNavigated) return;
    setHasNavigated(true);
    navigation.replace('PaymentSuccessScreen', {
      orderId: params.orderId,
    });
  }, [hasNavigated, navigation, params.orderId]);

  const onFailure = useCallback(() => {
    if (hasNavigated) return;
    setHasNavigated(true);
    navigation.replace('PaymentFailureScreen', {
      orderId: params.orderId,
    });
  }, [hasNavigated, navigation, params.orderId]);

  // For web
  useSuccessWatch({ onSuccess, onFailure });

  // For mobile
  useRedirectResultWatch({ onSuccess, onFailure });

  // Fallback for both
  useCartStatusWatch({ cartId: params.cartId, onSuccess, onFailure });

  return {
    checkingPayment: !!params.redirectResult || params.success !== undefined,
  };
}
