import { TouchableOpacity } from 'react-native';
import BackBtn from 'src/svgIcons/BackBtn';

interface BackButtonProps {
  goBack: () => void;
}

export const BackButton = ({ goBack }: BackButtonProps) => {
  return (
    <TouchableOpacity onPress={() => goBack()} testID="BUTTON.BACK">
      <BackBtn />
    </TouchableOpacity>
  );
};

export default BackButton;
