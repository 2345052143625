import {
  BCModalScreen,
  GroupProgressModalScreen,
  InvitedModalScreen,
  LoginModalScreen,
  ModalScreen,
  TimeoutModalScreen,
} from '@screens';
import IScreen from '../IScreen';
import { ModalStackParamList } from './modal.model';

export const ModalRoutes: IScreen<ModalStackParamList>[] = [
  {
    name: 'MessageModalScreen',
    component: ModalScreen,
  },
  {
    name: 'LoginModalScreen',
    component: LoginModalScreen,
  },
  {
    name: 'BCModalScreen',
    component: BCModalScreen,
  },
  {
    name: 'InvitedModalScreen',
    component: InvitedModalScreen,
  },
  {
    name: 'GroupProgressModalScreen',
    component: GroupProgressModalScreen,
  },
  {
    name: 'TimeoutModalScreen',
    component: TimeoutModalScreen,
  },
];

export default ModalRoutes;
