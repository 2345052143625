import { ApolloError, useMutation } from '@apollo/client';
import { i18n } from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { CreateBrandVotesProps } from '../../model';
import { CREATE_BRAND_VOTES } from '../../query';

const useCreateBrandVotes = () => {
  const toast = useShowToast();
  const [createBrandVotes, { loading }] = useMutation(CREATE_BRAND_VOTES);

  const showError = (error?: ApolloError) => {
    toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
  };

  const handleCreateBrandVotes = ({ brandNames, options }: CreateBrandVotesProps) => {
    createBrandVotes({
      ...options,
      variables: { brandNames },
      onError: (error: ApolloError) => showError(error),
    });
  };

  return { handleCreateBrandVotes, loadingCreateBrandVotes: loading };
};

export default useCreateBrandVotes;
