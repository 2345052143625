import { CreateProfileScreen, CreateUserScreen, TermsAndConditionScreen, UserInterestedScreen } from '@screens';
import IScreen from '../IScreen';
import { AuthStackParamList } from './authRoutes.model';

export const AuthRoutes: IScreen<AuthStackParamList>[] = [
  {
    name: 'TermsScreen',
    component: TermsAndConditionScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'CreateUserScreen',
    component: CreateUserScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'CreateProfileScreen',
    component: CreateProfileScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'UserInterestedScreen',
    component: UserInterestedScreen,
    options: {
      headerShown: false,
    },
  },
];

export default AuthRoutes;
