import { makeRedirectUri, Prompt, ResponseType } from 'expo-auth-session';
import Constants from 'expo-constants';
import { Platform } from 'react-native';

const constants = Constants.expoConfig?.extra;

const getRedirectUrl = (extraConfig: any) => {
  const useProxy = Platform.select({ web: false, default: true });

  if (extraConfig?.APP_ENV === 'development') {
    const redirectUri = makeRedirectUri({
      useProxy,
    });
    return redirectUri;
  } else {
    if (Platform.OS === 'ios') {
      return extraConfig?.REDIRECT_AZURE_IOS;
    } else if (Platform.OS === 'android') {
      return extraConfig?.REDIRECT_AZURE_ANDROID;
    } else {
      const redirectUri = makeRedirectUri({
        useProxy,
        path: '',
        scheme: Constants?.expoConfig?.scheme,
      });
      return redirectUri;
    }
  }
};

function getTokenEndpoint(authParameter: string) {
  const baseUrl = constants?.AUTH_ENDPOINT.split('oauth2')[0];
  return `${baseUrl}${authParameter}/oauth2/v2.0/token`;
}

function getDiscoveryDocument(authParameter: string) {
  return {
    authorizationEndpoint: constants?.AUTH_ENDPOINT,
    tokenEndpoint: getTokenEndpoint(authParameter),
  };
}

function getAuthConfig(authParameter: string) {
  return {
    clientId: constants?.CLIENT_ID,
    responseType: ResponseType.Code,
    scopes: [constants?.SCOPES, 'offline_access'],
    usePKCE: true,
    prompt: Prompt.Login,
    redirectUri: getRedirectUrl(constants),
    extraParams: {
      p: authParameter,
    },
  };
}

const GoogleDiscoveryDocument = getDiscoveryDocument(constants?.GOOGLE_AUTH_PARAMETER);
const FacebookDiscoveryDocument = getDiscoveryDocument(constants?.FACEBOOK_AUTH_PARAMETER);
const VippsDiscoveryDocument = getDiscoveryDocument(constants?.VIPPS_AUTH_PARAMETER);
const AppleDiscoveryDocument = getDiscoveryDocument(constants?.APPLE_AUTH_PARAMETER);
const PhoneDiscoveryDocument = getDiscoveryDocument(constants?.PHONE_AUTH_PARAMETER);

const googleAuthConfig = getAuthConfig(constants?.GOOGLE_AUTH_PARAMETER);
const facebookAuthConfig = getAuthConfig(constants?.FACEBOOK_AUTH_PARAMETER);
const vippsAuthConfig = getAuthConfig(constants?.VIPPS_AUTH_PARAMETER);
const appleAuthConfig = getAuthConfig(constants?.APPLE_AUTH_PARAMETER);
const phoneAuthConfig = getAuthConfig(constants?.PHONE_AUTH_PARAMETER);

const enum OauthType {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  VIPPS = 'vipps',
  APPLE = 'apple',
  PHONE = 'phone',
}

const refreshTokenConfig = {
  clientId: constants?.CLIENT_ID,
};

export {
  OauthType,
  refreshTokenConfig,
  GoogleDiscoveryDocument,
  FacebookDiscoveryDocument,
  VippsDiscoveryDocument,
  AppleDiscoveryDocument,
  PhoneDiscoveryDocument,
  googleAuthConfig,
  facebookAuthConfig,
  vippsAuthConfig,
  appleAuthConfig,
  phoneAuthConfig,
};
