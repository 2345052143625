import { ModalStateSlice, ProductDetailModalState, useProductDetailsStore } from '@core';
import { shallow } from 'zustand/shallow';

const selector = ({ modalState, setModalState, showLoading, setShowLoading }: ModalStateSlice) => ({
  modalState,
  setModalState,
  showLoading,
  setShowLoading,
});

const useModalState = () => {
  const { modalState, setModalState, showLoading, setShowLoading } = useProductDetailsStore(selector, shallow);

  const handleShowModal = (newState: ProductDetailModalState) => {
    setModalState(newState);
  };

  const handleCloseModal = () => {
    setModalState(null);
  };

  const handleShowLoading = () => {
    setShowLoading(true);
  };

  const handleCloseLoading = () => {
    setShowLoading(false);
  };

  return {
    modalState,
    showLoading,
    handleShowModal,
    handleCloseModal,
    handleShowLoading,
    handleCloseLoading,
  };
};

export default useModalState;
