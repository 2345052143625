/* eslint-disable complexity */
import GroupIcon from '@assets/images/group.png';
import { AppLogo } from '@svgIcons';
import { Dimensions, Image, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import Button from '../Button/Button';
import TextButton from '../TextButton/TextButton';

export interface CustomAlertProps {
  title: string;
  subTitle?: string | null;
  description?: string;
  image: string;
  btnType: 'logo' | 'btn';
  btnSuccessLabel?: string;
  btnSuccessAction?: () => void;
  btnFailLabel?: string;
  btnFailAction?: () => void;
  layoutColor: string;
}

const CustomAlert = ({
  layoutColor,
  title,
  subTitle,
  btnType,
  description,
  btnSuccessAction,
  btnSuccessLabel,
  btnFailLabel,
  btnFailAction,
}: CustomAlertProps) => {
  return (
    <View style={[tw`flex-1 flex relative`, { backgroundColor: layoutColor }]} testID="LAYOUT.ALERT">
      <View style={tw`w-12 h-1 bg-black mx-auto mt-4`}></View>
      <Text variant="headlineLarge" style={tw`text-center mt-10`}>
        {title}
      </Text>
      {subTitle && (
        <Text variant="labelLarge" style={tw`text-center mt-4`}>
          {subTitle}
        </Text>
      )}
      <View
        style={[
          tw`flex mt-8 mr-8`,
          {
            height: Dimensions.get('screen').height / 3,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: layoutColor,
            transform: [{ translateX: 25 }],
          },
        ]}
      >
        {/* <CreateGroupIcon  fill={'#FF94FF'} color={'none'} /> */}
        <Image style={{ justifyContent: 'center', alignContent: 'center' }} source={GroupIcon} />
      </View>
      {description && (
        <Text variant="labelLarge" style={tw`px-8 text-center mt-4`}>
          {description}
        </Text>
      )}
      {btnType === 'logo' ? (
        <View style={[tw`absolute bottom-14 right-0 left-0 items-center`]} testID="VIEW.LOGO">
          <AppLogo />
        </View>
      ) : (
        <View>
          {!!btnSuccessLabel && btnSuccessAction && (
            <Button
              title={btnSuccessLabel}
              onPress={btnSuccessAction}
              customStyle={tw`px-4 mt-6 mx-auto`}
              customTextStyle={tw`text-sm`}
            />
          )}
          {!!btnFailLabel && btnFailAction && (
            <TextButton title={btnFailLabel} onPress={btnFailAction} customTextStyle={tw`text-black mt-4`} />
          )}
        </View>
      )}
    </View>
  );
};

export default CustomAlert;
