/* eslint-disable complexity */
import { generateInviteGroupLink, getTranslationValuesForRemainingTime, i18n, useUserStore } from '@core';
import * as Clipboard from 'expo-clipboard';
import { FC, PropsWithChildren } from 'react';
import { Share, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { isWeb } from 'src/core/helpers/platformHelper';
import { ERROR_MESSAGES } from 'src/core/messages';
import { UseUserStore } from 'src/core/store/user/useUserStore';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useShowToast } from 'src/hooks/useShowToast';
import { useGroupDetails, useProductDetails } from 'src/screens/ProductDetailScreen/hooks';
import tw from 'twrnc';
import { shallow } from 'zustand/shallow';

const selector = ({ user }: UseUserStore) => ({ user });

interface IShareGroupWrapperProps extends PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  productId: string;
  groupId?: string;
  disabled?: boolean;
}

const ShareGroupWrapper: FC<IShareGroupWrapperProps> = ({ children, productId, style, groupId, disabled = false }) => {
  const toast = useShowToast();
  const { user } = useUserStore(selector, shallow);
  const { groupDetails } = useGroupDetails({ id: groupId });
  const { product } = useProductDetails({ productId });

  const handleShare = async () => {
    if (!user.id || !groupId || !groupDetails || !product) {
      toast.danger(i18n.t(ERROR_MESSAGES.default));
      return;
    }

    const productUrl = await generateInviteGroupLink({ productId, groupId, inviteUserId: user.id });

    if (isWeb) {
      await Clipboard.setStringAsync(productUrl);
      alert(`The following referral link was copied to your clipboard.\n\n${productUrl}`);
      return;
    }

    const remainingTimeOptions = getTranslationValuesForRemainingTime(groupDetails.expiredDate);
    const remainingTime = remainingTimeOptions ? i18n.t(remainingTimeOptions.key, remainingTimeOptions.values) : '';

    try {
      const title = i18n.t('product_share_title');
      const message = i18n.t('product_invite_to_group_singular', {
        brand: product.brandName,
        productName: product.name,
        groupMemberPrice: product.groupPrice,
        soloPrice: product.soloPrice,
        groupMembersStillNeeded: product.groupSize - groupDetails.numberOfMembers,
        remainingTime,
        groupLink: productUrl,
      });

      await Share.share({ message, url: productUrl, title });
    } catch (error: any) {
      sendErrorToSentry(error, { tags: { screen: 'ShareButtonWrapper', function: 'handleShare' } });
      toast.danger(error.message);
    }
  };

  return (
    <TouchableOpacity
      testID="share-group-button"
      accessibilityRole="button"
      style={[tw`opacity-${disabled ? 50 : 100}`, style]}
      disabled={disabled}
      onPress={handleShare}
    >
      {children}
    </TouchableOpacity>
  );
};

export default ShareGroupWrapper;
