import { getShortProductName } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ProductDetailScreenNavigationProps } from '@routes';
import { LinearGradient } from 'expo-linear-gradient';
import { Pressable, Text } from 'react-native';
import { tw } from 'src/core/tw';
import ProductDetailPrices from 'src/domain/prices/components/ProductDetailPrices';
import { IVideo } from 'src/screens/VideoScreen/model';

interface Props {
  data: IVideo;
}

const VideoProductDetail = ({ data }: Props) => {
  const navigation = useNavigation<ProductDetailScreenNavigationProps>();

  const handlePress = () => {
    navigation.navigate('ProductDetailScreen', { id: data.id });
  };

  const name = getShortProductName({ name: data.name });

  return (
    <>
      <LinearGradient
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: 500,
          zIndex: 0,
        }}
        pointerEvents="none"
        colors={['rgba(0, 0, 0, 0)', 'rgba(0,0,0,0.4)', 'rgba(0, 0, 0, 0.6) 100%']}
      />
      <Pressable testID="button" style={tw`absolute bottom-4 left-0 px-3 w-full z-10`} onPress={handlePress}>
        <Text style={[tw`text-sm font-bold text-white `, { fontFamily: 'TheFuture-Medium' }]}>
          {data.productType.brandName ?? data.producer.organizationName}
        </Text>
        <Text
          style={[
            tw`text-base font-normal text-white pb-2 z-10`,
            { fontFamily: 'TheFuture-Regular', fontSize: 14, zIndex: 10 },
          ]}
          numberOfLines={2}
        >
          {name}
        </Text>
        <ProductDetailPrices
          groupPrice={data.groupPrice}
          currency={data.currency}
          soloPrice={data.soloPrice}
          hasActiveGroupOrders={data.activeGroupOrders.length > 0}
          banditCashDiscount={data.banditCashDiscount}
          fontSizes={{ heading: 'text-4xl', subHeading: 'text-2xl' }}
          fontColor="white"
          shouldShowBanditCash
        />
        <Text
          numberOfLines={2}
          style={[tw`text-sm flex-1 font-normal text-white w-[80%] pt-4 z-10`, { fontFamily: 'TheFuture-Regular' }]}
        >
          {data.description}
        </Text>
      </Pressable>
    </>
  );
};

export default VideoProductDetail;
