/* eslint-disable max-params */
import { create } from 'zustand';
import { IMessageState, MessageType } from './types';

export const useMessageState = create<IMessageState>((set) => ({
  message: null,
  setInfoMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.INFO, message, description, options },
    })),
  setSuccessMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.SUCCESS, message, description, options },
    })),
  setWarningMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.WARNING, message, description, options },
    })),
  setDangerMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.DANGER, message, description, options },
    })),
  clearMessage: () => set((state) => ({ ...state, message: null })),
}));
