import { IImage, UseProductDetailsStore, useProductDetailsStore } from '@core';
import { shallow } from 'zustand/shallow';

const selector = ({ fullScreenImageState, setFullScreenImageState, setModalState }: UseProductDetailsStore) => ({
  fullScreenImageState,
  setFullScreenImageState,
  setModalState,
});

const useFullScreenImages = () => {
  const { fullScreenImageState, setFullScreenImageState, setModalState } = useProductDetailsStore(selector, shallow);

  const handleFullScreenImage = (images: IImage[], index: number) => {
    setFullScreenImageState(images, index);
    setModalState('fullScreenImage');
  };

  return {
    fullScreenImageState,
    handleFullScreenImage,
  };
};

export default useFullScreenImages;
