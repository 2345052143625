import { gql } from '@apollo/client';
export const GET_USERS = gql`
  query getUsers {
    users {
      email
      id
      firstName
      lastName
      interests
      photoUrl
      zipCode
      city
      age
      address
      phoneNumber
      role {
        name
        id
      }
    }
  }
`;

export const GET_BC_LIST = gql`
  query getBCHistory($date: timestamp!) {
    banditCash(where: { expiringAt: { _gte: $date } }) {
      credit
    }
  }
`;
