import Svg, { Path, SvgProps } from 'react-native-svg';

const LogoutLogo = (props: SvgProps) => (
  <Svg width={20} height={14} fill="none" {...props}>
    <Path
      d="M4.477 10.384 1 6.907 4.477 3.43M2.75 6.85h10.5"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7 9.426V11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v1.47"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default LogoutLogo;
