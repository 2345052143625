import { i18n, OauthType, storeOauthInfo, useProxy } from '@core';
import {
  AccessTokenRequestConfig,
  AuthRequestConfig,
  DiscoveryDocument,
  exchangeCodeAsync,
  useAuthRequest,
} from 'expo-auth-session';
import { ERROR_MESSAGES } from 'src/core/messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useLoginHook } from 'src/screens/LoginModalScreen/hooks';
import { useShowToast } from './useShowToast';

export const useOauth = ({
  discoveryDocument,
  authConfig,
  oauthType,
}: {
  discoveryDocument: DiscoveryDocument;
  authConfig: AuthRequestConfig;
  oauthType: OauthType;
}) => {
  const [request, _, promptAsync] = useAuthRequest(authConfig, discoveryDocument);
  const { loginUser } = useLoginHook();
  const toast = useShowToast();

  // eslint-disable-next-line complexity
  const login = async () => {
    const response = await promptAsync({
      useProxy,
      showInRecents: true, // IMPORTANT!, if removed android login fails
    });

    switch (response.type) {
      case 'success': {
        try {
          const accessTokenRequestConfig: AccessTokenRequestConfig = {
            clientId: authConfig.clientId,
            redirectUri: authConfig.redirectUri,
            code: response.params.code,
          };
          if (request?.codeVerifier) {
            accessTokenRequestConfig.extraParams = {
              code_verifier: request.codeVerifier,
            };
          }

          const exchangeTokenResponse = await exchangeCodeAsync(accessTokenRequestConfig, discoveryDocument);
          await storeOauthInfo(exchangeTokenResponse, oauthType);
          loginUser(oauthType, exchangeTokenResponse);

          return {
            exchangeTokenResponse,
            oauthType,
          };
        } catch (error) {
          sendErrorToSentry(error, {
            tags: {
              screen: 'useOauth',
              function: 'login',
            },
          });
          toast.danger(i18n.t('general_error_message'));
        }
        break;
      }
      case 'error': {
        toast.danger(i18n.t(ERROR_MESSAGES.authentication_error));
        break;
      }
      case 'dismiss': {
        toast.danger(i18n.t(ERROR_MESSAGES.authentication_cancelled));
        break;
      }
      case 'locked': {
        toast.danger(i18n.t(ERROR_MESSAGES.authentication_locked));
        break;
      }
    }

    return null;
  };

  return { login };
};

export default useOauth;
