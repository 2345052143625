import { i18n, useBanditTheme } from '@core';
import { Platform, Text } from 'react-native';
import { tw } from 'src/core/tw';

const EndOfFeedFeedbackToast = () => {
  const { colors } = useBanditTheme();
  return (
    <Text
      style={[
        tw`text-xs font-normal text-black bg-[${colors.primary_yellow}] text-center border-[1.5px] border-black rounded p-1.5 w-full overflow-hidden`,
        { fontFamily: 'TheFuture-Regular' },
        Platform.OS === 'web' && tw`max-w-199 m-5 mx-auto mb-0`,
      ]}
    >
      {i18n.t('end_of_feed_feedback_text')}
    </Text>
  );
};

export default EndOfFeedFeedbackToast;
