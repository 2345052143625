import { BackHeader, Loading } from '@components';
import { i18n, OrderStatus } from '@core';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, Text, View } from 'react-native';
import { tw } from 'src/core/tw';
import useOrderHistory from 'src/domain/order/hooks/useOrderHistory';
import OrderCard from './OrderCard/OrderCard';

const OrderHistoryScreen = () => {
  const navigation = useNavigation();
  const { orders, loading } = useOrderHistory();

  const getOrdersForStatuses = (statuses: string[]) => {
    return (
      orders
        ?.filter((order) => statuses.includes(order.status))
        .concat()
        .sort((a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime())
        .map((order) => <OrderCard key={order.orderId} order={order} />) ?? []
    );
  };

  const pending = getOrdersForStatuses([OrderStatus.Open, OrderStatus.Pending]);
  const completed = getOrdersForStatuses([OrderStatus.Completed, OrderStatus.Fulfilled]);
  const cancelled = getOrdersForStatuses([OrderStatus.Canceled, OrderStatus.Abandoned]);

  const generateCategory = (items: JSX.Element[], title: string) => {
    if (items.length === 0) {
      return null;
    }

    return (
      <>
        <Text style={tw`text-lg mt-5`}>{title}</Text>
        {items}
      </>
    );
  };

  if (loading || !orders) {
    return <Loading />;
  }

  if (orders?.length === 0) {
    return <Text style={tw`mt-10 mx-auto text-lg font-bold`}>{i18n.t('common:no_results_found')}</Text>;
  }

  return (
    <>
      <BackHeader title={i18n.t('profile_orderHistory_title')} goBack={navigation.goBack} />
      <ScrollView contentContainerStyle={tw`pb-4`}>
        <View style={tw`mx-5`}>
          {generateCategory(pending, i18n.t('order_history_pending'))}
          {generateCategory(completed, i18n.t('order_history_completed'))}
          {generateCategory(cancelled, i18n.t('order_history_unfulfilled'))}
        </View>
      </ScrollView>
    </>
  );
};

export default OrderHistoryScreen;
