import { ApolloError, LazyQueryHookOptions, useQuery } from '@apollo/client';
import { i18n, IGetActiveGroupsResponse } from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_ACTIVE_GROUPS_BY_ID } from '../../query';

interface IUseGroupDetailsProps {
  id: string | undefined;
  options?: LazyQueryHookOptions<IGetActiveGroupsResponse, { id: string }>;
}

const useGroupDetails = ({ id, options }: IUseGroupDetailsProps) => {
  const toast = useShowToast();
  const { data, loading, refetch } = useQuery<IGetActiveGroupsResponse, { id: string }>(GET_ACTIVE_GROUPS_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: { id: id! },
    skip: !id,
    pollInterval: 10000,
    onError: (error: ApolloError) => toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default)),
    ...options,
  });

  return {
    groupDetails: !!data?.activeGroupOrders?.[0]
      ? {
          ...data.activeGroupOrders[0],
          numberOfMembers: data.activeGroupOrders[0].members.length,
        }
      : undefined,
    loading,
    refetch,
  };
};

export default useGroupDetails;
