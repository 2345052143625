import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CreateUserScreenNavigationProps } from '@routes';
import { Right } from '@svgIcons';
import { View } from 'react-native';
import { List } from 'react-native-paper';
import { tw } from 'src/core/tw';

const PersonalInfo = () => {
  const navigation = useNavigation<CreateUserScreenNavigationProps>();
  return (
    <View style={tw`border-2 rounded-lg bg-white`}>
      <List.Item
        accessibilityLabelledBy={i18n.t('profile_settings_menu_personalInfo')}
        accessibilityLanguage={i18n.locale}
        title={i18n.t('profile_settings_menu_personalInfo')}
        titleStyle={{ fontFamily: 'TheFuture-Regular', fontSize: 14 }}
        right={(props) => (
          <View style={tw`w-4 h-4 mt-1`}>
            <Right />
          </View>
        )}
        onPress={() => {
          navigation?.navigate('CreateUserScreen', {
            isEdit: true,
          });
        }}
      />
    </View>
  );
};
export default PersonalInfo;
