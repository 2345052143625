import { BANDITCASH_TYPE, DeliveryMethod, i18n } from '@core';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { capitalizeFirstLetter } from 'src/core/helpers/capitalizeFirstLetter';
import { tw } from 'src/core/tw';
import { Order } from 'src/domain/order/types';

const DeliveryMethodKeys: { [key: string]: string } = {
  [DeliveryMethod.Bandit3PL]: 'order_placed_delivery_bandit_3_pl',
  [DeliveryMethod.OwnDelivery]: 'order_placed_delivery_own_delivery',
  [DeliveryMethod.Bandit2C]: 'order_placed_delivery_bandit_2_c',
};

const OrderInfo = ({ order }: { order: Order }) => {
  const paymentMethod = order.cart.payment?.paymentTransactions?.filter((payment) => payment.type !== BANDITCASH_TYPE);

  const orderDetails = [
    { label: 'order_placed_order_number', value: order.cartRef },
    { label: 'order_placed_tracking_info', value: order.cart.shipment?.trackingNumber ?? '-' },
    {
      label: 'order_placed_delivered_by',
      value: !!order.cart.shipment?.delivery ? i18n.t(DeliveryMethodKeys[order.cart.shipment.delivery]) : '-',
    },
    {
      label: 'order_placed_payment_method',
      value: capitalizeFirstLetter(paymentMethod?.[0]?.paymentMethod ?? '-'),
    },
  ];

  return (
    <>
      <>
        {orderDetails.map(({ label, value }) => {
          if (value !== '-') {
            return (
              <View key={label} style={tw`mt-4`}>
                <Text style={[tw`text-sm text-[#6E6E6E]`, { fontFamily: 'TheFuture-Regular' }]}>{i18n.t(label)}</Text>
                <Text testID={label} style={[tw`text-lg`, { fontFamily: 'TheFuture-Regular' }]}>
                  {value}
                </Text>
              </View>
            );
          }
        })}
      </>
      <View style={tw`mt-4`}>
        <View style={tw`mt-4`}>
          <Text style={[tw`text-sm text-[#6E6E6E]`, { fontFamily: 'TheFuture-Regular' }]}>
            {i18n.t('order_placed_delivery_location')}
          </Text>
          <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>{order.address}</Text>
          <Text style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>
            {order.zipCode}, {order.city}
          </Text>
        </View>
      </View>
    </>
  );
};

export default OrderInfo;
