import { Dimensions, ScrollView } from 'react-native';
import { tw } from 'src/core/tw';
import { ICategory } from 'src/screens/SearchScreen/model';
import CategoryItem from './CategoryItem';

type CategoriesProps = {
  list: ICategory[];
  clickHandler: (name: any) => void;
};

const { width } = Dimensions.get('screen');

const Categories = ({ list, clickHandler }: CategoriesProps) => {
  return (
    <ScrollView
      horizontal
      testID="SCROLL.CATEGORIES"
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[
        tw`grow flex-wrap`,
        {
          width: width + width / 2,
        },
      ]}
    >
      {list.map((item) => (
        <CategoryItem name={item.name} clickItem={() => clickHandler(item)} key={item.name} />
      ))}
    </ScrollView>
  );
};

export default Categories;
