import { i18n } from '@core';
import { DealsLogo } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IGroupCTACardProps {
  onPressStartGroup: () => void;
  groupPrice: number;
  soloPrice: number;
}

const GroupCTACard = ({ onPressStartGroup, groupPrice, soloPrice }: IGroupCTACardProps) => {
  return (
    <View style={tw`border-2 rounded-xl flex items-center py-6`}>
      <Text style={[tw`text-center text-sm mb-4 px-12`, { fontFamily: 'TheFuture-Regular' }]}>
        {i18n.t('product_detail_group_cta_card_start_a_group')} {i18n.t('product_detail_group_cta_card_extra_discount')}{' '}
        {i18n.t('product_detail_group_cta_card_being_a_group_leader')}
      </Text>

      <TouchableOpacity accessibilityRole="button" style={tw`bg-black btn flex-row`} onPress={onPressStartGroup}>
        <View style={tw`bg-bandit_yellow rounded-full w-8 h-8 items-center justify-center`}>
          <DealsLogo color={'black'} width={20} height={20} />
        </View>
        <View style={tw`items-start ml-2`}>
          <Text style={[tw`text-sm text-white font-thefuture_regular`]}>
            {i18n.t('product_detail_group_cta_card_start_own_group')}
          </Text>
          <View style={tw`flex-row items-center gap-2`}>
            <Text style={tw`text-white font-thefuture_medium`}>{Math.round(groupPrice * 0.8)} kr</Text>
            <Text style={tw`text-white line-through font-thefuture_regular`}>{soloPrice} kr</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default GroupCTACard;
