import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const ProfileBig = (props: SvgProps) => (
  <Svg width={82} height={82} fill="none" {...props}>
    <Circle cx={41} cy={41} r={40} fill="#fff" />
    <Path
      d="M54.656 31.541c-1.712 7.942-9.04 14.14-17.143 14.14-4.004 0-6.877-1.485-8.588-3.712-1.711-2.228-2.454-5.489-1.614-9.427 1.71-7.942 9.04-14.14 17.143-14.14 4.003 0 6.876 1.484 8.587 3.712 1.711 2.228 2.454 5.488 1.615 9.427Z"
      stroke="#000"
      strokeWidth={1.5}
    />
    <Path
      d="M41 1C18.918 1 1 18.885 1 41c0 12.074 5.391 22.89 13.85 30.218 6.037-10.848 17.595-18.402 30.218-18.402 8.006 0 13.979 3.002 17.595 7.684 2.292 2.97 3.712 6.747 4.003 11.105 8.749-7.328 14.302-18.337 14.302-30.637C80.968 18.885 63.082 1 41 1Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M41 80.968c22.082 0 40-17.886 40-40C81 18.853 63.082 1 41 1S1 18.885 1 41s17.918 40 40 40v-.032Z"
      stroke="#000"
      strokeWidth={1.5}
    />
  </Svg>
);

export default ProfileBig;
