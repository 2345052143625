import { BackHeader, Button, Category as CategoryList } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { FavoriteCategoryScreenRouteProps } from 'src/routes/settingRoutes/settingRoutes.model';
import { useUserInterestedHook } from './hooks';

const UserInterestedScreen = () => {
  const { categories, categoryLoading, onSelectItem, handleInterested, loading } = useUserInterestedHook();

  const [isEditMode, setIsEditMode] = useState(false);

  const { colors } = useBanditTheme();

  const navigation = useNavigation();

  const { params } = useRoute<FavoriteCategoryScreenRouteProps>();

  useEffect(() => {
    if (params?.isProfile) {
      const isProfile = params?.isProfile;
      if (isProfile) {
        setIsEditMode(true);
      }
    }
  }, [params]);

  return (
    <SafeAreaView style={[tw`flex-1`]}>
      {isEditMode && (
        <BackHeader title={i18n.t('profile_settings_menu_favorite_category')} goBack={navigation.goBack} />
      )}
      <View
        style={[
          tw`flex-1 px-4`,

          {
            backgroundColor: colors.secondary_cosmos_tint,
          },
        ]}
      >
        <View style={tw`w-full flex flex-row pt-6 mb-4`}>
          <Text style={tw`font-bold text-black`} variant="titleLarge">
            {i18n.t('userInterested_title')}
          </Text>
        </View>
        <View style={tw`w-full mb-4`}>
          <Text variant="bodyMedium">{i18n.t('userInterested_description')}</Text>
        </View>

        {categoryLoading ? (
          <View testID="VIEW.LOADING">
            <Text>Loading..</Text>
          </View>
        ) : (
          <View style={tw`flex flex-1 justify-start mt-20`}>
            <CategoryList list={categories} onSelectItem={onSelectItem} />
          </View>
        )}
      </View>
      <View style={tw`absolute bottom-0 w-full p-4 flex flex-row border-t bg-white justify-end border-gray-900`}>
        <Button
          loading={loading}
          textVariant="labelLarge"
          title={isEditMode ? i18n.t('common_submit') : i18n.t('userInterested_complete_profile')}
          disabled={!categories.some((item) => item.isSelected)}
          customStyle={tw`rounded-full px-6 py-2`}
          onPress={() => handleInterested(false, isEditMode)}
          testID="BUTTON.NEXT"
        />
      </View>
    </SafeAreaView>
  );
};

export default UserInterestedScreen;
