import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Platform, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import { ModalRoutes } from './ModalRoutes';
import { mainScreens } from './routesConfig';

const Stack = createStackNavigator();

export const Routes = () => {
  const routes = mainScreens;
  const injectWebStyles = Platform.OS === 'web' ? 'max-w-205 shadow-xl mx-auto w-full' : '';
  return (
    <SafeAreaProvider>
      <View style={tw`h-full ${injectWebStyles}`}>
        <Stack.Navigator
          screenOptions={{
            gestureEnabled: Platform.OS === 'ios',
          }}
        >
          <Stack.Group>
            {routes.map((route, i) => (
              <Stack.Screen key={route.name} name={route.name} component={route.component} options={route.options} />
            ))}
          </Stack.Group>
          <Stack.Group
            screenOptions={{
              presentation: 'modal',
              detachPreviousScreen: false,
              cardStyle: {
                backgroundColor: 'transparent',
                opacity: 0.99,
              },
              gestureEnabled: true,
              gestureResponseDistance: 500,
              animationEnabled: true,
              headerShown: false,
              ...TransitionPresets.ModalTransition,
            }}
          >
            {ModalRoutes.map((route, i) => (
              <Stack.Screen key={route.name} name={route.name} component={route.component} options={route.options} />
            ))}
          </Stack.Group>
        </Stack.Navigator>
      </View>
    </SafeAreaProvider>
  );
};

export default Routes;
