import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface UserIconProps extends SvgProps {
  size?: number;
  color?: string;
}

const UserIcon = (props: UserIconProps) => (
  <Svg
    fill="none"
    stroke={props.color || 'currentColor'}
    width={props.size || 26}
    height={props.size || 26}
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0zM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632z"
    />
  </Svg>
);
export default UserIcon;
