import { Button } from '@components';
import { i18n, useBanditTheme } from '@core';
import { Dispatch, SetStateAction } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import useCreateBrandVotes from '../../hooks/useCreateBrandVotes/useCreateBrandVotes';
import { BrandItem, EndOfFeedAddBrandsListState } from '../../model';

const EndOfFeedModalActionsBtn = ({
  customBrands,
  closeModal,
  openFeedbackToast,
  scrollToTop,
  setSelectedBrands,
}: {
  customBrands: EndOfFeedAddBrandsListState[];
  closeModal: () => void;
  openFeedbackToast: () => void;
  scrollToTop: () => void;
  setSelectedBrands: Dispatch<SetStateAction<BrandItem[] | []>>;
}) => {
  const { handleCreateBrandVotes, loadingCreateBrandVotes } = useCreateBrandVotes();

  const { colors } = useBanditTheme();

  const hasErrorOnBrands = customBrands.length && customBrands.some((brand) => brand.hasError === true);
  const isDisabled = hasErrorOnBrands || loadingCreateBrandVotes;

  const handleCancelPress = () => {
    closeModal();
  };

  const handleAddPress = () => {
    if (!customBrands.length) return;
    const allBrandsName = customBrands.filter((item) => item.value !== '').map((brandItem) => brandItem.value);

    handleCreateBrandVotes({
      brandNames: allBrandsName,
      options: {
        onCompleted: () => {
          setSelectedBrands([]);
          closeModal();

          setTimeout(() => {
            openFeedbackToast();
            scrollToTop();
          }, 250);
        },
      },
    });
  };
  return (
    <View style={tw`flex-row mb-10 justify-between items-center mt-3.5`}>
      <TouchableOpacity
        style={[tw`justify-center border-b border-black`]}
        onPress={handleCancelPress}
        accessibilityRole="button"
      >
        <Text
          variant="labelLarge"
          style={[tw`text-sm font-normal text-black text-center`, { fontFamily: 'TheFuture-Regular' }]}
        >
          {i18n.t('common_cancel')}
        </Text>
      </TouchableOpacity>
      <Button
        loading={loadingCreateBrandVotes}
        title={i18n.t('common_add')}
        disabled={isDisabled}
        customStyle={tw`rounded-full px-6 py-3 border-[1.5px] border-black bg-[${
          isDisabled ? colors.secondary_cosmos_tint_300 : colors.primary_black
        }]`}
        customTextStyle={[tw`text-sm font-normal text-white`, { fontFamily: 'TheFuture-Regular' }]}
        onPress={handleAddPress}
      />
    </View>
  );
};

export default EndOfFeedModalActionsBtn;
