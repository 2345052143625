import { useDetectOs } from './useDetectOs';

export function useAppStoreUrl() {
  const os = useDetectOs();
  if (os === 'iOS') {
    return 'https://apps.apple.com/no/app/bandit/id1644701808';
  }
  if (os === 'Android') {
    return 'https://play.google.com/store/apps/details?id=no.bandit';
  }
  return 'https://www.joinbandit.com/';
}
