import * as Linking from 'expo-linking';
import React, { ComponentType, useEffect } from 'react';
import { useDetectOs } from 'src/hooks/useDetectOs';

export function withRedirectDesktopToWeb<P extends object>(WrappedComponent: ComponentType<P>) {
  const WithRedirect = (props: P) => {
    const url = Linking.useURL();
    const os = useDetectOs();

    useEffect(() => {
      if (os === 'Desktop') {
        window.location.replace('https://www.joinbandit.com');
      }
    }, [url, os]);

    return <WrappedComponent {...props} />;
  };

  WithRedirect.displayName = `withRedirectDesktopToWeb(${getDisplayName(WrappedComponent)})`;
  return WithRedirect;
}

function getDisplayName(WrappedComponent: React.ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
