import { ScrollView, TouchableOpacity } from 'react-native';
import { List, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { IProducer } from 'src/screens/SearchScreen/model';

type BrandProps = {
  clickHandler: (name: any) => void;
  brands: IProducer[];
};

const Brands = ({ brands, clickHandler }: BrandProps) => {
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false} testID="SCROLL.BRANDS">
      {brands.map((brand) => (
        <TouchableOpacity key={brand.organizationName} style={tw`mr-4`} onPress={() => clickHandler(brand)}>
          <List.Image variant="image" source={{ uri: brand.logoUrl }} style={tw`border rounded-full w-12 h-12`} />
          <Text style={tw`mt-2 text-center`} variant="labelMedium">
            {brand.organizationName}
          </Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

export default Brands;
