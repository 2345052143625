import { GroupMemberListScreen, ProductDetailScreen } from '@screens';
import IScreen from '../IScreen';
import { ProductDetailParamList } from './productDetailRoutes.model';

export const ProductDetailRoutes: IScreen<ProductDetailParamList>[] = [
  {
    name: 'ProductDetailScreen',
    component: ProductDetailScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'GroupMemberListScreen',
    component: GroupMemberListScreen,
    options: {
      headerShown: false,
    },
  },
];

export default ProductDetailRoutes;
