import { Brands, Categories, Tags } from '@components';
import { i18n } from '@core';
import { FC } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { ICategory, IProducer, ITag } from '../../model';

interface SearchResultFooter {
  brands: IProducer[];
  categories: ICategory[];
  tags: ITag[];
  clickItem: (type: string, name: any) => void;
}

export const SearchResultFooter: FC<SearchResultFooter> = ({ brands, categories, tags, clickItem }) => {
  return (
    <>
      {brands.length > 0 && (
        <View style={tw`mt-10`}>
          <Text variant="headlineSmall" style={tw`mb-5`}>
            {i18n.t('search_result_brands')}
          </Text>
          <Brands brands={brands} clickHandler={(brand) => clickItem('producers', brand)} />
        </View>
      )}
      {categories.length > 0 && (
        <View style={tw`mt-10`}>
          <Text
            variant="headlineSmall"
            style={[tw`mb-2.5 leading-5 text-black font-normal text-4.5`, { fontFamily: 'TheFuture-Regular' }]}
          >
            {i18n.t('search_result_categories')}
          </Text>
          <Categories list={categories} clickHandler={(category) => clickItem('categories', category)} />
        </View>
      )}
      {tags.length > 0 && (
        <View style={tw`mt-10`}>
          <Text variant="headlineSmall" style={tw`mb-5`}>
            {i18n.t('search_result_tags')}
          </Text>
          <Tags tags={tags} clickHandler={(tag) => clickItem('tags', tag)} />
        </View>
      )}
    </>
  );
};
