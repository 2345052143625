/* eslint-disable complexity */
import { Loading } from '@components';
import { i18n, useBanditTheme } from '@core';
import { ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { Box, HistoryList } from './components';
import useBCHook from './hooks/useBCHook';

const BCModalScreen = () => {
  const { colors } = useBanditTheme();

  const { data, loading, coinAmount } = useBCHook();

  return (
    <View style={tw`flex-1 justify-end`} testID="LAYOUT.ALERT">
      <View style={[tw`w-full relative bg-white rounded-tl-2xl rounded-tr-2xl`, { height: '92%' }]}>
        <View style={tw`w-12 h-1 bg-black mx-auto mt-4`} />
        {loading ? (
          <Loading />
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={tw`mt-4 mx-auto text-lg pt-4 pb-0`}>{i18n.t('banditCash_your_bandit_balance_title')}</Text>

            <View style={tw`px-4 py-2`}>
              <HistoryList amount={coinAmount.toString()} list={data} />
            </View>

            <Text style={tw`mx-auto pt-4 text-base font-normal`}>Ways to grab Bandit Cash!</Text>
            <View style={tw`px-4 flex`}>
              <Box
                title={i18n.t('banditCash_invite_your_friends')}
                description={i18n.t('banditCash_invite_your_friends_description', { coinAmount: 50 })}
              />
              <Box
                title={i18n.t('banditCash_share_products_with_friends')}
                description={i18n.t('banditCash_share_products_with_friends_description')}
                titleBgColor={colors.secondary_fuschia_tint}
              />
              <Box title={i18n.t('banditCash_coin_expire')} titleBgColor={colors.secondary_ember_tint} titleSize="sm" />
            </View>
          </ScrollView>
        )}
      </View>
    </View>
  );
};
export default BCModalScreen;
