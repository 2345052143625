import { gql, useQuery } from '@apollo/client';
import { OrderStatus } from '@core';

export const GetCart = gql`
  query GetCart($where: CartsBoolExp) {
    carts(where: $where) {
      id
      status
      payment {
        discount
        paymentTransactions {
          paymentMethod
          amount
          type
        }
        groupLeaderDiscount
      }
    }
  }
`;

type Cart = {
  id: string;
  status: OrderStatus;
  payment: {
    discount: number;
    paymentTransactions: {
      paymentMethod: string;
      amount: number;
      type: string;
    }[];
    groupLeaderDiscount: number;
  };
};

export interface CartResponse {
  carts: Cart[];
}

export default function useCart({ cartId, pollInterval = 0 }: { cartId: string; pollInterval?: number }) {
  const { data, error, loading } = useQuery<CartResponse, { where: { id: { _eq: string } } }>(GetCart, {
    variables: { where: { id: { _eq: cartId } } },
    pollInterval,
  });

  return { cart: data?.carts[0], loading, error };
}
