import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders {
    myOrders(where: { status: { _eq: "FULFILLED" } }) {
      orderId
      orderRef
      productType {
        name
      }
    }
  }
`;

export const CREATE_INQUIRY = gql`
  mutation CreateInquiry($feedback: String!, $orderId: String!) {
    inquiryCreate(request: { feedback: $feedback, orderId: $orderId }) {
      success
    }
  }
`;
