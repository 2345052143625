/* eslint-disable complexity */
import { i18n, ProductActiveGroupOrdersMembers, useBanditTheme } from '@core';
import { useCountdown } from '@hooks';
import { ClockIcon, ProfileBig } from '@svgIcons';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Avatar, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

const IMAGE_SIZE = 64;

interface IGroupTimerCardProps {
  creator: ProductActiveGroupOrdersMembers;
  photoUrl: string | null;
  numberOfMembers: number;
  groupSize: number;
  expiredDate: string;
  isInvited?: boolean;
  showHeader?: boolean;
  onPress?: () => void;
}

const GroupTimerCard = ({
  creator,
  groupSize,
  expiredDate,
  photoUrl,
  numberOfMembers,
  isInvited = false,
  showHeader = true,
  onPress,
}: IGroupTimerCardProps) => {
  const { timeLeft, lessThanTenMins } = useCountdown({
    timeout: expiredDate,
    autoFormat: true,
    formatSpacing: 'normal',
  });

  const { colors } = useBanditTheme();

  const bgColor = lessThanTenMins ? colors.secondary_ember_tint : colors.secondary_fuschia_tint;

  return (
    <View style={tw`border-${showHeader ? 2 : 0} rounded-xl w-full`}>
      {showHeader && (
        <Text style={[tw`text-sm px-4 py-3`, { fontFamily: 'TheFuture-Regular' }]}>
          {isInvited ? `${creator.name}${i18n.t('product_detail_invited_group')}` : i18n.t('product_detail_your_group')}
        </Text>
      )}
      <TouchableOpacity
        testID="group-timer-card"
        style={tw`flex-row items-center justify-between pl-3 pr-5 py-4 bg-[${bgColor}] border-${
          showHeader ? 't-2' : '2'
        } rounded-${showHeader ? 'b' : 't'}-xl`}
        disabled={isInvited || !onPress}
        onPress={onPress}
      >
        <View style={tw`flex-row items-center`}>
          <View style={tw`border${photoUrl ? '-2' : ''} rounded-full self-start mr-3`}>
            {photoUrl ? (
              <Avatar.Image accessibilityRole="image" source={{ uri: photoUrl }} size={IMAGE_SIZE} />
            ) : (
              <ProfileBig
                testID="default-profile-image"
                accessibilityRole="image"
                width={IMAGE_SIZE + 2}
                height={IMAGE_SIZE + 2}
                viewBox="0 0 82 82"
              />
            )}
          </View>

          <Text style={{ height: Platform.OS === 'android' ? 24 : 'auto' }}>
            <Text style={[tw`text-3xl`, { fontFamily: 'TheFuture-Medium' }]}>{numberOfMembers || 0}</Text>
            <Text style={[tw`text-xl`, { fontFamily: 'TheFuture-Medium' }]}>/{groupSize}</Text>
          </Text>
        </View>
        <View style={tw`flex-row items-center`}>
          <Text style={{ fontFamily: 'TheFuture-Regular', fontSize: 30 }}>{timeLeft}</Text>
          <ClockIcon style={tw`ml-3`} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default GroupTimerCard;
