export interface IMessage {
  message: string;
  description?: string;
  type: MessageType;
  options?: IMessageOptions;
}

export interface IMessageOptions {
  autoHide?: boolean;
}

export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}
export interface IMessageState {
  message: IMessage | null;
  setInfoMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setSuccessMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setWarningMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setDangerMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  clearMessage: () => void;
}

export interface IMessage {
  message: string;
  description?: string;
  type: MessageType;
  options?: IMessageOptions;
}
