import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { IGroupProgressResponse } from '../../model';
import { GET_GROUP_PROGRESS_BY_ID } from '../../query';

interface IUseGroupProgressHookProps {
  activeOrderId: string;
}

const useGroupProgressHook = ({ activeOrderId }: IUseGroupProgressHookProps) => {
  const [getActiveOrderById, { data, loading }] = useLazyQuery<IGroupProgressResponse, { activeOrderId: string }>(
    GET_GROUP_PROGRESS_BY_ID,
    { fetchPolicy: 'cache-and-network' },
  );

  useEffect(() => {
    if (activeOrderId) {
      getActiveOrderById({
        variables: { activeOrderId: activeOrderId },
      });
    }
  }, [activeOrderId]);

  return { data: data?.productTypes?.[0], loading };
};

export default useGroupProgressHook;
