import { gql } from '@apollo/client';

const fields = `
  soloPrice
  groupPrice
  videoUrl
  shortDescription
  description
  id
  name
  categoryName
  currency
  activeOrders
  banditCashDiscount
  productType {
    brandName
    groupSize
  }
  producer {
    organizationName
    logoUrl
    description
  }
  activeGroupOrders {
    id
    expiredDate
  }
`;

export const GET_VIDEO_FEED = gql`
  query getVideoFeed {
    productTypeVideoGeneralFeed(where: { videoUrl: { _neq: "" } }) {
      ${fields}
    }
  }
`;

export const GET_USER_VIDEO_FEED = gql`
  query GetUserProducts {
    productTypeVideoUserFeed(where: { videoUrl: { _neq: "" } }) {
      ${fields} 
    }
  }
`;
