/* eslint-disable max-lines */
import { getIsMuted, storeIsMuted } from '@core';
import { useIsFocused } from '@react-navigation/native';
import { FlashList, ListRenderItem } from '@shopify/flash-list';
import { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  View,
  ViewToken,
} from 'react-native';
import Loading from 'src/components/Loading/Loading';
import EndOfFeedScreen from '../EndOfFeedScreen/EndOfFeedScreen';
import VideoWithDetails from './components/VideoWithDetails/VideoWithDetails';
import useVideoFeedHook from './hooks/useVideoFeedHook';
import { IVideo, MediaRef } from './model';

const VideoScreen = () => {
  const { feedData, isLoading, pullToRefresh } = useVideoFeedHook();
  const [isEndReached, setIsEndReached] = useState(false);
  const videoFeedRef = useRef<FlashList<IVideo>>(null);

  const isFocused = useIsFocused();

  const [height, setHeight] = useState(0);

  const mediaRefs = useRef<{ [key: string]: MediaRef }>({});

  const [isMuted, setIsMuted] = useState(false);

  const currentVideo = useRef<string>();
  const data =
    feedData?.type === 'authenticated' ? feedData.productTypeVideoUserFeed : feedData?.productTypeVideoGeneralFeed;

  const onViewableItemsChanged = ({ changed }: { changed: ViewToken[] }) => {
    changed.forEach((element, i) => {
      if (mediaRefs.current) {
        const cell = mediaRefs.current[element.key];
        if (cell) {
          if (element.isViewable) {
            currentVideo.current = element.key;
            cell.play();
          } else {
            cell.stop();
          }
        }
      }
    });
  };

  useEffect(() => {
    if (isFocused && currentVideo.current) {
      const cell = mediaRefs.current[currentVideo.current];
      cell?.play();
    }
  }, [data, isFocused]);

  const handleMuteChange = async () => {
    const updatedIsMuted = !isMuted;
    setIsMuted(updatedIsMuted);
    await storeIsMuted(updatedIsMuted);
  };

  const setInitialMute = async () => {
    const initialMute = await getIsMuted();
    setIsMuted(initialMute);
  };

  useEffect(() => {
    setInitialMute();
  }, []);

  const renderItem: ListRenderItem<IVideo> = ({ item, index }) => {
    return (
      <View style={{ height: height }} key={item.id}>
        {isFocused && (
          <VideoWithDetails
            ref={(videoRef) => {
              if (videoRef) {
                mediaRefs.current[item.id] = videoRef;
              }
            }}
            data={item}
            index={index}
            isMuted={isMuted}
            handleMuteChange={() => handleMuteChange()}
          />
        )}
      </View>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <View
      onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
      style={
        Platform.OS === 'web'
          ? {
              flex: 1,
              alignSelf: 'center',
              maxWidth: '100%',
              width: Dimensions.get('screen').width,
              backgroundColor: 'black',
            }
          : { flex: 1, width: Dimensions.get('screen').width }
      }
    >
      {/* <LinearGradient
        style={{
          position: 'absolute',
          zIndex: 15,
          left: 0,
          right: 0,
          top: 0,
          height: 100,
        }}
        pointerEvents="none"
        colors={['rgba(0, 0, 0, 0.2)', 'rgba(0, 0, 0, 0.15)', 'rgba(0, 0, 0, 0.05)', 'rgba(0, 0, 0, 0) 100%']}
      />
      <View
        style={[
          tw`absolute top-0 w-full flex-row justify-center items-center z-20`,
          {
            marginTop: insets.top,
          },
        ]}
        pointerEvents="none"
      >
        <AppLogo style={tw`my-2`} logoColor="white" />
      </View> */}

      {height != 0 && (
        <FlashList
          renderItem={renderItem}
          data={data}
          estimatedItemSize={height}
          removeClippedSubviews
          viewabilityConfig={{
            viewAreaCoveragePercentThreshold: 90,
          }}
          keyExtractor={(item) => item.id}
          refreshControl={<RefreshControl refreshing={isLoading} onRefresh={pullToRefresh} />}
          snapToAlignment="start"
          decelerationRate="fast"
          snapToInterval={height}
          extraData={[isMuted, isFocused]}
          onViewableItemsChanged={onViewableItemsChanged}
          ListFooterComponent={
            <EndOfFeedScreen
              scrollToTop={() => {
                if (videoFeedRef.current) {
                  videoFeedRef?.current.scrollToOffset({ offset: 0, animated: true });
                }
              }}
              isEndReached={isEndReached}
            />
          }
          ref={videoFeedRef}
          onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
            const END_OF_SCREEN_OFFSET = 5;
            const isAtEnd =
              event.nativeEvent.contentSize.height -
                (event.nativeEvent.contentOffset.y + event.nativeEvent.layoutMeasurement.height) <=
              END_OF_SCREEN_OFFSET;

            if (isEndReached !== isAtEnd) {
              setIsEndReached(isAtEnd);
            }
          }}
        />
      )}
    </View>
  );
};

export default VideoScreen;
