import { useQuery } from '@apollo/client';
import { MyOrders } from '../../model';
import { GET_ORDERS } from '../../query';

const useGetOrdersHook = () => {
  const { data, loading } = useQuery<MyOrders>(GET_ORDERS, { fetchPolicy: 'cache-and-network' });

  return {
    orders: data?.myOrders,
    loading,
  };
};

export default useGetOrdersHook;
