import {
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  ResizeMode,
  Video,
  VideoFullscreenUpdate,
  VideoFullscreenUpdateEvent,
} from 'expo-av';
import { RefObject, useState } from 'react';
import { tw } from 'src/core/tw';

interface IFloatingVideoProps {
  videoUrl: string;
  videoRef: RefObject<Video>;
  videoSize: 'large' | 'mini';
}

const FloatingVideo = ({ videoUrl, videoRef, videoSize }: IFloatingVideoProps) => {
  const [isMuted, setIsMuted] = useState(true);

  const [isPlaying, setIsPlaying] = useState(true);

  const handleShowFullScreenVideo = () => {
    if (videoSize === 'large') {
      videoRef.current?.presentFullscreenPlayer();
    }
  };

  const handleUpdateStatus = (status: AVPlaybackStatus) => {
    const { isMuted: isMutedStatus, isPlaying: isPlayingStatus } = status as AVPlaybackStatusSuccess;

    setIsMuted(isMutedStatus);
    setIsPlaying(isPlayingStatus);
  };

  const handleFullScreenUpdate = (event: VideoFullscreenUpdateEvent) => {
    if (event.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_DISMISS) {
      if (isPlaying) {
        videoRef.current?.playAsync();
      }
    }
  };

  return (
    <Video
      ref={videoRef}
      testID="floating-video"
      source={{ uri: videoUrl }}
      style={tw`w-full h-full border-2 rounded-xl bg-black`}
      resizeMode={ResizeMode.COVER}
      onPlaybackStatusUpdate={handleUpdateStatus}
      onFullscreenUpdate={handleFullScreenUpdate}
      onTouchEnd={handleShowFullScreenVideo}
      isMuted={isMuted}
      shouldPlay
      isLooping
    />
  );
};

export default FloatingVideo;
