import { ApolloError, useMutation } from '@apollo/client';
import {
  getInvitedBy,
  i18n,
  IJoinOrderRequest,
  IJoinOrderResponse,
  useProductDetailsStore,
  UseProductDetailsStore,
} from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { shallow } from 'zustand/shallow';
import usePaymentSession from '../../../../domain/payment/hooks/usePaymentSession/usePaymentSession';
import { JOIN_GROUP } from '../../query';
import useModalState from '../useModalState/useModalState';

const PRODUCT_QUANTITY = 1;

const selector = ({ setCartId, setOrderPlacedId }: UseProductDetailsStore) => ({ setCartId, setOrderPlacedId });

interface IJoinOrderParams {
  productId: string;
  orderId: string;
  banditCash: number;
}

const useJoinGroupOrder = () => {
  const toast = useShowToast();
  const { setCartId, setOrderPlacedId } = useProductDetailsStore(selector, shallow);

  const { handleShowLoading, handleCloseLoading, showLoading } = useModalState();

  const { handleCreatePayment } = usePaymentSession();

  const [joinOrder] = useMutation<IJoinOrderResponse, IJoinOrderRequest>(JOIN_GROUP);

  const showError = (error?: ApolloError) => {
    handleCloseLoading();
    toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default));
  };

  const handleJoinOrder = async ({ productId, orderId, banditCash }: IJoinOrderParams) => {
    handleShowLoading();

    const ref = await getInvitedBy();

    joinOrder({
      variables: { productId, orderId, quantity: PRODUCT_QUANTITY, banditCash, ...(!!ref ? { invitedBy: ref } : {}) },
      onCompleted: ({ orderJoin }) => {
        if (!orderJoin?.cartId || !orderJoin?.id) {
          showError();
          return;
        }
        handleCreatePayment({ cartId: orderJoin.cartId, orderId: orderJoin.id });
        setCartId(orderJoin.cartId);
        setOrderPlacedId(orderJoin.id);
      },
      onError: (error: ApolloError) => showError(error),
    });
  };

  return { handleJoinOrder, isJoining: showLoading };
};

export default useJoinGroupOrder;
