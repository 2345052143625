import { ApolloError, gql, useMutation } from '@apollo/client';
import { i18n } from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_ACTIVE_GROUP_ORDERS } from '../../query';
import { GROUP_SCREEN_QUERY_VARIABLES } from '../useGroupHook/useGroupHook';

export const HIDE_ORDER = gql`
  mutation HidelOrder($orderId: String!) {
    orderCartInvisible(request: { orderId: $orderId }) {
      success
    }
  }
`;

export function useHideOrder(orderId: string) {
  const toast = useShowToast();
  const showError = (errorMessage?: string) => {
    toast.danger(errorMessage || i18n.t(ERROR_MESSAGES.default));
  };

  const [hideOrder, { loading }] = useMutation(HIDE_ORDER, {
    variables: { orderId },
    refetchQueries: [{ query: GET_ACTIVE_GROUP_ORDERS, variables: GROUP_SCREEN_QUERY_VARIABLES }],
    onError: (error: ApolloError) => showError(error.message),
  });

  return { hide: () => hideOrder({ variables: { orderId } }), loading };
}
