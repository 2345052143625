import { i18n } from '@core';
import { FC } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { PriceDifference } from './PriceDifference';

interface GroupInfoMessageProps {
  soloPrice: number;
  startGroupPrice: number;
  groupPrice: number;
  displayCurrency: string;
}

export const GroupInfoMessage: FC<GroupInfoMessageProps> = ({
  soloPrice,
  startGroupPrice,
  groupPrice,
  displayCurrency,
}) => {
  const discount = i18n.t('purchase_modal_start_group_leader_discount');
  const endOfMessage = i18n.t('purchase_modal_start_group_leader_end');

  return (
    <View style={tw`w-full flex flex-1`}>
      <Text>
        <Text>{i18n.t('purchase_modal_start_group_leader_price_message')}</Text>
        <Text style={tw`text-sm font-bold`}>{` ${discount} `}</Text>
        <Text>
          (<PriceDifference oldPrice={soloPrice} newPrice={startGroupPrice} currency={displayCurrency} />)
        </Text>
        <Text>{`. ${endOfMessage} `}</Text>
        <Text>
          <PriceDifference oldPrice={soloPrice} newPrice={groupPrice} currency={displayCurrency} />
          <Text>.</Text>
        </Text>
      </Text>
    </View>
  );
};
