/* eslint-disable max-params */
import { useMemo } from 'react';
import { IMessageOptions } from 'src/core/store/message/types';
import { useMessageState } from 'src/core/store/message/useMessageStore';

export const useShowToast = () => {
  const setInfoMessage = useMessageState((state) => state.setInfoMessage);
  const setSuccessMessage = useMessageState((state) => state.setSuccessMessage);
  const setWarningMessage = useMessageState((state) => state.setWarningMessage);
  const setDangerMessage = useMessageState((state) => state.setDangerMessage);

  const toast = useMemo(
    () => ({
      info: (message: string, description?: string, options?: IMessageOptions) =>
        setInfoMessage(message, description, options),
      success: (message: string, description?: string, options?: IMessageOptions) =>
        setSuccessMessage(message, description, options),
      warning: (message: string, description?: string, options?: IMessageOptions) =>
        setWarningMessage(message, description, options),
      danger: (message: string, description?: string, options?: IMessageOptions) =>
        setDangerMessage(message, description, options),
    }),
    [setInfoMessage, setSuccessMessage, setWarningMessage, setDangerMessage],
  );

  return toast;
};
