import Svg, { Path, SvgProps } from 'react-native-svg';

export const AppLogo = ({
  logoColor = 'black',
  ...props
}: SvgProps & {
  logoColor?: string;
}) => (
  <Svg width={120} height={32} fill="none" {...props} testID="AppLogo">
    <Path
      fill={logoColor}
      d="M59.66 17.035c-1.87 0-2.865 1.433-2.865 3.144v11.343h-7.403V10.905h7.403v1.99c1.194-1.313 3.105-2.467 5.652-2.467 4.497 0 7.482 2.945 7.482 7.642v13.452h-7.403V20.18c0-1.711-.995-3.144-2.865-3.144ZM103.427 10.905v20.617h-7.404V10.905h7.404ZM104.725 10.905h3.343V4.537h7.403v6.368h4.1v6.368h-4.1v14.25h-7.403v-14.25h-3.343v-6.368ZM13.413 10.428c-2.866 0-4.896 1.273-6.01 2.467V4.537H0v26.985h7.403v-1.99C8.517 30.726 10.547 32 13.413 32c5.492 0 10.11-4.577 10.11-10.786 0-6.21-4.617-10.786-10.11-10.786Zm2.746 10.786c-.541 2.547-2.835 4.537-5.422 4.537-2.587 0-4.035-1.99-3.493-4.537.541-2.547 2.835-4.537 5.422-4.537 2.587 0 4.035 1.99 3.493 4.537ZM40.319 10.905v1.99c-1.115-1.194-3.144-2.467-6.01-2.467-5.492 0-10.11 4.577-10.11 10.786S28.818 32 34.31 32c2.866 0 4.895-1.274 6.01-2.468v1.99h7.403V10.905H40.32Zm.16 10.309c-.542 2.547-2.836 4.537-5.423 4.537s-4.035-1.99-3.493-4.537c.54-2.548 2.835-4.538 5.422-4.538 2.587 0 4.035 1.99 3.493 4.538ZM87.082 4.537v8.358c-1.115-1.194-3.144-2.467-6.01-2.467-5.493 0-10.11 4.577-10.11 10.786S75.58 32 81.073 32c2.865 0 4.895-1.274 6.01-2.468v1.99h7.403V4.537h-7.403Zm.159 16.677c-.541 2.547-2.835 4.537-5.422 4.537-2.587 0-4.035-1.99-3.493-4.537.541-2.547 2.835-4.537 5.422-4.537 2.586 0 4.034 1.99 3.493 4.537ZM99.519 9.075c2.587 0 4.881-1.99 5.422-4.538.542-2.547-.906-4.537-3.493-4.537-2.587 0-4.88 1.99-5.422 4.537-.542 2.548.906 4.538 3.493 4.538Z"
    />
  </Svg>
);

export default AppLogo;
