import AsyncStorage from '@react-native-async-storage/async-storage';
import { STORAGE_KEY } from 'src/core/constant/storage';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import {
  FullScreenImagesSlice,
  IImage,
  ISelectedVariant,
  ModalStateSlice,
  OrderplacedSlice,
  ProductDetailModalState,
  ProductDetailsStateSlice,
  ProductDetailsStateValues,
  PurchaseState,
  PurchaseStateSlice,
  SelectedActiveGroupSlice,
  SelectedProductSlice,
  SelectedVariantsSlice,
  UseProductDetailsStore,
} from '../../../core/types';

const initailValues: ProductDetailsStateValues = {
  modalState: null,
  showLoading: false,
  fullScreenImageState: { images: [], activeIndex: 0 },
  selectedVariants: null,
  selectedProductId: null,
  purchaseState: 'soloBuy',
  canPurchase: { solo: true, group: true },
  cartId: null,
  orderPlacedId: null,
  orderId: null,
};

const createProductDetailsStateSlice: StateCreator<UseProductDetailsStore, [], [], ProductDetailsStateSlice> = (
  set,
) => ({
  resetAllState: () => set({ ...initailValues }),
  resetAfterPayment: () => {
    const { cartId, orderPlacedId, ...rest } = initailValues;
    set({ ...rest });
  },
});

const createModalStateSlice: StateCreator<UseProductDetailsStore, [], [], ModalStateSlice> = (set) => ({
  modalState: null,
  showLoading: false,
  setModalState: (newState: ProductDetailModalState) => set({ modalState: newState }),
  setShowLoading: (value: boolean) => set({ showLoading: value }),
});

const createFullScreenImageSlice: StateCreator<UseProductDetailsStore, [], [], FullScreenImagesSlice> = (set) => ({
  fullScreenImageState: { images: [], activeIndex: 0 },
  setFullScreenImageState: (images: IImage[], index: number) =>
    set({ fullScreenImageState: { images, activeIndex: index } }),
});

const createSelectedVariantsSlice: StateCreator<UseProductDetailsStore, [], [], SelectedVariantsSlice> = (set) => ({
  selectedVariants: null,
  setSelectedVariants: (variants: ISelectedVariant | null) => set({ selectedVariants: variants }),
});

const createSelectedProductIdSlice: StateCreator<UseProductDetailsStore, [], [], SelectedProductSlice> = (set) => ({
  selectedProductId: null,
  setSelectedProductId: (id: string | null) => set({ selectedProductId: id }),
});

const createPurchaseStateSlice: StateCreator<UseProductDetailsStore, [], [], PurchaseStateSlice> = (set) => ({
  purchaseState: 'soloBuy',
  canPurchase: { solo: true, group: true },
  setPurchseState: (state: PurchaseState) => set({ purchaseState: state }),
  setCanPurchase: (canPurchase: { solo: boolean; group: boolean }) => set({ canPurchase }),
});

const createOrderPlacedSlice: StateCreator<UseProductDetailsStore, [], [], OrderplacedSlice> = (set) => ({
  cartId: null,
  orderPlacedId: null,
  setCartId: (cartId: string | null) => set({ cartId }),
  setOrderPlacedId: (id: string | null) => set({ orderPlacedId: id }),
});

const createSelectedActiveGroupSlice: StateCreator<UseProductDetailsStore, [], [], SelectedActiveGroupSlice> = (
  set,
) => ({
  orderId: null,
  setOrderId: (id: string | null) => set({ orderId: id }),
});

const useProductDetailsStore = create<UseProductDetailsStore>()(
  devtools(
    persist(
      (...a) => ({
        ...createProductDetailsStateSlice(...a),
        ...createModalStateSlice(...a),
        ...createFullScreenImageSlice(...a),
        ...createSelectedVariantsSlice(...a),
        ...createSelectedProductIdSlice(...a),
        ...createPurchaseStateSlice(...a),
        ...createOrderPlacedSlice(...a),
        ...createSelectedActiveGroupSlice(...a),
      }),
      {
        name: STORAGE_KEY.PURCHASE_DATA,
        storage: createJSONStorage(() => AsyncStorage),
        partialize: ({ purchaseState, cartId, orderPlacedId }) => ({ purchaseState, cartId, orderPlacedId }),
      },
    ),
  ),
);

export default useProductDetailsStore;
