import { i18n } from '@core';
import { useState } from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

const DESCRIPTION_LIMIT = 60;

interface IExpandableDescriptionProps {
  description: string;
  charactersLimit?: number;
  style?: StyleProp<ViewStyle>;
  textColor?: string;
  showReadMore?: boolean;
}

const ExpandableDescription = ({
  description,
  charactersLimit = DESCRIPTION_LIMIT,
  style,
  textColor = 'black',
  showReadMore = true,
}: IExpandableDescriptionProps) => {
  const shouldExpandable = description.length > charactersLimit;

  const [isReadMore, setIsReadMore] = useState(!shouldExpandable);

  const displayDescription = isReadMore ? description : `${description.substring(0, charactersLimit)}... `;

  const toggleReadMore = (value: boolean) => setIsReadMore(value);

  return (
    <Pressable
      style={[tw`flex-row flex-wrap`, style]}
      onPress={() => toggleReadMore(!shouldExpandable ? true : !isReadMore)}
    >
      <Text style={[tw`text-sm leading-5 font-thefuture_regular`, { color: textColor }]}>
        {displayDescription}
        {showReadMore && !isReadMore && (
          <Pressable
            accessibilityRole="button"
            style={{ position: 'relative', height: 17 }}
            onPress={() => toggleReadMore(true)}
          >
            <Text
              style={[
                tw`text-sm font-medium font-thefuture_medium`,
                {
                  color: textColor,
                },
              ]}
            >
              {i18n.t('read_more')}
            </Text>
            <View style={tw`h-4.5 w-18 absolute top-1 border-b-2`}></View>
          </Pressable>
        )}
      </Text>
    </Pressable>
  );
};

export default ExpandableDescription;
