import { i18n, useBanditTheme } from '@core';
import { CoinLogo, Pencil, ProfileBig } from '@svgIcons';
import { TouchableOpacity, View } from 'react-native';
import { Avatar, Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface LoggedInProfileProps {
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string | null;
  city: string;
  coinAmount: string;
  goToBCModal: () => void;
  editProfileImage: () => void;
}

const LoggedInProfile = ({
  firstName,
  lastName,
  editProfileImage,
  city,
  photoUrl,
  coinAmount,
  goToBCModal,
}: LoggedInProfileProps) => {
  const { colors } = useBanditTheme();
  return (
    <View style={tw`border-2 rounded-lg bg-white`}>
      <View style={tw`py-2 flex flex-row items-center px-2`}>
        <View style={[tw`mx-1 rounded-full`, tw.style(photoUrl && 'border  border-black ')]}>
          {photoUrl ? (
            <Avatar.Image accessibilityRole="image" size={82} source={{ uri: photoUrl }} />
          ) : (
            <ProfileBig accessibilityRole="image" />
          )}
          <TouchableOpacity
            accessibilityRole="button"
            onPress={editProfileImage}
            style={tw`absolute bottom-0 -right-3 border-2 border-black w-6 h-6 rounded-full justify-center items-center`}
          >
            <Pencil />
          </TouchableOpacity>
        </View>
        <View style={tw`mx-3`}>
          <Text style={[tw`text-lg`, { fontFamily: 'TheFuture-Medium' }]}>
            {firstName} {lastName}
          </Text>
          <Text variant="bodyMedium">{city}</Text>
        </View>
      </View>
      <TouchableOpacity
        onPress={goToBCModal}
        style={[
          tw`py-4 px-4 border-t-2 mt-2 flex flex-row justify-between items-center rounded-bl-lg rounded-br-lg`,
          { backgroundColor: colors.secondary_fuschia_tint },
        ]}
      >
        <View style={tw`border-b`}>
          <Text variant="labelLarge">{i18n.t('profile_banditCash_text')}</Text>
        </View>
        <View style={tw`flex-row items-center`}>
          <Text style={tw`mr-2`} variant="labelLarge">
            {coinAmount || 0}
          </Text>
          <CoinLogo />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default LoggedInProfile;
