import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { videoColors } from 'src/core/theme/color';
export const Z_INDEX = 100;
export const MARGIN = 13;
export const OFFSET_WIDTH = 4;
export interface IStyle {
  container: ViewStyle;
  tooltip: ViewStyle;
  tooltipText: TextStyle;
  tooltipContainer: ViewStyle;
  button: ViewStyle;
  buttonText: TextStyle;
  bottomBar: ViewStyle;
  overlayContainer: ViewStyle;
  nonInteractionPlaceholder: ViewStyle;
}
export default StyleSheet.create<IStyle>({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: Z_INDEX,
  },
  tooltip: {
    position: 'absolute',
    paddingHorizontal: 15,
    overflow: 'hidden',
    width: '100%',
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 16,
    zIndex: Z_INDEX - 1,
  },
  nonInteractionPlaceholder: {
    backgroundColor: 'transparent',
    zIndex: Z_INDEX - 2,
  },
  tooltipText: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'TheFuture-Regular',
  },
  tooltipContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 10,
    backgroundColor: videoColors.primaryColor,
  },
  button: {
    padding: 15,
  },
  buttonText: {
    color: '#000',
    textDecorationLine: 'underline',
    paddingBottom: 15,
  },
  bottomBar: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  overlayContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
});
