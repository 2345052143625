import Constants from 'expo-constants';
import { Platform } from 'react-native';

const constants = Constants.expoConfig?.extra;
export const useProxy =
  constants?.APP_ENV === 'development'
    ? Platform.select({ web: false, default: true })
    : Platform.select({ web: false, default: false });

export default useProxy;
