import { useQuery } from '@apollo/client';
import { MyOrdersResponse } from '../types';
import { GetMyOrders } from './useOrder';

export default function useOrderHistory() {
  const { data, error, loading } = useQuery<MyOrdersResponse>(GetMyOrders, {
    variables: {},
  });

  return { orders: data?.myOrders, loading, error };
}
