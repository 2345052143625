import { ApolloError, gql, useMutation } from '@apollo/client';
import { i18n } from '@core';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';

interface UseCancelOrderProps {
  cartId: string;
  orderId: string;
  onSuccessCallback?: () => void;
}

const mutation = gql`
  mutation CancelOrder($orderId: String!, $cartId: String!) {
    cartCancel(request: { orderId: $orderId, cartId: $cartId }) {
      success
      message
    }
  }
`;

const useCancelOrder = ({ cartId, orderId, onSuccessCallback }: UseCancelOrderProps) => {
  const toast = useShowToast();
  const showError = (errorMessage?: string) => {
    toast.danger(errorMessage || i18n.t(ERROR_MESSAGES.default));
  };

  const [cancelOrder, { loading }] = useMutation(mutation, {
    variables: { orderId, cartId },
    onCompleted: ({ cartCancel }) => {
      if (!cartCancel.success) {
        showError(cartCancel?.message);
        return;
      }
      onSuccessCallback?.();
    },
    onError: (error: ApolloError) => showError(error.message),
  });

  return { cancelOrder, loading };
};

export default useCancelOrder;
