/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($productId: String!) {
    productTypes(where: { id: { _eq: $productId } }) {
      id
      videoUrl
      imageUrls {
        name
        carouselUrl
        originalUrl
        splitUrl
      }
      name
      brandName
      producer {
        organizationName
        description
        email
        imageUrls {
          name
          carouselUrl
          originalUrl
        }
      }
      groupPrice
      soloPrice
      currency
      description
      tags {
        name
      }
      products {
        id
        inventoryStock
        options {
          optionName
          optionValue
        }
      }
      faqs {
        question
        answer
      }
      groupSize
      banditCashDiscount
      hasOption
    }
  }
`;

export const GET_PRODUCT_ACTIVE_GROUPS = gql`
  query getProductActiveGroups($productId: String!, $currentTimestamp: timestamp!) {
    productTypes(where: { id: { _eq: $productId }, activeGroupOrders: { expiredDate: { _gt: $currentTimestamp } } }) {
      activeGroupOrders {
        id
        creator {
          photoUrl
          name
          joinAt
        }
        numberOfMembers
        members(where: { status: { _in: ["PENDING", "OPEN"] } }) {
          photoUrl
          name
          joinAt
        }
        expiredDate
      }
    }
  }
`;

export const GET_PRODUCT_MY_ACTIVE_GROUP = gql`
  query getProductMyActiveGroup($productId: String!, $userId: String!, $currentTimestamp: timestamp!) {
    activeGroupOrders(
      where: {
        productTypeId: { _eq: $productId }
        members: { _and: [{ userId: { _eq: $userId }, status: { _in: ["OPEN"] } }] }
        expiredDate: { _gte: $currentTimestamp }
      }
    ) {
      id
      creator {
        name
        city
        photoUrl
        joinAt
      }
      numberOfMembers
      expiredDate
      status
      members {
        name
        city
        photoUrl
        joinAt
      }
    }
  }
`;

export const GET_ACTIVE_GROUPS_BY_ID = gql`
  query GetActiveGroups($id: String!) {
    activeGroupOrders(where: { id: { _eq: $id } }) {
      id
      creator {
        city
        userId
        name
        photoUrl
        joinAt
      }
      numberOfMembers
      expiredDate
      status
      members(orderBy: { joinAt: desc }, where: { status: { _in: ["PENDING", "OPEN"] } }) {
        userId
        name
        photoUrl
        joinAt
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($productId: String!, $orderType: OrderType!, $quantity: Int!, $banditCash: Int!) {
    orderCreate(
      request: { productId: $productId, orderType: $orderType, quantity: $quantity, banditCash: $banditCash }
    ) {
      id
      orderStatus
      cartId
    }
  }
`;

export const JOIN_GROUP = gql`
  mutation JoinGroup($orderId: String!, $productId: String!, $quantity: Int!, $banditCash: Int!, $invitedBy: String) {
    orderJoin(
      request: {
        orderId: $orderId
        productId: $productId
        quantity: $quantity
        banditCash: $banditCash
        invitedBy: $invitedBy
      }
    ) {
      id
      orderStatus
      cartId
    }
  }
`;

export const GET_PAYMENT_SESSION = gql`
  query GetPaymentSession($cartId: String!, $returnUrl: String!) {
    paymentSession(request: { cartId: $cartId, returnUrl: $returnUrl }) {
      session {
        id
        sessionData
        expiresAt
      }
    }
  }
`;

export const GET_USER_BANDIT_CASH = gql`
  query getBCHistory($currentTime: timestamp!) {
    banditCash(where: { expiringAt: { _gte: $currentTime } }) {
      credit
    }
  }
`;

export const GET_REMAINING_INVENTORY = gql`
  query GetRemainingInventory($productId: String!) {
    productTypes(where: { id: { _eq: $productId } }) {
      id
      remainingInventory
      groupSize
    }
  }
`;
