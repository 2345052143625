/* eslint-disable complexity */
import { ApolloClient, concat, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { refreshAsync, TokenResponse } from 'expo-auth-session';
import { authVar, logout, storeOauthInfo } from '../constant';
import {
  AppleDiscoveryDocument,
  FacebookDiscoveryDocument,
  GoogleDiscoveryDocument,
  OauthType,
  PhoneDiscoveryDocument,
  refreshTokenConfig,
  VippsDiscoveryDocument,
} from '../constant/oauth';
import { jwtDecode } from '../helpers';
import { sendErrorToSentry } from '../telemetry/sendErrorToSentry';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {},
  },
});

const getRefreshToken = (refToken: string, loginType: OauthType): Promise<TokenResponse> => {
  const discovery = {
    google: GoogleDiscoveryDocument,
    facebook: FacebookDiscoveryDocument,
    vipps: VippsDiscoveryDocument,
    apple: AppleDiscoveryDocument,
    phone: PhoneDiscoveryDocument,
  };

  return refreshAsync({ ...refreshTokenConfig, refreshToken: refToken }, discovery[loginType]);
};

const getTokenState = () => {
  const { token } = authVar();

  if (!token) {
    return { valid: false, needRefresh: true };
  }

  const { exp } = jwtDecode(token);

  const expirationTimeInSecond = exp * 1000;

  const currentTimeInSecond = new Date().getTime();

  const isExpired = expirationTimeInSecond < currentTimeInSecond;
  if (!exp) {
    return { valid: false, needRefresh: true };
  } else if (isExpired) {
    return { valid: true, needRefresh: true };
  } else {
    return { valid: true, needRefresh: false };
  }
};

const apolloAuthLink = setContext(async (request, { headers }) => {
  const { token, refreshToken, loginType } = authVar();

  let headerToken = token;

  const { needRefresh } = getTokenState();
  if (token && needRefresh) {
    if (refreshToken) {
      try {
        const refreshPromise = await getRefreshToken(refreshToken, loginType as OauthType);
        await storeOauthInfo(refreshPromise, loginType as OauthType);
        authVar({
          token: refreshPromise.accessToken,
          refreshToken: refreshPromise?.refreshToken || null,
          loginType,
          idpToken: refreshPromise.idToken || null,
          loggedIn: true,
        });
        headerToken = refreshPromise.accessToken;
      } catch (e) {
        sendErrorToSentry(e, {
          tags: {
            login_type: loginType ?? '',
          },
        });
        await logout();
      }
    }
  }
  if (headerToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${headerToken}`,
      },
    };
  } else {
    return { headers };
  }
});

const link = (endpoint: string) => {
  return new HttpLink({
    uri: endpoint,
  });
};

const errorLink = (errorMessage: string) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    sendErrorToSentry(networkError, {
      tags: {
        operation: operation.operationName,
      },
    });
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) => {
        console.log(
          '[GraphQL error]',
          JSON.stringify(
            {
              message,
              code: extensions?.code,
              operation: operation.operationName,
            },
            null,
            2,
          ),
        );
        if (extensions.code === 'UNAUTHENTICATED' || extensions.code === 'invalid-jwt') {
          logout();
        }
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

const client = (errorMessage: string, endpoint: string) => {
  return new ApolloClient({
    cache,
    link: from([errorLink(errorMessage), concat(apolloAuthLink, link(endpoint))]),
  });
};

export default client;
