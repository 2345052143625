import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const Down = (props: SvgProps) => (
  <Svg fill="none" {...props}>
    <Path
      fill="#1C1B1F"
      d="M6 6.95c-.133 0-.258-.021-.375-.063a.872.872 0 0 1-.325-.212L.675 2.05a.891.891 0 0 1-.262-.688A.975.975 0 0 1 .7.675.948.948 0 0 1 1.4.4c.283 0 .517.092.7.275l3.9 3.9L9.925.65a.894.894 0 0 1 .688-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-4.6 4.6c-.1.1-.208.17-.325.212A1.099 1.099 0 0 1 6 6.95Z"
    />
  </Svg>
);
export default Down;
