/* eslint-disable complexity */
import { i18n, useBanditTheme } from '@core';
import Constants from 'expo-constants';
import { LegacyRef } from 'react';
import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import QuickLinks from '../QuickLinks/QuickLinks';
import BCAds from './components/BCAds/BCAds';
import LoggedInProfile from './components/LoggedInProfile/LoggedInProfile';
import Logout from './components/Logout/Logout';
import NotLoggedInProfile from './components/NotLoggedInProfile/NotLoggedInProfile';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';

interface ProfileViewProps {
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;
  city: string;
  coinAmount: string;
  loginUser: () => void;
  handleLogout: () => void;
  goToBCModal: () => void;
  onShare: () => void;
  onHideBanditAds?: (justTouch: boolean) => void;
  isAdsShow: boolean;
  onClickNotification: () => void;
  editProfileImage: () => void;
  setScrollRef: LegacyRef<ScrollView>;
}

const ProfileView = (props: ProfileViewProps) => {
  const isIOS = Platform.OS === 'ios';
  const isWeb = Platform.OS === 'web';
  const version = Constants.expoConfig?.version;
  const buildNumber = isIOS ? Constants.expoConfig?.ios?.buildNumber : Constants.expoConfig?.android?.versionCode;

  const { colors } = useBanditTheme();

  const pTop = isIOS ? 'pt-10' : 'pt-20';

  return (
    <>
      <SafeAreaView style={[tw`z-50`, { backgroundColor: colors.secondary_cosmos }]} />
      <View style={[tw`flex-1`, { backgroundColor: colors.secondary_cosmos_tint }]}>
        <View style={[tw`h-34 border-b-2 relative`, { backgroundColor: colors.secondary_cosmos }]} />
        <View style={tw`absolute z-10 -top-0 left-0 h-full right-0 mb-10`}>
          <ScrollView style={tw`px-5`} showsVerticalScrollIndicator={false} ref={props.setScrollRef}>
            <View style={tw`${pTop}`}>
              <View style={tw`w-[100%] mx-auto`}>
                {props.firstName ? <LoggedInProfile {...props} /> : <NotLoggedInProfile loginUser={props.loginUser} />}
              </View>
              <Text style={[tw`text-lg mt-6 mb-2`, { fontFamily: 'TheFuture-Regular' }]}>
                {i18n.t('profile_settings_quicklinks_title')}
              </Text>
              {props.isAdsShow && props.onHideBanditAds && <BCAds onHideBanditAds={props.onHideBanditAds} />}

              {!!props.firstName && <PersonalInfo />}
              <View style={tw.style('mb-5', { 'mt-5': !!props.firstName })}>
                <QuickLinks isLogin={!!props.email} onShare={props.onShare} />
              </View>
              {!!props.firstName && <Logout handleLogout={props.handleLogout} />}

              <Text style={tw`mt-4 mb-2 text-center`} variant="labelSmall">
                {version} {Constants?.expoConfig?.extra?.APP_ENV === 'development' && '/ DEV'}
              </Text>
              <Text style={tw`mb-4 text-center`} variant="labelSmall">
                B-{!isWeb ? buildNumber : version}
              </Text>
            </View>
          </ScrollView>
        </View>
      </View>
    </>
  );
};

export default ProfileView;
