import { authVar, useProductDetailsStore, UseProductDetailsStore } from '@core';
import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import useUpdateBanditCash from '../useUpdateBanditCash/useUpdateBanditCash';

const selector = ({ orderId, purchaseState, setPurchseState, setModalState, setOrderId }: UseProductDetailsStore) => ({
  orderId,
  purchaseState,
  setPurchseState,
  setModalState,
  setOrderId,
});

export const usePurchaseState = () => {
  const { orderId, purchaseState } = useProductDetailsStore(selector, shallow);

  return { purchaseState, orderId };
};

export const usePurchaseActions = () => {
  const { updateBanditCash } = useUpdateBanditCash();
  const navigation = useNavigation();
  const auth = authVar();
  const { setPurchseState, setModalState, setOrderId } = useProductDetailsStore(selector, shallow);
  const canPurchase = useCallback(() => {
    if (!auth.loggedIn) {
      // @ts-expect-error navigation type
      navigation.navigate('LoginModalScreen');
      return false;
    }
    updateBanditCash();
    return true;
  }, [auth.loggedIn, navigation, updateBanditCash]);

  const handlePressSoloBuy = () => {
    if (!canPurchase()) return;
    setPurchseState('soloBuy');
    setModalState('purchase');
  };

  const handlePressGroupBuy = () => {
    if (!canPurchase()) return;
    setPurchseState('groupBuy');
    setModalState('purchase');
  };

  const handleChangeToGroupBuy = () => {
    if (!canPurchase()) return;
    setPurchseState('groupBuy');
  };

  const handleJoinGroup = (id: string) => {
    if (!canPurchase()) return;
    setOrderId(id);
    setPurchseState('joinGroup');
    setModalState('purchase');
  };

  return { handlePressSoloBuy, handlePressGroupBuy, handleChangeToGroupBuy, handleJoinGroup };
};
