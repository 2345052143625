import { behavior } from '@core';
import { ReactNode } from 'react';
import { KeyboardAvoidingView as KeyboardView } from 'react-native';

interface IKeyboardAvoidView {
  children: ReactNode;
}

export const KeyboardAvoidView = ({ children }: IKeyboardAvoidView) => {
  return (
    <KeyboardView behavior={behavior} style={{ flex: 1 }} testID="KEYBOARD">
      {children}
    </KeyboardView>
  );
};

export default KeyboardAvoidView;
