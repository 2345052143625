import * as Random from 'expo-crypto';
import { useMemo } from 'react';
import { RANDOM_COLORS_LIST } from '../theme';

const randomizeColor = () => {
  const randomFloat = Random.getRandomBytes(1)[0] / Math.pow(2, 8);

  const index = Math.floor(randomFloat * RANDOM_COLORS_LIST.length);

  return RANDOM_COLORS_LIST[index];
};

export default randomizeColor;

export const useRandomColor = () => {
  const randomColor = useMemo(() => randomizeColor(), []);

  return randomColor;
};
