import React, { useState } from 'react';
import { Image, ImageProps, StyleSheet, View, ViewStyle } from 'react-native';

interface Props extends ImageProps {
  colors?: string[];
  angle?: number;
  containerStyle?: ViewStyle;
}
const GradientPlaceHolderImage: React.FC<Props> = (props) => {
  const { colors, angle = 15, containerStyle, ...imageProps } = props;

  const [height, setHeight] = useState(0);

  const [width, setWidth] = useState(0);

  const imageDimensions = { width, height };

  return (
    <View style={containerStyle}>
      <Image
        {...imageProps}
        {...imageDimensions}
        style={[imageDimensions, imageProps.style]}
        onLayout={(e) => {
          setHeight(e.nativeEvent.layout.height);
          setWidth(e.nativeEvent.layout.width);
        }}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
});
export default GradientPlaceHolderImage;
