import { ReactNode, useEffect, useState } from 'react';
import { Dimensions, Image } from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AnimatedSplash from 'react-native-animated-splash-screen';
import useInitialize from './hooks/useInitialize';

interface AnimatedSplashScreenProps {
  children: ReactNode;
  image: {
    uri: string | undefined;
  };
}

const { width, height } = Dimensions.get('window');

export function AnimatedSplashScreen({ children, image }: AnimatedSplashScreenProps) {
  useInitialize();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setLoaded(true);
    }, 300);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <AnimatedSplash
      translucent={true}
      logoImage={image}
      isLoaded={loaded}
      backgroundColor={'#FFE757'}
      customComponent={<Image resizeMode="contain" style={{ width: width, height: height }} source={image} />}
    >
      {children}
    </AnimatedSplash>
  );
}

export default AnimatedSplashScreen;
