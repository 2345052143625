/* eslint-disable complexity */
import { BackHeader, Button, Loading } from '@components';
import { i18n, useBanditTheme } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CreateProfileScreenNavigationProps } from '@routes';
import { Plus, Trash } from '@svgIcons';
import { Image, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';
import { useCreateProfileHook } from './hooks';

const CreateProfileScreen = () => {
  const navigation = useNavigation<CreateProfileScreenNavigationProps>();

  const { pickImage, onCreateProfile, imageBtnDisable, clearImage, image, loading, isEditMode } =
    useCreateProfileHook();

  const { colors } = useBanditTheme();

  const goBack = () => {
    if (isEditMode) {
      navigation.goBack();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate('Main', { screen: 'VideoScreen' });
    }
  };

  return (
    <SafeAreaView style={tw`flex flex-1 bg-white`}>
      <BackHeader goBack={goBack} title={isEditMode ? i18n.t('editUser_title') : i18n.t('createProfile_title')} />

      <View
        style={[
          tw`w-full items-center relative flex-1`,
          {
            backgroundColor: colors.secondary_cosmos_tint,
          },
        ]}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Text variant="titleMedium" style={tw`mb-4 mt-32`} testID="TEXT.PIC">
              {isEditMode ? i18n.t('editProfile_description') : i18n.t('createProfile_description')}
            </Text>
            <View>
              <TouchableOpacity onPress={pickImage} testID="BUTTON.IMAGE">
                <View style={styles.imageContainer}>
                  {image ? (
                    <Image source={{ uri: image }} style={{ width: 160, height: 160, resizeMode: 'cover' }} />
                  ) : (
                    <Plus style={tw`absolute top-16 left-16 w-8 h-8`} />
                  )}
                </View>
              </TouchableOpacity>
              {image && (
                <TouchableOpacity
                  onPress={clearImage}
                  testID="BUTTON.TRASH"
                  style={tw`border bg-white rounded-full border-gray-900 absolute bottom-0 right-0 p-2`}
                >
                  <Trash style={tw`w-6 h-6`} />
                </TouchableOpacity>
              )}
            </View>
          </>
        )}
      </View>
      <View style={[tw`absolute bottom-0 w-full p-4 flex flex-row border-t justify-between border-gray-900 bg-white`]}>
        <TouchableOpacity
          style={tw`justify-center m-4 border-b border-black`}
          onPress={() => onCreateProfile(true)}
          testID="BUTTON.SKIP"
        >
          <Text variant="labelLarge" style={tw`text-center`}>
            {isEditMode ? i18n.t('common_cancel') : i18n.t('common_skip')}
          </Text>
        </TouchableOpacity>

        <Button
          textVariant="labelLarge"
          disabled={imageBtnDisable}
          title={isEditMode ? i18n.t('common_update') : i18n.t('common_next')}
          customTextStyle={tw`mx-2`}
          customStyle={tw`w-30 p-4`}
          onPress={() => onCreateProfile(false)}
          testID="BUTTON.NEXT"
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    height: 160,
    width: 160,
    borderRadius: 80,
    borderWidth: 1,
    borderColor: '#000',
    position: 'relative',
    overflow: 'hidden',
    marginTop: 24,
  },
});
export default CreateProfileScreen;
