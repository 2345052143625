/* eslint-disable complexity */
import { useLazyQuery } from '@apollo/client';
import { authVar, i18n, OauthType, storeUserId, useLogout, UserRoles, useUserStore } from '@core';
import { useNavigation } from '@react-navigation/native';
import { CreateUserScreenNavigationProps } from '@routes';
import { TokenResponse } from 'expo-auth-session';
import { ERROR_MESSAGES } from 'src/core/messages';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { useShowToast } from 'src/hooks/useShowToast';
import { IUsers } from '../../model';
import { GET_USERS } from '../../query';

const useLoginHook = () => {
  const navigation = useNavigation<CreateUserScreenNavigationProps>();

  const { logout } = useLogout();
  const store = useUserStore();

  const toast = useShowToast();

  const [getUsers] = useLazyQuery<IUsers>(GET_USERS);

  const loginUser = (oauthType: OauthType, tokenResponse: TokenResponse) => {
    const { idToken, refreshToken, accessToken: token } = tokenResponse;
    getUsers({
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      onCompleted: (data) => {
        if (data.users.length && data.users[0].role.name !== UserRoles.CONSUMER) {
          logout();
          return;
        }

        if (data.users.length) {
          const user = data.users[0];
          storeUserId(data.users[0].id);
          store.setUser({
            phoneNumber: user.phoneNumber ?? '',
            photoUrl: user.photoUrl ?? '',
            address: user.address ?? '',
            age: user.age ?? 0,
            city: user.city ?? '',
            email: user.email ?? '',
            firstName: user.firstName ?? '',
            id: user.id ?? '',
            interests: user.interests ?? [],
            lastName: user.lastName ?? '',
            zipCode: user.zipCode ?? '',
          });

          authVar({
            token: token,
            loggedIn: true,
            idpToken: idToken || null,
            loginType: oauthType,
            refreshToken: refreshToken || null,
          });

          navigation.goBack();
        } else {
          if (idToken) {
            authVar({
              token: token,
              loggedIn: false,
              idpToken: idToken || null,
              loginType: oauthType,
              refreshToken: refreshToken || null,
            });
            navigation?.navigate('CreateUserScreen', { idpToken: idToken });
          } else {
            toast.info(i18n.t(ERROR_MESSAGES.token_error));
            sendErrorToSentry(i18n.t(ERROR_MESSAGES.token_error), {
              tags: {
                Screen: 'useLoginHook',
              },
            });
          }
        }
      },
      onError: (error) => {
        toast.info(i18n.t(ERROR_MESSAGES.token_error));
        sendErrorToSentry(error.message, {
          tags: {
            Screen: 'useLoginHook',
          },
        });
      },
    });
  };
  return { loginUser };
};
export default useLoginHook;
