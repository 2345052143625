import { useBanditTheme } from '@core';
import { CustomBottomSheetModal } from 'src/components/CustomBottomSheetModal/CustomBottomSheetModal';
import { Order } from 'src/domain/order/types';
import Receipt from './Receipt/Receipt';

const ReceiptModal = ({
  order,
  handleCloseModal,
  isVisible,
}: {
  order: Order;
  handleCloseModal: () => void;
  isVisible: boolean;
}) => {
  const { colors } = useBanditTheme();

  return (
    <CustomBottomSheetModal
      backgroundColor={colors.primary}
      closeModal={handleCloseModal}
      isVisible={isVisible}
      snapPoints={['90%']}
    >
      <Receipt order={order} />
    </CustomBottomSheetModal>
  );
};

export default ReceiptModal;
