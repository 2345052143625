import { ExpandableDescription } from '@components';
import { i18n, IImage } from '@core';
import { FlatList, Image, Pressable, View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

const IMAGE_SIZE = { width: 53, height: 30 };

interface IAboutSellerProps {
  images: IImage[];
  description: string;
  onShowFullScreenImage: (images: IImage[], index: number) => void;
}

const AboutSeller = ({ images, description, onShowFullScreenImage }: IAboutSellerProps) => {
  const renderItem = ({ item: { name, url }, index }: { item: IImage; index: number }) => (
    <Pressable
      key={name}
      style={tw.style('pr-5', index === 0 && 'pl-4')}
      onPress={() => onShowFullScreenImage(images, index)}
    >
      <Image
        testID="seller-image"
        accessibilityRole="image"
        style={tw`border-2 rounded-xl w-${IMAGE_SIZE.width} h-${IMAGE_SIZE.height}`}
        source={{ uri: url }}
        resizeMode="cover"
      />
    </Pressable>
  );

  return (
    <>
      <Text style={[tw`ml-4 text-sm font-medium mb-4`, { fontFamily: 'TheFuture-Medium' }]}>
        {i18n.t('product_detail_about_the_seller')}
      </Text>

      {!!images?.length && (
        <FlatList
          data={images}
          renderItem={renderItem}
          style={tw`mb-4`}
          showsHorizontalScrollIndicator={false}
          horizontal
        />
      )}

      <View style={tw`mx-4`}>
        <ExpandableDescription description={description} />
      </View>
    </>
  );
};

export default AboutSeller;
