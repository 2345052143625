/* eslint-disable complexity */
import { generateInviteGroupLink, generateProductLink, i18n, useUserStore } from '@core';
import * as Clipboard from 'expo-clipboard';
import { Platform, Share, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { UseUserStore } from 'src/core/store/user/useUserStore';
import { sendErrorToSentry } from 'src/core/telemetry/sendErrorToSentry';
import { tw } from 'src/core/tw';
import { useShowToast } from 'src/hooks/useShowToast';
import { shallow } from 'zustand/shallow';

const selector = ({ user }: UseUserStore) => ({ user });

interface IShareButtonWrapperProps {
  productId: string;
  children: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  groupId?: string;
  disabled?: boolean;
  groupShareText?: string;
  productShareText?: string;
}

const ShareButtonWrapper = (props: IShareButtonWrapperProps) => {
  const { children, productId, style, groupId, disabled = false, groupShareText, productShareText } = props;

  const toast = useShowToast();

  const { user } = useUserStore(selector, shallow);

  const handleShare = async () => {
    const canInvite = !!groupId && !!user?.id;

    const productUrl = canInvite
      ? await generateInviteGroupLink({ productId, groupId, inviteUserId: user.id as string })
      : await generateProductLink(productId);

    if (Platform.OS === 'web') {
      await Clipboard.setStringAsync(productUrl);
      alert(`The following referral link was copied to your clipboard.\n\n${productUrl}`);
    } else {
      try {
        await Share.share({
          message: `${productShareText || groupShareText} ${productUrl}`,
          url: productUrl,
          title: i18n.t('product_share_title'),
        });
      } catch (error: any) {
        sendErrorToSentry(error, {
          tags: {
            screen: 'ShareButtonWrapper',
            function: 'handleShare',
          },
        });
        toast.danger(error.message);
      }
    }
  };

  return (
    <TouchableOpacity
      testID="share-button"
      accessibilityRole="button"
      style={[tw`opacity-${disabled ? 50 : 100}`, style]}
      disabled={disabled}
      onPress={handleShare}
    >
      {children}
    </TouchableOpacity>
  );
};

export default ShareButtonWrapper;
