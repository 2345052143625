import { ApolloError, useLazyQuery } from '@apollo/client';
import { i18n, IGetProductDetailsPayload, IGetProductDetailsResponse } from '@core';
import { useEffect } from 'react';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_PRODUCT_IN_INVITE } from '../../query';

interface IUseGetProductFromInviteProps {
  productId: string;
}

const useGetProductFromInvite = ({ productId }: IUseGetProductFromInviteProps) => {
  const toast = useShowToast();
  const [getProductDetails, { data, loading, refetch }] = useLazyQuery<
    IGetProductDetailsResponse,
    IGetProductDetailsPayload
  >(GET_PRODUCT_IN_INVITE, {
    variables: { productId },
    fetchPolicy: 'cache-and-network',
    onError: (error: ApolloError) => toast.danger(error.message || i18n.t(ERROR_MESSAGES.default)),
  });

  useEffect(() => {
    if (!!productId) {
      getProductDetails();
    }
  }, [productId]);

  return {
    product: data?.productTypes?.[0],
    loading,
    refetch,
  };
};

export default useGetProductFromInvite;
