import { ApolloError, useQuery } from '@apollo/client';
import {
  convertProductDetailResponse,
  i18n,
  IGetProductDetailsPayload,
  IGetProductDetailsResponse,
  ProductDetails,
} from '@core';
import { useMemo } from 'react';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { GET_PRODUCT_DETAILS } from '../../query';
import useCheckInventory from '../useCheckInventory/useCheckInventory';

interface IUseProductDetailsProps {
  productId: string;
}

const useProductDetails = ({ productId }: IUseProductDetailsProps) => {
  const toast = useShowToast();

  const { checkInventory } = useCheckInventory({ productId });

  const { data, refetch } = useQuery<IGetProductDetailsResponse, IGetProductDetailsPayload>(GET_PRODUCT_DETAILS, {
    variables: { productId },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ productTypes }) => {
      if (productTypes?.length) {
        checkInventory();
      }
    },
    onError: (error: ApolloError) => toast.danger(error?.message || i18n.t(ERROR_MESSAGES.default)),
  });

  const product = useMemo<ProductDetails | undefined>(() => {
    if (!data?.productTypes?.length) return undefined;
    return {
      ...convertProductDetailResponse(data?.productTypes[0]),
      groupShareText: i18n.t('group_share_msg', {
        brand: data?.productTypes[0].brandName ?? data?.productTypes[0].producer.organizationName,
        productName: data?.productTypes[0].name,
        discount: (data?.productTypes[0].soloPrice - data?.productTypes[0].groupPrice).toFixed(2),
        joinPrice: data?.productTypes[0].groupPrice.toFixed(2),
        soloPrice: data?.productTypes[0].soloPrice.toFixed(2),
        timeRemaining: '',
        membersStillNeeded: data?.productTypes[0].groupSize,
      }),
    };
  }, [data]);

  return {
    product,
    refetch,
  };
};

export default useProductDetails;
