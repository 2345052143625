import { useState } from 'react';
import { OrderItem } from '../../model';
import useGetOrdersHook from '../useGetOrdersHook/useGetOrdersHook';

const useOrderIssuesScreen = () => {
  const { orders, loading } = useGetOrdersHook();

  const [selectedOrderId, setSelectedOrderId] = useState('');

  const handleOrderSelected = (order: OrderItem) => {
    setSelectedOrderId(order?.orderId || '');
  };

  return {
    fetchingOrders: loading,
    orders,
    handleOrderSelected,
    selectedOrderId,
  };
};

export default useOrderIssuesScreen;
