import { gql } from '@apollo/client';

export const GET_DEALS_BY_CATEGORY = gql`
  query GetDeals($where: ProductTypeGeneralFeedBoolExp, $offset: Int, $limit: Int) {
    productTypeGeneralFeed(where: $where, offset: $offset, limit: $limit) {
      activeOrders
      categoryName
      id
      name
      soloPrice
      groupPrice
      currency
      banditCashDiscount
      groupSize
      activeOrders
      successOrders
      imageUrls {
        name
        carouselUrl
        originalUrl
        splitUrl
        feedUrl
      }
      producer {
        organizationName
        logoUrl
      }
      productType {
        brandName
      }
      activeGroupOrders {
        id
        numberOfMembers
        status
        createdAt
        expiredDate
      }
    }
  }
`;

export const GET_DEALS_BY_CATEGORY_USER = gql`
  query GetDeals($where: ProductTypeUserFeedBoolExp, $offset: Int, $limit: Int) {
    productTypeUserFeed(where: $where, offset: $offset, limit: $limit) {
      activeOrders
      categoryName
      description
      id
      name
      soloPrice
      groupPrice
      currency
      banditCashDiscount
      groupSize
      activeOrders
      successOrders
      imageUrls {
        name
        carouselUrl
        originalUrl
        splitUrl
        feedUrl
      }
      producer {
        organizationName
        logoUrl
      }
      productType {
        brandName
      }
      activeGroupOrders {
        id
        numberOfMembers
        status
        createdAt
        expiredDate
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      id
      name
    }
  }
`;

export const GET_POPULAR_DEALS_BY_CATEGORY = gql`
  query GetUserProducts {
    productTypeUserPopularFeed {
      activeOrders
      categoryName
      description
      id
      name
      shortDescription
      soloPrice
      groupPrice
      currency
      banditCashDiscount
      imageUrls {
        feedUrl
      }
      producer {
        organizationName
        logoUrl
      }
    }
  }
`;
