import Constants from 'expo-constants';

const fetchVippsToken = async (token: string) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const APP_ENV = Constants.expoConfig?.extra?.APP_ENV;

  const url =
    APP_ENV === 'development'
      ? 'https://apitest.vipps.no/vipps-userinfo-api/userinfo'
      : 'https://api.vipps.no/vipps-userinfo-api/userinfo';

  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
    mode: 'cors',
  });

  return response.json();
};
export default fetchVippsToken;
