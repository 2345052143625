import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

interface IOrderInfoItemProps {
  label: string;
  value: string;
}

const OrderInfoItem = ({ label, value }: IOrderInfoItemProps) => {
  return (
    <View style={tw`flex-row items-center justify-between`}>
      <Text style={[tw`text-sm flex-shrink`, { fontFamily: 'TheFuture-Regular' }]}>{label}</Text>
      <Text testID={label} style={[tw`text-sm`, { fontFamily: 'TheFuture-Regular' }]}>
        {value}
      </Text>
    </View>
  );
};

export default OrderInfoItem;
