import { Loading } from '@components';
import { i18n } from '@core';
import { useNavigation } from '@react-navigation/native';
import { GroupMemberListScreenNavigationProps } from '@routes';
import { View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { tw } from 'src/core/tw';
import { GroupMembersCard } from '../ProductDetailScreen/components';
import { useGroupMemberListHook } from './hooks';

const GroupInfoScreen = () => {
  const navigation = useNavigation<GroupMemberListScreenNavigationProps>();

  const { data, loading } = useGroupMemberListHook();

  const handleGoBack = () => {
    navigation.goBack();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <SafeAreaView style={tw`flex-1 bg-[#F3CFF3]`}>
      <View style={tw`flex-1 bg-[#F3CFF3] pt-7 px-5`}>
        <View style={tw`relative flex-row items-center justify-center mb-8`}>
          <IconButton
            testID="back-button"
            accessibilityRole="button"
            icon="arrow-left"
            style={tw`border-2 border-black m-0 absolute left-0`}
            onPress={handleGoBack}
            size={18}
            accessibilityLabelledBy={undefined}
            accessibilityLanguage={undefined}
          />
          <Text style={[tw`text-sm text-center`, { fontFamily: 'TheFuture-Medium' }]}>
            {i18n.t('group_info_your_group')}
          </Text>
        </View>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {!!data?.members.length && <GroupMembersCard {...data} groupSize={10} canViewGroupInfo={false} />}
      </View>
    </SafeAreaView>
  );
};

export default GroupInfoScreen;
