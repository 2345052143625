/* eslint-disable complexity */
import { useBanditTheme } from '@core';
import { FieldError } from 'react-hook-form';
import { Platform, TextInput, View } from 'react-native';
import { FloatingLabelInput, FloatingLabelProps } from 'react-native-floating-label-input';
import { Text } from 'react-native-paper';
import { tw } from 'src/core/tw';

type FloatingInputProps = {
  error?: FieldError;
} & FloatingLabelProps;

const labelStyle = { fontFamily: 'TheFuture-Regular', fontSize: 12 };
const inputStyle = { fontFamily: 'TheFuture-Regular', fontSize: 14 };

const FloatingInput = (props: FloatingInputProps) => {
  const { colors } = useBanditTheme();

  return Platform.OS === 'web' ? (
    <View style={[tw`px-4 py-2`, props.style, props.containerStyles]}>
      <View style={tw`flex-row justify-between`}>
        <Text style={[labelStyle, { color: !!props.error ? colors.secondary_ember : colors.black }]}>
          {props.label}
        </Text>
        {!!props.error && (
          <Text style={[tw`text-[${colors.secondary_ember}] text-xs capitalize`, labelStyle]} variant="labelSmall">
            {props.error.message}
          </Text>
        )}
      </View>
      <TextInput {...props} placeholderTextColor={colors.gray_400} style={inputStyle} />
    </View>
  ) : (
    <>
      <FloatingLabelInput
        testID="FLOATING_INPUT"
        customLabelStyles={{
          colorFocused: Boolean(props.error) ? colors.secondary_ember : colors.black,
        }}
        labelStyles={{
          ...labelStyle,
          paddingTop: 8,
        }}
        {...props}
        inputStyles={{ ...inputStyle, paddingTop: 16, paddingBottom: 2, color: colors.black }}
        containerStyles={{ ...tw`px-4 py-2 relative`, ...props.containerStyles }}
      />
      {props.error && (
        <View style={tw`absolute right-4 top-1.5 justify-center`}>
          <Text style={[tw`text-[${colors.secondary_ember}] text-xs capitalize`, labelStyle]} variant="labelSmall">
            {props.error.message}
          </Text>
        </View>
      )}
    </>
  );
};

export default FloatingInput;
