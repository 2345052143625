import { makeVar, useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useUserStore } from '../store';
import { OauthType } from './oauth';
import { deleteAllUserInfo, getIsShowTutorial, getUserInfo } from './storage';

interface ICheckAuth {
  token: string | null;
  loggedIn: boolean;
  isShowTutorial?: boolean;
  idpToken: string | null;
  loginType: OauthType | null;
  refreshToken: string | null;
}

export enum UserRoles {
  CONSUMER = 'Consumer',
  ADMIN = 'Admin',
  PRODUCER = 'Producer',
}

export const authVar = makeVar<ICheckAuth>({
  token: null,
  loggedIn: false,
  isShowTutorial: false,
  idpToken: null,
  loginType: null,
  refreshToken: null,
});

export const initializeUserLogin = async () => {
  const { accessToken, loginType, idToken, refreshToken } = await getUserInfo();

  const showTutorial = await getIsShowTutorial();
  const isShowTutorial = !showTutorial;

  authVar({
    token: accessToken,
    loggedIn: !!accessToken,
    idpToken: idToken,
    isShowTutorial,
    loginType: loginType as OauthType,
    refreshToken: refreshToken,
  });
};

export const logout = async () => {
  await deleteAllUserInfo();

  authVar({
    token: null,
    loggedIn: false,
    idpToken: null,
    loginType: null,
    refreshToken: null,
  });
};

export function useLogout({ onCompleted }: { onCompleted?: () => void } = {}) {
  const store = useUserStore();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const logoutHook = useCallback(async () => {
    try {
      setLoading(true);
      await logout();
      store.resetAllState();
      await client.clearStore();
      onCompleted?.();
    } finally {
      setLoading(false);
    }
  }, [client, onCompleted, store]);

  return { logout: logoutHook, loading };
}
