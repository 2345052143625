import { useMutation } from '@apollo/client';
import { i18n, useUserStore } from '@core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { CreateProfileScreenNavigationProps, CreateProfileScreenRouteProps } from '@routes';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { ERROR_MESSAGES } from 'src/core/messages';
import { useShowToast } from 'src/hooks/useShowToast';
import { IUserUpdatePayload, IUserUpdateResponse } from '../../model';
import { UPDATE_USER } from '../../query';

const useCreateProfileHook = () => {
  const navigation = useNavigation<CreateProfileScreenNavigationProps>();

  const route = useRoute<CreateProfileScreenRouteProps>();

  const [image, setImage] = useState<string | null>();

  const [imageBtnDisable, setImageBtnDisable] = useState(true);

  const [userUpdate] = useMutation<IUserUpdateResponse, IUserUpdatePayload>(UPDATE_USER);

  const [loading, setLoading] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  const userStore = useUserStore();

  const toast = useShowToast();

  useEffect(() => {
    if (route.params?.isEdit) {
      const isEdit = route.params?.isEdit;
      if (isEdit) {
        setIsEditMode(true);

        const user = userStore.user;
        setImage(user?.photoUrl);
      }
    }
  }, [route]);

  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status === 'granted') {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setImage(result.assets[0].uri);
        setImageBtnDisable(false);
      }
    }
  };

  const onCreateProfile = async (isSkip: boolean) => {
    if (isSkip) {
      isEditMode ? navigation.goBack() : navigation.navigate('UserInterestedScreen');
    } else if (image) {
      setLoading(true);
      setImageBtnDisable(true);

      const base64 =
        Platform.OS !== 'web'
          ? await FileSystem.readAsStringAsync(image, { encoding: 'base64' })
          : image.toString().replace(/^data:image\/(png|jpg|webp|jpeg);base64,/, '');
      userUpdate({
        variables: {
          request: {
            base64Photo: base64,
          },
        },
        onCompleted: (data: IUserUpdateResponse) => {
          setLoading(false);
          if (data.userUpdate) {
            isEditMode
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                navigation.navigate('Main', { screen: 'ProfileScreen' })
              : navigation.navigate('UserInterestedScreen');

            userStore.setPhotoUrl(data.userUpdate.photoUrl!);
            setImageBtnDisable(false);
          }
        },
        onError: (error) => {
          setLoading(false);
          setImageBtnDisable(false);
          toast.danger(i18n.t(ERROR_MESSAGES.error_create_profile));
        },
      });
    }
  };

  const clearImage = () => {
    setImage(undefined);
    setImageBtnDisable(true);
  };

  return { pickImage, onCreateProfile, clearImage, image, loading, isEditMode, imageBtnDisable };
};

export default useCreateProfileHook;
